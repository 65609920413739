import React, { Fragment, useState } from 'react';
import { AccordionDetails } from '@material-ui/core';
import { KeyboardArrowUp, Cancel, CheckCircleOutline } from '@material-ui/icons';
import { DateTime } from 'luxon';
import Image from 'next/image';
import { Row } from './Row';
import { PlacesWrapper, AccordionSummary, Title, BoxWrapper, PlaceAddressContainer, StyledAccordion } from './styled';
import { Place } from './Place';
import { License as TypeLIcense } from '@http/models/api/company';
import { Nullable } from 'types';
import { MarginWrapper } from '@theme/shared/wrappers';

interface Props {
  license: TypeLIcense;
}

const createLicenceRpnLink = (id: number) => `https://license.rpn.gov.ru/rpn/license-registry/${id}/profile`;

export const License: React.FC<Props> = ({ license }) => {
  const { number, registrator, isActive, places, dated, order } = license;
  const formatDated = DateTime.fromISO(dated).toLocaleString(DateTime.DATE_SHORT);
  const [activePlaceIndex, setAcivePlaceIndex] = useState<Nullable<number>>(0);

  return (
    <>
      <Row title="Номер лицензии">{number}</Row>
      <Row title="Выдана">{registrator}</Row>
      <Row title="Приказ лицензирующего органа о предоставлении лицензии">
        <>
          {order} от {formatDated}
          <br />
          <BoxWrapper>
            {isActive ? (
              <>
                <span style={{ marginRight: '0.5rem' }}>Действующая</span>
                <CheckCircleOutline color="primary" fontSize="small" />
              </>
            ) : (
              <>
                <span style={{ marginRight: '0.5rem' }}>Недействующая</span>
                <Cancel color="error" fontSize="small" />
              </>
            )}
          </BoxWrapper>
        </>
      </Row>
      {license.rpnId && (
        <Row
          title={
            <MarginWrapper display="flex" alignItems="center">
              <MarginWrapper marginRight="s">
                <Image width={30} height={30} src="/rpn-logo.png" alt="Росприрод надзор логотип" />
              </MarginWrapper>
              Лицензия на РПН
            </MarginWrapper>
          }
        >
          <a href={createLicenceRpnLink(license.rpnId)} target="_blank" rel="noopener noreferrer">
            Перейти
          </a>
        </Row>
      )}
      {places && (
        <>
          <Title>Места осуществления деятельности</Title>
          <PlacesWrapper>
            {places.map((place, index) => {
              const isActive = index === activePlaceIndex;

              const handleOpenCollapse = (_: any) => setAcivePlaceIndex(isActive ? null : index);

              return (
                <Fragment key={index}>
                  <StyledAccordion expanded={isActive} key={place.id} onChange={handleOpenCollapse}>
                    <AccordionSummary
                      aria-controls={`panel-content-${place.id}`}
                      id={`panel-header-${place.id}`}
                      expandIcon={<KeyboardArrowUp />}
                    >
                      <PlaceAddressContainer>{place.address}</PlaceAddressContainer>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      {isActive && <Place place={place} />}
                    </AccordionDetails>
                  </StyledAccordion>
                </Fragment>
              );
            })}
          </PlacesWrapper>
        </>
      )}
    </>
  );
};
