import styled from 'styled-components';
import TextField from '@lib/Components/fields/TextField';
import { BoxWrapper } from '@theme/shared/components';
import { Text } from '@theme/shared/text';
import { Button } from '@components/Button';

export const Container = styled(BoxWrapper)`
  margin-top: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      margin-top: ${({ theme }) => theme.spaces.m};
      padding: ${({ theme }) => theme.spaces.m};
  `}
`;

export const CompanyTextField = styled(TextField)`
  width: 100%;

  .MuiFilledInput-root {
    border-radius: 6px;
    padding-top: 0;
  }
`;

export const CardHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  display: flex;
  align-items: center;

  ${({ theme }) => theme.utils.media.moreThanMobile`
      margin-bottom: ${({ theme }) => theme.spaces.m};
  `};
`;

export const Title = styled(Text.Header3)`
  font-size: ${({ theme }) => theme.textSizes.header4.size};
  line-height: ${({ theme }) => theme.textSizes.header4.lineHeight};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      font-size: ${({ theme }) => theme.textSizes.header3.size};
      line-height: ${({ theme }) => theme.textSizes.header3.lineHeight};
  `};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spaces.xs};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      margin-top: ${({ theme }) => theme.spaces.m};
      width: 150px;
  `}
`;
