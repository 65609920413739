import { useMutation, useQuery } from 'react-query';
import { createAsk, deleteAsk, getAsks, getAskById, getCurrentAsk } from '@http/endpoints/ask';
import { CreatePoolRequest } from '@http/models/api/ask';

export const useAsks = (params?: { isPublished?: boolean; enabled?: boolean }) => {
  return useQuery(['asks', params?.isPublished], () => getAsks({ isPublished: params?.isPublished }), {
    staleTime: Infinity,
    enabled: params?.enabled ?? true,
    refetchOnWindowFocus: 'always',
  });
};

export const useCurrentAsk = (isEnabled: boolean) => {
  return useQuery(['current_ask'], () => getCurrentAsk(), {
    staleTime: Infinity,
    enabled: isEnabled,
  });
};

export const useAskById = (id: number) => {
  return useQuery(['asks', id], () => getAskById(id));
};

interface UseCreateAskParams {
  successHandler: () => void;
  errorHandler: () => void;
}

export const useCreateAsk = ({ successHandler, errorHandler }: UseCreateAskParams) =>
  useMutation<any, any, CreatePoolRequest>(createAsk, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useDeleteAsk = ({ successHandler, errorHandler }: UseCreateAskParams) =>
  useMutation<any, any, number>(deleteAsk, {
    onSuccess: successHandler,
    onError: errorHandler,
  });
