import styled from 'styled-components';

interface CanvasProps {
  width: number;
  height: number;
}

export const CropZone = styled.div`
  width: 257px;
  height: 117px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  overflow: hidden;
  position: relative;

  & .cropper-view-box {
    border-radius: 50%;
  }
`;

export const CropperContainer = styled.div`
  width: 278px;
  height: 117px;
  display: flex;
  gap: ${({ theme }) => theme.spaces.l};
  margin-bottom: 55px;
`;

export const CropPreviews = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.m};
`;

export const CroppedPreview = styled.div<CanvasProps>`
  border: 1px solid ${({ theme }) => theme.palette.grey.light};
  border-radius: 50%;
  object-fit: 'cover';
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
`;
