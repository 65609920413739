import styled from 'styled-components';

export const ListWrapper = styled.div`
  grid-gap: 20px;
  display: grid;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    grid-template-columns: repeat(2,1fr);
    grid-gap: 20px;
  `}
`;
