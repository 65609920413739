import { CompanyTabName } from '../types';
import { useCompanySales } from '@http/hooks/company';
import { CompanyFullAPI } from '@http/models/api/company';
import { useUser } from 'providers/user';
import { isNotNull } from '@shared/utils/is-not-null';

interface TabParams {
  name: CompanyTabName;
}

export const useCompanyTabs = (company: CompanyFullAPI) => {
  const user = useUser();
  const userCompany = user.getCompany();
  const { data: companySales } = useCompanySales(company.id);
  const isUserCanEdit = user.isAdmin() || (userCompany?.id === company.id && userCompany.isVerified);

  const tabsParams = [
    {
      name: CompanyTabName.Info,
    },
    (isUserCanEdit || companySales?.length) && {
      name: CompanyTabName.Sales,
    },
    company.licenses?.length && {
      name: CompanyTabName.Licences,
    },
    {
      name: CompanyTabName.Reviews,
    },
  ].filter(isNotNull) as TabParams[];

  return tabsParams;
};
