import React from 'react';
import { useTheme } from 'styled-components';
import { ExtractKeys } from '../../types';
import SmViewGridAddOutline from './icons/outline/sm-view-grid-add.svg';
import DocumentSearchOutline from './icons/outline/document-search.svg';
import InformationCircleOutline from './icons/outline/information-circle.svg';
import QuestionMarkCircleOutline from './icons/outline/question-mark-circle.svg';
import SpeakerPhoneOutline from './icons/outline/speakerphone.svg';
import MenuOutline from './icons/outline/menu.svg';
import ProfileOutline from './icons/outline/profile.svg';
import CheckOutline from './icons/outline/check.svg';
import UserAddOutline from './icons/outline/user-add.svg';
import LogoutOutline from './icons/outline/logout.svg';
import TrashOutline from './icons/outline/trash.svg';
import UserCircleFilled from './icons/filled/user-cirlce.svg';
import LocationMarkerFilled from './icons/filled/location-marker.svg';
import BanFilled from './icons/filled/ban.svg';
import CloseOutline from './icons/outline/close.svg';
import ExclamationCircleOutline from './icons/outline/exclamation-circle.svg';
import CheckCircleOutline from './icons/outline/check-circle.svg';
import PencilFilled from './icons/filled/pencil.svg';
import CheveronRightOutline from './icons/outline/cheveron-right.svg';
import CheveronLeftOutline from './icons/outline/cheveron-left.svg';
import PlusSolid from './icons/solid/plus.svg';
import ExclamationSolid from './icons/solid/exclamation.svg';
import Fingure from './icons/solid/fingure.svg';
import ChatSolid from './icons/solid/chat.svg';
import CalendarOutline from './icons/outline/calendar.svg';
import PdfFiled from './icons/filled/pdf.svg';
import CreateOrderIcon from './icons/filled/create-order-icon.svg';
import Logo from './icons/filled/logo.svg';
import CheckSolid from './icons/solid/check.svg';
import ArrowRightSolid from './icons/solid/arrow-right.svg';
import PhoneSolid from './icons/solid/phone.svg';
import EmailOpenSolid from './icons/solid/email-open.svg';
import Contacts from './icons/outline/contacts.svg';
import DocumentOutline from './icons/outline/document.svg';
import SwitchVertical from './icons/outline/switch-vertical.svg';
import CreditCard from './icons/outline/credit-card.svg';
import GroupSolid from './icons/solid/group.svg';
import Robot from './icons/filled/robot.svg';
import LogoText from './icons/filled/logo-text.svg';
import LandfillOutline from './icons/outline/landfill.svg';
import PromFactoryOutline from './icons/outline/promFactory.svg';
import RecyclersOutline from './icons/outline/recyclers.svg';
import TransportOutline from './icons/outline/transport.svg';
import TelegramLogo from './icons/filled/telegram_logo.svg';
import ViewGrid from './icons/outline/view-grid.svg';
import LocationMarkerOutline from './icons/outline/location-marker.svg';
import PlusContol from './icons/outline/plus.svg';
import MinusContol from './icons/outline/minus.svg';
import CollectionType from './icons/filled/collection.svg';
import TransportType from './icons/filled/transport.svg';
import TreatmentType from './icons/filled/treatment.svg';
import DisposalType from './icons/filled/disposal.svg';
import NeutralizationType from './icons/filled/neutralization.svg';
import PlacementType from './icons/filled/placement.svg';
import LogoMono from './icons/filled/logo_monochrome.svg';
import RpnBannerBackground from './icons/filled/rpnBannerBackground.svg';
import ExcelDocument from './icons/solid/excel-document.svg';
import EditPencil from './icons/solid/edit-pencil.svg';
import EditPencilOutline from './icons/outline/edit-pencil.svg';
import XCircle from './icons/outline/x-circle.svg';
import CheckCircle from './icons/solid/check-circle.svg';
import XCircleSolid from './icons/solid/x-circle.svg';
import YouTube from './icons/filled/youtube.svg';
import CheckCircleMini from './icons/solid/check-circle-mini.svg';
import Upload from './icons/solid/upload.svg';
import SaveDisk from './icons/outline/save-disk.svg';
import Search from './icons/solid/search.svg';
import Edit from './icons/solid/edit.svg';
import TriangleForm from './icons/solid/triangleForm.svg';
import ChartSquareBar from './icons/outline/chart-square-bar.svg';
import Settings from './icons/outline/settings.svg';
import Briefcase from './icons/outline/briefcase.svg';
import DocumentText from './icons/outline/document-text.svg';
import Users from './icons/outline/users.svg';
import UserCircle from './icons/outline/user-circle.svg';
import CheveronDownOutline from './icons/outline/cheveron-down.svg';
import ArrowCircleLeft from './icons/outline/arrowCircleLeft.svg';
import MenuAlt from './icons/outline/menu-alt.svg';
import DownloadSolid from './icons/solid/download.solid.svg';
import TrashSolid from './icons/solid/trash.svg';
import Mail from './icons/solid/mail.svg';
import CameraInCircle from './icons/solid/camera-in-circle.svg';
import CollectionSolid from './icons/solid/collection.svg';
import ArrowNarrowSolid from './icons/solid/arrow-narrow-left.svg';
import Inbox from './icons/outline/inbox.svg';
import QuotesOutline from './icons/outline/quotes.svg';
import CogOutline from './icons/outline/cog.svg';
import ArrowsExpandOutline from './icons/outline/arrows-expand.svg';
import SparklesOutline from './icons/outline/sparkles.svg';
import ThumbIpOutline from './icons/outline/thumb-up.svg';
import PlusCircleFille from './icons/filled/plus-circle.svg';
import ClipboardSolid from './icons/solid/clipboard-copy.svg';
import LogoOffColors from './icons/solid/logo-off-colors.svg';
import DotsVertical from './icons/solid/dots-vertical.svg';
import ChipSolid from './icons/solid/chip.svg';
import PaperClip from './icons/outline/paper-clip.svg';
import { IconName } from '@components/Icon/types';
import { StyleTheme, ThemePalette } from '@theme/types';
import { deepPath } from '@shared/utils/helpers';
import { px } from '@theme/shared/wrappers';

const Map: { [key in IconName]: React.FC } = {
  'sm-view-grid-add.outline': SmViewGridAddOutline,
  'document-search.outline': DocumentSearchOutline,
  'information-circle.outline': InformationCircleOutline,
  'question-mark-circle.outline': QuestionMarkCircleOutline,
  'speakerphone.outline': SpeakerPhoneOutline,
  'menu.outline': MenuOutline,
  'logout.outline': LogoutOutline,
  'user-add.outline': UserAddOutline,
  'check.outline': CheckOutline,
  'check-circle.outline': CheckCircleOutline,
  'profile.outline': ProfileOutline,
  'user-circle.filled': UserCircleFilled,
  'trash.outline': TrashOutline,
  'location-marker.filled': LocationMarkerFilled,
  'x.outline': CloseOutline,
  'exclamation-circle.outline': ExclamationCircleOutline,
  'exclamation.solid': ExclamationSolid,
  'ban.filled': BanFilled,
  'pencil.filled': PencilFilled,
  'cheveron-right.outline': CheveronRightOutline,
  'cheveron-left.outline': CheveronLeftOutline,
  'plus.solid': PlusSolid,
  'calendar.outline': CalendarOutline,
  'chat.solid': ChatSolid,
  'pdf.filled': PdfFiled,
  'create-order-icon.filled': CreateOrderIcon,
  'logo.filled': Logo,
  'check.solid': CheckSolid,
  'contacts.outline': Contacts,
  'arrowRight.solid': ArrowRightSolid,
  'emailOpen.solid': EmailOpenSolid,
  'phone.solid': PhoneSolid,
  'document.outline': DocumentOutline,
  'switch-vertical.outline': SwitchVertical,
  'group.solid': GroupSolid,
  'robot.filled': Robot,
  'logo-text.filled': LogoText,
  'landfill.outline': LandfillOutline,
  'promFactory.outline': PromFactoryOutline,
  'recyclers.outline': RecyclersOutline,
  'transport.outline': TransportOutline,
  'view-grid.outline': ViewGrid,
  'location-marker.outline': LocationMarkerOutline,
  'plusControll.outline': PlusContol,
  'minusControll.outline': MinusContol,
  'collectionType.filled': CollectionType,
  'transportType.filled': TransportType,
  'treatmentType.filled': TreatmentType,
  'disposalType.filled': DisposalType,
  'neutralizationType.filled': NeutralizationType,
  'placementType.filled': PlacementType,
  'rpnBannerBackground.filled': RpnBannerBackground,
  'excelDocument.solid': ExcelDocument,
  'editPencil.solid': EditPencil,
  'checkCircle.solid': CheckCircle,
  'youtube.filled': YouTube,
  'check-circle-mini.solid': CheckCircleMini,
  'upload.solid': Upload,
  'save-disk.outline': SaveDisk,
  'search.solid': Search,
  'edit.solid': Edit,
  'chart-square-bar.outline': ChartSquareBar,
  'settings.outline': Settings,
  'briefcase.outline': Briefcase,
  'document-text.outline': DocumentText,
  'users.outline': Users,
  'user-circle.outline': UserCircle,
  'cheveron-down.outline': CheveronDownOutline,
  'telegramLogo.filled': TelegramLogo,
  'logoMono.filled': LogoMono,
  'triangleForm.solid': TriangleForm,
  'fingure.solid': Fingure,
  'arrowCircleLeft.outline': ArrowCircleLeft,
  'menu-alt.outline': MenuAlt,
  'download.solid': DownloadSolid,
  'mail.solid': Mail,
  'camera-in-circle.solid': CameraInCircle,
  'inbox.outline': Inbox,
  'editPencil.outline': EditPencilOutline,
  'quotes.outline': QuotesOutline,
  'cog.outline': CogOutline,
  'arrows-expand.outline': ArrowsExpandOutline,
  'plus-circle.filled': PlusCircleFille,
  'clipboard-copy.solid': ClipboardSolid,
  'collection.solid': CollectionSolid,
  'trash.solid': TrashSolid,
  'x-circle.outline': XCircle,
  'logo-off-colors.solid': LogoOffColors,
  'arrow-narrow-left.solid': ArrowNarrowSolid,
  'dots-vertical.solid': DotsVertical,
  'chip.solid': ChipSolid,
  'credit-card.outline': CreditCard,
  'xCircle.solid': XCircleSolid,
  'thumb-up.outline': ThumbIpOutline,
  'sparkles.outline': SparklesOutline,
  'paper-clip.outline': PaperClip,
};

type IconSize = 's' | 'm' | 'sm';

interface Props extends React.SVGProps<SVGSVGElement> {
  name: IconName;
  className?: string;
  size?: IconSize | number;
  color?: ExtractKeys<ThemePalette>;
}

const createStyles = (size: Props['size'], theme: StyleTheme, color?: Props['color']): React.CSSProperties => {
  const result: React.CSSProperties = {};

  if (color) {
    result.color = deepPath(theme.palette, color);
  }

  if (typeof size === 'number') {
    result.minWidth = px(size);
    result.minHeight = px(size);
    result.width = px(size);
    result.height = px(size);
  }

  if (size === 's') {
    result.width = '16px';
    result.height = '16px';
  }

  if (size === 'sm') {
    result.width = '20px';
    result.height = '20px';
  }

  if (size === 'm') {
    result.width = '24px';
    result.height = '24px';
  }

  return result;
};

export const Icon = ({ name, size, color, ...svgProps }: Props) => {
  const theme = useTheme();
  const Icon = Map[name] as any;
  return <Icon {...svgProps} {...createStyles(size, theme, color)} data-svg-icon={true} />;
};
