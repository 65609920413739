import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Fkko } from '../../../screens/Fkko/Fkko';
import { useModals } from '../index';
import { routes } from '../../../routes';
import { useFkko } from '@http/hooks/fkko';
import { Button } from '@components/Button';
import { OpenBlank } from '@components/OpenBlank';
import { MarginWrapper } from '@theme/shared/wrappers';

export const FkkoInlineComponent = ({ activeId }: { activeId: string }) => {
  const fkkoModal = useModals();
  const { data, isLoading } = useFkko(activeId);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data) return null;

  return (
    <>
      <MarginWrapper marginBottom="s">
        <OpenBlank url={routes.fkko(activeId)} />
      </MarginWrapper>
      <Fkko data={data} />
      <MarginWrapper marginTop="s">
        <Button onClick={() => fkkoModal.close('fkko')}>Закрыть окно</Button>
      </MarginWrapper>
    </>
  );
};
