import styled from 'styled-components';
import { Button as MuiButton, withStyles } from '@material-ui/core';
import { rgba } from 'polished';

export const Wrapper = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spaces.s};
  max-height: 300px;
  left: ${({ theme }) => theme.spaces.s};
  right: ${({ theme }) => theme.spaces.s};
  background: ${({ theme }) => rgba(theme.colors.medium, 0.99)};
  padding: ${({ theme }) => theme.spaces.s};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  color: #fff;
  font-size: 14px;
  z-index: 99;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    left: initial;
    max-width: 330px;
  `}
`;

export const Button = withStyles({
  root: {
    color: '#fff',
    width: '100%',
  },
  outlined: {
    borderColor: '#fff',
  },
})(MuiButton);

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
