import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NoteLink } from './styled';
import { continueParamsFactory, routes } from 'routes';
import { Note } from '@components/Note';

export const UnauthorizedNote: FC = () => {
  const { asPath } = useRouter();

  return (
    <Note>
      <span>
        Неавторизованные пользователи не могут оставлять отзывы,{` `}
        <Link passHref href={routes.loginQuery(continueParamsFactory(asPath))}>
          <NoteLink>авторизуйтесь</NoteLink>
        </Link>
        {` `}или{` `}
        <Link passHref href={routes.registrationQuery(continueParamsFactory(asPath))}>
          <NoteLink>зарегистрируйтесь</NoteLink>
        </Link>
        {` `}для получения всех возможностей.
      </span>
    </Note>
  );
};
