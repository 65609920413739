import styled from 'styled-components';
import React, { useEffect } from 'react';
import { LoaderTick, LoaderWrapper } from './styled';
import { useBoolean } from '@shared/hooks/use-boolean';
import { useThrottler } from '@shared/hooks/use-throttler';

type Size = 's' | 'm' | 'normal';

const getSize = (size?: Size): string => {
  if (!size || size === 'normal') {
    return '150px';
  }

  if (size === 'm') {
    return '90px';
  }

  return '64px';
};

export const PulseLoader = ({ size = 'normal', delayToShowMs }: { size?: Size; delayToShowMs?: number }) => {
  const sizeVal = getSize(size);
  const isShowBoolean = useBoolean();
  const throttler = useThrottler('loader');
  useEffect(() => {
    throttler.name('show').throttle(isShowBoolean.setTrue, { ms: delayToShowMs || 50 });
  }, [delayToShowMs]);
  return (
    <LoaderWrapper style={{ width: sizeVal, height: sizeVal }}>
      <LoaderTick />
      <LoaderTick />
    </LoaderWrapper>
  );
};

const PageLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const PageLoading = () => {
  const isShowBoolean = useBoolean();
  const throttler = useThrottler('loader');
  useEffect(() => {
    throttler.name('show').throttle(isShowBoolean.setTrue, { ms: 50 });
  }, []);

  return <PageLoadingWrapper>{isShowBoolean.value && <PulseLoader />}</PageLoadingWrapper>;
};
