import React from 'react';
import { useRouter } from 'next/router';
import { routes } from '../../routes';
import { useModals } from 'providers/modals';
import { ActiveModals } from 'providers/modals/types';

interface Props {
  value: string;
  customRender?: React.ReactNode | string;
}

export const FkkoLink = ({ value: rawValue, customRender }: Props) => {
  const value = rawValue?.replace(/\s/g, '') || '';
  const fkkoModal = useModals();
  const router = useRouter();

  const handleClick = (e: any) => {
    (e as Event).preventDefault();
    const payload: ActiveModals = {
      type: 'fkko',
      activeId: value,
    };

    if (fkkoModal.shouldOpenPage(payload)) {
      router.push(routes.fkko(value));
      return;
    }

    fkkoModal.open(payload);
  };

  return (
    <a href={routes.fkko(value)} onClick={handleClick} onKeyPress={handleClick}>
      {customRender ? customRender : rawValue}
    </a>
  );
};
