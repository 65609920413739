import { useOffer } from '../context';
import { CommentHint, CommentIcon } from '../../CreateOffer/compose/row/styled';
import { Table } from '@components/Table';
import { MarginWrapper } from '@theme/shared/wrappers';
import { BoxWrapper } from '@theme/shared/components';
import { typesMoveDictionary } from '@constants/company';
import { Icon } from '@components/Icon';
import { breaksHundreds, normalizeFloatDigits } from '@lib/Components/fields/input-value-helpers';
import { FkkoLink } from '@components/FkkoLink';
import { DEFAULT_MAX_FLOAT_DIGITS, PRICE_MAX_FLOAT_DIGITS } from '@constants/numbers';

export const TableData = () => {
  const offer = useOffer();
  return (
    <MarginWrapper marginTop="m">
      <BoxWrapper>
        <Table
          data={offer.tableData}
          columns={[
            {
              name: 'Код',
              render: model => <FkkoLink value={model.code} />,
            },
            {
              name: 'Вид работ',
              render: model => (
                <div style={{ whiteSpace: 'pre-line' }}>
                  {model.typeMove.map(t => typesMoveDictionary[t]).join('\n')}
                </div>
              ),
            },
            {
              name: 'Наименование',
              render: model => (
                <>
                  {model.title}
                  {model.comment && (
                    <CommentHint title={model.comment}>
                      <CommentIcon>
                        <Icon name="chat.solid" />
                      </CommentIcon>
                    </CommentHint>
                  )}
                </>
              ),
            },
            {
              name: 'Масса',
              render: model => <>{breaksHundreds(model.netto)} т</>,
            },
            {
              name: 'Cтоимость за ед.',
              render: model => (
                <>{breaksHundreds(normalizeFloatDigits(model.pricePerUnit, DEFAULT_MAX_FLOAT_DIGITS))} ₽</>
              ),
            },
            {
              name: 'Общая стоимость',
              render: model => <>{breaksHundreds(normalizeFloatDigits(model.price, PRICE_MAX_FLOAT_DIGITS))} ₽</>,
            },
          ]}
        />
      </BoxWrapper>
    </MarginWrapper>
  );
};
