import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Fragment, useMemo } from 'react';
import {
  TitleProp,
  Title,
  Paragraph,
  Raw,
  NumberPart,
  DescPart,
  TableTitle,
  ScrollableTable,
  Explanation,
  ExplanationChild,
} from './styled';
import { Cell } from './Components/Cell';
import { CellGroup } from './Components/CellGroup';
import { NextPageFC } from 'types';
import { WasteTypeViewModel } from '@http/models/view-models/wasteType';
import { FkkoLink } from '@components/FkkoLink';
import { FkkoUtil } from '@shared/fkkoUtil';

interface Props {
  data: WasteTypeViewModel;
}

export const Fkko: NextPageFC<Props> = ({ data }) => {
  const fkkoTools = useMemo(() => new FkkoUtil(data), [data]);
  // TODO: может стоит фильтровать по code и title не равным Null
  return (
    <>
      <Title>{data.title}</Title>
      <Paragraph>
        <TitleProp>Иерархия: </TitleProp>
        <span>{fkkoTools.getFkkoGroupByCode()}</span>
      </Paragraph>
      <Paragraph>
        <TitleProp>Расшифровка кода:</TitleProp>
      </Paragraph>
      <Raw marginBottom>{data.code && <CellGroup code={data.code} />}</Raw>
      {fkkoTools.excludeZeroPointsMapped().map(({ cellNumber, color, title, explanation }) => (
        <Fragment key={cellNumber}>
          <Raw>
            <NumberPart>
              <Cell cellNumber={cellNumber} color={color} />
            </NumberPart>
            <DescPart>
              <p>{title}</p>
            </DescPart>
          </Raw>
          <Raw marginBottom>
            <Explanation>
              {Array.isArray(explanation) ? (
                explanation.map((item, idx) => (
                  <ExplanationChild key={item.name} nthChild={idx}>
                    <FkkoLink
                      value={item.name}
                      customRender={
                        <>
                          <b>{item.name}</b> - {item.desc}
                        </>
                      }
                    />
                  </ExplanationChild>
                ))
              ) : (
                <>
                  <b>{explanation.name}</b> - {explanation.desc}
                </>
              )}
            </Explanation>
          </Raw>
        </Fragment>
      ))}
      {data.childs.length > 0 && (
        <>
          <TableTitle>Список дочерних кодов</TableTitle>
          <ScrollableTable>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Код</TableCell>
                  <TableCell component="th">Наименование</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  // TODO: может стоит фильтровать по code не равным Null
                  data.childs.map(item => (
                    <TableRow key={item.code}>
                      <TableCell scope="Raw">{item.code && <FkkoLink value={item.code} />}</TableCell>
                      <TableCell scope="Raw">
                        {item.code && <FkkoLink value={item.code} customRender={item.title} />}
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </ScrollableTable>
        </>
      )}
    </>
  );
};
