import { StyledButton } from './styled';
import { Icon } from '@components/Icon';

interface Props {
  onClick: () => void;
}

export const AddButton: React.FC<Props> = ({ onClick }) => {
  return (
    <StyledButton fullWidth variant="outlined" startIcon={<Icon name="plus.solid" />} onClick={onClick}>
      Добавить новую услугу
    </StyledButton>
  );
};
