import React from 'react';
import { Wrapper } from './styled';
import { Icon } from '@components/Icon';

interface Props {
  url: string;
}

export const OpenBlank = ({ url }: Props) => {
  const onOpenBlank = (e: any) => {
    (e as MouseEvent).preventDefault();
    window.open(url, '_blank');
  };
  return (
    <Wrapper href={url} onClick={onOpenBlank}>
      <Icon name="document.outline" />
      Открыть в новом окне
    </Wrapper>
  );
};
