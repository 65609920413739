// TODO:  перенести в orders.ts
import { useQuery, useMutation } from 'react-query';
import { addOfferFavorite, getOrderById } from '@http/endpoints/orders';
import { Nullable } from 'types';

interface useOrderByIdParams {
  orderId: number;
  authToken?: Nullable<string>;
}

export const useOrderById = ({ orderId, authToken = null }: useOrderByIdParams) =>
  useQuery(['orderGetById', orderId, authToken], () => getOrderById(orderId, authToken), { staleTime: Infinity });

interface UseSetOfferFavoriteParams {
  successHandler: () => void;
  errorHandler: () => void;
}

export const useSetOfferFavorite = ({ successHandler, errorHandler }: UseSetOfferFavoriteParams) =>
  useMutation(addOfferFavorite, {
    onSuccess: successHandler,
    onError: errorHandler,
  });
