import { FC, useRef } from 'react';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { ZoomControl } from '../ZoomControl';
import { CroppedPreview, CropperContainer, CropPreviews, CropZone } from './styled';
import { MarginWrapper } from '@theme/shared/wrappers';
import { Nullable } from 'types';
import { Text } from '@theme/shared/text';

export interface MiniatureCropperProps {
  image: string;
  cropperInstance: Nullable<Cropper>;
  onCropperInitialized: (instance: Cropper) => void;
}

const ZOOM_STEP = 0.1;

export const MiniatureCropper: FC<MiniatureCropperProps> = ({ image, cropperInstance, onCropperInitialized }) => {
  const miniaturePreviewRef = useRef(null);
  const miniatureMicroPreviewRef = useRef(null);

  const preview =
    miniaturePreviewRef.current && miniatureMicroPreviewRef.current
      ? [miniaturePreviewRef.current, miniatureMicroPreviewRef.current]
      : '.img-preview';

  const handleZoomClick = (cropper: Nullable<Cropper>, zoomValue: number) => {
    cropper?.zoom(zoomValue);
  };

  return (
    <MarginWrapper>
      <MarginWrapper marginBottom="s">
        <Text.Header4>Область миниатюры</Text.Header4>
      </MarginWrapper>
      <CropperContainer>
        <CropZone>
          <ZoomControl
            zoomToClick={() => handleZoomClick(cropperInstance, ZOOM_STEP)}
            zoomOutClick={() => handleZoomClick(cropperInstance, -ZOOM_STEP)}
          />

          <Cropper
            style={{ height: 117, width: '100%' }}
            initialAspectRatio={1}
            preview={preview}
            src={image}
            viewMode={1}
            aspectRatio={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={onCropperInitialized}
            guides={true}
          />
        </CropZone>
        <CropPreviews>
          <CroppedPreview ref={miniaturePreviewRef} className="img-preview" width={50} height={50} />
          <CroppedPreview ref={miniatureMicroPreviewRef} className="img-preview" width={40} height={40} />
        </CropPreviews>
      </CropperContainer>
    </MarginWrapper>
  );
};
