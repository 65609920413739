import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 18px;

  svg {
    margin-top: ${({ theme }) => theme.spaces.l};
  }

  button {
    margin-top: ${({ theme }) => theme.spaces.xl};
  }
`;

export const Title = styled(Text.Header1)`
  margin-top: ${({ theme }) => theme.spaces.xl};
`;

export const Description = styled(Text.SubHeader4)`
  margin-top: ${({ theme }) => theme.spaces.s};
`;
