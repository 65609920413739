import { useCallback, useEffect, useState } from 'react';
import useWindowSize from '@react-hook/window-size';

const selectorScrollableRoot = '[data-scrollable-gallery="true"]';

const getScrollableRootEl = () => {
  if (typeof window !== 'undefined') {
    return document.querySelector(selectorScrollableRoot);
  }
};

export const useGalleryRootScroll = (handler: (event: Event) => void) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const nodeEl = getScrollableRootEl();
      if (!nodeEl) {
        console.error('tableScrollerItem required');
      } else {
        const handle = (e: Event) => {
          handler(e);
        };

        nodeEl.addEventListener('scroll', handle);
        return () => {
          nodeEl.removeEventListener('scroll', handle);
        };
      }
    }
  }, [handler]);
};

export interface GalleryScrollParams {
  isLeftScrolled?: boolean;
  isRightScrolled?: boolean;
}

interface GalleryScrollReturnType {
  scrollParams: GalleryScrollParams;

  slideLeft(): void;

  slideRight(): void;
}

const getContainerScrollParams = (): GalleryScrollParams => {
  const scrollableNodeEl = getScrollableRootEl();
  const scrollableNodeChildren = scrollableNodeEl?.children;
  const leftChild = scrollableNodeChildren?.[0];
  const rightChild = scrollableNodeChildren?.[scrollableNodeChildren?.length - 1];

  if (!leftChild || !rightChild || !scrollableNodeEl) {
    return {};
  }

  return {
    isLeftScrolled: leftChild.getBoundingClientRect().left < scrollableNodeEl.getBoundingClientRect().left,
    isRightScrolled: rightChild.getBoundingClientRect().right > scrollableNodeEl.getBoundingClientRect().right,
  };
};

const SCROLL_STEP_PX = 5;
const SCROLL_FPS = 0;
const RECALCULATE_DELAY_MS = 30;

let recalculateTimeout: any;
let animateScrollInterval: any;

export const useGalleryScroll = (itemsQuantity?: number): GalleryScrollReturnType => {
  const [scrollParams, setScrollParams] = useState<GalleryScrollParams>(getContainerScrollParams());

  const windowSize = useWindowSize();

  const setParams = useCallback(() => {
    clearTimeout(recalculateTimeout);
    recalculateTimeout = setTimeout(() => {
      setScrollParams(getContainerScrollParams());
    }, RECALCULATE_DELAY_MS);
  }, []);

  useEffect(() => {
    setParams();
  }, [windowSize, itemsQuantity]);

  useGalleryRootScroll(setParams);

  const move = useCallback((type: 'left' | 'right') => {
    clearInterval(animateScrollInterval);
    const node = getScrollableRootEl();
    if (!node) {
      return;
    }
    let currentStep: number = 100;
    animateScrollInterval = setInterval(() => {
      if (type === 'left') {
        node.scrollLeft -= SCROLL_STEP_PX;
      }
      if (type === 'right') {
        node.scrollLeft += SCROLL_STEP_PX;
      }

      --currentStep;
      if (currentStep === 0) {
        clearInterval(animateScrollInterval);
      }
    }, SCROLL_FPS);
  }, []);

  const slideLeft = useCallback(() => {
    move('left');
  }, [move]);

  const slideRight = useCallback(() => {
    move('right');
  }, [move]);

  return {
    scrollParams,
    slideLeft,
    slideRight,
  };
};
