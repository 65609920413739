import { object, string } from 'yup';

export const validationSchema = object().shape({
  email: string()
    .email('Не верный формат, необходим example@mail.ru')
    .required('Укажите почту')
    .typeError('Укажите почту'),
  text: string()
    .min(10, 'Минимум 10 символов')
    .required('Укажите причину обращения')
    .typeError('Укажите причину обращения'),
});
