import React from 'react';
import { routes } from '../../routes';
import Contacts from './Contacts';
import Documents from './Documents';
import { Faq } from './Faq';
import { TabsProps } from '@components/Tabs';

class TabsConfig<T extends string> {
  tabs: TabsProps['tabs'] = [];
  tabsPosition: string[] = [];

  constructor(readonly name: T) {}

  register(tabName: string, title: string, fc: React.FC): this {
    this.tabsPosition.push(tabName);
    this.tabs.push({
      title,
      content: fc,
    });
    return this;
  }

  getTabPosition(tabName: string): number {
    return this.tabsPosition.indexOf(tabName);
  }
}

export const supportTabs = new TabsConfig('support-tabs')
  .register('faq', 'Инструкции', Faq)
  .register('contacts', 'Контакты', Contacts)
  .register('docs', 'Документы', Documents);

export type SupportTabsQueryParams = Record<typeof supportTabs.name, string>;

export const getSupportContactsTabLink = () =>
  routes.support({
    [supportTabs.name]: supportTabs.getTabPosition('contacts').toString(),
  });
