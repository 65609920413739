import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const OrderTitleWrap = styled(Text.Header2)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: 32px;
    line-height: 46px;
    margin-top: 0;
  `}

  span: first-child {
    width: 100%;

    ${({ theme }) => theme.utils.media.moreThanMobile`
      width: auto;
    `}
  }

  span: nth-child(2) {
    display: none;

    ${({ theme }) => theme.utils.media.moreThanMobile`
      display: block;
      margin: 0 ${({ theme }) => theme.spaces.xxs};
    `}
  }

  span: last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const HiddenCompanyNameSpan = styled.span`
  background: #bcacac1c;
  height: 28px;
  width: 300px;
  margin-top: ${({ theme }) => theme.spaces.xxs};
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: 0;
  `}
`;
