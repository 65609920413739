import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';
import { Text } from '@theme/shared/text';

export const Wrapper = styled(BoxWrapper)`
  margin-top: ${({ theme }) => theme.spaces.xs};
  display: grid;
  grid-gap: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const Value = styled.div`
  margin-left: ${({ theme }) => theme.spaces.m};
`;

export const Key = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    min-width: 230px;
  `}
`;

export const KeyValue = styled.div`
  display: flex;
`;

export const Comment = styled.div`
  padding: 5px;
  margin-top: ${({ theme }) => theme.spaces.xs};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const CompanyText = styled.span`
  color: ${({ theme }) => theme.palette.text.link};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const BlockTitle = styled(Text.Header3)`
  color: ${({ theme }) => theme.palette.main.accent};
`;
