import { FC } from 'react';
import {
  Info,
  MenuWrapper,
  Place,
  PlaceIcon,
  Title,
  Wrapper,
  TitleWrapper,
  InfoContainer,
  OpenBlankWrapper,
  LogoWrapper,
} from './styled';
import { CompanyLogo } from './CompanyLogo';
import { CompanyViewModel } from '@http/models/view-models/company';
import { Icon } from '@components/Icon';
import { CompanyAdminMenu } from '@components/CompanyAdminMenu';
import { useCompany } from '@http/hooks/company';
import { useUser } from 'providers/user';
import { MarginWrapper } from '@theme/shared/wrappers';
import { routes } from 'routes';

interface OpenBlankProps {
  url: string;
}

const OpenBlank = ({ url }: OpenBlankProps) => {
  const onOpenBlank = (e: any) => {
    (e as MouseEvent).preventDefault();
    window.open(url, '_blank');
  };
  return (
    <OpenBlankWrapper href={url} onClick={onOpenBlank}>
      Открыть в новом окне
      <Icon name="arrowRight.solid" />
    </OpenBlankWrapper>
  );
};

interface Props {
  topMenu?: React.ReactNode;
  company: CompanyViewModel;
  activeId?: string;
}

export const CompanyHeader: FC<Props> = ({ topMenu, company, activeId }) => {
  const { title, address, id, icon } = company || {};
  const { refetch } = useCompany(Number(id));
  const user = useUser();
  const userCompany = user.getCompany();
  const isLogoVisible = icon || (userCompany?.id === company.id && userCompany.isVerified) || user.isAdmin();

  const isOpenInModal = Boolean(activeId);

  return (
    <Wrapper>
      {topMenu && <MenuWrapper>{topMenu}</MenuWrapper>}
      {isOpenInModal && (
        <MarginWrapper>
          <OpenBlank url={routes.company.info(activeId!)} />
        </MarginWrapper>
      )}

      <Info>
        {isLogoVisible && (
          <LogoWrapper>
            <CompanyLogo company={company} logoImage={icon} />
          </LogoWrapper>
        )}

        <InfoContainer>
          <TitleWrapper>
            {user.isAdmin() && <CompanyAdminMenu companyData={company} onUpdate={refetch} />}

            <Title>{title}</Title>
            {address && (
              <Place>
                <PlaceIcon>
                  <Icon name="location-marker.filled" />
                </PlaceIcon>
                {address}
              </Place>
            )}
          </TitleWrapper>
        </InfoContainer>
        {/* <DownloadCardButton variant="outlined" startIcon={<Icon size="s" name="download.solid" />}>
          Скачать карточку компании
        </DownloadCardButton> */}
      </Info>
    </Wrapper>
  );
};
