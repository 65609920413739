import React, { useEffect, useState } from 'react';
import { Dialog, DialogContentText, DialogTitle } from '@material-ui/core';
import { useRouter } from 'next/dist/client/router';
import { useAppEventBroadcast } from '../../use-app-event-broadcast';
import { AppEvents } from '../../types';
import { continueParamsFactory, routes } from '../../../routes';
import { Controls } from './styled';
import { MarginWrapper } from '@theme/shared/wrappers';
import { Button } from '@components/Button';

export const UnauthorizedUserTryToCreateOrder: React.FC = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const router = useRouter();
  useAppEventBroadcast(AppEvents.unauthorized_user_try_to_create_order, () => {
    setIsShowModal(true);
  });

  useEffect(() => {
    setIsShowModal(false);
  }, [router.route]);

  if (!isShowModal) {
    return null;
  }

  return (
    <Dialog open={isShowModal} onClose={() => setIsShowModal(false)}>
      <DialogTitle>Для продолжения авторизуйтесь или зарегистрируйтесь</DialogTitle>
      <MarginWrapper marginY="s" marginX="m">
        <DialogContentText>
          Только авторизованные пользователи могут использовать данный функционал. <br />
          Пожалуйста, войдите в свой профиль или создайте новый для получения возможностей по:
          <MarginWrapper marginY="s">
            <MarginWrapper>
              <b>- Созданию новой заявки на утилизацию</b>
            </MarginWrapper>
            <MarginWrapper marginTop="xs">
              <b>- Просмотра контактов других компаний</b>
            </MarginWrapper>
            <MarginWrapper marginTop="xs">
              <b>- Отправку коммерческих предложений</b>
            </MarginWrapper>
          </MarginWrapper>
        </DialogContentText>
      </MarginWrapper>
      <Controls>
        <MarginWrapper marginRight="s">
          <Button href={routes.loginQuery(continueParamsFactory(routes.createOrder))} variant="outlined">
            Войти
          </Button>
        </MarginWrapper>
        <Button href={routes.registrationQuery(continueParamsFactory(routes.createOrder))} variant="filled">
          Регистрация
        </Button>
      </Controls>
    </Dialog>
  );
};
