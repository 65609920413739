import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import {
  getCompany,
  getCompanyContractors,
  getCompanyDetails,
  addExternalCompany,
  addCompanyContractor,
  deleteCompanyContractor,
  addCompanyContract,
  deleteCompanyContract,
  getCompanyContractor,
  addCompanyProductionArea,
  getCompanyProductionAreas,
  deleteCompanyProductionArea,
  getCompanyAdditional,
  getCompanySales,
  addCompanySale,
  deleteCompanySale,
  addBalanceCompany,
  subTrackBalanceCompany,
  updateCompanyContract,
} from '@http/endpoints/company';
import {
  CompanyDetailsApi,
  CompanyDetailsBody,
  CompanyFullAPI,
  CompanyAdditional,
  CompanyShortContactorsAPI,
  ProductionAreasResponse,
  CompanySale,
} from '@http/models/api/company';
import { deleteFile, uploadFile } from '@http/endpoints/file';
import { FileUploadResponse } from '@http/models/api/file';

export const useCompanyDetails = (body: CompanyDetailsBody, options?: UseQueryOptions<any, any, CompanyDetailsApi>) => {
  return useQuery(['company-details', body.id, body.fkkoList], () => getCompanyDetails(body), {
    staleTime: Infinity,
    ...options,
  });
};

export const useCompany = (id: number, options?: UseQueryOptions<any, any, CompanyFullAPI>) => {
  return useQuery(['company-full', id], () => getCompany(id), {
    staleTime: Infinity,
    ...options,
  });
};

export const useCompanyAdditional = (id: number, options?: UseQueryOptions<any, any, CompanyAdditional>) => {
  return useQuery(['company-additional', id], () => getCompanyAdditional(id), {
    staleTime: Infinity,
    ...options,
  });
};

export const useCompanyContractors = (id: number, options?: UseQueryOptions<any, any, CompanyShortContactorsAPI[]>) => {
  return useQuery(['company-сontractors', id], () => getCompanyContractors(id), {
    staleTime: Infinity,
    ...options,
  });
};

export const useAddExternalCompany = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (res: number) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addExternalCompany, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyAddContractor = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (contractorId: number) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addCompanyContractor, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyDeleteContractor = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteCompanyContractor, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyAddContract = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addCompanyContract, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyDeleteContract = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteCompanyContract, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useGetCompanyContractor = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (res: CompanyShortContactorsAPI) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(getCompanyContractor, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useGetCompanyContractorQuery = (
  userCompanyId: number,
  contractorId: number,
  options?: UseQueryOptions<any, any, CompanyShortContactorsAPI>
) => {
  return useQuery(
    ['company-сontractor', userCompanyId, contractorId],
    () =>
      getCompanyContractor({
        userCompanyId,
        contractorId,
      }),
    {
      staleTime: Infinity,
      ...options,
    }
  );
};

export const useCompanyAddFile = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (res: FileUploadResponse) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(uploadFile, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyAddProductionArea = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (productionAreaId: number) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addCompanyProductionArea, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyDeleteFile = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteFile, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyProductionAreas = (
  companyId: number,
  options?: UseQueryOptions<any, any, ProductionAreasResponse>
) => {
  return useQuery(['company-production-areas', companyId], () => getCompanyProductionAreas({ companyId }), {
    staleTime: Infinity,
    ...options,
  });
};

export const useCompanyDeleteProductionArea = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteCompanyProductionArea, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanySales = (companyId: number, options?: UseQueryOptions<any, any, CompanySale[]>) => {
  return useQuery(['company-sales', companyId], () => getCompanySales({ companyId }), {
    staleTime: Infinity,
    ...options,
  });
};

export const useCompanyAddSale = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (productionAreaId: number) => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addCompanySale, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useAddBalanceCompany = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addBalanceCompany, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyUpdateContract = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(updateCompanyContract, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useSubTrackBalanceCompany = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(subTrackBalanceCompany, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyDeleteSale = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteCompanySale, {
    onSuccess: successHandler,
    onError: errorHandler,
  });
