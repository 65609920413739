import { useUser } from '../../../../providers/user';
import { MainLayoutMenuItem } from '@components/main-layout/shared/menu-items/index';
import { useScreenSize } from '@shared/hooks/use-screen-size';

export const useVisibleMenuItems = (menuItems: MainLayoutMenuItem[]): MainLayoutMenuItem[] => {
  const user = useUser();
  const screenSize = useScreenSize();
  return menuItems.filter(item => {
    if (item.validateVisible) {
      return item.validateVisible({ userContext: user, screenSize });
    }
    return true;
  });
};
