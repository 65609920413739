import React from 'react';
import { OrderTitleOwnProps } from './types';
import { HiddenCompanyNameSpan, OrderTitleWrap } from './styled';
import { CompanyLink } from '@components/CompanyLink';

export const OrderTitle = ({ orderId, isPageLayout, companyName, companyId, isUserExist }: OrderTitleOwnProps) => {
  if (isPageLayout) {
    return null;
  }

  return (
    <OrderTitleWrap>
      <span>Заявка № {orderId}</span>
      <span>/</span>
      {isUserExist ? (
        <CompanyLink companyId={companyId} customRender={<span>{companyName}</span>} />
      ) : (
        <HiddenCompanyNameSpan />
      )}
    </OrderTitleWrap>
  );
};
