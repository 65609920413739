import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 184px;
  row-gap: ${({ theme }) => theme.spaces.m};
  margin-top: ${({ theme }) => theme.spaces.xs};
  flex-wrap: wrap;
  flex-direction: column;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: ${({ theme }) => theme.spaces.m};
  `}

  ${({ theme }) => theme.utils.media.desktop`
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  `}
`;

export const Wrapper = styled.div``;

export const Title = styled(Text.Header4)`
  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header3.size};
    line-height: ${({ theme }) => theme.textSizes.header3.lineHeight}
  `}
`;
