import { AccordionSummary as MuiAccordionSummary, Accordion, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const Title = styled(Text.Header3)`
  margin: ${({ theme: { spaces } }) => `${spaces.l} 0 ${spaces.s}`};
`;

export const PlacesWrapper = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xs};

  & .MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.palette.grey.light};
    border-radius: 6px;
  }

  & .MuiCollapse-root.MuiCollapse-entered {
    padding: 12px;
  }

  & .MuiPaper-root > .MuiButtonBase-root {
    border-radius: 6px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.light};
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  & .MuiAccordionSummary-content {
    font-size: ${({ theme }) => theme.textSizes.captionBold.size};
    font-weight: ${({ theme }) => theme.textSizes.captionBold.weight};

    ${({ theme }) => theme.utils.media.moreThanMobile`
     font-size: ${({ theme }) => theme.textSizes.header4.size};
  `}
  }

  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
    display: flex;
    align-items: center;
  }

  & .MuiAccordionDetails-root {
    padding: 12px;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    gap: ${({ theme }) => theme.spaces.s};
  `}
`;

export const PlaceWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root.Mui-expanded {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const AccordionSummary = withStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
}))(MuiAccordionSummary);

export const PlaceAddressContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
