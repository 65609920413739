import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const usePageLoading = () => {
  const router = useRouter();

  const [isLoading, setLoading] = useState(false);

  const handleStart = (url: string) => {
    getRouteFromUrl(url) !== getRouteFromUrl(router.asPath) && setLoading(true);
  };

  const handleComplete = () => setLoading(false);

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  return isLoading;
};

function getRouteFromUrl(url: string) {
  return url.split('?')[0];
}
