import { FC, useMemo } from 'react';
import Link from 'next/link';
import { config } from '../../../../config';
import { Wrap, Row, Label, Column, LabelsColumn, Bages, CopyUrlWrapper, LicensesTabLink } from './styled';
import { getClassTypesTitle, getTypeMovesByFlag } from '@shared/utils/helpers';
import { isNotEmpty } from '@shared/utils/is-empty';
import { CompanyViewModel } from '@http/models/view-models/company';
import { isNotNull } from '@shared/utils/is-not-null';
import { typesMoveDictionary } from '@constants/company';
import { CompanyTypesNew } from '@components/CompanyTypes/CompanyTypesNew';
import Bage from '@components/Bage';
import { LicensesStatus } from '@components/LicensesStatus';
import { runOnClient } from '@shared/utils/is-client';
import { useCompanyTabs } from 'screens/Company/CompanyTabs/hooks/company-tabs-route';
import { CompanyTabName } from 'screens/Company/CompanyTabs/types';
import { useNotifications } from 'providers/notifications';
import { routes } from 'routes';
interface Props {
  company: CompanyViewModel;
}

export const MainInfo: FC<Props> = ({ company }) => {
  const { inn, ogrn, classTypes, isVerified, typeMoves, receipt, licenses, link, id } = company;
  const notifications = useNotifications();
  const companyTabs = useCompanyTabs(company);
  const licencesTabIndex = companyTabs.findIndex(tab => tab.name === CompanyTabName.Licences);

  const companyCustomUrl: string | undefined = useMemo(() => {
    const prefix = config().PUBLIC_HOST;
    if (link && link !== id.toString()) {
      return `${prefix}/${link}`;
    }

    return `${prefix}${routes.company.info(id.toString())}`;
  }, [link, id]);

  const onCopyUrl = () => {
    runOnClient(async () => {
      await window.navigator.clipboard.writeText(companyCustomUrl);
      notifications.push({
        text: `Скопировано ${companyCustomUrl}`,
        severity: 'success',
      });
    });
  };
  return (
    <Wrap>
      <Row>
        <LabelsColumn>
          <Label>Типы деятельности:</Label>
        </LabelsColumn>
        <Column>
          <CompanyTypesNew flag={typeMoves} compactView={false} />
        </Column>
      </Row>
      <Row>
        <LabelsColumn>
          <Label>ИНН:</Label>
        </LabelsColumn>
        <Column>
          <div>{inn || '-'}</div>
        </Column>
      </Row>
      <Row>
        <LabelsColumn>
          <Label>ОГРН:</Label>
        </LabelsColumn>
        <Column>
          <div>{ogrn || '-'}</div>
        </Column>
      </Row>
      {isNotNull(typeMoves) && (
        <Row>
          <LabelsColumn>
            <Label>Виды работ:</Label>
          </LabelsColumn>
          <Column>
            <div>
              {getTypeMovesByFlag(typeMoves)
                .map(item => typesMoveDictionary[item])
                .join(' / ')}
            </div>
          </Column>
        </Row>
      )}
      {isNotNull(classTypes) && isNotEmpty(classTypes) && (
        <Row>
          <LabelsColumn>
            <Label>Класс опасности:</Label>
          </LabelsColumn>
          <Column>
            <div>{getClassTypesTitle(classTypes)}</div>
          </Column>
        </Row>
      )}
      {isNotNull(licenses) && isNotEmpty(licenses) && (
        <Row>
          <LabelsColumn>
            <Label>Лицензии:</Label>
          </LabelsColumn>
          <Column>
            <LicensesStatus licenses={licenses} />
            <Link href={routes.company.licences(String(company.id), licencesTabIndex)} passHref>
              <LicensesTabLink>Перейти</LicensesTabLink>
            </Link>
          </Column>
        </Row>
      )}
      <Row>
        <LabelsColumn>
          <Label>Ссылка на страницу</Label>
        </LabelsColumn>
        <Column>
          <div>{companyCustomUrl}</div>
          <CopyUrlWrapper onClick={onCopyUrl} tabIndex={0}>
            Скопировать
          </CopyUrlWrapper>
        </Column>
      </Row>
      <Bages>
        {isVerified && <Bage variant="secondary">Компания верифицирована</Bage>}
        {receipt && <Bage variant="error">Не принимает отходы</Bage>}
      </Bages>
    </Wrap>
  );
};
