import { useState } from 'react';
import { useNotifications } from 'providers/notifications';
import { useUser } from 'providers/user';
import { Nullable } from 'types';
import { CompanyFullAPI, CompanySale } from '@http/models/api/company';
import { useCompanyDeleteSale, useCompanySales } from '@http/hooks/company';
import { useTariffGuard } from 'providers/tariff-guard';

export function useCompanySalesHandlers(company: CompanyFullAPI) {
  const notifications = useNotifications();
  const [formOpened, setFormOpened] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<Nullable<CompanySale>>(null);

  const user = useUser();
  const userCompany = user.getCompany();
  const isUserCanEdit = Boolean(userCompany?.isVerified && (user.isAdmin() || userCompany?.id === company.id));
  const { isActionAllowed, setModalOpen } = useTariffGuard();

  const { data: companySales, refetch: refetchSales } = useCompanySales(company.id);
  const { mutate: deleteSale, isLoading: isDeleteLoading } = useCompanyDeleteSale({
    successHandler: handlDeleteSaleSuccess,
    errorHandler: handleDeleteSaleError,
  });

  const handleFormClose = () => {
    setFormOpened(false);
    setItemToEdit(null);
  };

  const handleAddSale = () => {
    if (isActionAllowed('AddCompanyService')) {
      setFormOpened(true);
      return;
    }

    setModalOpen(true);
  };

  const handleEditItem = (sale: CompanySale) => {
    if (isActionAllowed('EditCompanyService')) {
      setItemToEdit(sale);
      setFormOpened(true);
      return;
    }

    setModalOpen(true);
  };

  const handleOnSaleAdded = () => {
    refetchSales();
  };

  const handleDelete = (id: number) => {
    deleteSale(id);
  };

  function handlDeleteSaleSuccess() {
    notifications.push({
      text: `Услуга успешно удалена`,
      severity: 'success',
    });
    setFormOpened(false);
    setItemToEdit(null);
    refetchSales();
  }

  function handleDeleteSaleError() {
    notifications.push({
      text: 'Произошла ошибка при удалении услуги, пожалуйста обратитесь в службу техподдержки',
      severity: 'error',
    });
  }

  return {
    companySales,
    isUserCanEdit,
    formOpened,
    itemToEdit,
    isDeleteLoading,
    handleFormClose,
    handleAddSale,
    handleEditItem,
    handleOnSaleAdded,
    handleDelete,
  };
}
