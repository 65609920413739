import React, { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useRouter } from 'next/router';
import { OrderReviewFooterOwnProps } from './types';
import {
  GridOrderColumn,
  GridOrderFooter,
  ReviewPropNameSpan,
  NotAuthParagraph,
  OpenLinkNewTab,
  ActionBtn,
} from './styled';
import { getContact, deleteOrder } from '@http/endpoints/orders';
import { ContactOrder } from '@http/models/api/orders';
import { continueParamsFactory, routes } from 'routes';
import { useUser } from 'providers/user';
import { Text } from '@theme/shared/text';
import { Button } from '@components/Button';
import { useNotifications } from 'providers/notifications';
import { useConfirmDialog } from 'providers/confirm-dialog';
import { Nullable } from 'types';
import { DivWrapper } from '@theme/shared/wrappers';
import { SeparatorLight } from '@theme/shared/components';

export const OrderReviewFooter: FC<OrderReviewFooterOwnProps> = ({ orderApi, isPageLayout, onDelete }) => {
  const [contactOrder, setContact] = useState<Nullable<ContactOrder>>(null);
  const router = useRouter();
  const user = useUser();
  const notifications = useNotifications();
  const confirmDialog = useConfirmDialog();
  const isUserExist = user.exist();

  const showContactVoid = async () => {
    if (contactOrder) return;
    const contact = await getContact(orderApi.id);
    setContact(contact);
  };

  const deleteVoid = async () => {
    await deleteOrder(orderApi.id);
    notifications.push({
      text: `Ваша заявка под номером ${orderApi.id} была удалена`,
      severity: 'success',
    });

    if (onDelete) {
      onDelete();
    }

    if (isPageLayout) {
      await router.push(routes.orders());
    }
  };

  const deleteWithConfirm = () => {
    confirmDialog.show({ onApply: () => deleteVoid(), dialogText: 'Вы действительно хотите удалить заявку?' });
  };

  return (
    <GridOrderFooter>
      {isUserExist && contactOrder && (
        <GridOrderColumn>
          <Text.Header2>Контакты</Text.Header2>
          <p>
            <ReviewPropNameSpan>ИНН:</ReviewPropNameSpan>
            <span>{contactOrder.inn}</span>
          </p>
          <p>
            <ReviewPropNameSpan>Инициатор:</ReviewPropNameSpan>
            <span>{contactOrder.nickname}</span>
          </p>
          <p>
            <ReviewPropNameSpan>Телефон:</ReviewPropNameSpan>
            <span>{contactOrder.phone}</span>
          </p>
        </GridOrderColumn>
      )}
      {!isUserExist && (
        <Grid container direction="row" alignItems="center">
          <NotAuthParagraph>Для просмотра контактов требуется зарегистрироваться</NotAuthParagraph>
          <Button href={routes.registrationQuery(continueParamsFactory(router.asPath))} variant="filled">
            Регистрация
          </Button>
        </Grid>
      )}
      <DivWrapper paddingY="m" width="100%">
        <SeparatorLight />
      </DivWrapper>
      <DivWrapper display="flex" gap="xs" flexWrap={true}>
        {isUserExist && !contactOrder && (
          <Button variant="filled" onClick={showContactVoid}>
            Показать контакты
          </Button>
        )}
        {!isPageLayout && (
          <OpenLinkNewTab href={routes.order(orderApi.id)} variant="outlined" targetBlank={true}>
            Открыть в новом окне
          </OpenLinkNewTab>
        )}
        {!orderApi.isMyOrder && user.getCompany()?.isVerified && (
          <ActionBtn variant="filled" onClick={() => router.push(routes.createOffer(orderApi.id))}>
            Ответить на заявку
          </ActionBtn>
        )}
        {(orderApi.isMyOrder || user.isAdmin()) && (
          <ActionBtn variant="filled" onClick={() => deleteWithConfirm()}>
            Удалить
          </ActionBtn>
        )}
      </DivWrapper>
    </GridOrderFooter>
  );
};
