import React, { useMemo } from 'react';
import { CompanyInfo } from '../CompanyInfo';
import { Licenses } from '../Licenses';
import { CompanySales } from '../CompanyServices';
import { Reviews } from '../Reviews';
import { useReview } from '../Reviews/hooks';
import { StyledTab, TabCounter, TabsWrapper } from './styled';
import { useCompanyTabs } from './hooks/company-tabs-route';
import { CompanyTabName } from './types';
import { TabProps, Tabs } from '@components/Tabs';
import { NextPageFC } from 'types';
import { COMPANY_TABS } from 'types/routesQueryNames';
import { CompanyViewModel } from '@http/models/view-models/company';

export interface CompanyTabsProps {
  company: CompanyViewModel;
}

const CompanyTabs: NextPageFC<CompanyTabsProps> = ({ company }) => {
  const tabs = useCompanyTabs(company);

  const { companyReviews } = useReview(company);
  const reviewsCount = companyReviews?.totalCount || 0;

  const companyTabs: TabProps[] = useMemo(() => {
    const COMPANY_TAB_VIEW_MAP = {
      [CompanyTabName.Info]: {
        title: 'О компании',
        content: <CompanyInfo company={company} isShowCompanyTitle={false} />,
      },
      [CompanyTabName.Sales]: {
        title: 'Услуги',
        content: <CompanySales company={company} />,
      },
      [CompanyTabName.Licences]: {
        title: 'Лицензии',
        content: <Licenses company={company} />,
      },
      [CompanyTabName.Reviews]: {
        title: (
          <StyledTab>
            <span>Отзывы</span>
            {reviewsCount > 0 && <TabCounter>{reviewsCount}</TabCounter>}
          </StyledTab>
        ),
        content: <Reviews company={company} />,
      },
    };

    return tabs.map(tab => COMPANY_TAB_VIEW_MAP[tab.name]);
  }, [tabs, company, reviewsCount]);

  return (
    <TabsWrapper>
      <Tabs ariaLabel={COMPANY_TABS} tabs={companyTabs} />
    </TabsWrapper>
  );
};

export default CompanyTabs;
