import { LoginUserRequestModel, UserTokenApi } from '@http/models/api/user';
import { httpService } from '@http/service';
import { HttpOptions } from '@http/service/options';
import { buildQs } from '@http/shared';

export const loginUser = (model: LoginUserRequestModel) => {
  const options = new HttpOptions().withCredentials(false);
  return httpService.post<UserTokenApi>(buildQs(`/oauth/token`, model), null, options);
};

export const refreshToken = (oldToken: string) => {
  const options = new HttpOptions().withCredentials(false);
  return httpService.post<UserTokenApi>(buildQs(`/oauth/refresh`, { token: oldToken }), null, options);
};
