import styled from 'styled-components';
import { Button } from '@components/Button';

interface DescriptionContentProps {
  lines?: number;
}

export const DescriptionContainer = styled.div`
  font-size: ${({ theme }) => theme.textSizes.caption.size};

  ${({ theme }) => theme.utils.media.moreThanMobile`
       font-size: ${({ theme }) => theme.textSizes.body.size};
  `}
`;

export const DescriptionContent = styled.div<DescriptionContentProps>`
  display: block;
  margin-top: ${({ theme }) => theme.spaces.xs};
  white-space: pre-wrap;

  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => (lines !== -1 ? lines : -1)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.utils.media.moreThanMobile`
       margin-top: ${({ theme }) => theme.spaces.m};
`}
`;

export const MoreButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.xs};
  padding-left: 0;

  &:not(:disabled):hover {
    background: none;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
     margin-top: ${({ theme }) => theme.spaces.xxs};
`}
`;
