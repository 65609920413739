import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.xs};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
`;

export const TitleWithBage = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.xs};
`;

export const Title = styled.h4`
  display: inline-block;
  margin-right: ${({ theme: { spaces } }) => `${spaces.s}`};

  ${({ theme }) => theme.utils.media.moreThanMobile`
   font-size: ${({ theme }) => theme.textSizes.header4.size};
  `}
`;

export const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiInputLabel-root {
    font-size: ${({ theme }) => theme.textSizes.caption.size};
  }

  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.colors.error};
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.text.link};
  }
`;

export const TooltipWrap = styled.span`
  position: relative;

  svg {
    position: absolute;
    top: 0px;
    right: -22px;
    width: 18px;
    height: 18px;
  }
`;
