import styled from 'styled-components';
import { MenuPosition } from './types';

interface WrapperProps {
  postion?: MenuPosition;
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 40px;
  right: ${({ postion }) => postion?.right};
  padding: ${({ theme }) => theme.spaces.s};
  box-shadow: -1px 2px 15px 3px rgb(0 0 0 / 9%);
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: #fff;
  z-index: 20;
  max-width: 290px;
`;

export const Cross = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
