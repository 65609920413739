import { FC } from 'react';
import { Wrapper } from './styled';
import { MainLayout } from '@components/main-layout';
import { NextPageFC } from 'types';
import { Button } from '@components/Button';
import { routes } from 'routes';
import { Text } from '@theme/shared/text';
import { MarginWrapper } from '@theme/shared/wrappers';

export const Page500Content: FC = () => {
  return (
    <Wrapper>
      <Text.Header1>Произошла ошибка</Text.Header1>
      <MarginWrapper marginTop="m" marginBottom="xl">
        Мы уже знаем и устраняем неисправность, <br />
        приносим свои извинения за неудобства в ближайшее время всё начнёт работать
      </MarginWrapper>
      <Button href={routes.home} variant="outlined">
        На главную
      </Button>
    </Wrapper>
  );
};

const Page500: NextPageFC = () => <Page500Content />;

Page500.layout = ({ children }) => MainLayout({ children, topMenuProps: { title: ' ' } });
Page500.headProps = {
  title: 'Произошла ошибка',
};

export default Page500;
