import styled, { css } from 'styled-components';
import { ExtractKeys } from '../../types';
import { ButtonProps } from '@components/Button/index';
import { Text } from '@theme/shared/text';
import { ThemePalette } from '@theme/types';
import { getByPath } from '@shared/utils/arrays';

export type ButtonSize = 's' | 'normal';

interface Modes {
  variant: ButtonProps['variant'];
  fullWidth?: ButtonProps['fullWidth'];
  fullHeight?: ButtonProps['fullHeight'];
  size?: ButtonSize;
  color?: ExtractKeys<ThemePalette>;
  hoverColor?: ExtractKeys<ThemePalette>;
}

export const Wrapper = styled.button<Modes>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: none;
  transition: background 0.1s ${({ theme }) => theme.animate.base};
  white-space: nowrap;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  ${({ size }) => {
    if (size && size === 's') {
      return css`
        padding: 4px 8px;

        ${Text.ButtonText} {
          font-size: 14px;
        }
      `;
    }
  }}

  ${({ theme, variant, color, hoverColor }) => {
    if (variant === 'filled') {
      return css`
        color: ${theme.palette.grey.white};

        &:not(:disabled) {
          background: ${theme.palette.main.primary};

          &:hover {
            background: ${theme.palette.main.primaryHover};

            [data-svg-icon='true'] {
              color: ${theme.palette.grey.white};
              ${theme.palette.grey.white};
            }
          }
        }

        &:disabled {
          background: ${theme.palette.grey.light};
        }
      `;
    }

    if (variant === 'outlined') {
      const colorValue = color ? getByPath(theme.palette, color) : theme.palette.main.primary;
      const hoverColorValue = hoverColor ? getByPath(theme.palette, hoverColor) : colorValue;
      return css`
        background: none;

        &:not(:disabled) {
          border: 1px solid ${colorValue};
          color: ${colorValue};

          &:hover {
            background: ${hoverColorValue};
            color: ${theme.palette.grey.white};

            [data-svg-icon='true'] {
              color: ${theme.palette.grey.white};
            }
          }
        }

        &:disabled {
          border: 1px solid ${theme.palette.grey.middle};
          color: ${theme.palette.grey.middle};
        }
      `;
    }

    if (variant === 'text') {
      return css`
        background: none;

        &:not(:disabled) {
          color: ${theme.palette.text.link};

          &:hover {
            background: none;
            color: ${theme.palette.main.accent};

            [data-svg-icon='true'] {
              color: ${theme.palette.main.accent};
            }
          }
        }

        &:disabled {
          color: ${theme.palette.grey.middle};
        }
      `;
    }

    return '';
  }}

  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        width: 100%;
      `;
    }

    return '';
  }}

  ${({ fullHeight }) => {
    if (fullHeight) {
      return css`
        height: 100%;
      `;
    }

    return '';
  }}
`;

export const IconWrap = styled.div`
  margin-right: ${({ theme }) => theme.spaces.xxs};
  min-width: 1rem;
  height: 1rem;
`;
