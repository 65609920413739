import { ParsedUrlQuery } from 'querystring';
import { parse } from 'cookie';
import { GetServerSidePropsContext, NextPageContext, PreviewData } from 'next';
import { USER_AUTH_COOKIE_KEY } from '@constants/index';
import { Nullable } from 'types';

// TODO: move to /src/shared/userAuth/index.ts
export function getUserTokenByContext(
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData> | NextPageContext
): Nullable<string> {
  const cookies = parse(context.req?.headers?.cookie || '');
  return cookies[USER_AUTH_COOKIE_KEY] || null;
}
