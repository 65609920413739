import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App, { AppProps } from 'next/app';
import Head from 'next/head';
import { useUserAgent } from 'next-useragent';
import React, { useMemo } from 'react';
import { UserAgentProvider } from '../providers/user-agent';
import { UserProvider } from '../providers/user';
import { NotificationsProvider } from '../providers/notifications';
import { ConfirmDialogProvider } from '../providers/confirm-dialog';
import { AppEventsConsumer } from '../events/consumer';
import { initApp } from '../init-app';
import { config, isProduction } from '../config';
import { usePageLoading } from '@shared/hooks/use-page-loading';
import { PageProgress } from '@components/PageProgress';
import { UserData } from '@http/models/view-models/user';
import { ThemeProvider } from '@theme/provider';
import { NextPageFC, Nullable } from 'types';
import { companyPhoneNumber } from '@constants/company';
import { isCallable } from '@shared/utils/is-Callable';
import { AcceptCookies } from '@components/AcceprCookies';
import { RecomendationRegistration } from 'components/RecomendationNotification';
import { AskNotification } from '@components/AskNotification';
import { ModalProvider } from 'providers/modals';
// import { RpnFeatureBanner } from '@components/RpnFeatureBanner';
import ErrorBoundary from '@components/ErrrorBoundary';
import ErrorBoundary500 from 'screens/Page500/ErrorBoundary500';
import { PrevPathSessionStorageSync } from '@shared/PrevPathSessionStorageSync';
import { getUserDataOrLogOut } from '@shared/userAuth';
import { TariffGuardProvider } from 'providers/tariff-guard';

const queryClient = new QueryClient();
const MockWrapper: React.FC = ({ children }) => <>{children}</>;

interface PageProps extends AppProps {
  Component: NextPageFC<any, any>;
  userAgent: any;
  userData: Nullable<UserData>;
}

export interface HeadProps {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImageName?: string;
}

const createLayout = (ComponentConfig: NextPageFC<any, any>, pageProps: any): [React.FC, Record<any, any>] => {
  if (ComponentConfig.layout) {
    return [ComponentConfig.layout, { pageProps }];
  }

  return [MockWrapper, {}];
};

// TODOS add OG PHONE
const HeadWrapper = ({ title, description, ogDescription, ogTitle, ogImageName }: HeadProps) => {
  const faviconFile = isProduction() ? 'favicon' : 'favicon_dev';
  return (
    <Head>
      {title && <title>{title}</title>}
      <meta name="description" content={description} />
      <link rel="icon" href={`/${faviconFile}.ico`} sizes="any" />
      <link rel="icon" href={`/${faviconFile}.svg`} type="image/svg+xml" />
      <link rel="manifest" href="/manifest.webmanifest" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={`/og/images/${ogImageName || 'ogmain.jpg'}`} />
      <meta property="og:site_name" content="Критод" />
      <meta property="og:locale" content="ru" />
      <meta property="og:phone_number" content={companyPhoneNumber} />
      <meta property="og:country-name" content="Россия" />
      <meta property="og:locality" content="ru" />
    </Head>
  );
};

initApp();

function MyApp(props: PageProps) {
  const { Component, pageProps = {}, userAgent, userData } = props;
  const headProps = (isCallable(Component.headProps) ? Component.headProps(pageProps) : Component.headProps) || {};
  const [Layout, layoutProps] = useMemo(() => createLayout(Component, pageProps), [Component, pageProps]);

  const isLoading = usePageLoading();

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <UserAgentProvider userAgent={userAgent}>
            <ThemeProvider>
              <NotificationsProvider>
                <ConfirmDialogProvider>
                  <UserProvider userData={userData}>
                    <HeadWrapper {...headProps} />
                    <ReactQueryDevtools />
                    <AskNotification />
                    <PrevPathSessionStorageSync />
                    {/*{*/}
                    {/*  !isProduction() && <RpnFeatureBanner /> // TODO: временно потом убарть на релизе*/}
                    {/*}*/}
                    <AcceptCookies />
                    <RecomendationRegistration />
                    {isLoading && <PageProgress />}
                    <AppEventsConsumer />
                    <Layout {...layoutProps}>
                      <TariffGuardProvider>
                        <ModalProvider>
                          <ErrorBoundary500>
                            <Component {...pageProps} />
                          </ErrorBoundary500>
                        </ModalProvider>
                      </TariffGuardProvider>
                    </Layout>
                  </UserProvider>
                </ConfirmDialogProvider>
              </NotificationsProvider>
            </ThemeProvider>
          </UserAgentProvider>
        </Hydrate>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async (context: any) => {
  const { ctx } = context;
  if (ctx.req?.headers?.host && isProduction() && !/kritod.(ru|com)/g.test(ctx.req.headers.host)) {
    ctx.res?.writeHead(302, {
      Location: `${config().PUBLIC_HOST}?from=${encodeURIComponent(ctx.req.headers.host)}`,
    });
    ctx.res?.end();
  }

  const appPropsFromPage = await App.getInitialProps(context);

  const userAgentHeaders = ctx.req && ctx.req.headers && ctx.req.headers['user-agent'];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userAgent = userAgentHeaders ? useUserAgent(userAgentHeaders) : undefined;
  const userData = await getUserDataOrLogOut(ctx);

  return {
    ...appPropsFromPage,
    userAgent,
    userData,
  };
};

export default MyApp;
