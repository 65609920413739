import { useEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Container, StyledImage } from './styled';
import { Skeleton } from '@theme/shared/components';
import 'react-image-lightbox/style.css';

interface AttachedImageProps {
  imageUrl: string;
}

export const AttachedImage: React.FC<AttachedImageProps> = ({ imageUrl }) => {
  const [logoImgLoaded, setLogoImgLoaded] = useState<boolean>(false);
  const [imgOpened, setImgOpened] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && (ref.current as HTMLImageElement).complete) {
      setLogoImgLoaded(true);
    }
  }, [ref]);

  function handleOnLoad() {
    setLogoImgLoaded(true);
  }
  return (
    <Container>
      {!logoImgLoaded && <Skeleton />}
      <StyledImage
        ref={ref}
        src={imageUrl}
        $loaded={logoImgLoaded}
        onLoad={handleOnLoad}
        onClick={() => setImgOpened(true)}
      />
      {imageUrl && imgOpened && (
        <Lightbox
          mainSrc={imageUrl}
          onCloseRequest={() => setImgOpened(false)}
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Container>
  );
};
