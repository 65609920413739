import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FkkoResult } from './FkkoResult';
import { getSkip } from './helpers';
import { Header, StyledTextField, Title, TitleWithBage, TooltipWrap } from './styled';
import { PlaceShort } from '@http/models/api/company';
import { usePlaceFkko } from '@http/hooks/fkko';
import Pagination from '@components/Pagination';
import Bage from '@components/Bage';
import { Tooltip } from '@components/Tooltip';
import { Icon } from '@components/Icon';
import { useScreenSize } from '@shared/hooks/use-screen-size';

interface Props {
  place: PlaceShort;
}

const FKKO_SIZE = 10;

export const Place: React.FC<Props> = ({ place }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountByQuery, setTotalCountByQuery] = useState(0);
  const [query, setQuery] = useState('');

  const { isMobile, isTablet } = useScreenSize();

  const { data, isLoading } = usePlaceFkko({
    id: place.placeCopyId || place.id,
    size: FKKO_SIZE,
    skip: getSkip(currentPage, FKKO_SIZE),
    q: query,
  });

  // Устанавливаем общее кол-во фкко после получения данных
  useEffect(() => {
    if (data?.totalCount && !query) {
      setTotalCount(data.totalCount);
    }
  }, [data?.totalCount, query]);

  // Устанавливаем кол-во фкко по query
  useEffect(() => {
    data && setTotalCountByQuery(data?.totalCount);
  }, [data]);

  const handleInputChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.replace(/\s/g, ''));
    setCurrentPage(1);
  }, 250);

  const showPagination = totalCountByQuery > FKKO_SIZE;

  const totalCountLabel = isMobile || isTablet ? totalCount : `${totalCount} видов`;

  return (
    <>
      <Header>
        <TitleWithBage>
          <Title>Виды отходов по ФККО</Title>
          {!isLoading && totalCount && (
            <Tooltip
              title="Отображаемое количество кодов ФККО может отличаться от количества позиций в лицензии, все группы кодов отображаются в развёрнутом виде.
            "
              arrow
            >
              <TooltipWrap>
                <Bage variant="light">{totalCountLabel}</Bage>
                <Icon name="question-mark-circle.outline" />
              </TooltipWrap>
            </Tooltip>
          )}
        </TitleWithBage>
        {totalCount > 0 && (
          <StyledTextField
            label="Поиск по коду ФККО или названию"
            variant="outlined"
            onChange={handleInputChange}
            helperText={!totalCountByQuery && !isLoading && 'Ничего не найдено'}
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        )}
      </Header>
      <FkkoResult fkkoData={data} isLoading={isLoading} totalCountByQuery={totalCountByQuery} />
      {showPagination && (
        <Pagination
          count={Math.ceil(totalCountByQuery / FKKO_SIZE)}
          page={currentPage}
          disabled={isLoading}
          color={'primary'}
          size={'small'}
          shape={'rounded'}
          variant={'outlined'}
          onChange={(_: React.ChangeEvent<unknown>, page: number) => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  );
};
