import React, { useState } from 'react';
import { KeyboardArrowUp } from '@material-ui/icons';
import { Accordion, AccordionDetails } from '@material-ui/core';
import { AccordionSummary } from '../../Company/CompanyInfo/License/styled';
import { HowAddCompany } from './HowAddCompany';
import { MarginWrapper } from '@theme/shared/wrappers';
import { Text } from '@theme/shared/text';

interface Items {
  title: string;
  component: React.FC;
}

const faqItems: Items[] = [
  {
    title: 'Добавление компании',
    component: HowAddCompany,
  },
];

export const Faq = () => {
  const [itemsSet, setItemsSet] = useState<Set<number>>(new Set([0]));

  const toggle = (index: number) => {
    const copy = new Set(itemsSet);
    copy.has(index) ? copy.delete(index) : copy.add(index);
    setItemsSet(copy);
  };

  return (
    <MarginWrapper marginTop="m">
      {faqItems.map(({ title, component: Component }, index) => {
        const isActive = itemsSet.has(index);
        return (
          <Accordion key={title} expanded={isActive} onChange={() => toggle(index)}>
            <AccordionSummary expandIcon={<KeyboardArrowUp />}>
              <Text.Header4>Как добавить компанию</Text.Header4>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>{isActive && <Component />}</AccordionDetails>
          </Accordion>
        );
      })}
    </MarginWrapper>
  );
};
