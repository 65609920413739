import React from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import MUCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { FieldWrapper, Error } from './styled';

interface Props {
  name: string;
  label?: string;
  onChange?(): void;
  checkBoxProps?: CheckboxProps;
}

const Checkbox: React.FC<Props> = ({ name, label = '', onChange, checkBoxProps }) => {
  return (
    <FieldWrapper>
      <Field name={name} type="checkbox">
        {({ form, field }: FieldProps) => {
          return (
            <>
              <FormControlLabel
                label={label}
                control={
                  <MUCheckbox
                    color="primary"
                    checked={field.checked}
                    onChange={() => {
                      onChange && onChange();
                      form.setFieldValue(name, !field.checked);
                    }}
                    {...checkBoxProps}
                  />
                }
              />
              <ErrorMessage name={name} component={Error} />
            </>
          );
        }}
      </Field>
    </FieldWrapper>
  );
};

export default Checkbox;
