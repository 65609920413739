import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { CompanyPhotosUploader } from '../CompanyPhotosUploader';
import { Container, ScrollerArrow, Wrapper } from './styled';
import { GalleryScrollParams, useGalleryScroll } from './hooks/useGalleryScroll';
import GalleryImage from 'screens/Company/CompanyInfo/CompanyPhotos/PhotoGallery/GalleryImage';
import { Icon } from '@components/Icon';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { CompanyFullAPI } from '@http/models/api/company';
import { useCompany, useCompanyDeleteFile } from '@http/hooks/company';
import { useConfirmDialog } from 'providers/confirm-dialog';
import { useNotifications } from 'providers/notifications';
import { parseApiErrors } from '@http/shared';
import { Nullable } from 'types';

interface Props {
  company: CompanyFullAPI;
  images: Nullable<string[]>;
  editable: boolean;
}

export const PhotoGallery = ({ company, images = [], editable }: Props) => {
  const { slideLeft, slideRight, scrollParams } = useGalleryScroll(images?.length);
  const { isDesktop } = useScreenSize();
  const [openedPhotoIdx, setOpenedPhotoIdx] = useState<Nullable<number>>(null);
  const confirmDialog = useConfirmDialog();
  const { refetch } = useCompany(company.id);
  const notifications = useNotifications();

  const [galleryScrollParams, setGalleryScrollParams] = useState<GalleryScrollParams>({});

  useEffect(() => {
    setGalleryScrollParams(scrollParams);
  }, [scrollParams]);

  const { mutate: deleteCompanyPhoto } = useCompanyDeleteFile({
    successHandler: () => {
      notifications.push({
        severity: 'success',
        text: 'Фото успешно удалено',
      });
      refetch();
    },
    errorHandler: (err: unknown) => {
      const apiErrors = parseApiErrors(err);
      Sentry.captureException(err, {
        extra: {
          apiErrors: apiErrors,
        },
        tags: {
          label: 'delete-company-photo',
        },
      });
      notifications.push({
        severity: 'error',
        text:
          apiErrors && apiErrors.length > 0
            ? apiErrors.join(', ')
            : 'Ошибка сервера, пожалуйста обратитесь к администратору',
      });
    },
  });

  const handlePhotoDelete = async (src: string) => {
    confirmDialog.show({
      onApply: async () => {
        deleteCompanyPhoto(src);
      },
      dialogText: 'Вы уверены, что хотите удалить фото?',
    });
  };

  return (
    <Wrapper>
      {isDesktop && (
        <>
          <ScrollerArrow
            $isActive={!!galleryScrollParams.isLeftScrolled}
            style={{ left: '-5px', right: 'initial' }}
            onClick={slideLeft}
          >
            <Icon size={24} color="main.primary" name="cheveron-left.outline" />
          </ScrollerArrow>
          <ScrollerArrow
            $isActive={!!galleryScrollParams.isRightScrolled}
            style={{ right: '0px', left: 'initial' }}
            onClick={slideRight}
          >
            <Icon size={24} color="main.primary" name="cheveron-right.outline" />
          </ScrollerArrow>
        </>
      )}
      <Container data-scrollable-gallery="true">
        {editable && <CompanyPhotosUploader company={company} />}
        {images?.map((image, idx) => (
          <GalleryImage
            key={image}
            src={image}
            editable={editable}
            onDelete={handlePhotoDelete}
            onClick={() => setOpenedPhotoIdx(idx)}
          />
        ))}
        {images && images?.length > 0 && openedPhotoIdx !== null && (
          <Lightbox
            mainSrc={images[openedPhotoIdx]}
            nextSrc={images[(openedPhotoIdx + 1) % images.length]}
            prevSrc={images[(openedPhotoIdx + images.length - 1) % images.length]}
            onCloseRequest={() => setOpenedPhotoIdx(null)}
            onMovePrevRequest={() =>
              setOpenedPhotoIdx(prevPhotoIdx => (Number(prevPhotoIdx) + images.length - 1) % images.length)
            }
            onMoveNextRequest={() => setOpenedPhotoIdx(prevPhotoIdx => (Number(prevPhotoIdx) + 1) % images.length)}
            reactModalStyle={{ overlay: { zIndex: 1500 } }}
          />
        )}
      </Container>
    </Wrapper>
  );
};
