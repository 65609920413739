import { StyleTheme } from 'theme/types';

export const defaultTheme: Omit<StyleTheme, 'utils'> = {
  // todos remove after new ui
  colors: {
    primary: '#54C1A0',
    secondary: '#03899C',
    accent: '#EF7915',
    light: '#5FC0CE',
    text: '#20262D',
    info: '#808080',
    error: '#bf0900',
    gray: '#ececec',
    medium: '#3E5372',
    dark: '#1C2B3A',
    background: '#f7f6f9',
  },
  palette: {
    main: {
      primary: '#5ABFA1',
      primaryHover: '#139F75',
      secondary: '#CDE6BC',
      dark: '#55773E',
      linear: 'linear-gradient(180deg, #65D6B4 0%, #139F75 100%);',
      linearHover: 'linear-gradient(180deg,#139F75 0%,#139F75 100%)',
      accent: '#3F5471',
      accentHover: '#5b7599',
      light: '#DCE9FB',
      lightBlue: '#216CD3',
      lightGreen: '#0BB1A7',
      mediumGreen: '#ECFAE3',
      pink: '#D21972',
    },
    text: {
      primary: '#1F2E3E',
      secondary: '#91969B',
      link: '#3765A4',
    },
    grey: {
      middle: '#BDBDBD',
      light: '#DDDDDD',
      background: '#FAFAFA',
      cell: '#F1F1F1',
      white: '#fff',
    },
    system: {
      error: '#EC1A33',
      errorLight: '#FEEFF1',
      warning: '#f2bb1d',
    },
  },
  typography: {
    fontFamily: 'Manrope',
  },
  textSizes: {
    header1: {
      weight: 700,
      lineHeight: '46px',
      size: '32px',
    },
    header2: {
      weight: 700,
      size: '24px',
      lineHeight: '32px',
    },
    header3: {
      weight: 600,
      size: '22px',
      lineHeight: '30px',
    },
    header4: {
      weight: 700,
      size: '18px',
      lineHeight: '28px',
    },
    subHeader4: {
      weight: 400,
      size: '18px',
      lineHeight: '28px',
    },
    body: {
      weight: 400,
      size: '16px',
      lineHeight: '24px',
    },
    bodyBold: {
      weight: 600,
      size: '16px',
      lineHeight: '24px',
    },
    caption: {
      weight: 400,
      size: '14px',
      lineHeight: '20px',
    },
    captionBold: {
      weight: 600,
      size: '14px',
      lineHeight: '20px',
    },
    buttonText: {
      weight: 500,
      size: '17px',
      lineHeight: '24px',
      isUppercase: false,
    },
    menuCaption: {
      weight: 400,
      size: '12px',
      lineHeight: '18px',
    },
    menuCaptionBold: {
      weight: 600,
      size: '12px',
      lineHeight: '18px',
    },
  },
  screenSizes: {
    minDesktop: 992,
    minLargeDesktop: 1200,
    minTablet: 768,
  },
  spaces: {
    xxs: '8px',
    xs: '12px',
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '48px',
  },
  shadow: {
    base: '0px 4px 20px rgba(62, 83, 114, 0.08);',
    medium: '0px 4px 20px rgba(62, 83, 114, 0.3);',
    large: '0px 4px 20px rgba(62, 83, 114, 0.18);',
  },
  shape: {
    borderRadius: '6px',
  },
  animate: {
    base: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  __mobilePatch: {
    textSizes: {
      header1: {
        weight: 700,
        lineHeight: '46px',
        size: '18px',
      },
    },
  },
};
