import React, {
  ChangeEvent,
  createContext,
  DetailedHTMLProps,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Tabs as TabsMui, Box, Tab } from '@material-ui/core';
import { useRouter } from 'next/router';
import { TabTitleWrapper } from '../../screens/User/styled';
import { getCurrentTabIdx } from './getCurrentTabIdx';
import { TabsControlWrapper } from '@components/Tabs/styled';
import { isClient } from '@shared/utils/is-client';
import { IconName } from '@components/Icon/types';
import { Icon } from '@components/Icon';

export interface TabProps {
  title: string | React.ReactNode;
  content: React.ReactNode | React.FC;
}

export interface TabsProps {
  tabs: TabProps[];
  ariaLabel: string;
  extraComponent?: JSX.Element;
  ssr?: boolean;
  customTabletView?: boolean;
}

interface TabPanelOwnProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  ariaLabel: string;
  current: number;
  index: number;
  ssr?: boolean;
}

export interface TabsContextInterface {
  toggleTab(idx: number): void;
}

const Context = createContext<TabsContextInterface | undefined>(undefined);

function TabPanel(props: TabPanelOwnProps) {
  const { children, current, index, ariaLabel, ssr, ...other } = props;
  const isContentVisible = ssr && !isClient;
  return (
    <div
      role="tabpanel"
      hidden={current !== index && !isContentVisible}
      id={`${ariaLabel}-tabpanel-${index}`}
      aria-labelledby={`${ariaLabel}-tab-${index}`}
      {...other}
    >
      {(current === index || isContentVisible) && children}
    </div>
  );
}

function a11yProps(label: string, index: number) {
  return {
    id: `${label}-tab-${index}`,
    'aria-controls': `${label}-tabpanel-${index}`,
  };
}

export const renderTabTitle = (title: string, iconName: IconName): React.ReactNode => {
  return (
    <TabTitleWrapper>
      <Icon name={iconName} size="sm" strokeWidth={1.5} />
      {title}
    </TabTitleWrapper>
  );
};

export const Tabs = ({ tabs, ariaLabel, extraComponent, ssr, customTabletView }: TabsProps) => {
  const router = useRouter();
  const [current, setCurrent] = useState(
    getCurrentTabIdx({
      query: router.query,
      ariaLabel,
      tabsLen: tabs.length,
    })
  );

  useEffect(() => {
    return () => {
      // при размонтировании удалим лишние активные табы
      delete router.query[ariaLabel];

      if (window.location.pathname === router.asPath) {
        router.replace(
          {
            pathname: router.pathname,
            query: { ...router.query },
          },
          undefined,
          { scroll: false }
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrent(
      getCurrentTabIdx({
        query: router.query,
        ariaLabel,
        tabsLen: tabs.length,
      })
    );
    setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 0);
  }, [router.query, ariaLabel, tabs.length]);

  const handleChange = (_: ChangeEvent<any>, idx: number) => {
    setCurrent(idx);

    let newQuery = {};
    if (idx > 0) {
      newQuery = {
        [ariaLabel]: idx,
      };
    } else {
      delete router.query[ariaLabel];
    }

    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, ...newQuery },
      },
      undefined,
      {
        shallow: true,
        scroll: false,
      }
    );
  };

  const params: TabsContextInterface = {
    toggleTab: idx => setCurrent(idx),
  };

  return (
    <Context.Provider value={params}>
      <Box sx={{ width: '100%' }}>
        <TabsControlWrapper customTabletView={customTabletView}>
          <TabsMui
            scrollButtons="auto"
            variant="scrollable"
            value={current}
            onChange={handleChange}
            aria-label={ariaLabel}
          >
            {tabs.map((tab, i) => {
              return <Tab key={i} label={tab.title} {...a11yProps(ariaLabel, i)} />;
            })}
            {extraComponent && extraComponent}
          </TabsMui>
        </TabsControlWrapper>
        {tabs.map((tab, i) => (
          <TabPanel ssr={ssr} key={i} current={current} index={i} ariaLabel={ariaLabel}>
            {typeof tab.content === 'function' ? <tab.content /> : tab.content}
          </TabPanel>
        ))}
      </Box>
    </Context.Provider>
  );
};

export const useTabsControl = () => useContext(Context) as TabsContextInterface;
