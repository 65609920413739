import styled from 'styled-components';
import TextField from '@lib/Components/fields/TextField';
import Select from '@lib/Components/fields/Select';
import { Button } from '@components/Button';

export const Wrap = styled.div`
  min-width: 536px;
`;

export const CompanyTextField = styled(TextField)`
  width: 100%;

  .MuiFilledInput-root {
    border-radius: 6px;
  }
`;

export const PriceField = styled(TextField)`
  width: 100%;

  .MuiFilledInput-root {
    border-radius: 6px;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
     margin-right: 30px;

    &:after {
      content: '₽';
      position: absolute;
      right: -20px;
      top: 16px;
     }
  `}
`;

export const PriceDescriptionField = styled(TextField)`
  width: 100%;

  .MuiFilledInput-root {
    border-radius: 6px;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
     &:before {
      content: 'за';
      position: absolute;
      display: block;
      left: -26px;
      top: 16px;
    }
  `}
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  &.MuiFilledInput-root {
    border-radius: 6px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spaces.s};
  padding-top: ${({ theme }) => theme.spaces.m};

  & > div {
    width: 100%;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
      padding-top: 0;
  `}
`;

export const PriceSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spaces.s};

  & > div {
    width: 100%;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
      gap: 68px;
      flex-wrap: nowrap;
  `}
`;

export const ModalFooter = styled.div`
  margin-top: ${({ theme }) => theme.spaces.m};
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.m};
`;

export const DeleteButton = styled(Button)`
  &:not(:disabled) {
    color: ${({ theme }) => theme.palette.system.error};
  }

  & > div {
    font-size: ${({ theme }) => theme.textSizes.bodyBold.size};
    font-weight: ${({ theme }) => theme.textSizes.bodyBold.weight};
    line-height: ${({ theme }) => theme.textSizes.bodyBold.lineHeight};
  }

  & svg {
    height: 16px;
    width: 16px;
  }

  margin-top: ${({ theme }) => theme.spaces.m};
`;
