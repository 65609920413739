import { FC, useState } from 'react';
import { DropEvent } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { AddedFile } from '../types';
import { Wrapper, ImgThunbnail, DeleteButton, Container, Caption } from './styled';
import { Icon } from '@components/Icon';

export interface ImageThumbnailsProps {
  files: AddedFile[];
  onChangeOrder: (files: AddedFile[]) => void;
  onDelete: (idx: number) => void;
}

export const ImageThumbnails: FC<ImageThumbnailsProps> = ({ files, onChangeOrder, onDelete }) => {
  const [dragId, setDragId] = useState<string>();

  const handleDrag = (ev: React.DragEvent<HTMLDivElement>) => {
    const element = ev.currentTarget as HTMLElement;
    if (element.id) {
      setDragId(element.id);
    }
  };

  const handleDrop = (ev: DropEvent) => {
    const element = ev.currentTarget as HTMLElement;

    const dragBox = files.find(file => file.id === dragId);
    const dropBox = files.find(file => file.id === element?.id);

    const dragBoxOrder = dragBox?.order;
    const dropBoxOrder = dropBox?.order;

    if (dragBoxOrder !== undefined && dropBoxOrder != undefined) {
      const filesCopy = cloneDeep(files);
      const newBoxState = filesCopy.map(item => {
        if (item.id === dragId) {
          item.order = dropBoxOrder;
        }
        if (item.id === element.id) {
          item.order = dragBoxOrder;
        }
        return item;
      });
      onChangeOrder(newBoxState);
    }
  };

  return (
    <Wrapper>
      <Container>
        {files.map((file, idx) => (
          <ImgThunbnail
            key={idx}
            id={file.id}
            onDragOver={ev => ev.preventDefault()}
            onDragStart={handleDrag}
            onDrop={handleDrop}
          >
            <DeleteButton size="small" onClick={() => onDelete(idx)}>
              <Icon name="trash.outline" size={'s'} />
            </DeleteButton>
            <img src={file.src} alt="thumbnail" />
          </ImgThunbnail>
        ))}
      </Container>
      <Caption>Перетаскивайте для упорядочивания</Caption>
    </Wrapper>
  );
};
