import React, { useCallback, useMemo, useState } from 'react';
import { TableComponentOrderRowModel } from './types';
import { OrderRowViewModel, OrderViewModel } from '@http/models/view-models/orders';
import { OfferViewModel } from '@http/models/view-models/offer';
import { kgToTons, pennyToRubs } from '@shared/utils/helpers';
import { OrderRowApi } from '@http/models/api/orders';

export interface OfferContextProps {
  order: OrderViewModel;
  state: OfferViewModel;
  rowsMap: Map<number, OrderRowViewModel>;
  tableData: TableComponentOrderRowModel[];
  reportedRows: OrderRowViewModel[];
  setOfferAsInteresting(): void;
}

const Context = React.createContext<OfferContextProps | undefined>(undefined);

interface Props {
  order: OrderViewModel;
  offer: OfferViewModel;
}

export const OfferProvider: React.FC<Props> = ({ offer: initialOffer, order, children }) => {
  const [offer, setOffer] = useState(initialOffer);
  const rowsMap: Map<number, OrderRowApi> = useMemo(() => {
    return order.rows ? new Map(order.rows.map(row => [row.id, row])) : new Map();
  }, [order.rows]);

  const handleMarkOfferAsInteresting = useCallback(() => {
    setOffer(currrentState => ({ ...currrentState, interesting: true }));
  }, []);

  const value: OfferContextProps = useMemo(() => {
    const offerRows = new Map(offer.rows.map(r => [r.orderRowId, r]));

    const tableData = ((): TableComponentOrderRowModel[] => {
      const rows = order.rows ? [...order.rows] : [];
      const reportedFirst = rows.sort(a => {
        const hasPrice = Boolean(offerRows.get(a.id)?.price);
        return 0 - Number(hasPrice);
      });

      return reportedFirst.map(row => {
        const price = offerRows.get(row.id)?.price ?? 0;
        return {
          isReported: Boolean(offerRows.get(row.id)),
          rowId: row.id,
          code: row.code,
          price: pennyToRubs(price) * kgToTons(row.netto),
          pricePerUnit: pennyToRubs(price),
          netto: kgToTons(row.netto),
          comment: row.comment,
          title: row.title,
          typeMove: row.type,
        };
      });
    })();

    const reportedRows = (() => {
      const rows = order.rows ? order.rows : [];
      return rows.filter(row => offerRows.get(row.id)?.price);
    })();

    return {
      rowsMap,
      order,
      tableData,
      reportedRows,
      state: offer,
      setOfferAsInteresting: handleMarkOfferAsInteresting,
    };
  }, [rowsMap, order, offer, handleMarkOfferAsInteresting]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useOffer = () => React.useContext(Context) as OfferContextProps;
