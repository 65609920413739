import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const Wrapper = styled.div`
  max-width: 600px;
`;

export const ImgThunbnail = styled.div`
  width: 90px;
  min-width: 90px;
  height: 80px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const DeleteButton = styled(IconButton)`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.palette.grey.white};
    border: 1px solid ${({ theme }) => theme.palette.main.primary};
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 10;
    color: ${({ theme }) => theme.palette.main.primary};
  }

  &.MuiButtonBase-root:hover {
    background: ${({ theme }) => theme.palette.grey.white};
  }
`;

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.xs};
  overflow-x: auto;
`;

export const Caption = styled.div`
  font-size: ${({ theme }) => theme.textSizes.menuCaption.size};
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-top: ${({ theme }) => theme.spaces.xxs};
`;
