import { FC } from 'react';
import { Container, ZoomButtom } from './styled';
import { Icon } from '@components/Icon';

export interface ZoomControlProps {
  zoomToClick: () => void;
  zoomOutClick: () => void;
}

export const ZoomControl: FC<ZoomControlProps> = ({ zoomToClick, zoomOutClick }) => {
  return (
    <Container>
      <ZoomButtom onClick={zoomToClick}>
        <Icon name="plusControll.outline" />
      </ZoomButtom>
      <ZoomButtom onClick={zoomOutClick}>
        <Icon name="minusControll.outline" />
      </ZoomButtom>
    </Container>
  );
};
