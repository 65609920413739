import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';

export const Wrapper = styled(BoxWrapper)`
  margin-bottom: ${({ theme }) => theme.spaces.m};
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-top: 0;
  min-height: 560px;
`;

export const PageLink = styled.a`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.m};
  width: 100%;

  span {
    width: 250px;
  }

  svg {
    margin-left: ${({ theme }) => theme.spaces.xxs};
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header3.size};
    font-weight: ${({ theme }) => theme.textSizes.header3.weight};
    line-heigh: ${({ theme }) => theme.textSizes.header3.lineHeight};

    span {
      width: auto;
    }
  `}

  ${({ theme }) => theme.utils.media.desktop`
    margin-left: ${({ theme }) => theme.spaces.s};
    margin-top: ${({ theme }) => theme.spaces.xl};

    span {
      width: auto;
    }
  `}
`;
