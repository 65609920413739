import { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AttachDocument, DeleteFileButton, FileFieldWrapper } from './styled';
import { FileType, FileUploadResponse } from '@http/models/api/file';
import { toBytes } from '@shared/utils/helpers';
import { Nullable } from 'types';
import { useCompanyAddFile } from '@http/hooks/company';
import { Icon } from '@components/Icon';
import { MarginWrapper } from '@theme/shared/wrappers';
import { useNotifications } from 'providers/notifications';

const MAX_FILE_SIZE = toBytes(10, 'MB');

interface ReviewFileFieldProps {
  companyId: number;
  uploadedFileUrl?: Nullable<string>;
  onSucces: (fileUrl: string) => void;
  onDelete: () => void;
}

export const ReviewFileField: React.FC<ReviewFileFieldProps> = ({ companyId, uploadedFileUrl, onDelete, onSucces }) => {
  const notification = useNotifications();
  const [addedFile, setAddedFile] = useState<Nullable<File>>(null);

  const { mutate: addReviewPhoto, isLoading } = useCompanyAddFile({
    successHandler: (res: FileUploadResponse) => {
      onSucces(res.url);
    },
    errorHandler: () => {},
  });

  const onDropAccepted = useCallback(acceptedFile => {
    setAddedFile(acceptedFile[0]);
    sendFile(acceptedFile[0] as Blob);
  }, []);

  const onDropRejected = useCallback((rejections: FileRejection[]) => {
    rejections?.forEach(rejection => {
      rejection.errors.forEach(error => {
        if (error.code === 'file-too-large') {
          notification.push({ text: `Размер загружаемого файла превышает ${MAX_FILE_SIZE} MB`, severity: 'error' });
        } else {
          notification.push({ text: `Ошибка при загрузке файла: ${error.message}`, severity: 'error' });
        }
      });
    });
  }, []);

  const { getInputProps, getRootProps, open } = useDropzone({
    maxSize: MAX_FILE_SIZE,
    accept: { 'image/jpeg': [], 'image/png': [] },
    onDropAccepted,
    noClick: true,
    disabled: isLoading,
    multiple: false,
    onDropRejected,
  });

  const getFormData = (companyId: number, fileType: FileType, fileData: Blob) => {
    const formData = new FormData();
    formData.append('companyId', String(companyId));
    formData.append('fileType', String(fileType));
    formData.append('file', fileData);
    return formData;
  };

  const sendFile = (file: Blob) => {
    const formData = getFormData(companyId, FileType.Review, file);
    addReviewPhoto(formData);
  };

  const handleRemoveFile = () => {
    setAddedFile(null);
    onDelete();
  };

  return (
    <FileFieldWrapper {...getRootProps()} disabled={isLoading}>
      <input {...getInputProps()} />

      <AttachDocument type="button" variant="text" startIcon={<Icon name="paper-clip.outline" />} onClick={open}>
        {uploadedFileUrl && addedFile ? addedFile.name : 'Прикрепить документ'}
      </AttachDocument>
      {uploadedFileUrl && (
        <DeleteFileButton onClick={handleRemoveFile}>
          <Icon name="trash.outline" size={'s'} />
        </DeleteFileButton>
      )}
      {isLoading && (
        <MarginWrapper marginLeft="s">
          <CircularProgress size={25} />
        </MarginWrapper>
      )}
    </FileFieldWrapper>
  );
};
