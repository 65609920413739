import React from 'react';
import { continueParamsFactory, routes } from '../../../../routes';
import { UserContextProps } from '../../../../providers/user';
import { emitAppEvent } from '../../../../events/use-app-event-broadcast';
import { AppEvents } from '../../../../events/types';
import { Icon } from '@components/Icon';
import { ScreenSizeReturn } from '@shared/hooks/use-screen-size';
import { USER_TABS } from 'types/routesQueryNames';
import { DivWrapper, MarginWrapper } from '@theme/shared/wrappers';
import Bage from '@components/Bage';

interface MenuItemHandlerParams {
  userContext: UserContextProps;
  screenSize: ScreenSizeReturn;
}

export interface MainLayoutMenuItem {
  Icon?: React.ReactNode;
  to: string | ((params: string) => string);
  label: string | React.ReactNode;

  onClick?(params: MenuItemHandlerParams): boolean; // don't change route if return false
  validateVisible?(params: MenuItemHandlerParams): boolean; // don't display item if return false
}

export const mainMenuItems: MainLayoutMenuItem[] = [
  {
    label: 'Новая заявка',
    to: routes.createOrder,
    Icon: <Icon name="create-order-icon.filled" />,
    onClick(params: MenuItemHandlerParams): boolean {
      if (params.userContext?.exist()) {
        return true;
      }

      emitAppEvent(AppEvents.unauthorized_user_try_to_create_order);
      return false;
    },
  },
  {
    label: 'Заявки',
    to: routes.orders(),
    Icon: <Icon name="sm-view-grid-add.outline" />,
  },
  {
    label: (
      <DivWrapper display="flex" flexDirection="column" alignItems="center">
        <span>Поиск</span>
        <span>утилизаторов</span>
      </DivWrapper>
    ),
    to: routes.search(),
    Icon: <Icon name="document-search.outline" />,
  },
  {
    label: 'Новости',
    to: routes.newsList(),
    Icon: <Icon name="speakerphone.outline" />,
  },
  {
    label: 'О сервисе',
    to: routes.about,
    Icon: <Icon name="information-circle.outline" />,
  },
  {
    Icon: <Icon name="chart-square-bar.outline" />,
    label: 'Отчеты',
    to: routes.reports.root,
  },
  {
    Icon: <Icon name="profile.outline" />,
    label: 'Мой профиль',
    to: routes.profile,
    validateVisible: ({ userContext, screenSize: { isMobile, isTablet } }: MenuItemHandlerParams) => {
      return !isMobile && !isTablet && !!userContext?.exist();
    },
  },
];

export const profileMenuItems: MainLayoutMenuItem[] = [
  {
    Icon: <Icon name="profile.outline" />,
    label: 'Мой профиль',
    to: routes.profile,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.exist(),
  },
  {
    Icon: <Icon name="profile.outline" />,
    label: 'Войти',
    to: query => `${routes.loginQuery(continueParamsFactory(query))}`,
    validateVisible: (params: MenuItemHandlerParams) => !params.userContext?.exist(),
  },
  {
    Icon: <Icon name="user-add.outline" />,
    label: 'Регистрация',
    to: query => `${routes.registrationQuery(continueParamsFactory(query))}`,
    validateVisible: (params: MenuItemHandlerParams) => !params.userContext?.exist(),
  },
  {
    Icon: <Icon name="check.outline" />,
    label: 'Верификации',
    to: routes.admin.verifications,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="briefcase.outline" />,
    label: 'Управление тарифами',
    to: routes.admin.tariffs,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="check.outline" />,
    label: 'Опросы',
    to: routes.admin.ask,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="group.solid" />,
    label: 'Пользователи',
    to: routes.admin.users,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="credit-card.outline" />,
    label: 'Тарифы',
    to: routes.tariffs,
  },
  {
    Icon: <Icon name="question-mark-circle.outline" />,
    label: 'Поддержка',
    to: routes.support(),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.getCompany()?.isVerified,
  },
  {
    Icon: <Icon name="logout.outline" />,
    label: 'Выход',
    to: routes.api.logout,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.exist(),
  },
];

export const companyMenuItems: MainLayoutMenuItem[] = [
  {
    Icon: <Icon name="settings.outline" />,
    label: 'Настройки и контакты',
    to: routes.profileCompany({
      [USER_TABS]: `1`,
    }),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.getCompany()?.isVerified,
  },
  {
    Icon: <Icon name="briefcase.outline" />,
    label: 'Контрагенты',
    to: routes.profileCompany({
      [USER_TABS]: `4`,
    }),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.getCompany()?.isVerified,
  },
  {
    Icon: <Icon name="document-text.outline" />,
    label: 'Избранные ФККО',
    to: routes.profileCompany({
      [USER_TABS]: `2`,
    }),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.getCompany()?.isVerified,
  },
  {
    Icon: <Icon name="chart-square-bar.outline" />,
    label: 'Отчетность',
    to: routes.reports.root,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="users.outline" />,
    label: 'Сотрудники',
    to: routes.profileCompany({
      [USER_TABS]: `3`,
    }),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.getCompany()?.isVerified,
  },
  {
    Icon: <Icon name="briefcase.outline" />,
    label: 'Производственные территории',
    to: routes.profileCompany({
      [USER_TABS]: `5`,
    }),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.getCompany()?.isVerified,
  },
  {
    Icon: <Icon name="chart-square-bar.outline" />,
    label: (
      <MarginWrapper display="flex" justifyContent="center" alignItems="center" style={{ flexWrap: 'wrap' }}>
        Учет отходов
        <MarginWrapper marginLeft="xs">
          <Bage variant="light">BETA</Bage>
        </MarginWrapper>
      </MarginWrapper>
    ),
    to: routes.company.accounting({ year: `${new Date().getFullYear()}` }),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
];

export const companyCardMenuItem: MainLayoutMenuItem = {
  Icon: <Icon name="inbox.outline" />,
  label: 'Карточка',
  to: companyId => routes.company.info(companyId),
  validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.getCompany()?.isVerified,
};

export const adminMenuItems: MainLayoutMenuItem[] = [
  {
    Icon: <Icon name="settings.outline" />,
    label: 'Верификации',
    to: routes.admin.verifications,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="settings.outline" />,
    label: 'Опросы',
    to: routes.admin.ask,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="settings.outline" />,
    label: 'Пользователи',
    to: routes.admin.users,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="settings.outline" />,
    label: 'Новости',
    to: routes.admin.news.all(),
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
  {
    Icon: <Icon name="briefcase.outline" />,
    label: 'Управление тарифами',
    to: routes.admin.tariffs,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.isAdmin(),
  },
];

export const commonMenuItems: MainLayoutMenuItem[] = [
  {
    Icon: <Icon name="user-circle.outline" />,
    label: 'Профиль',
    to: routes.profile,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.exist(),
  },
  {
    Icon: <Icon name="logout.outline" />,
    label: 'Выход',
    to: routes.api.logout,
    validateVisible: (params: MenuItemHandlerParams) => !!params.userContext?.exist(),
  },
];
