import { Check } from '@material-ui/icons';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { useMemo } from 'react';
import { StyledTable, StyledTableHead } from './styled';
import { useCompanyData } from 'screens/Company/context';
import { WasteClass } from '@http/models/api/fkko';
import { typesMoveDictionary } from '@constants/company';
import { FkkoLink } from '@components/FkkoLink';
import { getTypeMovesByFlag } from '@shared/utils/helpers';

interface Props {
  fkkoList: WasteClass[];
}

export const Table: React.FC<Props> = ({ fkkoList }) => {
  const { typeMoves } = useCompanyData();
  const moveTypesToShow = useMemo(
    () =>
      getTypeMovesByFlag(typeMoves).filter(type => {
        return fkkoList.some(fkko => fkko.type & type);
      }),
    [typeMoves, fkkoList]
  );

  return (
    <StyledTable size="medium" aria-label="a dense table">
      <StyledTableHead>
        <TableRow>
          <TableCell component="th">Код</TableCell>
          <TableCell component="th">Наименование</TableCell>
          {moveTypesToShow.map((type, index) => (
            <TableCell component="th" key={index}>
              {typesMoveDictionary[type]}
            </TableCell>
          ))}
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {
          // TODO: может фильтровать по fkko у которых нет code или title ?
          fkkoList.map(fkko => (
            <TableRow key={fkko.id}>
              <TableCell scope="row">{fkko.code && <FkkoLink value={fkko.code} />}</TableCell>
              <TableCell>{fkko.code ? <FkkoLink value={fkko.code} customRender={fkko.title} /> : fkko.title}</TableCell>
              {moveTypesToShow.map(type => (
                <TableCell key={type}>
                  {(fkko.type & type) > 0 && (
                    <Check color="primary" fontSize="small" style={{ display: 'block', margin: 'auto' }} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))
        }
      </TableBody>
    </StyledTable>
  );
};
