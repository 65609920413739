import {
  AddCompanyReviewRequest,
  CompanyReviewsBody,
  SetReviewVisibilityRequest,
  CompanyReviewResponse,
  CompanyReview,
  UserReviewsBody,
} from '@http/models/api/reviews';
import { httpService } from '@http/service';
import { HttpOptions } from '@http/service/options';
import { buildQs } from '@http/shared';

export const addCompanyReview = (body: AddCompanyReviewRequest) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.post<number>('/api/reviews', body, options);
};

export const deleteCompanyReview = (id: number) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.delete(`/api/reviews/${id}`, null, options);
};

export const getCompanyReviews = (body: CompanyReviewsBody) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<CompanyReviewResponse[]>(buildQs('/api/reviews/companyReviews', { ...body }), options);
};

export const getUserReviews = ({ userId, isVisible }: UserReviewsBody) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<CompanyReview[]>(
    buildQs('/api/reviews/userReviews', { userId, size: 100500, isVisible }),
    options
  );
};

export const setReviewVisibility = (body: SetReviewVisibilityRequest) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.put('/api/reviews/changeVisibilityReview', body, options);
};
