import React, { FC, useCallback, useState } from 'react';
import { Title } from '../Title';
import { Wrapper, MenuButton } from './styled';
import { MobileMenuModal } from './mobile-menu-modal';
import { MainLayoutTopMenuProps } from '@components/main-layout/top-menu';
import { Icon } from '@components/Icon';
import { DivWrapper } from '@theme/shared/wrappers';
import { GoBackTitle } from '@components/main-layout/shared/GoBackTitle';
import { usePageLoading } from '@shared/hooks/use-page-loading';

export const MobileMenu: FC<MainLayoutTopMenuProps> = ({ title, colorTheme: buttonTheme, isShowBackBtn = false }) => {
  const [isOpenModal, setModalVisibleStatus] = useState(false);
  const handleCloseModal = useCallback(() => setModalVisibleStatus(false), [setModalVisibleStatus]);
  const handleShowMenu = () => setModalVisibleStatus(true);
  const isLoading = usePageLoading();

  const titleRender = <Title title={title} />;

  return (
    <Wrapper alignItems="center">
      <DivWrapper display="flex" gap="xs" width="0" flex="1">
        {isShowBackBtn ? (
          <GoBackTitle title={titleRender} isDisabled={isLoading} isActive={isShowBackBtn} />
        ) : (
          titleRender
        )}
      </DivWrapper>
      <MenuButton onClick={handleShowMenu} buttonTheme={buttonTheme}>
        <Icon name="menu.outline" size="m" />
      </MenuButton>
      <MobileMenuModal isOpen={isOpenModal} onClose={handleCloseModal} />
    </Wrapper>
  );
};
