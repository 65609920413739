import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xs};
`;

export const Wrapper = styled(BoxWrapper)`
  ${({ theme }) => theme.utils.media.moreThanMobile`
      padding: ${({ theme }) => theme.spaces.m};
  `}
`;
