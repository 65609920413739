import { Wrapper, IconWrapper } from './styled';
import { Icon } from '@components/Icon';
import { MarginWrapper } from '@theme/shared/wrappers';
import { Text } from '@theme/shared/text';

interface Props {
  title: string;
  hint?: string;
}

export const SearchNotFound = ({ title, hint }: Props) => {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="ban.filled" />
      </IconWrapper>
      <MarginWrapper display="flex" alignItems="center" marginTop="m">
        <Text.Header3 textAlign="center">{title}</Text.Header3>
      </MarginWrapper>
      {hint && (
        <MarginWrapper display="flex" justifyContent="center" marginTop="xs">
          <Text.Caption textAlign="center">{hint}</Text.Caption>
        </MarginWrapper>
      )}
    </Wrapper>
  );
};
