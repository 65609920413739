import React from 'react';
import { Wrapper } from './styled';

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const Colums = ({ left, right }: Props) => {
  return (
    <Wrapper>
      <div>{left}</div>
      <div>{right}</div>
    </Wrapper>
  );
};
