import {
  OrderApi,
  OrderRequest,
  OrderSearchBody,
  ContactOrder,
  OrderOfferRequest,
  AddOfferFavorite,
} from '@http/models/api/orders';
import { httpService } from '@http/service';
import { HttpOptions } from '@http/service/options';
import { PaginateResponse } from '@http/models/api';
import { ApiPaginateParams, buildQs } from '@http/shared';
import { OrderRowCPResponse } from '@http/models/api/fkko';
import { Nullable } from 'types';

export const createOrder = (body: OrderRequest) => {
  return httpService.post<number>('/orders', body, new HttpOptions().withCredentials(false).asAuthRoute());
};

interface Params {
  body: OrderSearchBody;
  paginate?: ApiPaginateParams;
  authToken?: Nullable<string>;
}

export const getOrders = ({ body, paginate, authToken }: Params) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  if (authToken) {
    options.setHeader('Authorization', `Bearer ${authToken}`);
  }
  return httpService.get<PaginateResponse<OrderApi>>(buildQs('/orders', { ...body, ...paginate }), options);
};

export const getOrderById = (id: number, authToken?: Nullable<string>) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  if (authToken) {
    options.setHeader('Authorization', `Bearer ${authToken}`);
  }
  return httpService.get<OrderApi>(buildQs(`/orders/${id}`), options);
};

export const getContact = (id: number) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<ContactOrder>(buildQs(`/orders/${id}/Contact`), options);
};

export const deleteOrder = (id: number) => {
  const options = new HttpOptions().withCredentials(false).asAuthRoute();
  return httpService.delete(`/orders/${id}`, null, options);
};

export const createOffer = (model: OrderOfferRequest) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.post<number>(`/orders/cp`, model, options);
};

export const addOfferFavorite = (body: AddOfferFavorite) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.post<number>(`/orders/cp/interesting`, body, options);
};

//fkko
export const getRowsforOffer = (id: number, authToken?: Nullable<string>) => {
  const options = new HttpOptions().withCredentials(false);
  if (authToken) {
    options.setHeader('Authorization', `Bearer ${authToken}`);
  }
  return httpService.get<OrderRowCPResponse>(buildQs(`/orders/${id}/fkko`), options);
};
