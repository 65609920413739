import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@theme/shared/text';

interface Props {
  className?: string;
}

const Badge: React.FC<Props> = ({ children, className }) => {
  return <Text.Caption className={className}>{children}</Text.Caption>;
};

interface StyledBageProps {
  variant: 'secondary' | 'light' | 'grey' | 'error';
  opacity?: number;
}

const StyledBadge = styled(Badge)<StyledBageProps>`
  padding: 4px 10px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background: ${({ theme, variant }) => {
    if (variant === 'secondary') {
      return theme.palette.main.secondary;
    }

    if (variant === 'light') {
      return theme.palette.main.light;
    }

    if (variant === 'grey') {
      return theme.palette.grey.background;
    }

    if (variant === 'error') {
      return theme.palette.system.errorLight;
    }

    return 'none';
  }};
  color: ${({ variant, theme }) => {
    if (['secondary'].includes(variant)) return theme.palette.main.dark;
    if (['error'].includes(variant)) return theme.palette.system.error;

    return theme.palette.text.primary;
  }};

  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

  svg {
    margin-right: 0.25rem;
    width: 14px;
  }
`;

export default StyledBadge;
