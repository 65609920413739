import React, { FC, useState } from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import { ClickAwayListener } from '@material-ui/core';
import { MobileTooltipButton } from './styled';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { useAppEventBroadcast } from 'events/use-app-event-broadcast';
import { AppEvents } from 'events/types';

const MobileTooltip: FC<TooltipProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(s => !s);
  };

  useAppEventBroadcast(AppEvents.scroll_layout, () => {
    setIsOpen(false);
  });

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <MuiTooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        {...props}
      >
        <MobileTooltipButton onClick={handleOpen}>{props.children}</MobileTooltipButton>
      </MuiTooltip>
    </ClickAwayListener>
  );
};

export const Tooltip: FC<TooltipProps> = props => {
  const { isDesktop } = useScreenSize();

  if (isDesktop) {
    return <MuiTooltip {...props}>{props.children}</MuiTooltip>;
  }

  return <MobileTooltip {...props}>{props.children}</MobileTooltip>;
};
