import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const Container = styled.div``;
export const UploadPhotoPlaceholder = styled.div`
  width: 270px;
  height: 240px;
  background-color: #f3fbee;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: 1px solid ${({ theme }) => theme.palette.main.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xxs};
  padding: ${({ theme }) => theme.spaces.m};
  text-align: center;
  cursor: pointer;
`;

export const PlaceholderCaption = styled(Text.MenuCaption)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;
