import { FC } from 'react';
import { ContactInfoValue, WrapInfoItem, Wrapper, ContactInfoTitle } from './styled';
import { Text } from '@theme/shared/text';
import { companyEmail, companyPhoneNumber } from '@constants/company';
import { clearPhoneString } from '@shared/utils/clearPhoneString';
import { Icon } from '@components/Icon';

const ContactsInfo: FC = () => {
  return (
    <Wrapper>
      <Text.Header2>Контакты</Text.Header2>
      <WrapInfoItem>
        <ContactInfoTitle>
          <Icon name="phone.solid" /> Телефон:
        </ContactInfoTitle>
        <ContactInfoValue href={`tel:${clearPhoneString(companyPhoneNumber)}`}>{companyPhoneNumber}</ContactInfoValue>
      </WrapInfoItem>
      <WrapInfoItem>
        <ContactInfoTitle>
          <Icon name="emailOpen.solid" /> E-mail:
        </ContactInfoTitle>
        <ContactInfoValue href={`mailto:${companyEmail}`}>{companyEmail}</ContactInfoValue>
      </WrapInfoItem>
    </Wrapper>
  );
};

export default ContactsInfo;
