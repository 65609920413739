import { FC, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import { EmptyLogoContent, IconWrapper, LoadLabel, Wrapper, CameraIcon } from './styled';
import { FileUploader } from '@components/FileUploader';
import { useCompany, useCompanyAddFile, useCompanyDeleteFile } from '@http/hooks/company';
import { CompanyViewModel } from '@http/models/view-models/company';
import { FileType } from '@http/models/api/file';
import { Nullable } from 'types';
import { parseApiErrors } from '@http/shared';
import { useNotifications } from 'providers/notifications';
import { useUser } from 'providers/user';
import { useConfirmDialog } from 'providers/confirm-dialog';
import { Skeleton } from '@theme/shared/components';
import { useTariffGuard } from 'providers/tariff-guard';
import { UploadedFilesParams } from '@components/FileUploader/types';

const DynamicLogo = dynamic(() => import('./Logo'), {
  ssr: false,
  loading: () => <Skeleton />,
});

enum ErrorTypes {
  AddLogo = 'add-company-logo',
  AddMiniature = 'add-company-logo-miniature',
  DeleteLogo = 'delete-company-logo',
  DeleteLogoMiniature = 'delete-company-logo-miniature',
}

export interface CompanyLogoProps {
  logoImage: Nullable<string>;
  company: CompanyViewModel;
}

export const CompanyLogo: FC<CompanyLogoProps> = ({ logoImage, company }) => {
  const [imageUploaderOpened, setImageUploaderOpened] = useState<boolean>(false);
  const [imagesToSave, setImagesToSave] = useState<UploadedFilesParams>({});
  const confirmDialog = useConfirmDialog();
  const { isActionAllowed, setModalOpen } = useTariffGuard();

  const notifications = useNotifications();
  const { refetch } = useCompany(company.id);
  const user = useUser();
  const userCompany = user.getCompany();

  const isUserCanEdit = (userCompany?.id === company.id && userCompany.isVerified) || user.isAdmin();

  const commonErrorHandler = (errorType: ErrorTypes) => {
    return function errorHandler(err: unknown) {
      const apiErrors = parseApiErrors(err);
      Sentry.captureException(err, {
        extra: {
          apiErrors: apiErrors,
        },
        tags: {
          label: errorType,
        },
      });
      notifications.push({
        severity: 'error',
        text:
          apiErrors && apiErrors.length > 0
            ? apiErrors.join(', ')
            : 'Ошибка сервера, пожалуйста обратитесь к администратору',
      });
    };
  };

  const getFormData = (companyId: number, fileType: FileType, fileData: Blob) => {
    const formData = new FormData();
    formData.append('companyId', String(companyId));
    formData.append('fileType', String(fileType));
    formData.append('file', fileData);
    return formData;
  };

  const { mutate: addCompanyLogo, isLoading: isLoadingAddCompanyLogo } = useCompanyAddFile({
    successHandler: () => {
      if (imagesToSave.cropedMiniature) {
        const formData = getFormData(company.id, FileType.CompanyShortIcon, imagesToSave.cropedMiniature);
        addCompanyMiniature(formData);
      }
    },
    errorHandler: commonErrorHandler(ErrorTypes.AddLogo),
  });

  const { mutate: addCompanyMiniature, isLoading: isLoadingAddCompanyMitiature } = useCompanyAddFile({
    successHandler: () => {
      setImageUploaderOpened(false);
      refetch();
      setImagesToSave({});
      notifications.push({
        severity: 'success',
        text: 'Логотип успешно изменен',
      });
    },
    errorHandler: commonErrorHandler(ErrorTypes.AddMiniature),
  });

  const { mutate: deleteCompanyLogo } = useCompanyDeleteFile({
    successHandler: () => {
      if (company.iconShort) {
        deleteCompanyMiniature(company.iconShort);
      }
    },
    errorHandler: commonErrorHandler(ErrorTypes.DeleteLogoMiniature),
  });

  const { mutate: deleteCompanyMiniature } = useCompanyDeleteFile({
    successHandler: () => {
      notifications.push({
        severity: 'success',
        text: 'Логотип успешно удален',
      });
      refetch();
    },
    errorHandler: commonErrorHandler(ErrorTypes.DeleteLogoMiniature),
  });

  const isUpdateLoading = isLoadingAddCompanyLogo || isLoadingAddCompanyMitiature;

  const handleSaveImage = (images: UploadedFilesParams) => {
    if (company.id) {
      setImagesToSave(images);
    }
  };

  useEffect(() => {
    if (imagesToSave.singleImage && imagesToSave.cropedMiniature) {
      const formData = getFormData(company.id, FileType.CompanyIcon, imagesToSave.singleImage);
      addCompanyLogo(formData);
    }
  }, [imagesToSave]);

  const handleImageEditCick = () => {
    if (isActionAllowed('EditCompanyLogo')) {
      setImageUploaderOpened(true);
      return;
    }

    setModalOpen(true);
  };

  const handleDeleteLogoClick = () => {
    if (isActionAllowed('EditCompanyLogo')) {
      confirmDialog.show({
        onApply: () => {
          if (logoImage) {
            deleteCompanyLogo(logoImage);
          }
        },
        dialogText: 'Вы уверены, что хотите удалить логотип?',
      });

      return;
    }

    setModalOpen(true);
  };

  const handleUploaderClose = () => {
    !isUpdateLoading && setImageUploaderOpened(false);
  };

  if (!isUserCanEdit) {
    return logoImage ? (
      <Wrapper>
        <DynamicLogo imgPath={logoImage} onEdit={handleImageEditCick} onDelete={handleDeleteLogoClick} />
      </Wrapper>
    ) : null;
  }

  return (
    <Wrapper>
      {logoImage ? (
        <DynamicLogo
          imgPath={logoImage}
          editable={isUserCanEdit}
          onEdit={handleImageEditCick}
          onDelete={handleDeleteLogoClick}
        />
      ) : (
        <EmptyLogoContent onClick={handleImageEditCick}>
          <IconWrapper>
            <CameraIcon name="camera-in-circle.solid" />
          </IconWrapper>
          <LoadLabel>Загрузите логотип</LoadLabel>
        </EmptyLogoContent>
      )}
      <FileUploader
        title="Добавить логотип"
        dropzoneText="Загрузите логотип"
        dropzoneCaption={<>Размер 1800 х 300. Формат&nbsp;png,&nbsp;jpg, не&nbsp;более&nbsp;10&nbsp;МБ</>}
        isOpen={imageUploaderOpened}
        withCrop
        singleImage
        isFething={isUpdateLoading}
        onClose={handleUploaderClose}
        onSave={handleSaveImage}
      />
    </Wrapper>
  );
};
