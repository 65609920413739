import { config } from './config';
import { runOnClient } from '@shared/utils/is-client';

export const initApp = () => {
  runOnClient(() => {
    (window as any).appInfo = {
      ui_version: config().UI_VERSION,
      admin_endpoint: config().API_URL,
      mode: config().mode,
    };
  });
};
