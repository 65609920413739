import { Component } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Page500Content } from '.';
import { parseApiErrors } from '@http/shared';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary500 extends Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e: any) {
    const apiErrors = parseApiErrors(e);
    Sentry.captureException(e, {
      extra: {
        apiErrors,
      },
      tags: {
        label: 'page-error',
      },
    });

    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Page500Content />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary500;
