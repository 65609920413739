import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { nanoid } from 'nanoid';
import { StyledTable, StyledTableHead, TableWrapper } from './styled';

export interface Column<T> {
  name: string;
  render: (model: T, index: number) => React.ReactNode;
  ceilVerticalAlign?: 'inherit' | 'text-top' | 'middle';
  width?: string;
}

export interface TableProps<T> {
  data: T[];
  id?: string;
  columns: Column<T>[];
  stickyHeader?: boolean;
  enableMarks?: boolean;
  getDataRowId?: (data: T) => string | undefined;
  getRowId?: (data: T) => string | undefined;
  renderRowControls?: (item: T, rowNode?: any) => string | React.ReactNode;
}

const getTableHtmlById = (tableId: string, rowIndex: number) => {
  return document.querySelector(`table[id=${tableId}] tbody tr:nth-of-type(${rowIndex + 1})`);
};

export function Table<T>({
  data,
  columns,
  stickyHeader,
  enableMarks,
  getDataRowId,
  getRowId,
  renderRowControls,
  id,
}: TableProps<T>) {
  const [tableId] = useState(id || nanoid(3));
  return (
    <TableWrapper enableMarks={Boolean(enableMarks)}>
      <StyledTable id={tableId} size="medium" aria-label="a dense table" stickyHeader={stickyHeader}>
        <StyledTableHead>
          <TableRow>
            {columns.map((column, idx) => (
              <TableCell key={column.name + idx} component="th" style={getCellStyle(column)}>
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {data.map((item, itemIndex) => {
            return (
              <TableRow
                key={`row_${itemIndex}`}
                id={getRowId ? getRowId(item) : undefined}
                data-row-id={getDataRowId ? getDataRowId(item) : undefined}
              >
                {columns.map((column, idx) => (
                  <TableCell key={`row_${column.name}_${idx}`} scope="row" style={getCellStyle(column)}>
                    {column.render(item, itemIndex)}
                    {renderRowControls &&
                      idx + 1 === columns.length &&
                      renderRowControls(item, getTableHtmlById(tableId, itemIndex))}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

function getCellStyle<T>(column: Column<T>) {
  const style: CSSProperties = {};

  if (column.ceilVerticalAlign) {
    style.verticalAlign = column.ceilVerticalAlign;
  }

  if (column.width) {
    style.width = column.width;
    style.minWidth = column.width;
    style.maxWidth = column.width;
  }

  return style;
}
