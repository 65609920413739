import React from 'react';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { createPathMenuItem, routes } from '../../../routes';
import { useUser } from '../../../providers/user';
import { isProduction } from '../../../config';
import {
  Wrapper,
  MenuWrapper,
  MenuIcon,
  MenuLabel,
  MenuItem,
  LogoWrapper,
  LogoLabel,
  LinkContent,
  DevIndicator,
} from './styled';
import MainLogo from '@assets/svg/logo.svg';
import { MainLayoutMenuItem, mainMenuItems } from '@components/main-layout/shared/menu-items';
import { useVisibleMenuItems } from '@components/main-layout/shared/menu-items/hooks';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { Icon } from '@components/Icon';

export const LeftSidebar = () => {
  const router = useRouter();
  const user = useUser();
  const screenSize = useScreenSize();
  const menuItems = useVisibleMenuItems(mainMenuItems);
  const handleClickLink = (e: React.MouseEvent, item: MainLayoutMenuItem) => {
    e.preventDefault();
    if (item.onClick && !item.onClick({ userContext: user, screenSize })) {
      return;
    }
    router.push(createPathMenuItem(item.to, router.asPath));
  };
  return (
    <Wrapper>
      <Link passHref href={routes.home}>
        <LinkContent>
          {!isProduction() && <DevIndicator>DEV</DevIndicator>}
          <LogoWrapper>
            <MainLogo />
            <LogoLabel>
              <Icon name="logo-text.filled" />
            </LogoLabel>
          </LogoWrapper>
        </LinkContent>
      </Link>
      <MenuWrapper>
        {menuItems.map((item, index) => {
          const { Icon, to, label } = item;
          const isActive = typeof to === 'string' && router.pathname === to.split('?')[0];

          return (
            <a
              onClick={e => handleClickLink(e, item)}
              key={index}
              href={createPathMenuItem(item.to, router.asPath)}
              className="text-decoration-none"
            >
              <MenuItem isActive={isActive}>
                <MenuIcon>{Icon}</MenuIcon>
                <MenuLabel>{label}</MenuLabel>
              </MenuItem>
            </a>
          );
        })}
      </MenuWrapper>
    </Wrapper>
  );
};
