import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

export const Notification: React.FC<{ isOpen: boolean }> = ({ isOpen, children }) => {
  if (isOpen) {
    return (
      <Wrapper
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        {children}
      </Wrapper>
    );
  }

  return null;
};

const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: ${({ theme }) => theme.spaces.s};
  left: ${({ theme }) => theme.spaces.s};
  right: ${({ theme }) => theme.spaces.s};
  background: ${({ theme }) => rgba(theme.colors.medium, 0.99)};
  padding: ${({ theme }) => theme.spaces.s};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  color: #fff;
  font-size: 14px;
  z-index: 200;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    left: initial;
    max-width: 330px;
  `}
`;
