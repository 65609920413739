import { Close } from '@material-ui/icons';
import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import Link from 'next/link';
import React from 'react';
import { useUser } from '../../../../../providers/user';
import {
  StyledModal,
  Wrapper,
  Header,
  Logo,
  CloseIcon,
  MenuItem,
  MenuItems,
  MenuLabel,
  MenuIcon,
  LogoLabel,
} from './styled';
import { createPathMenuItem, routes } from 'routes';
import {
  companyCardMenuItem,
  companyMenuItems,
  MainLayoutMenuItem,
  mainMenuItems,
  profileMenuItems,
} from '@components/main-layout/shared/menu-items';
import { useVisibleMenuItems } from '@components/main-layout/shared/menu-items/hooks';
import { Separator } from '@theme/shared/components';
import { Icon } from '@components/Icon';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { DivWrapper } from '@theme/shared/wrappers';

interface Props {
  isOpen: boolean;

  onClose(): void;
}

export const MobileMenuModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { route, pathname, asPath, push: routerPush } = useRouter();
  const user = useUser();
  const screenSize = useScreenSize();

  useEffect(() => {
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const mainMenu = useVisibleMenuItems(mainMenuItems);
  const extraMenu = useVisibleMenuItems(profileMenuItems);
  const companyMenu = useVisibleMenuItems(companyMenuItems);
  const [companyCardItem] = useVisibleMenuItems([companyCardMenuItem]);

  const handleClickLink = (e: React.MouseEvent, item: MainLayoutMenuItem, path?: string) => {
    e.preventDefault();
    if (item.onClick && !item.onClick({ userContext: user, screenSize })) {
      return;
    }

    const href = createPathMenuItem(item.to, path);

    if (pathname === href) {
      onClose();
    }

    routerPush(href);
  };

  const renderMenuItem = (item: MainLayoutMenuItem, index: number, path?: string) => {
    const { Icon, to, label } = item;
    return (
      <a
        href={createPathMenuItem(to, path)}
        onClick={e => handleClickLink(e, item, path)}
        key={index}
        className="text-decoration-none"
      >
        <MenuItem isActive={pathname === to} onClick={onClose}>
          <MenuIcon>{Icon}</MenuIcon>
          <MenuLabel>{label}</MenuLabel>
        </MenuItem>
      </a>
    );
  };

  const renderMenuItems = (items: MainLayoutMenuItem[]) => {
    return items.map((item, index) => renderMenuItem(item, index, asPath));
  };

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <Wrapper>
        <Header>
          <Link href={routes.home} passHref>
            <Logo className="text-decoration-none">
              <Icon name="logo.filled" />
              <LogoLabel>
                <Icon name="logo-text.filled" />
              </LogoLabel>
            </Logo>
          </Link>
          <CloseIcon onClick={onClose}>
            <Close fontSize="large" />
          </CloseIcon>
        </Header>
        <MenuItems>
          {companyCardItem && renderMenuItem(companyCardItem, 0, String(user.getCompany()?.id))}
          {renderMenuItems(mainMenu)}
        </MenuItems>
        <Separator />
        <MenuItems>{renderMenuItems(companyMenu)}</MenuItems>
        <Separator />
        <MenuItems>{renderMenuItems(extraMenu)}</MenuItems>
        <DivWrapper marginTop="l" style={{ height: '50px' }} />
      </Wrapper>
    </StyledModal>
  );
};
