export const isEmpty = (data: any): boolean => {
  if (data) {
    if (Array.isArray(data) && data.length > 0) {
      return false;
    }
    if (data instanceof Object && Object.keys(data).length > 0) {
      return false;
    }
  }

  return true;
};

export const isNotEmpty = (data: any): boolean => !isEmpty(data);
