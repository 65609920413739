import { makeStyles } from '@material-ui/core';

export const useGlobalStyles = makeStyles({
  '@global': {
    body: {
      fontSize: '1rem',
      lineHeight: 1.5,
      background: '#fff',
    },
  },
});
