import { last } from 'ramda';
import { WasteType } from '@http/models/api/fkko';
import { CellProps } from 'screens/Fkko/Fkko/Components/Cell';
import { Nullable } from 'types';

class ExplanationType {
  name: string;
  desc: string;
}

type RecType = CellProps & {
  id: number;
  title: string;
  explanation: ExplanationType | ExplanationType[];
};

type fkkoGroup = 'блок' | 'тип' | 'подтип' | 'группа' | 'подгруппа' | 'позиция';

const constFkkoBlocks = [
  {
    id: '1',
    name: 'Отходы сельского, лесного хозяйства, рыбоводства и рыболовства',
  },
  {
    id: '2',
    name: 'Отходы добычи полезных ископаемых',
  },
  {
    id: '3',
    name: 'Отходы обрабатывающих производств',
  },
  {
    id: '4',
    name: 'Отходы потребления производственные и непроизводственные; материалы, изделия, утратившие потребительские свойства, не вошедшие в блоки 1 - 3, 6 - 9',
  },
  {
    id: '5',
    name: '',
  },
  {
    id: '6',
    name: 'Отходы обеспечения электроэнергией, газом и паром',
  },
  {
    id: '7',
    name: 'Отходы при водоснабжении, водоотведении, деятельности по сбору, обработке, утилизации, обезвреживанию, размещению отходов',
  },
  {
    id: '8',
    name: 'Отходы строительства и ремонта',
  },
  {
    id: '9',
    name: 'Отходы при выполнении прочих видов деятельности, не вошедшие в блоки 1 - 3, 6 - 8',
  },
];

const aggregates = {
  '10': 'Жидкое /Индивидуальные вещества, растворы',
  '20': 'Твердое /Используется, если твердый отход представлен смесью различных физических форм',
  '21': 'Кусковая форма',
  '22': 'Стружка',
  '23': 'Волокно',
  '29': 'Прочие формы твердых веществ',
  '30': 'Дисперсные системы',
  '31': 'Жидкое в жидком /Эмульсия',
  '32': 'Твердое в жидком /Суспензия',
  '33': 'Твердое в жидком /Паста',
  '39': 'Прочие дисперсные системы',
  '40': 'Твердые сыпучие материалы',
  '41': 'Порошок',
  '42': 'Пыль',
  '43': 'Опилки',
  '49': 'Прочие сыпучие материалы',
  '50': 'Изделия из твердых материалов, за исключением волокон',
  '51': 'Изделие из одного материала',
  '52': 'Изделия из нескольких материалов',
  '53': 'Изделия, содержащие жидкость',
  '54': 'Изделия, содержащие газ',
  '60': 'Изделия из волокон',
  '61': 'Изделие из одного волокна',
  '62': 'Изделия из нескольких волокон',
  '70': 'Смеси твердых материалов и изделий',
  '71': 'Смесь твердых материалов (включая волокна)',
  '72': 'Смесь твердых материалов (включая волокна) и изделий',
  '00': 'Не требует определения агрегатного состояния и физической формы',
};

const warningClass = {
  '1': 'I класс - Наиболее токсичная группа опасных отходов. Сюда входят остатки, которые могут нанести серьезный вред окружающей среде и людям',
  '2': 'II класс - Высокий показатель опасности. После нанесения ущерба экологии, прежняя ситуация нормализуется минимум через 30 лет',
  '3': 'III класс - Умеренно опасные отходы. Нарушают экологию, но восстановление длится идет гораздо быстрее, примерно – 10 лет',
  '4': 'IV класс - Малоопасные отходы. Низкая степень негативного воздействия на окружающую среду, время восстановления после ущерба не менее 3 лет',
  '5': 'V класс - Безвредные. Практически неопасные. Их угроза окружающей среде стремится к 0',
};

export const warningClassMap: Record<string, string> = {
  '1': 'I',
  '2': 'II',
  '3': 'III',
  '4': 'IV',
  '5': 'V',
};

const records: RecType[] = [
  {
    id: 1,
    cellNumber: '',
    color: 'accent',
    title: 'Номер блока ФККО',
    explanation: {
      name: '',
      desc: '',
    },
  },
  {
    id: 2,
    cellNumber: '',
    color: 'lightBlue',
    title: 'Код происхождения вида отходов и их состава',
    explanation: [],
  },
  {
    id: 3,
    cellNumber: '',
    color: 'lightGreen',
    title: 'Код агрегатного состояния и физической формы вида отхода',
    explanation: {
      name: '',
      desc: '',
    },
  },
  {
    id: 4,
    cellNumber: '',
    color: 'pink',
    title: 'Код класса опасности вида отходов в зависимости от степени негативного воздействия на окружающую среду',
    explanation: {
      name: '',
      desc: '',
    },
  },
];

export class FkkoUtil {
  code: Nullable<string>;
  parent: WasteType;

  constructor(wasteClass: WasteType) {
    this.code = wasteClass.code;
    this.parent = wasteClass.parent;
  }

  public getFkkoGroupByCode(): Nullable<fkkoGroup> {
    return FkkoUtil.fkkoGroupByCode(this.code);
  }

  public mapFkkoRecords(): RecType[] {
    if (!this.code) return records;

    const first = records.find(x => x.id === 1);
    if (first) {
      first.cellNumber = this.code.substring(0, 1);
      first.explanation = {
        name: this.code.substring(0, 1),
        desc: constFkkoBlocks.find(x => x.id == first.cellNumber)?.name || '',
      };
    }

    const second = records.find(x => x.id === 2);
    if (second) {
      second.cellNumber = `${this.code.substring(1, 3)} ${this.code.substring(3, 6)} ${this.code.substring(6, 8)}`;

      const explanationParent = new ExplanationType();
      explanationParent.name = this.parent.code || '';
      explanationParent.desc = this.parent.title || '';
      second.explanation = [explanationParent];
    }

    const third = records.find(x => x.id === 3);
    if (third) {
      third.cellNumber = this.code.substring(8, 10);
      third.explanation = {
        name: third.cellNumber,
        desc: (aggregates as any)[third.cellNumber],
      };
    }

    const four = records.find(x => x.id === 4);
    if (four) {
      four.cellNumber = this.code.substring(10);
      four.explanation = {
        name: '',
        desc: (warningClass as any)[four.cellNumber],
      };
    }
    return records;
  }

  public excludeZeroPoints(records: RecType[]): RecType[] {
    return records.filter(({ cellNumber }) => !['0', '00'].includes(cellNumber));
  }

  public excludeZeroPointsMapped(): RecType[] {
    return this.excludeZeroPoints(this.mapFkkoRecords());
  }

  static fkkoGroupByCode(code: Nullable<string>): Nullable<fkkoGroup> {
    if (!code) return null;
    if (code.includes('0000000000')) return 'блок';
    if (code.includes('000000000')) return 'тип';
    if (code.includes('00000000')) return 'подтип';
    if (code.includes('0000000')) return 'группа';
    if (code.includes('000')) return 'подгруппа';
    if (code.substring(9) != '00') return 'позиция';
    return null;
  }

  static isValidForOrder(candidate: string): boolean {
    return candidate.slice(-3) !== '000';
  }

  static isValidForAccounting(candidate: string): boolean {
    return FkkoUtil.isValidForOrder(candidate);
  }

  static isValidForRpnReport(candidate: string): boolean {
    return candidate.slice(-1) !== '0';
  }

  static getWarningClass(code?: string): Nullable<number> {
    if (!code) {
      return null;
    }
    const warningClassId = last(code);
    return warningClassId ? Number(warningClassId) : null;
  }

  static warningClassByCode(code: string): string {
    const warningClassId = FkkoUtil.getWarningClass(code);
    const warningClass = warningClassId && warningClassMap[warningClassId.toString()];

    if (!warningClass) {
      return '';
    }
    return `${warningClass} класс опасности`;
  }

  static splitCode(code?: string): string {
    if (!code) {
      return '';
    }

    return [
      code.substring(0, 1),
      code.substring(1, 3),
      code.substring(3, 6),
      code.substring(6, 8),
      code.substring(8, 10),
      code.substring(10),
    ].join(' ');
  }
}
