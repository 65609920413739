import styled from 'styled-components';
import { DESKTOP_LEFT_SIDEBAR_WIDTH_PX } from './shared/constants';

export const Wrapper = styled.main`
  height: inherit;
`;

export const RightContent = styled.div.attrs(() => ({
  'data-scrollable-root': true,
}))`
  height: inherit;
  position: relative;

  ${({ theme }) => {
    return theme.utils.media.desktop`
        position: fixed;
        overflow: scroll;
        top: 0;
        right: 0;
        left: ${DESKTOP_LEFT_SIDEBAR_WIDTH_PX}px;
      `;
  }}
`;

export const ContentWrapper = styled.div`
  padding-top: 66px; // свиг для меню
  padding-bottom: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.desktop`
    padding-top: 0;
  `}
`;

export const Content = styled.div<{ noPaddingMobile?: boolean }>`
  height: inherit;
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme, noPaddingMobile }) => (noPaddingMobile ? '' : theme.spaces.xs)};
  padding-right: ${({ theme, noPaddingMobile }) => (noPaddingMobile ? '' : theme.spaces.xs)};

  ${({ theme }) => {
    return theme.utils.media.moreThanMobile`
      padding-left: ${theme.spaces.m};
      padding-right: ${theme.spaces.m};
    `;
  }}
`;

export const UserCounterWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spaces.s};
`;
