import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../../providers/user';
import { Wrapper, Location, LocationMarker, CompanyName } from './styled';
import { OfferShortViewModel } from '@http/models/view-models/offer';
import { kgToTons, totalBy } from '@shared/utils/helpers';
import { Icon } from '@components/Icon';
import { MarginWrapper } from '@theme/shared/wrappers';
import { Text } from '@theme/shared/text';
import { breaksHundreds, normalizeFloatDigits } from '@lib/Components/fields/input-value-helpers';
import { DEFAULT_MAX_FLOAT_DIGITS } from '@constants/numbers';
import { useModals } from 'providers/modals';
import { routes } from 'routes';
import { ActiveModals } from 'providers/modals/types';

interface Props {
  offer: OfferShortViewModel;
}

export const OfferShort = ({ offer }: Props) => {
  const user = useUser();
  const router = useRouter();
  const isMyOffer = offer.companyId === user.getCompany()?.id;
  const offerModal = useModals();
  const totalWeight = useMemo(() => {
    if (!offer.order.rows) {
      return '';
    }

    const total = totalBy(offer.order.rows, r => kgToTons(r.netto));
    return breaksHundreds(normalizeFloatDigits(total, DEFAULT_MAX_FLOAT_DIGITS));
  }, [offer.order.rows]);

  const handleOpenOffer = () => {
    const payload: ActiveModals = {
      type: 'offer',
      adjectiveId: offer.orderId.toString(),
      activeId: offer.id.toString(),
    };

    if (offerModal.shouldOpenPage(payload)) {
      router.push(routes.offer(offer.orderId, offer.id));
      return;
    }

    offerModal.open(payload);
  };

  return (
    <Wrapper onClick={handleOpenOffer}>
      <CompanyName>
        <Text.Header3>{isMyOffer ? offer.order.companyName : offer.companyName}</Text.Header3>
      </CompanyName>
      <Location>
        <LocationMarker>
          <Icon name="location-marker.filled" />
        </LocationMarker>
        <MarginWrapper marginLeft="xs">
          <Text.Body>{offer.order.regionTitle}</Text.Body>
        </MarginWrapper>
      </Location>
      <br />
      <div>Дата подачи: {DateTime.fromISO(offer.createdAt).toLocaleString()}</div>
      <div>Дата создания: {DateTime.fromISO(offer.order.createdAt).toLocaleString()}</div>
      <div>Общая масса: {totalWeight} т.</div>
    </Wrapper>
  );
};
