import { Nullable } from '../../../types';
import { SoCloseDistanceAPI } from './company';

export interface RegistrationUserRequestModel {
  phone: Nullable<string>;
  email: string;
  nickname: string;
  companyId?: number;
}

export interface LoginUserRequestModel {
  username: string;
  password: string;
}

export interface UserTokenApi {
  bearer: string;
  role: string;
  userId: string;
}

export interface UserDataApi {
  email: string;
  phone: Nullable<string>;
  nickname: string;
  type: UserTypeApi;
  lastDateActivity: string;
  verifiedDate: string;
  id: number;
  companyIds: number[];
  company: Nullable<UserCompany[]>;
  metaData?: string;
}

export enum UserTypeApi {
  USER = 1,
  ADMIN = 2,
  COMPANY_ADMIN,
}

export interface UpdatableUserRequestModel {
  phone?: string;
  nickname?: string;
  password?: string;
}

export interface UserCompany {
  id: number;
  isVerified: Nullable<boolean>;
  inn: string;
  title: string;
  nds: boolean;
  subExpireDate?: string;
  subName?: string;
  subType?: number;
}

export interface UsersForVerifiedResponse {
  recods: UserForVerified[];
}

export interface UserForVerified extends UserDataApi {
  companyInn: string;
  companyId: number;
  companyTitle: string;
  verifiedCode: string;
  companyComment: string;
  companyPhone: string;
  hasLicense: string;
  subExpireDate?: string;
  subName?: string;
  subType?: number;
  companyBalance?: number;
}

export interface UserCompanyUpdateRequest {
  companyId: number;
  phone: Nullable<string>;
  email: Nullable<string>;
  site: Nullable<string>;
  stationaryPhone: Nullable<string>;
  nds: Nullable<boolean>;
  receipt: Nullable<boolean>;
  fullTitle: Nullable<string>;
  inn: Nullable<string>;
  ogrn: Nullable<string>;
  kpp: Nullable<string>;
  okpo: Nullable<string>;
  okved: Nullable<string>;
  address: Nullable<string>;
  oktmo: Nullable<string>;
  postAddress: Nullable<string>;
  postOKTMO: Nullable<string>;
  regionId: Nullable<number>;
  link: string;
  addressCode: number;
  postAddressCode: Nullable<number>;
}

export interface UserCompanyAdditionalUpdateRequest {
  id?: number;
  companyId: number;
  surname: Nullable<string>;
  name: Nullable<string>;
  middlename: Nullable<string>;
  registerNalogDate: Nullable<string>;
  registerNalogDocumentDate: Nullable<string>;
  mailAddress: Nullable<string>;
  isDetachedAssociation: Nullable<boolean>;
  isForeignPerson: Nullable<boolean>;
  descriptionShort: Nullable<string>;
}

export interface UserForVerifiedExtra extends UserForVerified {
  createdAt: string;
  isVerified: Nullable<boolean>;
  lastDateActivity: string;
}

export interface UserForVerifiedTotalRecord {
  recods: UserForVerifiedExtra[];
  totalCount: number;
  errorMessage: Nullable<string>;
  soCloseDistance: Nullable<SoCloseDistanceAPI[]>;
}

export interface UsersAdminQuery {
  userEmail?: string;
  titleOrInn?: string;
  isVerified?: boolean;
  from?: string;
  to?: string;
  size?: number;
  skip?: number;
}

export interface UpdateCompanyDescriptionRequest {
  companyId: number;
  description: string;
}
