import { FC, useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { CircularProgress } from '@material-ui/core';
import * as Sentry from '@sentry/nextjs';
import { CompanyTextField, Container, Title, StyledButton, CardHeader } from './styled';
import { validationSchema } from './validationSchema';
import { TextTruncated } from './TextTruncated';
import { useNotifications } from 'providers/notifications';
import { useUser } from 'providers/user';
import { useCompanyDescriptionUpdate } from '@http/hooks/user';
import { parseApiErrors } from '@http/shared';
import { CompanyFullAPI } from '@http/models/api/company';
import { useCompany } from '@http/hooks/company';
import { useTariffGuard } from 'providers/tariff-guard';

export interface Props {
  company: CompanyFullAPI;
}

export const CompanyDescription: FC<Props> = ({ company }) => {
  const notifications = useNotifications();
  const user = useUser();
  const userCompany = user.getCompany();
  const isUserCanEdit = userCompany?.isVerified && (user.isAdmin() || userCompany?.id === company.id);
  const { refetch } = useCompany(company.id);

  const { description = '' } = company || {};

  const [isEditing, setIsEditing] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(description);
  const { isActionAllowed, setModalOpen } = useTariffGuard();

  const { mutate: updateDescription, isLoading } = useCompanyDescriptionUpdate({
    successHandler: () => {
      notifications.push({
        text: 'Описание компании обновлено',
        severity: 'success',
      });

      setDescriptionValue(formik.getFieldMeta('description').value);
      formik.resetForm();
      setIsEditing(false);
      refetch();
    },
    errorHandler: (err: any) => {
      const apiErrors = parseApiErrors(err);
      notifications.push({
        text: 'Ошибка при обновлении описания',
        severity: 'error',
      });
      Sentry.captureException(err, {
        extra: {
          apiErrors,
        },
        tags: {
          label: 'company-description-update',
        },
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      description: '',
    },
    validateOnBlur: true,
    validateOnMount: false,
    isInitialValid: true,
    enableReinitialize: true,
    validationSchema,
    async onSubmit(values) {
      updateDescription({ companyId: company.id, description: values.description });
    },
  });

  useEffect(() => {
    setDescriptionValue(description);
  }, [description]);

  useEffect(() => {
    if (isEditing) {
      formik.setFieldValue('description', descriptionValue);
    }
  }, [isEditing]);

  const renderForm = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <CompanyTextField
            name="description"
            placeholder="Добавьте описание вашей компании"
            multiline
            minRows={3}
            maxRows={10}
          />
          <StyledButton type="submit"> {isLoading ? <CircularProgress size={25} /> : 'Сохранить'}</StyledButton>
        </Form>
      </FormikProvider>
    );
  };

  const handleSetEditing = () => {
    if (isActionAllowed('EditCompanyDescription')) {
      setIsEditing(true);
      return;
    }

    setModalOpen(true);
  };

  if (!descriptionValue && !isUserCanEdit) {
    return null;
  }

  return (
    <Container>
      <CardHeader>
        <Title>О компании</Title>
        {descriptionValue && isUserCanEdit && !isEditing && (
          <IconButton onClick={handleSetEditing}>
            <EditIcon fontSize="small" color="primary" />
          </IconButton>
        )}
      </CardHeader>
      {descriptionValue && !isEditing ? <TextTruncated value={descriptionValue} /> : renderForm()}
    </Container>
  );
};
