import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { Wrapper } from './styled';
import { MenuPosition } from './types';

interface Props {
  onClose(): void;
  hideCross?: boolean;
  postion?: MenuPosition;
}

export const ContextMenu: React.FC<Props> = ({ onClose, children, postion }) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Wrapper postion={postion}>{children}</Wrapper>
    </ClickAwayListener>
  );
};
