import styled from 'styled-components';
import { BlockCollapse } from '@theme/shared/components';

export const Number = styled.span`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spaces.xs};

  span {
    margin-right: ${({ theme }) => theme.spaces.xs};
  }

  span: last-child {
    margin-right: 0;
  }
`;

export const Wrap = styled(BlockCollapse)`
  font-size: ${({ theme }) => theme.textSizes.header3.size};
  font-weight: ${({ theme }) => theme.textSizes.header3.weight};
  line-height: ${({ theme }) => theme.textSizes.header3.lineHeight};
  width: max-content;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header1.size};
    font-weight: ${({ theme }) => theme.textSizes.header1.weight};
    line-height: ${({ theme }) => theme.textSizes.header1.lineHeight};
  `}

  ${Number}: first-child {
    color: ${({ theme }) => theme.palette.main.accent};
  }
  ${Number}: nth-child(2) {
    color: ${({ theme }) => theme.palette.main.lightBlue};
  }
  ${Number}: nth-child(3) {
    color: ${({ theme }) => theme.palette.main.lightGreen};
  }
  ${Number}: last-child {
    color: ${({ theme }) => theme.palette.main.pink};
  }
`;
