import React from 'react';
import { useOrderById } from '@http/hooks/order';
import { LoaderBox } from '@components/LoaderBox';
import { OrderOwnProps } from 'screens/Order/types';
import { OrderView } from 'screens/Order/OrderView';

export interface OrderInfoInlineCardOwnProps extends Omit<OrderOwnProps, 'data' | 'isPageLayout'> {
  activeId: string;
}

export const OrderInfoInlineCard = ({ activeId, onDelete }: OrderInfoInlineCardOwnProps) => {
  const orderId = Number(activeId);

  const { data, isLoading } = useOrderById({ orderId });

  if (Number.isNaN(orderId) || !data?.id) return null;

  if (isLoading) return <LoaderBox />;

  return <OrderView data={data} isPageLayout={false} onDelete={onDelete} />;
};
