import { NewsPageRecordsQuery } from '@http/models/api/news';

export interface ApiPaginateParams {
  skip?: number;
  size?: number;
}

export interface ApiPaginateNewsParams {
  skip?: number;
  limit?: number;
  isPublished?: boolean;
}

export const buildQs = (baseUrl: string, params?: any): string => {
  if (!params || Object.keys(params).length === 0) {
    return baseUrl;
  }

  const qs = Object.keys(params)
    .flatMap(key => {
      if (!params[key] && typeof params[key] !== 'boolean') {
        return null;
      }

      if (Array.isArray(params[key])) {
        return params[key].map((item: any) => `${key}=${item}`);
      }

      return `${key}=${params[key]}`;
    })
    .filter(Boolean)
    .join('&');

  return `${baseUrl}?${qs}`;
};

export const applyMapper = <R, T>(asyncCall: Promise<T>, mapper: (rawData: T) => R): Promise<R> =>
  asyncCall.then(mapper);

export const eachElement =
  <T, R>(mapper: (el: T) => R): ((array: T[]) => R[]) =>
  (array: T[]) =>
    array.map(mapper);

export const parseApiErrors = (err: any): string[] | [] => {
  const errors = err?.response?.data?.errors;
  if (!errors) {
    return [];
  }

  try {
    return Object.keys(errors).flatMap(key => errors[key]);
  } catch {
    return err;
  }
};

export const getPagination = (page: number, size: number): ApiPaginateParams => ({
  size,
  skip: (page - 1) * size,
});

export const LIMIT_NEWS_ARTICLES = 10;
export const getPaginationNews = ({
  skip,
  limit,
  isPublished,
  categoryId,
}: NewsPageRecordsQuery): ApiPaginateNewsParams => {
  let query: NewsPageRecordsQuery = {};

  if (limit) {
    query.limit = limit;
  }

  if (skip) {
    query.skip = ((skip || 0) - 1) * (limit ?? 0);
  }

  if (isPublished) {
    query.isPublished = isPublished;
  }

  if (categoryId) {
    query.categoryId = categoryId;
  }

  return query;
};
