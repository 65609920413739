import { Form, FormikProvider, useFormik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { FormValues, INITIAL_VALUES } from './types';
import { validationSchema } from './validationSchema';
import { CompanyTextField, FormWrapper, ModalFooter, SubmitButton, Wrap } from './styled';
import { ReviewFileField } from './ReviewFileField';
import { InlineFrameForm } from '@components/InlineFrameForm';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { Modal } from '@components/Modal';
import { InlineFrame } from '@shared/inline-frame';
import { Button } from '@components/Button';
import { useCompanyAddReview } from '@http/hooks/reviews';
import { useNotifications } from 'providers/notifications';
import { useUser } from 'providers/user';
import { CompanyFullAPI } from '@http/models/api/company';
import RatingField from '@lib/Components/fields/RatingField';
import { Nullable } from 'types';

export interface CompanyReviewFormProps {
  isOpen: boolean;
  company: CompanyFullAPI;
  onClose: () => void;
  onSuccess: () => void;
}

export const AddReviewForm: React.FC<CompanyReviewFormProps> = ({ isOpen, onClose, company, onSuccess }) => {
  const notifications = useNotifications();
  const { isMobile } = useScreenSize();
  const user = useUser();
  const [attachedFileUrl, setAttachedFileUrl] = useState<Nullable<string>>(null);

  const { mutate: addReview, isLoading } = useCompanyAddReview({
    successHandler: handlAddReviewSuccess,
    errorHandler: handleAddReviewError,
  });

  const title = 'Оставить отзыв';

  const form = useFormik<FormValues>({
    initialValues: INITIAL_VALUES,
    validateOnBlur: true,
    validateOnMount: false,
    validationSchema,
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit(values) {
      const authorCompanyTitle = user.getCompany()?.title;
      if (user.data?.id && company.title && values.desc && values.rate !== null && authorCompanyTitle) {
        addReview({
          companyId: company.id,
          userId: user.data?.id,
          title: authorCompanyTitle,
          userAbout: values.userAbout || null,
          desc: values.desc,
          image: attachedFileUrl,
          rate: values.rate,
        });
      }
    },
  });

  const formContent = (
    <FormikProvider value={form}>
      <Form>
        <FormWrapper>
          <RatingField name="rate" ratingProps={{ size: 22 }} />
          <CompanyTextField name="userAbout" label="ФИО и должность" />
          <CompanyTextField name="desc" label="Текст отзыва" multiline />
          <ReviewFileField
            companyId={company.id}
            uploadedFileUrl={attachedFileUrl}
            onSucces={setAttachedFileUrl}
            onDelete={() => setAttachedFileUrl(null)}
          />
        </FormWrapper>
      </Form>
    </FormikProvider>
  );

  function handleAddReviewError() {
    notifications.push({
      text: `Произошла ошибка при добавлении отзыва, пожалуйста обратитесь в службу техподдержки`,
      severity: 'error',
    });
  }

  function handlAddReviewSuccess() {
    notifications.push({
      text: 'Отзыв успешно добавлен',
      severity: 'success',
    });
    form.resetForm();
    onSuccess();
    handleFormClose();
  }

  const handleFormClose = () => {
    form.resetForm();
    setAttachedFileUrl(null);
    onClose();
  };

  const handleSave = () => {
    form.submitForm();
  };

  if (isMobile) {
    return (
      <InlineFrame
        isOpen={isOpen}
        onClose={handleFormClose}
        modalContentPaddings={{
          default: '0px',
          moreThanMobile: '0px',
        }}
      >
        <InlineFrameForm
          isLoading={!!isLoading}
          formTitle={title}
          defaultSaveBtnText="Отправить"
          btnConfirmText="Отправить"
          closeInlineFrame={handleFormClose}
          handleSave={handleSave}
        >
          {formContent}
        </InlineFrameForm>
      </InlineFrame>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleFormClose}
      title={title}
      maxWidth={'600px'}
      modalContentPaddings={{ default: '32px 32px 24px' }}
    >
      <Wrap>
        {formContent}
        <ModalFooter>
          <Button variant="outlined" disabled={isLoading} onClick={handleFormClose}>
            Отмена
          </Button>
          <SubmitButton disabled={isLoading} onClick={handleSave}>
            {isLoading ? <CircularProgress size={25} /> : 'Отправить'}
          </SubmitButton>
        </ModalFooter>
      </Wrap>
    </Modal>
  );
};
