import { KritodTextFieldProps } from '@lib/Components/fields/TextField';
import { DEFAULT_MAX_FLOAT_DIGITS } from '@constants/numbers';
import { regExps } from '@constants/form';

export const removeSpaces = (value: string | number): string => value.toString().replace(/\s/g, '');

export const breaksHundreds = (value: string | number): string =>
  removeSpaces(value.toString()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

export const trimStartSpaces = (value: string | number): string => value.toString().trimStart();

export const trimAllSpaces = (value: string): string => {
  return value.replace(/\s/gm, '');
};

/**
 * Отрезаем у дроби часть разрада если он превает допустимый
 * Пример:
 * MAX_FLOAT_DIGIT = 3
 * normalizeFloatDigits(23.234325, MAX_FLOAT_DIGIT) -> 24.234
 * @param value
 * @param maxFloatDigits
 */
export const normalizeFloatDigits = (value: number, maxFloatDigits?: number) => {
  const digitsCountPattern = new Array(maxFloatDigits || DEFAULT_MAX_FLOAT_DIGITS).fill(`\\d`).join('');
  const reg = new RegExp(`(\\.${digitsCountPattern})(.+)$`, 'g');
  const replaced = value.toString().replace(reg, '$1');
  return Number(replaced);
};

export const stringPriceToNumber = (value: string): number => {
  try {
    const res = Number(trimAllSpaces(value));
    return res;
  } catch {
    console.error('Fail to parse price', value);
    return 0;
  }
};

const commaToDot = (candidate: string) => candidate.replace(',', '.');

export const onlyNumAndDotInputValueTransformer = (value: string, maxFloatDigits?: number) => {
  const reg =
    maxFloatDigits === 0
      ? new RegExp(`^[0-9]$`, 'gm')
      : new RegExp(`^[0-9]*[.,]?(\\d{1,${maxFloatDigits ?? DEFAULT_MAX_FLOAT_DIGITS}})?$`, 'gm');
  if (value.length && !reg.test(value.replace(/\s/g, ''))) {
    return commaToDot(value.replace(/[\D.,]/g, ''));
  }

  return commaToDot(value);
};

export const createOnlyNumAndDorInputTransformer = (maxFloatDigits: number) => {
  return (value: string) => {
    return onlyNumAndDotInputValueTransformer(value, maxFloatDigits);
  };
};

export const breaksHundredsInputValueTransformer = (value: string) => {
  if (value) {
    return breaksHundreds(value);
  }

  return value;
};

export const createMaxValueTransformer = (max: number) => {
  return (value: string): string => {
    if (Number(value.replace(/\s/g, '')) > max) {
      return max.toString();
    }

    return value;
  };
};

export const offSpacesValueTransformer = (value: string) => value.replace(/\s/g, '');

export const toLowerCaseValueTransformer = (value: string) => value.toLowerCase();

export const applyInputTransformers = (
  transformers: KritodTextFieldProps['transformValue'][]
): KritodTextFieldProps['transformValue'] => {
  return (value: string): string => {
    return transformers.reduce((acc, transformer) => (transformer ? transformer(acc) : acc), value);
  };
};

export const onlyPositiveNumbersTransformer = (value: string): string => {
  if (Number(value.replace(/\s/g, '')) < 0) {
    return `0`;
  }

  return value;
};

export const convertPhoneToSimpleString = (value: string) => {
  return value.replace(regExps.fromPhoneToString, '');
};

export const convertSimpleStringToPhone = (value: string) => {
  try {
    return value.replace(regExps.fromStringToPhone, '$1 ($2) $3-$4-$5');
  } catch (e: any) {
    console.error(e);
    return '-';
  }
};
