import styled from 'styled-components';
import { Text } from '@theme/shared/text';
import { BoxWrapper } from '@theme/shared/components';

interface CardContentProps {
  swiped?: boolean;
}

interface ControlsProps {
  visible?: boolean;
}

export const Container = styled(BoxWrapper)`
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
  background-color: ${({ theme }) => theme.palette.grey.background};
`;

export const CardContent = styled.div<CardContentProps>`
  padding: ${({ theme }) => theme.spaces.xs};
  z-index: 10;
  background: ${({ theme }) => theme.palette.grey.white};
  flex: 1;
  transform: ${({ swiped }) => (swiped ? 'translate(-144px, 0px)' : 'none')};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  transition: all 1s;
  min-height: 72px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 46px;
`;

export const Title = styled(Text.Caption)`
  max-height: calc(${({ theme }) => theme.textSizes.caption.lineHeight} * 3);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Price = styled(Text.CaptionBold)`
  white-space: nowrap;
`;

export const Body = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xxs};
`;

export const Description = styled(Text.MenuCaption)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Controls = styled(Text.MenuCaption)<ControlsProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: -16px;
  top: calc(50% - 35px);
  color: ${({ theme }) => theme.palette.grey.white};
`;

export const EditButton = styled(Text.MenuCaption)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 70px;
  background: ${({ theme }) => theme.palette.main.accent};
`;

export const DeleteButton = styled(Text.MenuCaption)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: ${({ theme }) => theme.palette.system.error};
  border-top-right-radius: ${({ theme }) => theme.shape.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius};
`;
