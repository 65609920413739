import { useCallback } from 'react';
import { AbortController, AbortSignal } from 'node-abort-controller';

interface RunnerParams {
  signal: AbortSignal;
}

type Runner = (params: RunnerParams) => void | Promise<void>;

interface ThrottleParams {
  emptyCall?: boolean;
  ms?: number; // time to run
}

type AddItemQueueType = (fn: Runner, params?: ThrottleParams) => void;

interface TaskCreator {
  throttle: AddItemQueueType;
  abort: () => void;
}

interface ReturnType {
  name(key: string): TaskCreator;
}

interface Meta {
  timeout: any;
  controller: AbortController;
}

interface QueueItem {
  creator: TaskCreator;
  _meta: Meta;
}

const queue: { [x: string]: QueueItem } = {};

const DEFAULT_TTC_MC = 100;

export const useThrottler = (baseName: string): ReturnType => {
  const key = useCallback((name: string) => `${baseName}_${name}`, [baseName]);

  const th: ReturnType['name'] = useCallback(
    name => {
      const exist = queue[key(name)];
      if (exist) {
        return exist.creator;
      }

      const createCallback = (): { cb: AddItemQueueType; controller: AbortController; timeout: any } => {
        const controller = new AbortController();
        const fnParams = { signal: controller.signal };

        const cb: AddItemQueueType = (fn, params) => {
          const q = queue[key(name)];
          clearTimeout(q._meta.timeout);
          if (q._meta.controller) {
            q._meta.controller.abort();
          }

          q._meta.controller = controller;
          q._meta.timeout = setTimeout(() => {
            fn(fnParams);
          }, params?.ms ?? DEFAULT_TTC_MC);
        };

        return { controller, cb, timeout: queue[key(name)]?._meta?.timeout };
      };

      const newCallback = createCallback();

      const params: TaskCreator = {
        throttle: newCallback.cb,
        abort() {
          newCallback.controller.abort();
        },
      };

      queue[key(name)] = {
        creator: params,
        _meta: {
          timeout: newCallback.timeout,
          controller: newCallback.controller,
        },
      };

      return params;
    },
    [baseName]
  );

  return {
    name: th,
  };
};
