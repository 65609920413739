import { useRouter } from 'next/router';
import { useCompanyTabs } from '../CompanyTabs/hooks/company-tabs-route';
import { CompanyTabName } from '../CompanyTabs/types';
import { CompanySalesSection } from './CompanySalesSection';
import { Title, Wrapper } from './styled';
import { CompanyContacts } from './Contacts';
import { MainInfo } from './MainInfo';
import { CompanyDescription } from './CompanyDescription';
import { CompanyPhotos } from './CompanyPhotos';
import { CompanyViewModel } from '@http/models/view-models/company';
import { UnauthorizedNote } from '@components/Note/UnauthorizedNote';
import { useUser } from 'providers/user';
import { MarginWrapper } from '@theme/shared/wrappers';
import { COMPANY_TABS } from 'types/routesQueryNames';
import { Button } from '@components/Button';
interface Props {
  company: CompanyViewModel;
  isShowCompanyTitle?: boolean;
}

export const CompanyInfo: React.FC<Props> = ({ isShowCompanyTitle = true, company }) => {
  const { title, licenses } = company;
  const user = useUser();
  const router = useRouter();

  const companyTabs = useCompanyTabs(company);
  const licencesTabIndex = companyTabs.findIndex(tab => tab.name === CompanyTabName.Licences);

  const handleClickToLicences = () => {
    router.replace({ query: { ...router.query, [COMPANY_TABS]: licencesTabIndex } }, undefined, { scroll: false });
  };

  return (
    <MarginWrapper marginTop="m">
      <Wrapper>
        {!user.exist() && <UnauthorizedNote text="контакты" />}
        {isShowCompanyTitle && <Title>{title}</Title>}
        <MainInfo company={company} />
        <CompanySalesSection company={company} />
        <CompanyDescription company={company} />
        <CompanyPhotos company={company} />
        <CompanyContacts company={company} />
        {Boolean(licenses?.length) && (
          <MarginWrapper marginTop="m" marginBottom="m">
            <Button onClick={handleClickToLicences}>Просмотр лицензий</Button>
          </MarginWrapper>
        )}
      </Wrapper>
    </MarginWrapper>
  );
};
