import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledLinearProgress = withStyles({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
  },
})(LinearProgress);

export const PageProgress: React.FC = () => {
  return <StyledLinearProgress />;
};
