import styled, { css } from 'styled-components';
import { Table, TableHead } from '@material-ui/core';
import { rgba } from 'polished';
import { BoxWrapper } from '@theme/shared/components';

export const Wrapper = styled(BoxWrapper)`
  margin-top: ${({ theme }) => theme.spaces.m};
`;

export const TableRowControlsWrapper = styled.div.attrs(() => ({
  'data-table-controls': true,
}))<{ isDisabled: boolean }>`
  position: sticky;
  z-index: 10;
  background: #ffffffd6;
  right: 0;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  padding-right: 48px;
  padding-left: ${({ theme }) => theme.spaces.s};
  display: flex;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const TableWrapper = styled.div<{ enableMarks?: boolean }>`
  ${({ enableMarks, theme }) =>
    enableMarks &&
    css`
      .MuiTableRow-root {
        &:not(.MuiTableRow-head):hover {
          background: ${rgba(theme.palette.main.light, 0.3)};

          ${TableRowControlsWrapper} {
            display: flex;
          }
        }
      }
    `}
`;

export const RowActionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  color: ${({ theme }) => theme.palette.text.link};
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  opacity: 0.8;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-shadow: ${({ theme }) => theme.shadow.base};
  border: 1px solid ${({ theme }) => theme.palette.text.link};

  &:hover {
    cursor: pointer;
    opacity: 1;
    background: ${({ theme }) => theme.palette.grey.cell};
  }
`;

export const StyledTable = styled(Table)<{ enableMarks?: boolean }>`
  min-width: 500px !important;

  .MuiTableCell-root {
    font-size: 12px;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    .MuiTableCell-root {
      font-size: 14px;
    }
  `}
`;

export const StyledTableHead = styled(TableHead)`
  background: ${({ theme }) => rgba(theme.palette.grey.light, 0.3)};

  th {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: bold;
  }
`;
