import React, { FC, ReactNode } from 'react';
import { H1 } from './styled';

interface Props {
  title: ReactNode;
}

export const Title: FC<Props> = ({ title }) => {
  if (!title) return null;

  if (typeof title === 'string') {
    return <H1>{title}</H1>;
  }

  return <>{title}</>;
};
