import React from 'react';
import { Nullable } from 'types';

interface Props {
  typeMoves: number;
}

const Context = React.createContext<Nullable<Props>>(null);

export const CompanyProvider: React.FC<Props> = ({ typeMoves, children }) => {
  return <Context.Provider value={{ typeMoves }}>{children}</Context.Provider>;
};

export const useCompanyData = () => React.useContext(Context) as Props;
