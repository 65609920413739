import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    display: flex;
    justify-content: space-between;
  `}
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    width: 33%;
  `};
`;

export const Value = styled.p`
  ${({ theme }) => theme.utils.media.moreThanMobile`
     width: 64%;
  `}
`;
