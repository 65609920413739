import styled from 'styled-components';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';

export const Wrapper = styled.div``;

export const Content = styled.div`
  ${({ theme }) => theme.utils.media.moreThanMobile`
      min-width: 460px;
      min-height: 161px;
  `}
`;
export const CropZonesWrapper = styled.div`
  ${({ theme }) => theme.utils.media.desktop`
      display: flex;
      column-gap: ${({ theme }) => theme.spaces.xl};
      align-items: center;
  `}
`;

export const DropzoneWrapper = styled.div`
  height: 161px;
  border: 1px solid ${({ theme }) => theme.palette.grey.light};
  background: ${({ theme }) => theme.palette.grey.background};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.xxs};
  cursor: pointer;
`;

export const DropzoneCaption = styled.div`
  font-size: ${({ theme }) => theme.textSizes.menuCaption.size};
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: ${({ theme }) => `0 ${theme.spaces.xxs}`};
`;

export const ModalFooter = styled.div`
  margin-top: ${({ theme }) => theme.spaces.m};
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.m};
`;

export const ConfirmButton = styled(Button)`
  width: 178px;

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const CancelButton = styled(Button)`
  width: 126px;
`;

export const CameraIcon = styled(Icon)`
  color: #d9d9d9;
`;
