import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const H1 = styled(Text.Header1)`
  margin-right: ${({ theme }) => theme.spaces.m};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  line-height: 30px;
`;
