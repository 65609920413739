import {
  ExtraWasteCodeCompanyResponse,
  FkkoBody,
  PlaceFkkoBody,
  WasteClass,
  WasteClassBulkTotalRecords,
  WasteClassTotalRecords,
  WasteType,
} from '@http/models/api/fkko';
import { httpService } from '@http/service';
import { buildQs } from '@http/shared';
import { HttpOptions } from '@http/service/options';

export const getFkkoList = (body: FkkoBody) => {
  const options = new HttpOptions().withCredentials(false);
  return httpService.get<WasteClass[]>(buildQs(`/fkko/search`, body), options);
};

export const getPlaceFkko = ({ id, ...rest }: PlaceFkkoBody) => {
  const options = new HttpOptions().withCredentials(false);
  return httpService.get<WasteClassTotalRecords>(buildQs(`/fkko/place/${id}`, rest), options);
};

export const getExtraWasteCodes = (companyId: number, authToken?: string): Promise<ExtraWasteCodeCompanyResponse> => {
  const options = new HttpOptions().withCredentials(false);
  if (authToken) {
    options.setHeader('Authorization', `Bearer ${authToken}`);
  }

  return httpService.get<ExtraWasteCodeCompanyResponse>(buildQs(`/fkko/company/${companyId}`, { size: 1000 }), options);
};

export const getFkkoCode = (code: string) => {
  const options = new HttpOptions().withCredentials(false);
  return httpService.get<WasteType>(buildQs(`/fkko/${code}`), options);
};

export const getFkkoBulk = (body: FkkoBody) => {
  const options = new HttpOptions().withCredentials(false);
  return httpService.get<WasteClassBulkTotalRecords>(buildQs(`/fkko/bulk`, body), options);
};
