export const regExps = {
  phone: /\+?[7]\s\((\d{3})\)\s(\d{3})-(\d{2})-(\d{2})/,
  url: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  fromStringToPhone: /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
  fromPhoneToString: /((?!(\+|\d)).)/g,
};

export enum Masks {
  phone = '+7 (999) 999-99-99',
}
