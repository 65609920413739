import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import { Text } from '@theme/shared/text';

interface ModalContentProps {
  maxWidth?: string;
  height?: string;
  modalContentPaddings?: {
    default: string;
  };
}

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  &:focus-visible {
    ouline: none;
  }
`;

export const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  padding: ${({ modalContentPaddings, theme: { spaces } }) =>
    modalContentPaddings ? modalContentPaddings.default : `${spaces.l} ${spaces.m} ${spaces.xl}`};
  margin: auto;
  max-width: calc(100% - 32px);
  background: white;
  overflow: auto;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  height: ${({ height }) => height || 'auto'};

  &:focus {
    outline: none;
  }

  ${({ theme, maxWidth }) => theme.utils.media.tablet`
    max-width: ${() => (maxWidth ? maxWidth : '600px')};
  `}
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spaces.s};
  right: ${({ theme }) => theme.spaces.s};
  width: 2rem;
  color: ${({ theme }) => theme.palette.main.accent};
  z-index: 100;
`;

export const Title = styled(Text.Header2)`
  padding-right: 4rem;
  margin-bottom: ${({ theme }) => theme.spaces.l};
`;
