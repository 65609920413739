import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Field, ErrorMessage, FieldProps, useFormikContext } from 'formik';
import InputMask from 'react-input-mask';
import MUTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Adornment, Error, FieldWrapper } from './styled';
import { Nullable } from 'types';

export interface KritodTextFieldProps {
  name: string;
  mask?: string;
  maskChar?: string;
  isLazy?: boolean;
  endAdornment?: ReactNode;
  manualShrink?: boolean;

  transformValue?(value: string): string;
}

const TextField: React.FC<KritodTextFieldProps & TextFieldProps> = ({
  name,
  type,
  mask,
  maskChar = ' ',
  transformValue,
  isLazy,
  disabled,
  endAdornment,
  manualShrink,
  ...rest
}) => {
  const FormikField = Field;
  const formik = useFormikContext();
  const formikValue = formik.getFieldMeta(name).value as string;
  const [value, setValue] = useState<Nullable<string>>(formikValue);

  useEffect(() => {
    setValue(cur => {
      if (cur !== formikValue) {
        return formikValue;
      }

      return cur;
    });
  }, [formikValue]);

  return (
    <FormikField name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched && !!meta.error;

        let inputProps: TextFieldProps = {
          variant: 'filled',
          error: hasError,
          ...field,
          ...rest,
        };

        if (!mask) {
          inputProps = {
            ...inputProps,
            value,
            onBlur(e: any) {
              if (isLazy) {
                formik.setFieldValue(name, value);
              }
              field.onBlur(e);
            },
          };
        }

        const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          if (type === 'number' && isNaN(+e.currentTarget.value)) return;
          if (transformValue) {
            e.currentTarget.value = transformValue(e.currentTarget.value);
          }

          setValue(e.currentTarget.value);
          if (!isLazy) {
            field.onChange(e);
          }
        };
        const shrink = Boolean(value) ? true : undefined;

        return (
          <FieldWrapper hasError={hasError} withAdornment={!!endAdornment}>
            {mask ? (
              <InputMask {...field} maskPlaceholder={maskChar} mask={mask} value={field.value} disabled={disabled}>
                {/** @ts-ignore */}
                {() => (
                  <MUTextField
                    {...inputProps}
                    disabled={disabled}
                    InputLabelProps={manualShrink ? { shrink } : undefined}
                  />
                )}
              </InputMask>
            ) : (
              <MUTextField
                {...inputProps}
                disabled={disabled}
                onChange={onChange}
                type={type !== 'number' ? type : undefined}
                InputLabelProps={manualShrink ? { shrink } : undefined}
              />
            )}
            {endAdornment && <Adornment>{endAdornment}</Adornment>}
            <ErrorMessage name={name} component={Error} />
          </FieldWrapper>
        );
      }}
    </FormikField>
  );
};

export default TextField;
