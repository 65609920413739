import { Table, TableHead } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  min-width: 500px !important;

  .MuiTableCell-root {
    font-size: 12px;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    .MuiTableCell-root {
      font-size: 14px;
    }
  `}
`;

export const StyledTableHead = styled(TableHead)`
  th {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
