import { useState } from 'react';
import {
  useCompanyDeleteReview,
  useCompanyPendingReviews,
  useCompanyReviews,
  useUserPendingReviews,
} from '@http/hooks/reviews';
import { CompanyFullAPI } from '@http/models/api/company';
import { useNotifications } from 'providers/notifications';
import { useUser } from 'providers/user';
import { useReviewSetVisibility } from '@http/hooks/reviews';

export const useReview = (company: CompanyFullAPI) => {
  const notifications = useNotifications();
  const [formOpened, setFormOpened] = useState(false);

  const user = useUser();
  const userCompany = user.getCompany();
  const isUserCanEdit = Boolean(userCompany?.isVerified && (user.isAdmin() || userCompany?.id === company.id));
  const userId = user.data?.id;
  const isAnonym = !user.exist() || !user.getCompany()?.isVerified;

  const {
    data: companyReviews,
    isLoading: isLoadingFetchReview,
    refetch: refetchReviews,
  } = useCompanyReviews({ companyId: company.id });

  const { data: pendingReviewsData, refetch: refetchPendingReviews } = useCompanyPendingReviews(company.id, {
    enabled: isUserCanEdit,
  });
  const { data: pendingUserReviews, refetch: refetchPendingUserReviews } = useUserPendingReviews(
    { userId: userId!, companyId: company.id },
    {
      enabled: Boolean(!isUserCanEdit && !isAnonym),
    }
  );

  const { mutate: setReviewVisibility, isLoading: isLoadingModerateReview } = useReviewSetVisibility({
    successHandler: () => handleReviewModerateSuccess(true),
    errorHandler: () => handleReviewModerateError(true),
  });
  const { mutate: deleteReview } = useCompanyDeleteReview({
    successHandler: () => handleReviewModerateSuccess(false),
    errorHandler: () => handleReviewModerateError(false),
  });

  const handleFormClose = () => {
    setFormOpened(false);
  };

  const handleAddReview = () => {
    setFormOpened(true);
  };

  const handleOnApprove = (id: number) => {
    setReviewVisibility({ id, isVisible: true });
  };

  const handleOnReject = (id: number) => {
    deleteReview(id);
  };

  function handleReviewModerateSuccess(accepted: boolean) {
    succesModerateNotify(accepted);
    refetchReviews();
    if (isUserCanEdit) {
      refetchPendingReviews();
    } else {
      refetchPendingUserReviews();
    }
  }

  const succesModerateNotify = (accepted: boolean) => {
    notifications.push({
      text: `Отзыв успешно ${accepted ? 'принят' : 'отклонен'}`,
      severity: 'success',
    });
  };

  function handleReviewModerateError(accepted: boolean) {
    const text = `Произошла ошибка при 
    ${accepted ? 'принятии' : 'отклонении'} отзыва, пожалуйста обратитесь в службу техподдержки`;

    notifications.push({
      text,
      severity: 'error',
    });
  }

  return {
    companyReviews,
    isUserCanEdit,
    formOpened,
    isLoadingFetchReview,
    isLoadingModerateReview,
    pendingReviewsData,
    pendingUserReviews,
    isAnonym,
    refetchPendingReviews,
    refetchPendingUserReviews,
    handleOnReject,
    refetchReviews,
    handleFormClose,
    handleAddReview,
    handleOnApprove,
  };
};
