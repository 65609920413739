import React from 'react';
import { ModalProps } from '@material-ui/core';
import { CloseBtn, ModalContent, StyledModal, Title } from './styled';
import { Icon } from '@components/Icon';

interface Props extends ModalProps {
  title?: string;
  maxWidth?: string;
  height?: string;
  modalContentPaddings?: {
    default: string;
  };
}

export const Modal: React.FC<Props> = ({
  children,
  title,
  modalContentPaddings,
  maxWidth,
  height,
  onClose,
  ...rest
}) => {
  const handleClick = (e: any) => (onClose as any)(e);
  return (
    <StyledModal {...rest} onClose={onClose}>
      <ModalContent modalContentPaddings={modalContentPaddings} maxWidth={maxWidth} height={height}>
        <CloseBtn onClick={handleClick}>
          <Icon name="x.outline" />
        </CloseBtn>
        {title && <Title>{title}</Title>}
        {children}
      </ModalContent>
    </StyledModal>
  );
};
