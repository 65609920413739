import React, { useRef, useState, FC } from 'react';
import { Popover } from '@material-ui/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTheme } from 'styled-components';
import {
  Wrapper,
  PopoverContent,
  PopoverItem,
  PopoverItemTitle,
  StyledButton,
  MenuSection,
  Column,
  StyledMenuSectionCaption,
  MenuIcon,
  CommonMenuItemsWrapper,
  MenuItemsWrapper,
} from './styled';
import { Icon } from '@components/Icon';
import { useVisibleMenuItems } from '@components/main-layout/shared/menu-items/hooks';
import {
  adminMenuItems,
  commonMenuItems,
  companyCardMenuItem,
  companyMenuItems,
  MainLayoutMenuItem,
} from '@components/main-layout/shared/menu-items';
import { createPathMenuItem } from 'routes';
import { Nullable } from 'types';
import { useUser } from 'providers/user';
import { ColorTheme } from '@theme/types';

interface RenderMenuItemsParams {
  item: MainLayoutMenuItem;
  iconColor?: string;
  labelColor?: string;
  path?: string;
}

export interface UserContextMenuProps {
  buttonTheme?: ColorTheme;
}

export const UserContextMenu: FC<UserContextMenuProps> = ({ buttonTheme }) => {
  const { asPath } = useRouter();
  const user = useUser();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLDivElement>>(null);
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const commonItems = useVisibleMenuItems(commonMenuItems);
  const companyItems = useVisibleMenuItems(companyMenuItems);
  const adminItems = useVisibleMenuItems(adminMenuItems);
  const [companyCardItem] = useVisibleMenuItems([companyCardMenuItem]);

  const hasSections = Boolean(companyItems.length || adminItems.length);

  const renderMenuItem = ({ item, iconColor, labelColor }: RenderMenuItemsParams, index: number, path?: string) => {
    const { to, label, Icon } = item || {};
    return (
      <Link passHref href={createPathMenuItem(to, path)} key={index}>
        <a className="text-decoration-none">
          <PopoverItem onClick={() => setAnchorEl(null)}>
            <MenuIcon style={{ color: iconColor }}>{Icon}</MenuIcon>
            <PopoverItemTitle style={{ color: labelColor }}>{label}</PopoverItemTitle>
          </PopoverItem>
        </a>
      </Link>
    );
  };

  const renderMenuItems = (items: MainLayoutMenuItem[]) => {
    return items.map((item, index) => renderMenuItem({ item }, index, asPath));
  };

  return (
    <>
      <Wrapper tabIndex={0} ref={ref} onClick={() => setAnchorEl(ref.current || null)}>
        <StyledButton
          startIcon={<Icon name="profile.outline" size="m" />}
          endIcon={<KeyboardArrowDownIcon />}
          $isActive={Boolean(anchorEl)}
          $colorTheme={buttonTheme}
        >
          {user.data?.nickname}
        </StyledButton>
      </Wrapper>
      <Popover
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        anchorEl={anchorEl}
      >
        <PopoverContent>
          {hasSections && (
            <Column>
              {companyItems.length > 0 && (
                <MenuSection>
                  <StyledMenuSectionCaption>
                    <div>Моя комания</div>
                    {user.getCompany()?.title}
                  </StyledMenuSectionCaption>
                  <MenuItemsWrapper>
                    {companyCardItem && renderMenuItem({ item: companyCardItem }, 0, String(user.getCompany()?.id))}
                    {renderMenuItems(companyItems)}
                  </MenuItemsWrapper>
                </MenuSection>
              )}
              {adminItems.length > 0 && (
                <MenuSection>
                  <StyledMenuSectionCaption>Администратор</StyledMenuSectionCaption>
                  <MenuItemsWrapper>{renderMenuItems(adminItems)}</MenuItemsWrapper>
                </MenuSection>
              )}
            </Column>
          )}
          <Column>
            <MenuSection>
              {hasSections && <StyledMenuSectionCaption>Мой аккаунт</StyledMenuSectionCaption>}
              <CommonMenuItemsWrapper>
                {renderMenuItem({ item: commonItems[0] }, 0)}
                {renderMenuItem(
                  {
                    item: commonItems[1],
                    iconColor: theme.palette.system.error,
                    labelColor: theme.palette.system.error,
                  },
                  1
                )}
              </CommonMenuItemsWrapper>
            </MenuSection>
          </Column>
        </PopoverContent>
      </Popover>
    </>
  );
};
