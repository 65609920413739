import { LicencesWrapper, LicenseStatusWrapper } from './styled';
import { Nullable } from 'types';
import { wordEnding } from '@shared/utils/wordEnding';
import { Icon } from '@components/Icon';
import { License } from '@http/models/api/company';

export const LicensesStatus: React.FC<{ licenses: Nullable<License[]> }> = ({ licenses }) => {
  const activLicenses = [];
  const notActiveLicenses = [];

  licenses?.forEach(l => {
    if (l.isActive) {
      activLicenses.push(l);
    } else {
      notActiveLicenses.push(l);
    }
  });

  const renderQuantityLabel = (isActive: boolean, quantity: number) => {
    if (!quantity) {
      return null;
    }

    const icon = isActive ? 'check-circle.outline' : 'exclamation-circle.outline';
    const label = wordEnding(quantity, {
      1: isActive ? 'действующая' : 'просроченная',
      2: isActive ? 'действующие' : 'просроченные',
      5: isActive ? 'действующих' : 'просроченных',
    });

    return (
      <LicenseStatusWrapper className={isActive ? 'active' : 'outdated'}>
        <Icon name={icon} size="sm" strokeWidth={'2px'} />
        <span>{`${quantity} ${label}`}</span>
      </LicenseStatusWrapper>
    );
  };

  return (
    <LicencesWrapper>
      {renderQuantityLabel(true, activLicenses.length)}
      {renderQuantityLabel(false, notActiveLicenses.length)}
    </LicencesWrapper>
  );
};
