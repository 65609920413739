import { GetServerSideProps } from 'next';
import { FC } from 'react';
import { UtilizationSummary } from './UtilizationSummary';
import { OfferProvider, useOffer } from './context';
import { TableData } from './TableData';
import { Notes } from './Notes';
import { Controls } from './Controls';
import { NextPageFC } from 'types';
import { getOrderById } from '@http/endpoints/orders';
import { getOfferById } from '@http/endpoints/offer';
import { OfferViewModel } from '@http/models/view-models/offer';
import { OrderViewModel } from '@http/models/view-models/orders';
import { OfferSummary } from '@components/OfferSummary';
import { getUserTokenByContext } from '@shared/utils/getUserTokenByContext';
import { Page403 } from 'screens/Page403';

interface Props {
  offer: OfferViewModel;
  order: OrderViewModel;
  isForbidden?: boolean;
  isPageLayout?: boolean;
}

const OfferSummaryWrapper: FC<Pick<Props, 'isPageLayout'>> = ({ isPageLayout }) => {
  const { order } = useOffer();
  return <OfferSummary order={order} isPageLayout={isPageLayout} />;
};

const Offer: NextPageFC<Props> = ({ offer, order, isForbidden, isPageLayout }) => {
  if (isForbidden) {
    return <Page403 />;
  }

  return (
    <OfferProvider offer={offer} order={order}>
      <Notes />
      <OfferSummaryWrapper isPageLayout={isPageLayout} />
      <UtilizationSummary />
      <TableData />
      <Controls />
    </OfferProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async ctx => {
  const orderId = Number(ctx.params?.id);
  const offerId = Number(ctx.params?.offerId);

  if ([offerId, orderId].some(isNaN)) {
    return {
      notFound: true,
    };
  }

  try {
    const bearerToken = getUserTokenByContext(ctx);
    const [order, offer] = await Promise.all([
      getOrderById(orderId, bearerToken),
      getOfferById(orderId, offerId, bearerToken),
    ]);

    return {
      props: {
        order,
        offer,
      },
    };
  } catch (err) {
    if (err.isAxiosError && [401, 403].includes(err.response.status)) {
      ctx.res.statusCode = 403;

      return {
        props: {
          order: {},
          offer: {},
          isForbidden: true,
        },
      };
    }

    if (err.isAxiosError && err.response.status === 404) {
      return {
        notFound: true,
      };
    }

    throw err;
  }
};

export default Offer;
