import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { FormikProvider, useFormik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import { Wrapper, Form, FieldWrapper, TextFieldBorders, ErrorText, SubmitButtonWithErrors, ButtonFlex } from './styled';
import { validationSchema } from './validation';
import { Values } from './types';
import { INITIAL_VALUES } from './initial-values';
import { Text } from '@theme/shared/text';
import * as supportApi from '@http/endpoints/support';
import { useNotifications } from 'providers/notifications';
import {
  applyInputTransformers,
  offSpacesValueTransformer,
  toLowerCaseValueTransformer,
} from '@lib/Components/fields/input-value-helpers';

const ContactsForm: FC = () => {
  const notifications = useNotifications();
  const { isError, mutate: createRequest, isLoading } = useMutation(supportApi.postSupportOrder);
  const [error, setError] = useState('');

  useEffect(() => {
    isError && setError('Произошла ошибка сервера');
  }, [isError]);

  const form = useFormik<Values>({
    initialValues: INITIAL_VALUES,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit(body: Values, helpers) {
      if (!body.email.length) {
        return setError('Укажите почту и причину обращения');
      }

      setError('');

      createRequest(
        { body },
        {
          async onSuccess() {
            notifications.push({
              severity: 'success',
              text: 'Ваша заявка успешно создана',
            });
          },
        }
      );

      helpers.resetForm();
    },
  });

  const submitButtonWithError = useMemo(() => {
    return (
      <>
        <ButtonFlex type="submit" fullWidth>
          Отправить {isLoading && <CircularProgress size="1rem" style={{ color: 'white', marginLeft: '0.5rem' }} />}
        </ButtonFlex>
        {error && <ErrorText>{error}</ErrorText>}
      </>
    );
  }, [isLoading, error]);

  return (
    <Wrapper>
      <Text.Header2>Отправить обращение</Text.Header2>
      <FormikProvider value={form}>
        <Form>
          <FieldWrapper>
            <TextFieldBorders
              name="email"
              label="Ваш email"
              placeholder="email@example.com"
              transformValue={applyInputTransformers([toLowerCaseValueTransformer, offSpacesValueTransformer])}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextFieldBorders isLazy name="text" label="Ваш вопрос или комментарий" multiline maxRows={3} minRows={3} />
          </FieldWrapper>
          <SubmitButtonWithErrors>{submitButtonWithError}</SubmitButtonWithErrors>
        </Form>
      </FormikProvider>
    </Wrapper>
  );
};

export default ContactsForm;
