import { ParsedUrlQuery } from 'querystring';

/**
 * Возвращает индекс активного таба, если на странице присутствует несколько групп табов,
 * то для каждой группы при переданном query параметре будет установлен необходимый индекс
 * @param param { amountTabsOnPage: query параметры; ariaLabel: именование групп табов; tabsLen: число табов, счёт с нуля }
 * @returnsшвч idx активного таба
 */
export function getCurrentTabIdx({
  query,
  ariaLabel,
  tabsLen,
}: {
  query: ParsedUrlQuery;
  ariaLabel: string;
  tabsLen: number;
}) {
  const idxFromQuery = query[ariaLabel] ?? 0;
  if (Array.isArray(idxFromQuery) || +idxFromQuery > tabsLen) {
    return 0;
  }

  return +idxFromQuery;
}
