import { UseQueryOptions, useQuery } from 'react-query';
import { getOfferById, getOffers, GetOffersFilter, GetOffersResponse } from '@http/endpoints/offer';

export const useOffers = (filter: GetOffersFilter, options?: UseQueryOptions<any, any, GetOffersResponse>) => {
  return useQuery(['get_offers', filter], () => getOffers(filter), {
    staleTime: Infinity,
    ...options,
  });
};

interface UseOfferByIdParams {
  orderId: number;
  offerId: number;
}

export const useOfferById = ({ orderId, offerId }: UseOfferByIdParams) =>
  useQuery(['orderGetById', orderId, offerId], () => getOfferById(orderId, offerId), {
    staleTime: Infinity,
  });
