import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
`;

export const Body = styled.div`
  padding: ${({ theme }) => theme.spaces.s};
  width: 100%;

  ${({ theme }) =>
    theme.utils.media.moreThanMobile`
      padding: ${() => theme.spaces.m};
  `}
`;

export const Footer = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.l};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  padding: ${({ theme }) => `${theme.spaces.m} ${theme.spaces.l}`};
  width: 100%;
  button {
    width: 160px;
  }
`;

export const ButtonWrap = styled.div`
  position: relative;
`;
