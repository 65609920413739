import { FC } from 'react';
import ContactsInfo from './ContactsInfo';
import ContactsForm from './ContactsForm';
import { Colums } from '@components/Colums';
import { MarginWrapper } from '@theme/shared/wrappers';

const Contacts: FC = () => {
  return (
    <MarginWrapper marginTop="m">
      <Colums left={<ContactsInfo />} right={<ContactsForm />} />
    </MarginWrapper>
  );
};

export default Contacts;
