import { KeyboardArrowUp } from '@material-ui/icons';
import { Accordion, AccordionDetails } from '@material-ui/core';
import { useState } from 'react';
import { CompanyProvider } from '../context';
import { License } from '../CompanyInfo/License';
import { Subtitle, Wrapper, IconWrap, AccordionSummary, SubtitleWrap } from './styled';
import { CompanyViewModel } from '@http/models/view-models/company';
import { Icon } from '@components/Icon';
import { Nullable } from 'types';

interface Props {
  company: CompanyViewModel;
  isShowCompanyTitle?: boolean;
}

export const Licenses: React.FC<Props> = ({ company }) => {
  const { licenses, typeMoves } = company;
  const [expandedIndex, setExpandedIndex] = useState<Nullable<number>>(0);

  const handleAccordionChange = (index: number) => (_: any, newExpanded: any) => {
    setExpandedIndex(newExpanded ? index : null);
  };

  return (
    <Wrapper>
      <SubtitleWrap>
        <Subtitle>Лицензии</Subtitle>
      </SubtitleWrap>
      {licenses &&
        licenses.map((license, index) => (
          <Accordion expanded={expandedIndex === index} key={license.id} onChange={handleAccordionChange(index)}>
            <AccordionSummary
              aria-controls={`panel-content-${license.id}`}
              id={`panel-header-${license.id}`}
              expandIcon={<KeyboardArrowUp />}
              isActive={license.isActive}
            >
              {license.number}{' '}
              <IconWrap isActive={license.isActive}>
                <Icon name={license.isActive ? 'check-circle.outline' : 'exclamation-circle.outline'} />
              </IconWrap>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              <CompanyProvider typeMoves={typeMoves}>
                <License license={license} />
              </CompanyProvider>
            </AccordionDetails>
          </Accordion>
        ))}
    </Wrapper>
  );
};
