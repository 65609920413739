import styled from 'styled-components';
import { Tooltip } from '@components/Tooltip';

export const Wrapper = styled.div`
  position: relative;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spaces.xs};
    padding-top: ${({ theme }) => theme.spaces.xs};
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const PriceFieldWrapper = styled.div<{ isActive: boolean }>`
  min-width: 200px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
`;

export const CommentHint = styled(Tooltip)`
  padding-top: 3px;
  width: 15px;
  height: 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const CommentIcon = styled.div`
  min-width: 15px;
  min-height: 15px;
  color: ${({ theme }) => theme.palette.main.primary};
`;

interface OpacitySwitcherProps {
  isActive: boolean;
}

export const OpacitySwitcher = styled.div<OpacitySwitcherProps>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
`;
