import { object, string } from 'yup';
import { testBlackListWords } from '@shared/utils/validation';

export const validationSchema = object().shape({
  title: string()
    .test('blackList', 'Указаны запрещенные символы', testBlackListWords)
    .nullable()
    .required('Обязательное поле')
    .max(200, 'Максимум 200 символов'),
  price: string().nullable().required('Обязательное поле'),
  priceDesc: string().nullable().max(50, 'Максимум 50 символов'),
  description: string()
    .test('blackList', 'Указаны запрещенные символы', testBlackListWords)
    .nullable()
    .required('Обязательное поле')
    .max(200, 'Максимум 200 символов'),
});
