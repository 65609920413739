import React, { useState } from 'react';
import { DateTime } from 'luxon';
import Cookies from 'js-cookie';
import { MarginWrapper } from '../../theme/shared/wrappers';
import { Button, ButtonWrapper } from './styled';
import { useTimeout } from '@shared/hooks/use-timeout';
import { USER_ACCEPTED_COOKIES_KEY } from '@constants/index';
import { Notification } from 'components/Notification';
import { useUser } from 'providers/user';

const TIME_TO_SHOW_MS = 30000;

export const AcceptCookies: React.FC = () => {
  const [isOpen, setOpenStatus] = useState(false);
  const user = useUser();

  useTimeout(() => {
    const isAccepted = Boolean(Cookies.get(USER_ACCEPTED_COOKIES_KEY));
    if (!user.exist() && !isAccepted) {
      setOpenStatus(true);
    }
  }, TIME_TO_SHOW_MS);

  const handleAccept = () => {
    setOpenStatus(false);
    Cookies.set(USER_ACCEPTED_COOKIES_KEY, DateTime.now().toISO(), { expires: 365, path: '/', samesite: 'Strict' });
  };

  return (
    <Notification isOpen={isOpen}>
      <MarginWrapper marginBottom="s">
        Мы используем файлы cookie и другие аналогичные технологии, чтобы вам было удобнее работать с нашим сайтом.
      </MarginWrapper>
      <ButtonWrapper>
        <Button variant="outlined" onClick={handleAccept}>
          Понятно
        </Button>
      </ButtonWrapper>
    </Notification>
  );
};
