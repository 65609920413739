import React, { FC, useMemo } from 'react';
import { CompanyTypesListNew } from './styled';
import { CompanyTypeViewNew } from './CompanyTypeViewNew';
import { getTypeMovesByFlag } from '@shared/utils/helpers';
import { typesCompanyDictionaryKeys, typesCompanyDictionaryNew } from '@constants/company';
import { Nullable } from 'types';

interface Props {
  flag: Nullable<number>;
  compactView: boolean;
}

export const CompanyTypesNew: FC<Props> = ({ flag, compactView }) => {
  const companyTypes = useMemo(() => (Number.isFinite(flag) && getTypeMovesByFlag(flag!)) || [], [flag]);

  if (!flag) {
    return null;
  }

  return (
    <CompanyTypesListNew>
      {typesCompanyDictionaryKeys.map(typeKey => {
        const isExist = companyTypes.includes(typeKey);

        return (
          <CompanyTypeViewNew
            key={typeKey}
            icon={typesCompanyDictionaryNew[typeKey][1]}
            title={typesCompanyDictionaryNew[typeKey][0]}
            compactView={compactView}
            isExist={isExist}
          />
        );
      })}
    </CompanyTypesListNew>
  );
};
