import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { Text } from '@theme/shared/text';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 65px;
  flex: 1;
`;

export const Title = styled(Text.Body)`
  max-height: calc(${({ theme }) => theme.textSizes.body.lineHeight} * 3);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex: 1;
  max-width: 400px;
`;

export const Price = styled(Text.BodyBold)`
  text-align: end;
  flex: 1;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    text-align: start;
  `}
`;

export const Body = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xxs};
`;

export const Description = styled(Text.Caption)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const TopBlock = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.m};
`;

export const Controls = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.xxs};
`;

export const Control = styled(IconButton)`
  &.MuiButtonBase-root {
    border: 1px solid ${({ theme }) => theme.palette.main.primary};
    width: 28px;
    height: 28px;

    &:hover {
      background: ${({ theme }) => theme.palette.main.primary};
      border-color: ${({ theme }) => theme.palette.main.primary};
      svg {
        color: ${({ theme }) => theme.palette.grey.white};
      }
    }
  }
`;

export const TopRightBlock = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spaces.m};
  justify-content: space-between;
  max-width: 240px;
  flex: 1;
`;
