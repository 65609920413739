interface YM {
  reachGoal(event: string): void;
}

const findYMCounter = (): YM => {
  const counters: any[] = Object.keys(window).filter(key => key.includes('yaCounter'));
  if (counters.length === 0) {
    return {
      reachGoal(event) {
        console.log('YM [Mock] trigger reach goal', event);
      },
    };
  }

  if (counters.length > 1) {
    console.warn('Found multiple YA counters, use first', counters);
  }

  return window[counters[0]] as unknown as YM;
};

export const applyYMGoal = (event: string) => {
  const counter = findYMCounter();
  counter.reachGoal(event);
};
