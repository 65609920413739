import styled from 'styled-components';
import { Text } from '@theme/shared/text';
import { BlockCollapse } from '@theme/shared/components';

export interface RawProps {
  marginBottom?: boolean;
}

export interface ExplanationChildProps {
  nthChild: number;
}

const widthColumnCode = '155px';

export const Title = styled(Text.Header2)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spaces.m};
`;

export const TitleProp = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Paragraph = styled.p`
  margin-bottom: ${({ theme }) => theme.spaces.s};
`;

export const Raw = styled.div<RawProps>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${({ theme, marginBottom }) => (marginBottom ? theme.spaces.m : 0)};

  ${({ theme }) => theme.utils.media.tablet`
    flex-wrap: nowrap;
  `}
`;

export const NumberPart = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.tablet`
    max-width: ${widthColumnCode};
    margin-right: ${({ theme }) => theme.spaces.m};
    margin-bottom: 0;
  `}
`;

export const DescPart = styled.div`
  width: 100%;

  p {
    font-size: ${({ theme }) => theme.textSizes.body.size};
    font-weight: ${({ theme }) => theme.textSizes.bodyBold.weight};
    line-height: ${({ theme }) => theme.textSizes.body.lineHeight};
  }
`;

export const Explanation = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.tablet`
    margin-left: calc(${({ theme }) => theme.spaces.l} + ${widthColumnCode});
    margin-top: 0;
  `}
`;

export const ExplanationChild = styled.div<ExplanationChildProps>`
  color: ${({ theme }) => theme.palette.text.link};

  a {
    display: flex;
  }

  ${({ theme, nthChild }) => theme.utils.media.tablet`
    b {
      margin-left: calc(${({ theme }) => theme.spaces.s} * ${nthChild} );
    }
  `}

  ${({ theme }) => theme.utils.media.mobile`
    a {
      flex-direction: column;
    }
  `}
`;

export const TableTitle = styled(BlockCollapse)`
  background-color: ${({ theme }) => theme.palette.grey.background};
  font-size: ${({ theme }) => theme.textSizes.subHeader4.size};
  font-weight: ${({ theme }) => theme.textSizes.bodyBold.weight};
  line-height: ${({ theme }) => theme.textSizes.subHeader4.lineHeight};
  margin-top: ${({ theme }) => theme.spaces.xl};
  margin-bottom: ${({ theme }) => theme.spaces.s};
  width: 100%;
`;

export const ScrollableTable = styled.div`
  overflow-x: auto;
  padding: 0 ${({ theme }) => theme.spaces.s};
  margin-bottom: ${({ theme }) => theme.spaces.s};

  .MuiTableCell-root {
    border-color: ${({ theme }) => theme.palette.grey.light};
    color: ${({ theme }) => theme.palette.text.link};
    line-height: 20px;
    padding: 20px 20px 10px 0;
    min-width: 80px;
  }

  .MuiTableHead-root {
    .MuiTableCell-root {
      border-bottom: 2px solid ${({ theme }) => theme.palette.grey.light};
      color: ${({ theme }) => theme.palette.text.secondary};
      padding: 8px 10px 8px 0;
    }
  }

  ${({ theme }) => theme.utils.media.mobile`
    .MuiTableRow-root {
      display: flex;
      flex-direction: column;
    }
  `}
`;
