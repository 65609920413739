import { ExtractKeys } from '../../types';
import { isNotNull } from './is-not-null';
import { typesMoveDictionary } from '@constants/company';
import { TypeCompany, TypeMove, WarningClass } from '@http/models/api/company';

export const getTypesMoveTitle = (moveTypes: TypeMove[] = []) =>
  moveTypes
    .map((moveType: string | number) => (typesMoveDictionary as any)[moveType])
    .filter(Boolean)
    .join(' / ');

export const getTypeMovesByFlag = (flag: number): TypeMove[] => {
  return Object.values(TypeMove)
    .map(type => (typeof type !== 'string' ? type : null))
    .filter(isNotNull)
    .filter(type => type & flag);
};

export const getTypesCompanyByFlag = (flag: number): TypeCompany[] => {
  return Object.values(TypeCompany)
    .map(type => (typeof type !== 'string' ? type : null))
    .filter(isNotNull)
    .filter(type => type & flag);
};

export const getClassTypesTitle = (classTypes: WarningClass[] = []) => classTypes.filter(Boolean).sort().join(',');

export const totalBy = <T>(arr: T[], getTickNumber: (model: T) => number) => {
  return arr.reduce((acc, cur) => {
    return acc + getTickNumber(cur);
  }, 0);
};

/**
 *
 * @param obj any
 * @param path string
 * @description getting object field by lodash path example 'deepPath({ foo: { bar: 1 } }, 'foo.bar') // 1
 */
export const deepPath = <T extends Record<string, any>>(obj: T, path: ExtractKeys<T>) => {
  const paths = path.toString().split('.');
  let current = obj;

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current as any;
};

export const tonsToKg = (value: number) => {
  return value * 1000;
};

export const kgToTons = (value: number) => {
  return value / 1000;
};

export const rubsToPenny = (value: number) => {
  return value * 100;
};

export const pennyToRubs = (value: number) => {
  return value / 100;
};

export const getRandomFromArray = <T>(array: Array<T>): T => array[Math.floor(Math.random() * array.length)];

export function getQuarterByMonth(month: number) {
  return Math.floor(month / 3);
}

export function getQuarterByDate(date: Date) {
  return Math.floor((date.getMonth() + 3) / 3);
}

export const getSeveralRandomFromArray = <T>(array: Array<T>, amount: number = 1): T[] => {
  const mutableArr = [...array];
  return Array.from({ length: amount })
    .map(() => {
      const index = Math.floor(Math.random() * mutableArr.length);
      return mutableArr.splice(index, 1)[0];
    })
    .filter(isNotNull);
};

export const removeBase64ImgPrefix = (base64String?: string) => {
  const prefixRegexp = /^data:image\/[a-z]+;base64,/;
  return base64String?.replace(prefixRegexp, '');
};

const sizeNames = ['B', 'KB', 'MB', 'GB', 'TB'] as const;
export type SizeTypes = typeof sizeNames[number];

export function toBytes(size: number, type: SizeTypes) {
  const key = sizeNames.indexOf(type);

  return size * 1024 ** key;
}

export const sleep = async (waitForMs: number): Promise<void> =>
  new Promise(resolve => setTimeout(() => resolve(), waitForMs));

export const safe = async <R = void>(fn: () => R | Promise<R>) => {
  try {
    await fn();
  } catch {
    // silence
  }
};

export const getOptionsFromDict = (dict: Record<any, any>) =>
  Object.keys(dict).map(key => ({
    label: dict[key],
    value: +key,
  }));
