import { HttpOptions } from '@http/service/options';
import { httpService } from '@http/service';
import { ApiPaginateParams, buildQs } from '@http/shared';
import { OfferShortViewModel, OfferViewModel } from '@http/models/view-models/offer';
import { Nullable } from 'types';

export const getOfferById = (orderId: number, offerId: number, authToken?: Nullable<string>) => {
  const options = new HttpOptions().asAuthRoute();
  if (authToken) {
    options.setHeader('Authorization', `Bearer ${authToken}`);
  }
  return httpService.get<OfferViewModel>(buildQs(`/orders/${orderId}/cp/${offerId}`), options);
};

export interface GetOffersFilter extends ApiPaginateParams {
  orderId?: number;
  onlyMe?: boolean;
  forMe?: boolean;
}

export interface GetOffersResponse {
  recods: OfferShortViewModel[];
  totalCount: number;
}

export const getOffers = (filter: GetOffersFilter) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<GetOffersResponse>(buildQs('/orders/cp', filter), options);
};
