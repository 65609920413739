import { DateTime } from 'luxon';
import { Key, Value, Wrapper, KeyValue, SummaryWrapper } from './styled';
import { statusOrderText } from '@http/models/api/orders';
import { kgToTons, totalBy } from '@shared/utils/helpers';
import { OrderViewModel } from '@http/models/view-models/orders';
import { breaksHundreds } from '@lib/Components/fields/input-value-helpers';
import { CompanyLink } from '@components/CompanyLink';
import { Text } from '@theme/shared/text';

interface Props {
  order: OrderViewModel;
  isPageLayout?: boolean;
}

export const OfferSummary = ({ order }: Props) => {
  const keyValue = (key: string, value: any) => (
    <KeyValue>
      <Key>{key}:</Key>
      <Value>{value || '-'}</Value>
    </KeyValue>
  );

  return (
    <Wrapper>
      <CompanyLink companyId={order.companyId} customRender={<Text.Header4>{order.companyName}</Text.Header4>} />
      <SummaryWrapper>
        <div>
          {keyValue('Местонахождение отходов', order.regionTitle)}
          {keyValue('Статус заявки', statusOrderText[order.status])}
          {order.rows && keyValue('Общая масса', `${breaksHundreds(totalBy(order.rows, m => kgToTons(m.netto)))} т`)}
        </div>
        <div>
          {keyValue('Дата создания', DateTime.fromISO(order.createdAt).toLocaleString())}
          {keyValue('Дата окончания', order.dateEnd && DateTime.fromISO(order.dateEnd).toLocaleString())}
          {keyValue('Комментарий', order.comment)}
        </div>
      </SummaryWrapper>
    </Wrapper>
  );
};
