import React, { useMemo } from 'react';
import { Wrapper, Item, BlockLink, BlockSpan, Title, Container } from './styled';
import { CompanyViewModel } from '@http/models/view-models/company';
import { Icon } from '@components/Icon';
import { useUser } from 'providers/user';

interface Props {
  company: CompanyViewModel;
}

export const CompanyContacts = ({ company: { site, phone, stationaryPhone, email } }: Props) => {
  const user = useUser();

  const sites = useMemo(() => {
    if (!site) {
      return null;
    }

    return site.split(',').map(item => {
      const siteUrl = item.trim();
      return (
        <BlockLink key={siteUrl} href={`http://${siteUrl}`} target="_blank" rel="noreferrer noopener">
          {siteUrl}
        </BlockLink>
      );
    });
  }, [site]);

  const phones = useMemo(() => {
    if (!stationaryPhone) {
      return null;
    }

    return stationaryPhone.split(',').map(item => {
      const phoneNumber = item.trim();
      return <BlockSpan key={phoneNumber}>{phoneNumber}</BlockSpan>;
    });
  }, [stationaryPhone]);

  if (!user.exist()) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Контакты</Title>
      <Container>
        <div>
          {phone && (
            <Item>
              <Icon name="phone.solid" size="sm" />
              <a style={{ display: 'block' }} href={`tel:${phone}`} rel="noreferrer noopener">
                {phone}
              </a>
            </Item>
          )}
          {email && (
            <Item>
              <Icon name="mail.solid" size="sm" />
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </Item>
          )}
        </div>
        <div>
          {sites && (
            <Item>
              <div className="label">Сайты:</div>
              <div>{sites}</div>
            </Item>
          )}

          {(stationaryPhone || email) && (
            <div>
              {email && (
                <Item>
                  <div className="label">Email:</div>
                  <div>
                    <a href={`mailto:${email}`}>{email}</a>
                  </div>
                </Item>
              )}
              {stationaryPhone && (
                <Item>
                  <div className="label">Дополнительные номера:</div>
                  <div>{phones}</div>
                </Item>
              )}
            </div>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};
