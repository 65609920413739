import { FC, ReactNode } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Wrapper, Body, Footer, ButtonWrap } from './styled';
import { Text } from '@theme/shared/text';
import { Button } from '@components/Button';
import { Tooltip } from '@components/Tooltip';

interface Props {
  formTitle: ReactNode | (() => ReactNode);
  isLoading: boolean;
  disabledSaveBtnText?: NonNullable<ReactNode>;
  defaultSaveBtnText: NonNullable<ReactNode>;
  btnConfirmText?: string;
  closeInlineFrame: () => void;
  handleSave: () => void;
}

export const InlineFrameForm: FC<Props> = ({
  formTitle,
  children,
  isLoading,
  disabledSaveBtnText,
  defaultSaveBtnText,
  btnConfirmText = 'Сохранить',
  closeInlineFrame,
  handleSave,
}) => {
  return (
    <Wrapper>
      <Body>
        {typeof formTitle === 'function' ? formTitle() : <Text.Header3>{formTitle}</Text.Header3>}
        {children}
      </Body>
      <Footer>
        <Button disabled={isLoading} variant="outlined" onClick={closeInlineFrame}>
          Отмена
        </Button>
        <Tooltip title={disabledSaveBtnText || defaultSaveBtnText}>
          <ButtonWrap>
            <Button
              startIcon={isLoading && <CircularProgress size={16} color="inherit" />}
              variant="filled"
              onClick={handleSave}
              disabled={disabledSaveBtnText !== undefined}
            >
              {btnConfirmText}
            </Button>
          </ButtonWrap>
        </Tooltip>
      </Footer>
    </Wrapper>
  );
};
