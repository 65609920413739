import { FC } from 'react';
import dynamic from 'next/dynamic';
import { MarginWrapper } from '@theme/shared/wrappers';
import { LoaderBox } from '@components/LoaderBox';
import { TariffPlan } from 'screens/Tariffs/tariffsMap';
import { Nullable } from 'types';

const DynamicModal = dynamic(() => import('./'), {
  ssr: false,
  loading: () => (
    <MarginWrapper marginTop="m">
      <LoaderBox />
    </MarginWrapper>
  ),
});

interface UnavailableByTariffModalLazyProps {
  isOpen: boolean;
  recommendedTariff: Nullable<TariffPlan>;
  closeModal: () => void;
}

export const UnavailableByTariffModalLazy: FC<UnavailableByTariffModalLazyProps> = ({
  isOpen,
  recommendedTariff,
  closeModal,
}) => {
  if (!recommendedTariff) return null;

  return <DynamicModal isOpen={isOpen} recommendedTariff={recommendedTariff} closeModal={closeModal} />;
};
