import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const Wrapper = styled.div`
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`;

export const Title = styled(Text.Header1)`
  margin-bottom: ${({ theme }) => theme.spaces.s};
  padding-right: 40px;
`;
