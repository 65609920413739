import { useOffer } from '../context';
import { Note } from '@components/Note';

export const Notes = () => {
  const { order, state: offer } = useOffer();
  return (
    <>
      {offer.interesting && (
        <Note color="success">
          {order.isMyOrder ? 'Вы выбрали этот ответ как интересный' : 'Этот ответ заинтересовал эколога'}
        </Note>
      )}
    </>
  );
};
