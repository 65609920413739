import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { FkkoInlineComponent } from './component/FkkoInlineComponent';
import { CompanyInlineComponent } from './component/CompanyInlineComponent';
import { OrderInfoInlineCard } from './component/OrderInfoInlineCard';
import { ActiveModals, ContextProps, DeleteCallbacks, DeleteCallbacksPayload, ModalType } from './types';
import { OfferInlineComponent } from './component/OfferInlineComponent';
import { InlineFrame } from '@shared/inline-frame';
import { isNotNull } from '@shared/utils/is-not-null';
import { useUser } from 'providers/user';
import { COMPANY_TABS, MODAL_COMPANY_INFO, MODAL_ORDER_INFO } from 'types/routesQueryNames';
import { openModalIfHaveId } from '@shared/utils/openModalIfHaveId';
import { Nullable } from 'types';

const modalsWithQuery: ModalType[] = ['company', 'order'];

const getQueryKey = (payload: ModalType): typeof MODAL_COMPANY_INFO | typeof MODAL_ORDER_INFO | undefined => {
  switch (payload) {
    case 'company':
      return MODAL_COMPANY_INFO;
    case 'order':
      return MODAL_ORDER_INFO;
    default:
      return;
  }
};

const Context = React.createContext<ContextProps | undefined>(undefined);

export const useModals = () => React.useContext(Context) as ContextProps;

export const ModalProvider: React.FC = ({ children }) => {
  const [activeModals, setActiveModals] = useState<ActiveModals[]>([]);
  const [deleteCallbacks, setDeleteCallbacks] = useState<Nullable<DeleteCallbacks>>(null);
  const [multipleModal, setMultipleModal] = useState<Nullable<ActiveModals>>(null);
  const router = useRouter();
  const user = useUser();
  const isUserExist = user.exist();

  useEffect(() => {
    const activeCompany: Nullable<number> = router.query && openModalIfHaveId(router.query, MODAL_COMPANY_INFO);
    const activeOrder: Nullable<number> = router.query && openModalIfHaveId(router.query, MODAL_ORDER_INFO);
    const queryModals: ActiveModals[] = [
      activeOrder && {
        type: 'order',
        activeId: activeOrder.toString(),
      },
      activeCompany && {
        type: 'company',
        activeId: activeCompany.toString(),
      },
    ]
      .filter((qm: 0 | ActiveModals | null): qm is ActiveModals => {
        if (!qm) return false;

        return true;
      })
      .filter(qm => {
        const alreadyAdded = activeModals.find(modal => modal.type === qm.type);
        if (alreadyAdded) {
          return;
        }

        if (activeOrder && !isUserExist && qm.type === 'company') {
          return;
        }

        return qm;
      });

    if (queryModals.length === 2 && !multipleModal) {
      setMultipleModal(queryModals[1]);
    }

    if (queryModals.length > 0) {
      const updatedModals = new Set([...activeModals, ...queryModals]);
      setActiveModals(Array.from(updatedModals));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose: ContextProps['close'] = useCallback(
    (payload: ModalType) => {
      const updatedModals = activeModals
        .map(modal => {
          if (modal.type === payload) {
            return null;
          }

          return modal;
        })
        .filter(isNotNull);

      setActiveModals(updatedModals);

      if (updatedModals.length === 1 && multipleModal) {
        setMultipleModal(null);
      }

      if (modalsWithQuery.includes(payload)) {
        const queryKey = getQueryKey(payload);

        if (!queryKey) return;

        if (payload === 'company' && router.query[COMPANY_TABS]) {
          delete router.query[COMPANY_TABS];
        }

        delete router.query[queryKey];
        router.replace(
          {
            pathname: router.pathname,
            query: { ...router.query },
          },
          undefined,
          { scroll: false }
        );
      }
    },
    [activeModals, multipleModal, router]
  );

  const handleOpen: ContextProps['open'] = useCallback(
    (payload: ActiveModals) => {
      let isShouldAdd = true;
      let updatedModals = activeModals.map(addedModal => {
        if (payload.type === addedModal.type) {
          isShouldAdd = false;
          return payload;
        }

        return addedModal;
      });

      if (isShouldAdd) {
        updatedModals = [...updatedModals, payload];
      }

      setActiveModals(updatedModals);

      if (updatedModals.length === 2) {
        setMultipleModal(payload);
      }

      if (modalsWithQuery.includes(payload.type)) {
        const queryKey = getQueryKey(payload.type);

        if (!queryKey) return;

        router.replace(
          {
            pathname: router.pathname,
            query: { ...router.query, [queryKey]: payload.activeId },
          },
          undefined,
          { scroll: false }
        );
      }
    },
    [activeModals, router]
  );

  const handleDelete: ContextProps['close'] = useCallback(
    payload => {
      if (deleteCallbacks && deleteCallbacks[payload]) {
        deleteCallbacks[payload]?.();
      }
      handleClose(payload);
    },
    [deleteCallbacks, handleClose]
  );

  const setDeleteCallback: (payload: DeleteCallbacksPayload) => void = useCallback(
    (payload: DeleteCallbacksPayload) => {
      if (!deleteCallbacks) {
        setDeleteCallbacks({
          [payload.type]: payload.onDelete,
        });
        return;
      }

      setDeleteCallbacks({
        ...deleteCallbacks,
        [payload.type]: payload.onDelete,
      });
    },
    [deleteCallbacks]
  );

  const handleCheckOpenPage: ContextProps['shouldOpenPage'] = useCallback(
    (payload: ActiveModals) => {
      if (payload.type === 'fkko' && activeModals.find(addedModal => addedModal.type === payload.type)) {
        return false;
      }
      return activeModals.length > 1;
    },
    [activeModals]
  );

  const value = useMemo((): ContextProps => {
    return {
      open: handleOpen,
      close: handleClose,
      delete: setDeleteCallback,
      shouldOpenPage: handleCheckOpenPage,
    };
  }, [handleOpen, handleClose, handleCheckOpenPage, setDeleteCallback]);

  return (
    <Context.Provider value={value}>
      {children}
      {activeModals.map(modal => {
        switch (modal.type) {
          case 'fkko':
            return (
              <InlineFrame
                isOpen
                isMultiple={Boolean(multipleModal && modal.type === multipleModal.type)}
                onClose={() => handleClose(modal.type)}
              >
                <FkkoInlineComponent activeId={modal.activeId} />
              </InlineFrame>
            );
          case 'order':
            return (
              <InlineFrame
                isOpen
                isMultiple={Boolean(multipleModal && modal.type === multipleModal.type)}
                onClose={() => handleClose(modal.type)}
              >
                <OrderInfoInlineCard activeId={modal.activeId} onDelete={() => handleDelete(modal.type)} />
              </InlineFrame>
            );
          case 'offer':
            return (
              <InlineFrame
                isOpen
                isMultiple={Boolean(multipleModal && modal.type === multipleModal.type)}
                onClose={() => handleClose(modal.type)}
              >
                <OfferInlineComponent activeId={modal.activeId} adjectiveId={modal.adjectiveId} />
              </InlineFrame>
            );
          case 'company':
            return (
              <InlineFrame
                isOpen
                isMultiple={Boolean(multipleModal && modal.type === multipleModal.type)}
                onClose={() => handleClose(modal.type)}
                colorTheme={'light'}
                modalContentPaddings={{
                  default: '0',
                  moreThanMobile: '0',
                }}
              >
                <CompanyInlineComponent activeId={modal.activeId} />
              </InlineFrame>
            );
          default:
            return null;
        }
      })}
    </Context.Provider>
  );
};
