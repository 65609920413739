import { newsParamsFactory, routes } from 'routes';

export const protectedNewsCategoryId = 4; // Право

export const protectedRoutes = [
  routes.company.accounting(),
  routes.reports.report2TPWasteCreate,
  routes.newsList(newsParamsFactory({ categoryId: protectedNewsCategoryId })),
];

export type ProtectedAction =
  | 'EditCompanyLogo'
  | 'AddCompanyService'
  | 'EditCompanyService'
  | 'EditCompanyDescription'
  | 'AddCompanyPhoto';

export const forbiddenActions: ProtectedAction[] = [
  'EditCompanyLogo',
  'AddCompanyService',
  'EditCompanyService',
  'EditCompanyDescription',
  'AddCompanyPhoto',
];
