import {
  Author,
  Header,
  StyledRating,
  Title,
  TitleContainer,
  Wrapper,
  Date,
  Content,
  EditControls,
  Container,
  LeftColumn,
  ImageContainer,
} from './styled';
import { AttachedImage } from './AttachedImage';
import StyledBadge from '@components/Bage';
import { Button } from '@components/Button';
import { formatDate } from '@shared/utils/date';
import { CompanyReview } from '@http/models/api/reviews';
import { useUser } from 'providers/user';

interface ReviewCardProps {
  review: CompanyReview;
  hasOwnerRules?: boolean;
  onApprove?: (id: number) => void;
  onReject?: (id: number) => void;
}

export const ReviewCard: React.FC<ReviewCardProps> = ({ review, hasOwnerRules, onApprove, onReject }) => {
  const { id, title, image, userAbout, createDate, rate, userId, isVisible, desc } = review;
  const user = useUser();
  const isNeedModerate = hasOwnerRules && !isVisible;
  const isUserAuthor = user.data?.id === userId;
  const isModeratePending = isUserAuthor && !hasOwnerRules && !isVisible;

  const renderBadge = () => {
    if (isNeedModerate) {
      return <StyledBadge variant="secondary">Новый</StyledBadge>;
    }

    if (isModeratePending) {
      return <StyledBadge variant="error">На проверке</StyledBadge>;
    }
  };

  return (
    <Container>
      <LeftColumn>
        <Header>
          <TitleContainer>
            <Title>{title}</Title>
            {renderBadge()}
          </TitleContainer>
          <Author>{userAbout}</Author>
          <Wrapper>
            <Date>{formatDate(createDate)}</Date>
            <StyledRating size={16} initialValue={rate} readonly />
          </Wrapper>
        </Header>
        <Content>{desc}</Content>
        {isNeedModerate && (
          <EditControls>
            {onApprove && <Button onClick={() => onApprove(id)}>Принять</Button>}
            {onReject && (
              <Button onClick={() => onReject(id)} variant="outlined">
                Отклонить
              </Button>
            )}
          </EditControls>
        )}
      </LeftColumn>
      {image && (
        <ImageContainer>
          <AttachedImage imageUrl={image} />
        </ImageContainer>
      )}
    </Container>
  );
};
