import { SaleItem } from '../SaleItem';
import { AddButton } from '../AddButton';
import { Container, Title, Wrapper } from './styled';
import { CompanySale } from '@http/models/api/company';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { MarginWrapper } from '@theme/shared/wrappers';

export const MAX_SALES_SHOWN = 6;
interface Props {
  sales?: CompanySale[];
  hasEditControls?: boolean;
  hideAfterMax?: boolean;
  onClickAdd: () => void;
  onEditItemClick: (item: CompanySale) => void;
  onDeleteClick: (id: number) => void;
}

export const SaleItemList: React.FC<Props> = ({
  sales,
  hasEditControls,
  onClickAdd,
  hideAfterMax,
  onEditItemClick,
  onDeleteClick,
}) => {
  const { isMobile } = useScreenSize();

  const renderItems = (items: CompanySale[]) => {
    return items.map((sale, idx) => {
      const isSaleHidden = hideAfterMax && idx >= MAX_SALES_SHOWN;

      return (
        <div key={sale.id} style={{ display: isSaleHidden ? 'none' : 'block' }}>
          <SaleItem
            sale={sale}
            hasEditControls={hasEditControls}
            hideDescription={isMobile}
            onEditClick={onEditItemClick}
            onDeleteClick={onDeleteClick}
          />
        </div>
      );
    });
  };

  const renderAddButton = () => {
    if (!sales?.length && hasEditControls) {
      return (
        <MarginWrapper marginTop="l">
          <AddButton onClick={onClickAdd} />
        </MarginWrapper>
      );
    }
  };

  return (
    <Wrapper>
      <Title>Услуги</Title>
      {renderAddButton()}
      {sales?.length ? (
        <Container>
          {renderItems(sales)}
          {hasEditControls && <AddButton onClick={onClickAdd} />}
        </Container>
      ) : null}
    </Wrapper>
  );
};
