export enum RequestType {
  Get = 'get',
  Put = 'put',
  Post = 'post',
  Delete = 'delete',
}

export interface AxiosProgressEvent {
  loaded: number;
  total: number;
  progress?: number;
  bytes: number;
  estimated?: number;
  rate?: number;
  upload?: boolean;
}
