import React from 'react';
import { useCompany } from '@http/hooks/company';
import { CompanyHeader } from 'screens/Company/CompanyHeader';
import CompanyTabs from 'screens/Company/CompanyTabs';
import { DivWrapper } from '@theme/shared/wrappers';
import { PulseLoader } from '@components/PulseLoader';

export const CompanyInlineComponent = ({ activeId }: { activeId: string }) => {
  const intCompanyId = Number(activeId);
  const { data, isLoading } = useCompany(intCompanyId);

  if (Number.isNaN(intCompanyId)) return null;

  if (isLoading || !data)
    return (
      <DivWrapper height="100%" display="flex" alignItems="center" justifyContent="center">
        <PulseLoader delayToShowMs={150} />
      </DivWrapper>
    );

  return (
    <>
      <CompanyHeader company={data} activeId={activeId} />
      <CompanyTabs company={data} />
    </>
  );
};
