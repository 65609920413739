import { ReactNode } from 'react';
import { nanoid } from 'nanoid';
import { defaultTheme } from '@theme/list/default-theme';

export const tariffs = {
  light: {
    name: 'Критод 1',
    icon: null,
    price: 0,
    backgroundColor: defaultTheme.palette.main.light,
    textColor: defaultTheme.palette.main.accent,
    abilitiesExplanation: null,
    abilityCheckColor: defaultTheme.palette.text.link,
    extraBenefit: null,
  },
  standart: {
    name: 'Критод 2',
    icon: 'thumb-up.outline',
    price: 500,
    backgroundColor: defaultTheme.palette.main.primary,
    textColor: defaultTheme.palette.grey.white,
    abilitiesExplanation: {
      text: (
        <>
          Все возможности тарифа <b>Лайт</b>
        </>
      ),
      color: defaultTheme.palette.main.primary,
    },
    abilityCheckColor: defaultTheme.palette.main.primary,
    extraBenefit: {
      text: 'Выгодно',
      backgroundColor: defaultTheme.palette.main.secondary,
    },
  },
  premium: {
    name: 'Критод 3',
    icon: 'sparkles.outline',
    price: 1000,
    backgroundColor: 'linear-gradient(156.39deg, #5B7599 5.27%, #3E5372 84.79%)',
    textColor: defaultTheme.palette.grey.white,
    abilitiesExplanation: {
      text: (
        <>
          Все возможности тарифа <b>Стандарт</b>
        </>
      ),
      color: defaultTheme.palette.main.accent,
    },
    abilityCheckColor: defaultTheme.palette.main.accent,
    extraBenefit: null,
  },
} as const;

export type TariffPlan = keyof typeof tariffs;

export const tariffsList: TariffPlan[] = Object.keys(tariffs) as Array<TariffPlan>;

export interface TariffsApiMapped {
  tariffs: TariffPlan[];
  prices?: { [k in TariffPlan]: number };
}

export interface TariffDescriptionType {
  key: string;
  title: string;
  tariffs: TariffPlan[];
  comments?: ReactNode;
}

export const tariffsAbilitiesList: TariffDescriptionType[] = [
  {
    key: nanoid(2),
    title: 'Проверка актуальности лицензии',
    tariffs: ['light', 'standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Поиск контактных данных лицензиата',
    tariffs: ['light', 'standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Поиск исполнителя для выполнения работ',
    tariffs: ['light', 'standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Поиск заявок от крупнейших промышленных предприятий',
    tariffs: ['light', 'standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Актуальные новости сферы утилизации',
    tariffs: ['light', 'standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Пакетный поиск',
    tariffs: ['light', 'standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Учет отходов',
    tariffs: ['standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: '2 ТП-отходы',
    tariffs: ['standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Кастомизация карточки компании',
    tariffs: ['standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Комментарии юристов по изменению законодательства',
    tariffs: ['standart', 'premium'],
  },
  {
    key: nanoid(2),
    title: 'Заявка с модерацией',
    tariffs: ['premium'],
    comments: 'Поможем пройти модерацию',
  },
  {
    key: nanoid(2),
    title: 'Заявка с поддержкой специального менеджера на запрос ТКП',
    tariffs: ['premium'],
  },
  {
    key: nanoid(2),
    title: 'Выделенная поддержка по телефону',
    tariffs: ['premium'],
  },
  {
    key: nanoid(2),
    title: 'Работа проф. менеджера с проблемными отходами',
    tariffs: ['premium'],
  },
];

export const tariffsAbilitiesListMap = tariffsAbilitiesList.reduce(
  (acc, ability) => {
    if (ability.tariffs.includes('light')) {
      acc.light.push(ability);
    }

    if (ability.tariffs.includes('standart') && !ability.tariffs.includes('light')) {
      acc.standart.push(ability);
    }

    if (
      ability.tariffs.includes('premium') &&
      !ability.tariffs.includes('light') &&
      !ability.tariffs.includes('standart')
    ) {
      acc.premium.push(ability);
    }

    return acc;
  },
  {
    light: [] as TariffDescriptionType[],
    standart: [] as TariffDescriptionType[],
    premium: [] as TariffDescriptionType[],
  }
);

export const stylesConstants = {
  blockWidth: '200px',
  blockGap: defaultTheme.spaces.s,
} as const;

export function convertPriceToString(price: number) {
  if (price === 0) {
    return 'Бесплатно';
  }

  return `${price} ₽/кв`;
}

export function isTariffExistInMap(subName: string): subName is TariffPlan {
  return subName === 'light' || subName === 'standart' || subName === 'premium';
}
