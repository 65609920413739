import { AccountingAddFormType } from '../screens/Accounting/components/AddForm';

export enum AppEvents {
  unauthorized_user_try_to_create_order = 'ui.unauthorized_user_try_to_create_order',
  start_search_companies = 'ui.start_search_companies',
  create_new_order = 'ui.create_new_order',
  register_new_user = 'ui.register_new_user',
  user_add_company = 'ui.user_add_company',
  user_add_company_by_admin = 'ui.user_add_company_by_admin',
  go_to_registration_from_suggestion = 'ui.go_to_registration_from_suggestion',
  manually_close_registration_suggest = 'ui.manually_close_registration_suggest',
  scrollToElement = 'ui.scroll_to_element',
  scroll_layout = 'ui.scroll_layout',
  upload_file_update_percent = 'ui.upload_file_update_percent',

  // accounting
  accounting_focus_field = 'accounting.focus-field',
  accounting_submit_form = 'accounting.accounting_submit_form',
  accounting_set_form = 'accounting_set_form',
  accounting_reset_form = 'accounting_reset_form',

  // report
  report_focus_field = 'report_focus_field',
}

export interface AppEventsMetadata {
  [AppEvents.unauthorized_user_try_to_create_order]: void;
  [AppEvents.start_search_companies]: void;
  [AppEvents.create_new_order]: void;
  [AppEvents.register_new_user]: void;
  [AppEvents.user_add_company]: void;
  [AppEvents.user_add_company_by_admin]: void;
  [AppEvents.go_to_registration_from_suggestion]: void;
  [AppEvents.manually_close_registration_suggest]: void;
  [AppEvents.scrollToElement]: string;
  [AppEvents.scroll_layout]: void;
  [AppEvents.upload_file_update_percent]: { id: string; value: number };
  [AppEvents.accounting_set_form]: { form?: AccountingAddFormType };

  // accounting
  [AppEvents.accounting_focus_field]: { fieldName: string };
  [AppEvents.accounting_submit_form]: void;
  [AppEvents.accounting_reset_form]: void;

  //report
  [AppEvents.report_focus_field]: { fieldName: string };
}
