import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    margin-top: ${({ theme }) => theme.spaces.l};
  }

  ${({ theme }) => theme.utils.media.largeDesktop`
    flex-direction: row;

    > div {
      display: flex;
      flex-flow: column;
    }

    > div:nth-child(1) {
      margin-right: ${({ theme }) => theme.spaces.s};
      width: 50%;
    }

    > div:nth-child(2) {
      margin-top: 0;
      width: 50%;
      margin-left: ${({ theme }) => theme.spaces.s};
    }
  `}
`;
