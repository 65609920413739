import styled from 'styled-components';

export const Wrapper = styled.a`
  color: ${({ theme }) => theme.palette.text.link};
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  svg {
    width: 15px;
    margin-right: 6px;
  }
`;
