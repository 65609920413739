import { useState } from 'react';
import { AddButton, Container, ReviewsContainer, Header, Title, AddButtonUser, PaginationWrapper } from './styled';
import { ReviewCard } from './ReviewCard';
import { useReview } from './hooks';
import { AddReviewForm } from './AddReviewForm';
import { UnauthorizedNote } from './UnautorizedNote';
import { Icon } from '@components/Icon';
import { CompanyFullAPI } from '@http/models/api/company';
import { useCompanyReviews } from '@http/hooks/reviews';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { CompanyReview } from '@http/models/api/reviews';
import Pagination from '@components/Pagination';
import { useUser } from 'providers/user';

const TOTAL_REVIEWS_PER_PAGE = 10;

export interface ReviewsProps {
  company: CompanyFullAPI;
}

export const Reviews: React.FC<ReviewsProps> = ({ company }) => {
  const { isMobile } = useScreenSize();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const user = useUser();

  const { data } = useCompanyReviews({ companyId: company.id, page: currentPage });
  const { list: reviews, totalCount } = data || {};

  const {
    formOpened,
    handleAddReview,
    handleFormClose,
    handleOnApprove,
    refetchReviews,
    handleOnReject,
    refetchPendingReviews,
    refetchPendingUserReviews,
    isAnonym,
    pendingReviewsData,
    pendingUserReviews,
    isUserCanEdit,
  } = useReview(company);

  const renderReviews = (reviews?: CompanyReview[]) => {
    return reviews?.map(r => {
      const isUserOwner = user?.data?.id === r.userId;
      if (!isUserCanEdit && !r.isVisible && !isUserOwner) {
        return null;
      }

      return (
        <ReviewCard
          key={r.id}
          review={r}
          hasOwnerRules={isUserCanEdit}
          onApprove={handleOnApprove}
          onReject={handleOnReject}
        />
      );
    });
  };

  const handleOnSuccessAdd = () => {
    refetchReviews();
    if (isUserCanEdit) {
      refetchPendingReviews();
    } else {
      refetchPendingUserReviews();
    }
  };

  const handeOnPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      {isAnonym && <UnauthorizedNote />}
      <Header isOwnerView={isUserCanEdit || isMobile}>
        <Title>Отзывы</Title>
        {isUserCanEdit && (
          <AddButton variant="filled" startIcon={<Icon name="plus.solid" />} onClick={handleAddReview}>
            Добавить отзыв
          </AddButton>
        )}
        {!isUserCanEdit && !isAnonym && (
          <AddButtonUser variant="filled" onClick={handleAddReview}>
            {`Оставить отзыв${isMobile ? '' : ' о компании'}`}
          </AddButtonUser>
        )}
      </Header>
      <ReviewsContainer>
        {!isAnonym && (
          <>
            {renderReviews(pendingUserReviews)}
            {renderReviews(pendingReviewsData?.list)}
          </>
        )}
        {renderReviews(reviews)}
      </ReviewsContainer>
      {totalCount && totalCount > TOTAL_REVIEWS_PER_PAGE ? (
        <PaginationWrapper>
          <Pagination
            count={Math.ceil(totalCount / TOTAL_REVIEWS_PER_PAGE)}
            shape="rounded"
            color="primary"
            variant="outlined"
            page={currentPage}
            onChange={handeOnPageChange}
          />
        </PaginationWrapper>
      ) : null}

      <AddReviewForm isOpen={formOpened} company={company} onClose={handleFormClose} onSuccess={handleOnSuccessAdd} />
    </Container>
  );
};
