import styled, { css } from 'styled-components';
import { CONTENT_DESKTOP_MAX_WIDTH } from '../constants';
import { ColorTheme } from '@theme/types';

interface UsersCounterWrapper {
  colorTheme: ColorTheme;
}

export const UsersCounterWrapper = styled.div<UsersCounterWrapper>`
  font-size: 12px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-top: 20px;
  margin-right: ${({ theme }) => theme.spaces.s};
  max-width: ${CONTENT_DESKTOP_MAX_WIDTH}px;

  ${({ theme, colorTheme }) => {
    if (colorTheme === 'light') {
      return css`
        background-color: ${theme.palette.grey.white};
        padding: 0 ${theme.spaces.s};
        border-radius: 8px;
      `;
    }

    return '';
  }}

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-left: 20px;
`}
  ${({ theme }) => theme.utils.media.desktop`
    margin-left: 0;
    margin-top: 0;
`}
    ${({ theme }) => theme.utils.media.largeDesktop`
    margin-top: 0px;
  `};
`;

export const OnlineDot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.palette.main.primary};
  border-radius: 50%;
`;
