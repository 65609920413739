import { ThemedCssFunction } from 'styled-components';
import { DefaultTheme, css } from 'styled-components';
import { StyleTheme } from '@theme/types';

export interface MediaUtils {
  largeDesktop: ThemedCssFunction<DefaultTheme>;
  desktop: ThemedCssFunction<DefaultTheme>;
  tablet: ThemedCssFunction<DefaultTheme>;
  mobile: ThemedCssFunction<DefaultTheme>;
  moreThanMobile: ThemedCssFunction<DefaultTheme>;
  lessThanDesktop: ThemedCssFunction<DefaultTheme>;
  screenLessThan1600: ThemedCssFunction<DefaultTheme>;
}

export const mediaUtilsFactory = (largeDesktopSize: number, desktopSize: number, tabletSize: number): MediaUtils => {
  const pxWrap = (size: number) => `${size}px`;
  return {
    largeDesktop: (...args: [any]) => css`
      @media (min-width: ${pxWrap(largeDesktopSize)}) {
        ${css(...args)};
      }
    `,
    desktop: (...args: [any]) => css`
      @media (min-width: ${pxWrap(desktopSize)}) {
        ${css(...args)};
      }
    `,
    tablet: (...args: [any]) => css`
      @media (min-width: ${pxWrap(tabletSize)}) {
        ${css(...args)};
      }
    `,
    mobile: (...args: [any]) => css`
      @media (max-width: ${pxWrap(tabletSize - 1)}) {
        ${css(...args)};
      }
    `,
    moreThanMobile: (...args: [any]) => css`
      @media (min-width: ${pxWrap(tabletSize)}) {
        ${css(...args)};
      }
    `,
    lessThanDesktop: (...args: [any]) => css`
      @media (max-width: ${pxWrap(largeDesktopSize)}) {
        ${css(...args)};
      }
    `,
    screenLessThan1600: (...args: [any]) => css`
      @media (min-width: 1600px) {
        ${css(...args)};
      }
    `,
  };
};

export interface ThemeContextUtils {
  media: MediaUtils;
}

export const contextUtilsFactory = (theme: Omit<StyleTheme, 'utils'>): ThemeContextUtils => {
  return {
    media: mediaUtilsFactory(
      theme.screenSizes.minLargeDesktop,
      theme.screenSizes.minDesktop,
      theme.screenSizes.minTablet
    ),
  };
};
