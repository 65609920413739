import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Button } from '@components/Button';

interface FileFieldWrapperProps {
  disabled?: boolean;
}

export const FileFieldWrapper = styled.div<FileFieldWrapperProps>`
  display: flex;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${({ disabled }) => {
    if (disabled) {
      return css`
        button:not(:disabled) {
          color: ${({ theme }) => theme.palette.grey.middle};
        }
      `;
    }
  }}
`;

export const AttachDocument = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  white-space: break-spaces;
`;

export const DeleteFileButton = styled(IconButton)`
  svg {
    color: ${({ theme }) => theme.palette.grey.middle};
  }
`;
