import styled from 'styled-components';
import { MarginWrapper } from '../../theme/shared/wrappers';
import { Text } from '@theme/shared/text';
import { CardWrapper } from '@theme/shared/components';

export const Wrapper = styled(CardWrapper)`
  position: fixed;
  top: 8px;
  left: 0;
  right: 0;
  padding: 8px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  width: 95%;
  margin: 0 auto;
  max-height: 230px;
  z-index: 205;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    top: ${theme.spaces.xs};
    right:  ${theme.spaces.m};
    left: initial;
    padding: ${({ theme }) => theme.spaces.s};
   
    width: 500px;
  `}
`;

export const Content = styled(MarginWrapper)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    flex-direction: row;
    gap: ${theme.spaces.xs};
    padding: ${theme.spaces.s};
  `}

  svg {
    flex: 0.5 0 auto;
    max-width: 90px;

    ${({ theme }) => theme.utils.media.moreThanMobile`
      flex: 1 0 auto;
    `}
  }
`;

export const TextWrap = styled(MarginWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SmallText = styled(Text.Body)`
  font-size: 14px;
  line-height: 20px;
  margin-top: ${({ theme }) => theme.spaces.xs};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  border: none;
  padding: ${({ theme }) => theme.spaces.xxs};

  svg {
    width: ${({ theme }) => theme.spaces.s};
    height: ${({ theme }) => theme.spaces.s};
  }
`;

export const RegistrationLink = styled.a`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
