import React, { FC } from 'react';
import { Wrap, Number } from './styled';

export const CellGroup: FC<{ code: string }> = ({ code }) => {
  return (
    <Wrap>
      <Number>{code.substring(0, 1)}</Number>
      <Number>
        <span>{code.substring(1, 3)}</span>
        <span>{code.substring(3, 6)}</span>
        <span>{code.substring(6, 8)}</span>
      </Number>
      <Number>{code.substring(8, 10)}</Number>
      <Number>{code.substring(10)}</Number>
    </Wrap>
  );
};
