import React from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import { InputLabel, FormControl, Select, SelectProps, withStyles } from '@material-ui/core';
import { FieldWrapper, Error, StyledMenuItem } from './styled';
import { useScreenSize } from '@shared/hooks/use-screen-size';

export type SelectOption = { label: string; value: string | number };

interface Props extends SelectProps {
  name: string;
  label?: string;
  options: SelectOption[];
  customOnSelect?: (option: any) => void;
}

const StyledSelect = withStyles({
  root: {
    width: '100%',
  },
})(Select);

const SelectComponent: React.FC<Props> = ({ name, label = '', options, autoComplete, customOnSelect, ...rest }) => {
  const { isMobile } = useScreenSize();
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const toggleOpen = (o: boolean) => {
    setOpen(o);
  };

  const handleChange = (): void => {
    if (isMobile) {
      toggleOpen(false);
    }
  };

  return (
    <Field name={name} as="select">
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched && !!meta.error;

        return (
          <FieldWrapper hasError={hasError}>
            <FormControl fullWidth error={hasError}>
              <InputLabel id={name} variant="filled">
                {label}
              </InputLabel>
              <StyledSelect
                variant="filled"
                labelId={name}
                displayEmpty
                {...field}
                {...rest}
                onChange={args => {
                  handleChange();
                  field.onChange(args);
                  customOnSelect && customOnSelect(args.target);
                }}
                open={isOpen}
                onClose={() => toggleOpen(false)}
                onOpen={() => toggleOpen(true)}
                autoComplete={autoComplete}
              >
                {options.map(({ value, label }) => (
                  <StyledMenuItem value={value} key={value}>
                    {label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <ErrorMessage name={name} component={Error} />
          </FieldWrapper>
        );
      }}
    </Field>
  );
};

export default SelectComponent;
