import EditIcon from '@material-ui/icons/Edit';
import {
  Body,
  Container,
  Controls,
  Description,
  Control,
  Header,
  Price,
  Title,
  TopBlock,
  TopRightBlock,
} from './styled';
import { Icon } from '@components/Icon';
import { CompanySale } from '@http/models/api/company';
import { useConfirmDialog } from 'providers/confirm-dialog';
import { pennyToRubs } from '@shared/utils/helpers';
import { breaksHundreds, normalizeFloatDigits } from '@lib/Components/fields/input-value-helpers';
import { PRICE_MAX_FLOAT_DIGITS } from '@constants/numbers';
import { Tooltip } from '@components/Tooltip';

export interface SaleItemProps {
  sale: CompanySale;
  hasEditControls?: boolean;
  hideDescription?: boolean;
  onEditClick: (item: CompanySale) => void;
  onDeleteClick: (id: number) => void;
}

export const SaleItem: React.FC<SaleItemProps> = ({
  sale,
  hasEditControls,
  hideDescription,
  onEditClick,
  onDeleteClick,
}) => {
  const confirm = useConfirmDialog();
  const { title, price, description, priceFrom } = sale;

  const preparedPrice = breaksHundreds(normalizeFloatDigits(pennyToRubs(price), PRICE_MAX_FLOAT_DIGITS));
  const priceLabel = `${priceFrom ? 'от' : ''} ${preparedPrice}`;

  const handleOnDelete = () => {
    confirm.show({
      dialogText: `Удалить услугу ${sale.title}?`,
      onApply() {
        onDeleteClick(sale.id);
      },
    });
    return;
  };

  return (
    <Container>
      <TopBlock>
        <Header>
          <Title>
            <Tooltip title={title} placement="top" arrow>
              <span>{title}</span>
            </Tooltip>
          </Title>
          <TopRightBlock>
            <Price>{`${priceLabel}`}&nbsp;₽</Price>
            {hasEditControls && (
              <Controls>
                <Control color="primary" size="small" onClick={() => onEditClick(sale)}>
                  <EditIcon fontSize="small" />
                </Control>
                <Control color="primary" size="small" onClick={handleOnDelete}>
                  <Icon name="trash.outline" size="s" />
                </Control>
              </Controls>
            )}
          </TopRightBlock>
        </Header>
      </TopBlock>

      <Body>{<Description style={{ display: hideDescription ? 'none' : 'block' }}>{description}</Description>}</Body>
    </Container>
  );
};
