import styled from 'styled-components';
import TextField from '@lib/Components/fields/TextField';
import { Button } from '@components/Button';

export const Wrap = styled.div`
  min-width: 536px;
`;

export const CompanyTextField = styled(TextField)`
  width: 100%;

  .MuiFilledInput-root {
    border-radius: ${({ theme }) => theme.shape.borderRadius};
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spaces.s};
  padding-top: ${({ theme }) => theme.spaces.m};

  & > div {
    width: 100%;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
      padding-top: 0;
  `}
`;

export const ModalFooter = styled.div`
  margin-top: ${({ theme }) => theme.spaces.m};
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.m};
`;

export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spaces.s};
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const SubmitButton = styled(Button)`
  width: 178px;
`;
