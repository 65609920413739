import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Bage from '@components/Bage';
import { Text } from '@theme/shared/text';
import { SimpleLink } from '@components/Link';
import { Button } from '@components/Button';

export const GridOrderColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: auto;

  p {
    margin-top: ${({ theme }) => theme.spaces.xs};

    span: last-child {
      margin-left: ${({ theme }) => theme.spaces.m};
    }

    ${({ theme }) => theme.utils.media.moreThanMobile`
      display: grid;
      grid-template-columns: 1fr 1fr;
  `}
  }
`;

export const GridOrderComment = styled(Grid)`
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spaces.l};
  width: auto;

  span: last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: ${({ theme }) => theme.spaces.m};
  }
`;

export const OrderBage = styled(Bage)`
  color: ${({ theme }) => theme.palette.main.accent};
  margin-left: ${({ theme }) => theme.spaces.m};
`;

export const ReviewPropNameSpan = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ScrollableTable = styled.div`
  margin-top: ${({ theme }) => theme.spaces.m};
  overflow-x: auto;

  .MuiTableCell-root {
    border-color: ${({ theme }) => theme.palette.grey.light};
    line-height: 20px;
    padding: 20px 20px 10px 0;
    min-width: 80px;
  }

  .MuiTableCell-root:nth-child(4),
  .MuiTableCell-root:nth-child(5) {
    min-width: 250px;
  }

  .MuiTableHead-root {
    .MuiTableCell-root {
      border-bottom: 2px solid ${({ theme }) => theme.palette.grey.light};
      color: ${({ theme }) => theme.palette.text.secondary};
      padding: 8px 10px 8px 0;
    }
  }
`;

export const GridOrderFooter = styled(Grid)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spaces.l};
  width: auto;
`;

export const ActionBtn = styled(Button)``;

export const OpenLinkNewTab = styled(SimpleLink)`
  margin-top: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: 0;
    margin-left: auto;
  `}
`;

export const NotAuthParagraph = styled(Text.SubHeader4)`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-right: ${({ theme }) => theme.spaces.l};
  margin-bottom: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.largeDesktop`
    margin-bottom: 0;
  `}
`;

export const SimpleButton = styled.button`
  color: ${({ theme }) => theme.palette.text.link};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.textSizes.bodyBold.weight};
  font-size: ${({ theme }) => theme.textSizes.bodyBold.size};
  line-height: ${({ theme }) => theme.textSizes.bodyBold.lineHeight};
  margin-left: ${({ theme }) => theme.spaces.xs};
`;
