import dynamic from 'next/dynamic';
import { LoaderBox } from '@components/LoaderBox';
import { MarginWrapper } from '@theme/shared/wrappers';

export const VideoPlayerDynamic = dynamic(() => import('./player'), {
  ssr: false,
  loading: () => (
    <MarginWrapper marginTop="m">
      <LoaderBox />
    </MarginWrapper>
  ),
});
