import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FC } from 'react';
import { ZoomControl } from '../ZoomControl';
import { MainCropzoneSection } from './styled';
import { Nullable } from 'types';

export interface MainCropperProps {
  image: string;
  cropperInstance: Nullable<Cropper>;
  onCropperInitialized: (instance: Cropper) => void;
}

const MAIN_IMG_CROPPER_HEIGHT = 117;
const MAIN_IMG_CROPPER_WIDTH = 257;
const ASPECT_RATIO = MAIN_IMG_CROPPER_WIDTH / MAIN_IMG_CROPPER_HEIGHT;
const ZOOM_STEP = 0.1;

export const MainCropper: FC<MainCropperProps> = ({ image, cropperInstance, onCropperInitialized }) => {
  const handleZoomClick = (cropper: Nullable<Cropper>, zoomValue: number) => {
    cropper?.zoom(zoomValue);
  };

  return (
    <MainCropzoneSection>
      <ZoomControl
        zoomToClick={() => handleZoomClick(cropperInstance, ZOOM_STEP)}
        zoomOutClick={() => handleZoomClick(cropperInstance, -ZOOM_STEP)}
      />
      <Cropper
        style={{ height: '100%', width: '100%' }}
        initialAspectRatio={ASPECT_RATIO}
        aspectRatio={ASPECT_RATIO}
        autoCropArea={1}
        src={image}
        minCanvasWidth={MAIN_IMG_CROPPER_WIDTH}
        minCanvasHeight={MAIN_IMG_CROPPER_HEIGHT}
        background={true}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={onCropperInitialized}
        guides={true}
      />
    </MainCropzoneSection>
  );
};
