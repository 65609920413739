import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  z-index: 100;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.xs};
`;

export const ZoomButtom = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.palette.grey.white};
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
  border-radius: 50%;

  svg {
    color: ${({ theme }) => theme.palette.main.primary};
    width: 12px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.main.primary};
    svg {
      color: ${({ theme }) => theme.palette.grey.white};
    }
  }
`;
