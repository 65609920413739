import { Container, Modal } from '@material-ui/core';
import styled from 'styled-components';
import { DESKTOP_HEADER_HEIGHT as HEADER_HEIGHT } from '@components/main-layout/shared/constants';
import { Text } from '@theme/shared/text';

const mobileWidth = '60px';
const tabletWidth = '80px';

export const StyledModal = styled(Modal)`
  background: #fff !important;
  overflow-y: auto;
`;

export const Logo = styled.a`
  width: ${mobileWidth};
  height: ${mobileWidth};
  display: flex;
  align-items: center;

  svg {
    min-width: ${mobileWidth};
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    width: ${tabletWidth};
    height: ${tabletWidth};

    svg {
      min-width: ${tabletWidth};
    }
  `}
`;

export const LogoLabel = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spaces.xs};
  width: 100%;
  color: ${({ theme }) => theme.palette.main.accent};
`;

export const CloseIcon = styled.div`
  width: ${mobileWidth};
  height: ${mobileWidth};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    width: ${tabletWidth};
    height: ${tabletWidth};

    svg {
      font-size: 3rem;
    }
  `}
`;

export const Wrapper = styled(Container)`
  height: 100% !important;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
`;

export const MenuItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spaces.s};
  margin-top: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    grid-gap: ${({ theme }) => theme.spaces.m} ${({ theme }) => theme.spaces.s};
    margin-top: ${({ theme }) => theme.spaces.l};
  `}
`;

export const MenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.xxs};
  color: ${({ theme }) => theme.palette.main.accent};
`;

export const MenuIcon = styled.div`
  width: 31px;
  height: 31px;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    width: 50px;
    height: 50px;
  `}
`;

export const MenuLabel = styled(Text.MenuCaption)`
  margin-top: ${({ theme }) => theme.spaces.xxs};
  text-align: center;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: ${({ theme }) => theme.spaces.xs};
    font-size: ${({ theme }) => theme.textSizes.header4.size};
    line-heigh: ${({ theme }) => theme.textSizes.header4.lineHeight};
  `}
`;
