import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const CompanyTypesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.s};
`;

export const TooltipWrap = styled.span`
  position: relative;
  color: ${({ theme }) => theme.palette.main.primary};
  height: 20px;

  svg {
    width: 25px;
    height: 20px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  svg {
    color: ${({ theme }) => theme.palette.main.primary};
    width: 28px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spaces.xs};
  }
`;

/* New Part */

export const CompanyTypesListNew = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.xs};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    gap: 24px;
`}
`;

interface ExistProps {
  isExist: boolean;
}

export const WrapNew = styled.div<ExistProps>`
  display: flex;
  align-items: center;
  flex-direction: row;

  svg {
    color: ${({ theme, isExist }) => (isExist ? theme.palette.main.primary : theme.palette.grey.light)};
    width: 40px;
    height: 40px;
    margin-right: ${({ theme }) => theme.spaces.xs};
  }
`;

export const TooltipWrapNew = styled.span<ExistProps>`
  position: relative;
  color: ${({ theme, isExist }) => (isExist ? theme.palette.main.primary : theme.palette.grey.light)};
  height: 28px;

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const Caption = styled(Text.Caption)<ExistProps>`
  color: ${({ theme, isExist }) => (isExist ? theme.palette.text.primary : theme.palette.text.secondary)};
`;
