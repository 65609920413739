import { Check } from '@material-ui/icons';
import { capitalize, TableBody, TableRow } from '@material-ui/core';
import { useMemo } from 'react';
import { Label, MoveType, StyledTable, MoveTypesContainer, StyledTableCell } from './styled';
import { useCompanyData } from 'screens/Company/context';
import { WasteClass } from '@http/models/api/fkko';
import { typesMoveDictionary } from '@constants/company';
import { FkkoLink } from '@components/FkkoLink';
import { getTypeMovesByFlag } from '@shared/utils/helpers';

interface Props {
  fkkoList: WasteClass[];
}

export const MobileTable: React.FC<Props> = ({ fkkoList }) => {
  const { typeMoves } = useCompanyData();
  const moveTypesToShow = useMemo(
    () =>
      getTypeMovesByFlag(typeMoves).filter(type => {
        return fkkoList.some(fkko => fkko.type & type);
      }),
    [typeMoves, fkkoList]
  );

  const renderTableBody = () => {
    return fkkoList.map(fkko => {
      const fkkoTitle = fkko.title ? capitalize(fkko.title) : fkko.title;
      const fkkoLabel = fkko.code ? (
        <FkkoLink value={fkko.code} customRender={`${fkko.code} ${fkkoTitle}`} />
      ) : (
        fkkoTitle
      );

      return (
        <TableRow key={fkko.id}>
          <StyledTableCell>
            <Label>{fkkoLabel}</Label>
            <MoveTypesContainer>
              {moveTypesToShow.map(type => (
                <>
                  {(fkko.type & type) > 0 && (
                    <MoveType key={type}>
                      <Check color="primary" fontSize="small" />
                      {typesMoveDictionary[type]}
                    </MoveType>
                  )}
                </>
              ))}
            </MoveTypesContainer>
          </StyledTableCell>
        </TableRow>
      );
    });
  };

  return (
    <StyledTable size="medium" aria-label="a dense table">
      <TableBody>{renderTableBody()}</TableBody>
    </StyledTable>
  );
};
