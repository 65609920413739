import { Form, FormikProvider, useFormik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import {
  CompanyTextField,
  DeleteButton,
  FormWrapper,
  ModalFooter,
  PriceDescriptionField,
  PriceField,
  PriceSection,
  Wrap,
} from './styled';
import { FormValues, INITIAL_VALUES } from './types';
import { validationSchema } from './validationSchema';
import { Modal } from '@components/Modal';
import { InlineFrameForm } from '@components/InlineFrameForm';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { InlineFrame } from '@shared/inline-frame';
import Checkbox from '@lib/Components/fields/Checkbox';
import { useCompanyAddSale } from '@http/hooks/company';
import { useNotifications } from 'providers/notifications';
import { Button } from '@components/Button';
import { CompanySale } from '@http/models/api/company';
import { Nullable } from 'types';
import { Icon } from '@components/Icon';
import { useConfirmDialog } from 'providers/confirm-dialog';
import { pennyToRubs, rubsToPenny } from '@shared/utils/helpers';
import {
  applyInputTransformers,
  breaksHundredsInputValueTransformer,
  createMaxValueTransformer,
  createOnlyNumAndDorInputTransformer,
  trimStartSpaces,
  stringPriceToNumber,
} from '@lib/Components/fields/input-value-helpers';
import { MAX_SALE_PRICE_PER_ITEM, PRICE_MAX_FLOAT_DIGITS } from '@constants/numbers';

export interface CompanyServiceFormProps {
  isOpen: boolean;
  companyId: number;
  editingSale?: Nullable<CompanySale>;
  isLoading?: boolean;
  onClose: () => void;
  afterAdded: () => void;
  onDelete: (id: number) => void;
}

export const CompanyServiceForm: React.FC<CompanyServiceFormProps> = ({
  isOpen,
  companyId,
  editingSale,
  onClose,
  afterAdded,
  onDelete,
}) => {
  const notifications = useNotifications();
  const confirm = useConfirmDialog();
  const { isMobile, isDesktop } = useScreenSize();

  const title = editingSale ? 'Редактировать услугу' : 'Новая услуга';

  const { mutate: addNewSale, isLoading: isLoadingAddNewSale } = useCompanyAddSale({
    successHandler: handlAddSaleSuccess,
    errorHandler: handleAddSaleError,
  });

  const form = useFormik<FormValues>({
    initialValues: INITIAL_VALUES,
    validateOnBlur: true,
    validateOnMount: false,
    validationSchema,
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit(values) {
      addNewSale({
        id: editingSale?.id,
        companyId,
        title: values.title.trim(),
        price: rubsToPenny(stringPriceToNumber(String(values.price))),
        priceDesc: values.priceDesc,
        priceFrom: values.priceFrom,
        description: values.description.trim(),
      });
    },
  });

  useEffect(() => {
    if (editingSale) {
      form.setValues({
        title: editingSale.title,
        description: editingSale.description || '',
        price: pennyToRubs(editingSale.price),
        priceDesc: editingSale.priceDesc,
        priceFrom: editingSale.priceFrom,
      });
    }
  }, [editingSale]);

  const handleSave = () => {
    form.submitForm();
  };

  const handleFormClose = () => {
    form.resetForm();
    onClose();
  };

  function handleAddSaleError() {
    notifications.push({
      text: `Произошла ошибка при ${
        editingSale ? 'редактировании' : 'добавлении'
      } услуги, пожалуйста обратитесь в службу техподдержки`,
      severity: 'error',
    });
  }

  function handlAddSaleSuccess() {
    notifications.push({
      text: `Услуга успешно ${editingSale ? 'отредактирована' : 'добавлена'}`,
      severity: 'success',
    });
    form.resetForm();
    afterAdded();
    handleFormClose();
  }

  const handleDelete = () => {
    if (editingSale?.id) {
      confirm.show({
        dialogText: `Удалить услугу ${editingSale?.title}?`,
        onApply() {
          onDelete(editingSale.id);
        },
      });
    }
  };

  const formContent = (
    <FormikProvider value={form}>
      <Form>
        <FormWrapper>
          <CompanyTextField
            name="title"
            label="Название услуги"
            transformValue={applyInputTransformers([trimStartSpaces])}
          />
          <PriceSection>
            <PriceField
              name="price"
              label={isDesktop ? 'Цена' : 'Цена, ₽'}
              transformValue={applyInputTransformers([
                createOnlyNumAndDorInputTransformer(PRICE_MAX_FLOAT_DIGITS),
                createMaxValueTransformer(MAX_SALE_PRICE_PER_ITEM),
                breaksHundredsInputValueTransformer,
              ])}
            />
            <PriceDescriptionField name="priceDesc" label={isDesktop ? 'Кол-во' : 'За количество'} />
          </PriceSection>
          <Checkbox name="priceFrom" label='Добавить предлог "от"' />
          <CompanyTextField
            name="description"
            label="Описание услуги"
            multiline
            transformValue={applyInputTransformers([trimStartSpaces])}
          />
        </FormWrapper>
      </Form>
    </FormikProvider>
  );

  if (isMobile) {
    return (
      <InlineFrame
        isOpen={isOpen}
        onClose={handleFormClose}
        modalContentPaddings={{
          default: '0px',
          moreThanMobile: '0px',
        }}
      >
        <InlineFrameForm
          isLoading={!!isLoadingAddNewSale}
          formTitle={title}
          defaultSaveBtnText="Сохранить"
          closeInlineFrame={onClose}
          handleSave={handleSave}
        >
          {formContent}
          {editingSale?.id && (
            <DeleteButton variant="text" startIcon={<Icon name="trash.outline" size="s" />} onClick={handleDelete}>
              Удалить услугу
            </DeleteButton>
          )}
        </InlineFrameForm>
      </InlineFrame>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleFormClose}
      title={title}
      maxWidth={'600px'}
      modalContentPaddings={{ default: '32px 24px 24px' }}
    >
      <Wrap>
        {formContent}
        <ModalFooter>
          <Button variant="outlined" disabled={isLoadingAddNewSale} onClick={onClose}>
            Отмена
          </Button>
          <Button disabled={isLoadingAddNewSale} onClick={handleSave}>
            {isLoadingAddNewSale ? <CircularProgress size={25} /> : 'Сохранить'}
          </Button>
        </ModalFooter>
      </Wrap>
    </Modal>
  );
};
