import styled from 'styled-components';
import { Text } from '@theme/shared/text';
import { BoxWrapper } from '@theme/shared/components';

export const Wrapper = styled(BoxWrapper)`
  margin-top: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: ${({ theme }) => theme.spaces.m};
  `}
`;

export const Title = styled(Text.Header3)`
  font-size: ${({ theme }) => theme.textSizes.header4.size};
  line-height: ${({ theme }) => theme.textSizes.header4.lineHeight};
  margin-bottom: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header3.size};
    line-height: ${({ theme }) => theme.textSizes.header3.lineHeight};
  `}
`;

export const Container = styled.div`
  display: flex;
`;
