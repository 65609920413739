import { AbortController } from 'node-abort-controller';
import {
  RegistrationUserRequestModel,
  UpdatableUserRequestModel,
  UserDataApi,
  UsersForVerifiedResponse,
  UserForVerifiedTotalRecord,
  UsersAdminQuery,
  UserCompanyAdditionalUpdateRequest,
  UpdateCompanyDescriptionRequest,
} from '@http/models/api/user';
import { httpService } from '@http/service';
import { HttpOptions } from '@http/service/options';
import { applyMapper, buildQs, eachElement } from '@http/shared';
import { UserData, UserMeta } from '@http/models/view-models/user';
import { mapUserToViewModel } from '@http/models/mappers/user';

export const registerUser = (model: RegistrationUserRequestModel) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.post('/users', model, options);
};

export const getUser = (token: string) => {
  const options = new HttpOptions().setHeader('Authorization', `Bearer ${token}`);
  return applyMapper(httpService.get<UserDataApi>('/users/me', options), mapUserToViewModel);
};

export const setUserMeta = (params: UserMeta) => {
  const body = JSON.stringify(params).replace(/\"/g, "'");

  const options = new HttpOptions().asAuthRoute();
  options.setHeader('Content-type', 'application/json');

  return httpService.post<UserDataApi>('/users/meta', body, options);
};

export const resetPassword = ({ email, controller }: { email: string; controller: AbortController }) => {
  const options = new HttpOptions().withCredentials(false).setAbortController(controller);

  return httpService.get(
    buildQs('/users/resetpassword', {
      email: encodeURIComponent(email),
    }),
    options
  );
};

export const updateUser = (model: UpdatableUserRequestModel) => {
  return httpService.post('/users/me', model, new HttpOptions().withCredentials(false).asAuthRoute());
};

export const deleteUser = (userId: number) => {
  return httpService.delete(`/users/${userId}`, null, new HttpOptions().asAuthRoute({ strict: true }));
};

export const usersForVerified = () =>
  httpService.get<UsersForVerifiedResponse>(`/users/verified`, new HttpOptions().asAuthRoute());

export const getUsersByCompany = (companyId: number): Promise<UserData[]> => {
  const options = new HttpOptions().asAuthRoute();
  return applyMapper(
    httpService.get<UserDataApi[]>(`/users/byCompany/${companyId}`, options),
    eachElement(mapUserToViewModel)
  );
};

export const userCompanyAdditionalUpdate = (model: UserCompanyAdditionalUpdateRequest) =>
  httpService.post(`/companyadditional`, model, new HttpOptions().asAuthRoute());

export const getUsersAdmin = (query: UsersAdminQuery) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<UserForVerifiedTotalRecord>(buildQs(`/users`, query), options);
};

export const getUsersCounter = (controller: AbortController) => {
  const options = new HttpOptions().setAbortController(controller);
  return httpService.get<number>(buildQs('/users/generation', { verifiedCode: 'random' }), options);
};

export const updateCompanyDescription = (params: UpdateCompanyDescriptionRequest) =>
  httpService.post(`/company/description`, params, new HttpOptions().asAuthRoute());
