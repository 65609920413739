import { SupportOrderBody } from '@http/models/api/support';
import { HttpOptions } from '@http/service/options';
import { httpService } from '@http/service';

interface Params {
  body: SupportOrderBody;
}

export const postSupportOrder = ({ body }: Params) => {
  const options = new HttpOptions();
  return httpService.post<any>('/support', body, options);
};
