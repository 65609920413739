import React from 'react';
import { Wrapper } from './styled';
import { Skeleton } from '@theme/shared/components';

export const SearchLoading = () => {
  return (
    <Wrapper>
      <Skeleton height={150} />
      <Skeleton height={150} />
      <Skeleton height={150} />
    </Wrapper>
  );
};
