import React, { useMemo } from 'react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { defaultTheme } from '../list/default-theme';
import GlobalStyle from '../global-styles';
import { contextUtilsFactory } from '../context-utils';
import { StyleTheme } from 'theme/types';
import { useGlobalStyles } from 'theme/mui-global-styles';

export const applyThemePatch = (target: Record<string, any>, diff: Record<string, any>): StyleTheme => {
  const result = target;
  Object.keys(diff).forEach(key => {
    if (typeof result[key] === 'object') {
      applyThemePatch(result[key], diff[key]);
    } else {
      // eslint-disable-next-line no-param-reassign
      result[key] = diff[key];
    }
  });
  return result as unknown as StyleTheme;
};

export const ThemeProvider: React.FC = ({ children }) => {
  const currentTheme = useMemo(() => {
    return defaultTheme;
  }, []);

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: currentTheme.palette.main.primary,
      },
      secondary: {
        main: currentTheme.palette.main.secondary,
      },
      info: {
        main: currentTheme.colors.info,
      },
      error: {
        main: currentTheme.palette.system.error,
        light: currentTheme.palette.system.errorLight,
      },
      background: {
        default: currentTheme.palette.grey.background,
      },
      text: {
        primary: currentTheme.palette.text.primary,
        secondary: currentTheme.palette.text.secondary,
        hint: currentTheme.palette.text.link,
      },
    },
    shape: {
      borderRadius: +currentTheme.shape.borderRadius.replace('px', ''),
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: 'white',
        },
        containedSecondary: {
          color: 'white',
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '14px',
          padding: '8px',
        },
      },
    },
    typography: {
      allVariants: {
        lineHeight: 1.4,
        fontFamily: currentTheme.typography.fontFamily,
      },
      h1: {
        fontSize: '2.5rem',
        fontWeight: 600,
      },
      h2: {
        fontSize: '2.25rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.75rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1.5rem',
      },
      h6: {
        fontSize: '1.25rem',
      },
    },
  });

  const theme: StyleTheme = {
    ...defaultTheme,
    utils: contextUtilsFactory(currentTheme),
  };

  useGlobalStyles();

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ScThemeProvider theme={theme}>
        <>
          <CssBaseline />
          <GlobalStyle />
          {children}
        </>
      </ScThemeProvider>
    </MuiThemeProvider>
  );
};
