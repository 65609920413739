import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { StyledButton } from './styled';
import { ContextMenu } from '@components/ContextMenu';
import { NativeSwitch } from '@lib/Components/fields/Switch';
import { useNotifications } from 'providers/notifications';
import { parseApiErrors } from '@http/shared';
import { useUserCompanyUpdate } from '@http/hooks/user';
import { CompanyFullAPI } from '@http/models/api/company';

interface Props {
  companyData: CompanyFullAPI;
  onUpdate: () => void;
}

export const CompanyAdminMenu = ({ companyData, onUpdate }: Props) => {
  const [adminContextMenuVisible, setAdminContextMenuVisible] = useState<boolean>(false);
  const [receiptState, setReceiptState] = useState<boolean>(companyData.receipt);

  useEffect(() => {
    setReceiptState(companyData.receipt);
  }, [companyData.receipt]);

  const notifications = useNotifications();
  const { mutateAsync: updateCompany, isLoading } = useUserCompanyUpdate({
    successHandler: () => {
      notifications.push({
        text: 'Данные компании обновлены',
        severity: 'success',
      });
      onUpdate();
    },
    errorHandler: (err: any) => {
      const apiErrors = parseApiErrors(err);
      notifications.push({
        text: 'Ошибка при обновлении',
        severity: 'error',
      });
      Sentry.captureException(err, {
        extra: {
          apiErrors,
        },
        tags: {
          label: 'setting-company-information',
        },
      });
    },
  });

  const handleUpdateReceipt = async () => {
    setReceiptState(!receiptState);

    await updateCompany({
      ...companyData,
      companyId: companyData.id,
      receipt: !receiptState,
      link: companyData.id.toString(),
      regionId: companyData.regionId ? Number(companyData.regionId) : null,
    });
  };

  return (
    <div>
      <StyledButton variant="text" onClick={() => setAdminContextMenuVisible(true)}>
        Меню администратора
      </StyledButton>
      {adminContextMenuVisible && (
        <ContextMenu onClose={() => setAdminContextMenuVisible(false)}>
          <NativeSwitch
            disabled={isLoading}
            checked={receiptState}
            onChange={handleUpdateReceipt}
            label="Не принимает отходы"
          />
        </ContextMenu>
      )}
    </div>
  );
};
