import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';

export const Wrapper = styled(BoxWrapper)`
  padding-bottom: ${({ theme }) => theme.spaces.l};
`;

export const SummaryWrapper = styled.div`
  display: grid;
  margin-top: ${({ theme }) => theme.spaces.l};
  grid-gap: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const Value = styled.div`
  margin-left: ${({ theme }) => theme.spaces.m};
`;

export const Key = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ theme }) => theme.utils.media.moreThanMobile`
    min-width: 230px;
  `}
`;

export const KeyValue = styled.div`
  display: flex;
`;
