import { number, object, string } from 'yup';
import { testBlackListWords } from '@shared/utils/validation';

export const validationSchema = object().shape({
  rate: number().nullable().required('Обязательное поле'),
  userAbout: string()
    .nullable()
    .test('blackList', 'Указаны запрещенные символы', testBlackListWords)
    .required('Обязательное поле')
    .max(200, 'Максимум 200 символов'),
  desc: string()
    .nullable()
    .required('Обязательное поле')
    .test('blackList', 'Указаны запрещенные символы', testBlackListWords)
    .max(1000, 'Максимум 200 символов'),
});
