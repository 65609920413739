import styled from 'styled-components';
import { Text } from '@theme/shared/text';
import { Button } from '@components/Button';

interface HeaderProps {
  isOwnerView?: boolean;
}

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
   margin-top: ${({ theme }) => theme.spaces.m};
  `}
`;

export const Header = styled.div<HeaderProps>`
  display: ${({ isOwnerView }) => (isOwnerView ? 'flex' : 'block')};
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.utils.media.moreThanMobile`
     box-shadow: ${({ theme }) => theme.shadow.base};
     border-top-left-radius: ${({ theme }) => theme.shape.borderRadius};
     border-top-right-radius: ${({ theme }) => theme.shape.borderRadius};
     background: ${({ theme }) => theme.palette.grey.white};
     padding: ${({ theme }) => theme.spaces.m};
  `}
`;

export const Title = styled(Text.Header3)`
  font-size: ${({ theme }) => theme.textSizes.header4.size};
  line-height: ${({ theme }) => theme.textSizes.header4.lineHeight};
  font-weight: ${({ theme }) => theme.textSizes.header4.weight};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header3.size};
    line-height: ${({ theme }) => theme.textSizes.header3.lineHeight};
    font-weight: ${({ theme }) => theme.textSizes.header3.weight};
  `}
`;

export const AddButton = styled(Button)``;

export const AddButtonUser = styled(Button)`
  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: ${({ theme }) => theme.spaces.l};
    margin-bottom: ${({ theme }) => theme.spaces.s};
  `}
`;

export const ReviewsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xxs};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: 0;
    row-gap: 0;
    padding: ${({ theme }) => theme.spaces.m};

    box-shadow: 0px 4px 10px rgba(62, 83, 114, 0.08);
    overflow: auto;
    border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius};
    background: #fff;
    padding-top: ${({ theme }) => theme.spaces.xxs};
  `}
`;

export const PaginationWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: -44px;
  `}
`;
