import styled, { css } from 'styled-components';

interface Props {
  customTabletView?: boolean;
}

/*
 * Пока не стал пробрасывать как props, для табов на странице orders
 * если в дальнейшем, где то будет мешать max-width, тогда надо будет вынести
 * в отдельный css класс. Данное ограничение нужно, чтобы не обрезалось боковое меню
 * max-width: calc(100vw - 240px);
 */
export const TabsControlWrapper = styled.div<Props>`
  .MuiButtonBase-root {
    font-size: 18px;
    text-transform: initial;
    font-weight: 600;
    min-width: 100px;

    ${({ theme }) => theme.utils.media.moreThanMobile`
      font-size:  ${({ theme }) => theme.textSizes.body.size};
      margin-right: ${({ theme }) => theme.spaces.m};
    `}
  }

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.palette.main.primary} !important;
    border-radius: 3px;
    height: 3px;
  }

  .MuiTab-textColorInherit {
    opacity: 1;
  }

  .MuiTab-textColorInherit.Mui-selected {
    color: ${({ theme }) => theme.palette.main.primary};
  }

  .MuiTab-root {
    max-width: initial;

    ${({ theme }) => theme.utils.media.moreThanMobile`
       padding: 6px 0px;
    `}
  }

  .MuiTabs-root {
    position: relative;

    .MuiTabScrollButton-root {
      min-width: 44px;
      margin-right: 0;
      justify-content: flex-end;
    }

    .MuiTabScrollButton-root:first-child {
      position: absolute;
      height: 100%;
      z-index: 1;
      opacity: 1;
      background: ${({ theme }) => theme.palette.grey.background};
      justify-content: flex-start;
    }

    .MuiButtonBase-root.Mui-disabled:first-child {
      display: none;
    }
  }

  ${({ customTabletView }) =>
    customTabletView &&
    css`
      position: relative;

      .MuiTab-root:first-child {
        margin-left: ${({ theme }) => theme.spaces.s};
        padding-left: ${({ theme }) => theme.spaces.xxs};
      }

      .MuiButtonBase-root {
        margin-right: ${({ theme }) => theme.spaces.xxs};
      }

      .MuiTabScrollButton-root.Mui-disabled {
        opacity: 1;
      }

      ${({ theme }) => theme.utils.media.largeDesktop`
        .MuiTab-root:first-child {
          margin-left: 0;
          padding-left: 0;
        }

        .MuiButtonBase-root {
          margin-right: ${({ theme }) => theme.spaces.m};
        }
      `}

      .MuiTabs-root {
        .MuiTabScrollButton-root {
          position: absolute;
          margin: 0;
          top: calc(50% - 14px);
          width: 28px;
          height: 28px;
          min-width: 28px;
          background: ${({ theme }) => theme.palette.grey.white};
          border-radius: 50%;
          border: 1px solid ${({ theme }) => theme.palette.main.primary};
          z-index: 50;
          opacity: 1;

          svg {
            color: ${({ theme }) => theme.palette.main.primary};
          }

          &:first-child {
            left: ${({ theme }) => `-${theme.spaces.xs}`};
          }

          &:last-child {
            right: ${({ theme }) => `-${theme.spaces.m}`};
          }
        }
      }
    `}
`;
