import dynamic from 'next/dynamic';
import { useCallback, useState } from 'react';
import { useNotifications } from '../../../providers/notifications';
import { useOffer } from '../context';
import { useConfirmDialog } from '../../../providers/confirm-dialog';
import { PdfLinkWrapper } from './styled';
import { Button } from '@components/Button';
import { useSetOfferFavorite } from '@http/hooks/order';
import { Icon } from '@components/Icon';

const DynamicOfferPdfModule = dynamic(() => import('../OfferPdf/OfferPdfModule'), {
  ssr: false,
  loading: () => <span>Загрузка ...</span>,
});

export const Controls = () => {
  const { state: offer, order, setOfferAsInteresting } = useOffer();
  const notifications = useNotifications();
  const confirmDialog = useConfirmDialog();
  const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(false);

  const successHandler = () => {
    setOfferAsInteresting();
    notifications.push({
      text: `Предложение ${offer.id} было отмечено как интересное`,
      severity: 'success',
    });
  };

  const errorHandler = () => {
    notifications.push({
      text: 'Произошла ошибка, пожалуйста обратитесь к администратору',
      severity: 'error',
    });
  };

  const { mutate, isLoading: isLoadingFavorite } = useSetOfferFavorite({ successHandler, errorHandler });

  const handleInterestingClick = () => {
    confirmDialog.show({
      dialogText: 'Это предложение вам интересно?',
      onApply: () => {
        mutate({ cpId: offer.id });
      },
    });
  };

  const handlePdfReady = useCallback(() => {
    setIsPdfGenerated(false);
  }, []);

  return (
    <PdfLinkWrapper marginTop="l">
      <Button
        onClick={() => {
          setIsPdfGenerated(true);
        }}
        variant="outlined"
        startIcon={<Icon name="pdf.filled" />}
        className="pdf-link"
      >
        {isPdfGenerated ? (
          <DynamicOfferPdfModule offer={offer} order={order} onDownloadSuccess={handlePdfReady} />
        ) : (
          <span>Скачать в PDF</span>
        )}
      </Button>
      {order.isMyOrder && !offer.interesting && (
        <Button className={'favorite'} onClick={handleInterestingClick} disabled={isLoadingFavorite} variant="outlined">
          Интересное предложение
        </Button>
      )}
    </PdfLinkWrapper>
  );
};
