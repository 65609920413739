import { SupportTabsQueryParams } from './screens/Support/tabs.config';
import { buildQs } from '@http/shared';
import {
  CONTINUE,
  REG_EMAIL,
  MODAL_ORDER_INFO,
  MY_COMPANY_TABS,
  NEWS_SELECTED_CATEGORY,
  MODAL_COMPANY_INFO,
  NEWS_SELECTED_ID,
  COMPANY_TABS,
} from 'types/routesQueryNames';
import { Nullable } from 'types';

export const routes = {
  home: '/',
  profile: '/user',
  profileCompany: (queryParams?: Record<string, string>) => buildQs('/user', queryParams),
  userCreateCompany: (queryParams?: QueryContinueParams) => buildQs(`/user/add-company`, queryParams),
  login: '/login',
  loginQuery: (queryParams?: QueryLoginParams) => buildQs(`/login`, queryParams),
  registration: '/registration',
  registrationQuery: (queryParams?: QueryContinueParams) => buildQs(`/registration`, queryParams),
  about: '/about',
  support: (qs?: SupportTabsQueryParams) => buildQs('/support', qs),
  privacy: '/privacy',
  createOrder: '/create-order',
  search: (queryParams?: string) => `/search${queryParams ? `?${queryParams}` : ''}`,
  company: {
    info: (id: string) => `/company/${id}`,
    accounting: (qs?: Record<string, string>) => buildQs('/company/accounting', qs),
    sales: (id: string, tabIdx: number) => buildQs(`/company/${id}`, { [COMPANY_TABS]: tabIdx }),
    licences: (id: string, tabIdx: number) => buildQs(`/company/${id}`, { [COMPANY_TABS]: tabIdx }),
    reviews: (id: string, tabIdx: number) => buildQs(`/company/${id}`, { [COMPANY_TABS]: tabIdx }),
  },
  newsList: (queryParams?: QueryNewsPageParams) => buildQs('/news', queryParams), // `/news?page=${page || 1}`,
  newsArticle: (id: string, backPage: string) => `/news/${id}?backPage=${backPage}`,
  resetPassword: '/reset-password',
  orders: (queryParams?: string) => `/orders${queryParams ? `?${queryParams}` : ''}`,
  order: (orderId: number) => `/order/${orderId}`,
  createOffer: (orderId: number) => `/order/${orderId}/offer`,
  offer: (orderId: number, offerId: number) => `/order/${orderId}/offer/${offerId}`,
  fkko: (code: string) => `/fkko/${code}`,
  privacyPolicy: '/privacy-policy',
  personalAgreement: '/personal-agreement',
  reports: {
    report2TPWasteCreate: '/reports/2tp/waste/create',
    root: '/reports',

    /**
     * @deprecated
     */
    report2tp: '/reports/2tp',
    /**
     * @deprecated
     */
    edit2tp: (id: number) => `/reports/2tp/${id}`,
  },
  admin: {
    verifications: '/admin/verifications',
    users: '/admin/users',
    ask: '/admin/ask',
    tariffs: '/admin/tariffs',
    news: {
      all: (queryParams?: QueryAdminNewsParams) => buildQs('/admin/news', queryParams),
      edit: (queryParams?: QueryAdminNewsEditParams) => buildQs(`/admin/news/edit`, queryParams),
    },
  },
  api: {
    logout: '/api/logout',
  },
  promo: '/promo',
  tariffs: '/tariffs',
};

export const siteMapPages: string[] = [
  'news',
  'search',
  'login',
  'registration',
  'orders',
  'privacy',
  'support',
  'create-order',
  'personal-agreement',
  'privacy-policy',
  'tariffs',
];

export const continueParamsFactory = (path?: string): QueryContinueParams =>
  path ? { [CONTINUE]: encodeURIComponent(path) } : {};

export const createPathMenuItem = (itemParam: string | ((params?: string) => string), path?: string): string =>
  typeof itemParam === 'function' ? itemParam(path) : itemParam;

export const newsParamsFactory = ({
  categoryId,
  page,
}: {
  categoryId?: Nullable<number>;
  page?: number;
}): QueryNewsPageParams => {
  let query: QueryNewsPageParams = {};

  if (categoryId) {
    query = {
      ...query,
      [NEWS_SELECTED_CATEGORY]: `${categoryId}`,
    };
  }

  if (page) {
    query = {
      ...query,
      page: `${page}`,
    };
  }

  return query;
};

export type QueryParams = {
  [REG_EMAIL]?: string;
  [CONTINUE]?: string;
  [MODAL_ORDER_INFO]?: string;
  [MY_COMPANY_TABS]?: string;
  [MODAL_COMPANY_INFO]?: string;
  [NEWS_SELECTED_CATEGORY]?: string;
  [NEWS_SELECTED_ID]?: string;
  page?: string;
  [k: string]: string | string[] | undefined;
};

export type QueryContinueParams = Pick<QueryParams, 'continue'>;

export type QueryLoginParams = Pick<QueryParams, 'continue' | 'reg_email'>;

export type QueryNewsPageParams = Pick<QueryParams, 'categoryId' | 'page'>;

export type QueryAdminNewsParams = Pick<QueryParams, 'page'>;

export type QueryAdminNewsEditParams = Pick<QueryParams, 'news_selected_id'>;
