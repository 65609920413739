import { ReactNode } from 'react';
import { routes } from 'routes';

export interface DocumentItem {
  title: ReactNode;
  link: string;
}

export const documentsList: DocumentItem[] = [
  {
    title: <>Пользовательское соглашение</>,
    link: routes.privacy,
  },
  {
    title: <>Политика конфиденциальности</>,
    link: routes.privacyPolicy,
  },
  {
    title: <>Согласие на обработку персональных&nbsp;данных</>,
    link: routes.personalAgreement,
  },
];
