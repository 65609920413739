import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import Truncate from 'react-truncate';
import { useOffer } from '../context';
import { Wrapper, Key, Value, KeyValue, Comment, CompanyText, BlockTitle } from './styled';
import { kgToTons, totalBy } from '@shared/utils/helpers';
import { breaksHundreds, normalizeFloatDigits } from '@lib/Components/fields/input-value-helpers';
import { MarginWrapper } from '@theme/shared/wrappers';
import { CompanyLink } from '@components/CompanyLink';
import { PRICE_MAX_FLOAT_DIGITS } from '@constants/numbers';

export const UtilizationSummary = () => {
  const offerContext = useOffer();
  const { order, state: offer } = offerContext;
  const totalNetto = useMemo(() => {
    return order.rows && normalizeFloatDigits(totalBy(order.rows, row => kgToTons(row.netto)));
  }, [order.rows]);

  const totalReportedNetloc = useMemo(() => {
    return normalizeFloatDigits(totalBy(offerContext.reportedRows, row => kgToTons(row.netto)));
  }, [offerContext.reportedRows]);

  const keyValue = (key: string, value: any) => (
    <KeyValue>
      <Key>{key}:</Key>
      <Value>{value || '-'}</Value>
    </KeyValue>
  );

  const totalPrice = useMemo(() => {
    const total = totalBy(offerContext.tableData, model => model.price);
    return breaksHundreds(normalizeFloatDigits(total, PRICE_MAX_FLOAT_DIGITS));
  }, [offerContext]);

  return (
    <>
      <MarginWrapper marginTop="m">
        <BlockTitle>Об утилизаторе</BlockTitle>
        <Wrapper>
          <div>
            {keyValue(
              'Компания',
              <CompanyLink companyId={offer.companyId} customRender={<CompanyText>{offer.companyName}</CompanyText>} />
            )}
            {keyValue('Дата подачи', DateTime.fromISO(offer.createdAt).toLocaleString())}
            {keyValue('Общая сумма', `${totalPrice} ₽`)}

            {keyValue(
              'Общая масса',
              `${breaksHundreds(totalReportedNetloc)}${totalNetto && ` из ${breaksHundreds(totalNetto)}`} т`
            )}
            {keyValue('НДС', `${offer.nds ? 'С НДС' : 'БЕЗ НДС'}`)}
          </div>
          <div>
            <Key>Комментарий</Key>
            <Comment>
              <Truncate lines={4}>{offer.comment || '-'}</Truncate>
            </Comment>
          </div>
        </Wrapper>
      </MarginWrapper>
    </>
  );
};
