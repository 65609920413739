import React, { FC, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Container, PlaceholderCaption, UploadPhotoPlaceholder } from './styled';
import { FileUploader } from '@components/FileUploader';
import { Text } from '@theme/shared/text';
import { Icon } from '@components/Icon';
import { useCompany, useCompanyAddFile } from '@http/hooks/company';
import { FileType } from '@http/models/api/file';
import { CompanyFullAPI } from '@http/models/api/company';
import { useNotifications } from 'providers/notifications';
import { parseApiErrors } from '@http/shared';
import { UploadedFilesParams } from '@components/FileUploader/types';
import { useTariffGuard } from 'providers/tariff-guard';

interface Props {
  company: CompanyFullAPI;
}

export const CompanyPhotosUploader: FC<Props> = ({ company }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [progressCount, setProgressCount] = useState(0);
  const notifications = useNotifications();
  const { isActionAllowed, setModalOpen: setTariffModalOpen } = useTariffGuard();

  const { refetch } = useCompany(company.id);

  const handleUploaderClose = () => {
    setModalOpened(false);
  };

  const { mutateAsync: addCompanyPhotos, isLoading: isLoadingAddCompanyPhoto } = useCompanyAddFile({
    successHandler: () => {},
    errorHandler: errorAddPhotos,
  });

  function errorAddPhotos(err: unknown) {
    const apiErrors = parseApiErrors(err);
    Sentry.captureException(err, {
      extra: {
        apiErrors: apiErrors,
      },
      tags: {
        label: 'add-company-photos',
      },
    });

    notifications.push({
      severity: 'error',
      text:
        apiErrors && apiErrors.length > 0
          ? apiErrors.join(', ')
          : 'Ошибка сервера, пожалуйста обратитесь к администратору',
    });
  }

  const getFormData = (companyId: number, fileType: FileType, fileData: Blob) => {
    const formData = new FormData();
    formData.append('companyId', String(companyId));
    formData.append('fileType', String(fileType));
    formData.append('file', fileData);
    return formData;
  };

  const handleOnSaveImages = async ({ multipleFiles = [] }: UploadedFilesParams) => {
    for (let i = 0; i < multipleFiles?.length || 0; i++) {
      setProgressCount(c => c + 1);
      await sendPhoto(multipleFiles[i]);
    }
    setProgressCount(0);
    setModalOpened(false);
    refetch();
  };

  const sendPhoto = async (file: Blob) => {
    const formData = getFormData(company.id, FileType.CompanyWork, file);
    await addCompanyPhotos(formData);
  };

  const handleFileUploaderClick = () => {
    if (isActionAllowed('AddCompanyPhoto')) {
      setModalOpened(true);
      return;
    }

    setTariffModalOpen(true);
  };

  return (
    <Container>
      <UploadPhotoPlaceholder onClick={handleFileUploaderClick}>
        <Icon name="camera-in-circle.solid" color="main.secondary" />
        <Text.MenuCaptionBold>Загрузите фотографии</Text.MenuCaptionBold>
        <PlaceholderCaption>Размер 1800 х 300. Формат png, jpg, не более 10 МБ</PlaceholderCaption>
      </UploadPhotoPlaceholder>
      <FileUploader
        title="Добавить фотографии"
        dropzoneText="Загрузите фотографии вашей компании"
        dropzoneCaption="Размер 1800 х 300. Формат png, jpg, не более 10 МБ"
        isOpen={modalOpened}
        isFething={isLoadingAddCompanyPhoto}
        progressCount={progressCount}
        onClose={handleUploaderClose}
        onSave={handleOnSaveImages}
      />
    </Container>
  );
};
