import styled, { css } from 'styled-components';
// @ts-ignore
import { FadeInRightBig } from 'animate-css-styled-components';
import { ColorTheme } from '@theme/types';

interface FadeInProps {
  isMultiple: boolean;
  customWidth?: string;
}

interface CloseButtonProps {
  colorTheme?: ColorTheme;
}

export const ModalContent = styled.div<{
  paddings?: {
    default: string;
    moreThanMobile: string;
  };
}>`
  position: relative;
  padding: ${({ theme, paddings }) => paddings?.default ?? theme.spaces.xs};
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;

  ${({ theme, paddings }) =>
    theme.utils.media.moreThanMobile`
    padding: ${() => paddings?.moreThanMobile ?? theme.spaces.l};
  `}
`;

export const ModalCloseBtn = styled.button<CloseButtonProps>`
  position: absolute;
  top: ${({ theme }) => theme.spaces.xs};
  right: ${({ theme }) => theme.spaces.xs};
  width: 32px;
  z-index: 100;

  ${({ colorTheme }) => {
    if (colorTheme === 'light') {
      return css`
        height: 32px;
        background-color: ${({ theme }) => theme.palette.grey.white};
        box-shadow: ${({ theme }) => theme.shadow.medium};
        border-radius: 4px;
      `;
    }

    return '';
  }}

  ${({ theme }) =>
    theme.utils.media.moreThanMobile`
    top: ${({ theme }) => theme.spaces.m};
    right: ${({ theme }) => theme.spaces.m};
  `}
`;

export const FadeIn = styled(FadeInRightBig)<FadeInProps>`
  height: 100%;

  ${({ theme, isMultiple, customWidth }) => theme.utils.media.desktop`
    width: ${customWidth ? customWidth : isMultiple ? '85%' : '90%'};
    margin-left: auto;
  `}
`;
