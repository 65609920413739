import styled, { css, ThemedStyledProps } from 'styled-components';
import { StyleTheme, StyleThemeTextSize, StyleThemeTextSizes, ThemePalette } from '../types';
import { ExtractKeys } from '../../types';
import { getByPath } from '@shared/utils/arrays';

export type Space = keyof StyleTheme['spaces'] | string;

interface BaseProps {
  display?: 'flex' | 'grid' | 'inline-block';
  alignItems?: 'center' | 'flex-start' | 'space-between' | 'flex-end';
  justifyContent?: 'center' | 'flex-start' | 'space-between' | 'flex-end';
  flexDirection?: string;
  flex?: string;
}

interface PropParam {
  px?: boolean; // add px to end
}

export const px = (val: string | number) => `${val}px`;

const rule = (name: string, value?: string | number, param?: PropParam) => {
  if (!value) return '';
  return `${name}: ${Boolean(param?.px) ? px(value) : value};`;
};

const themeSpacing = (theme: StyleTheme, cssName: string, value?: string): string => {
  if (!value) {
    return '';
  }
  const spaces = theme.spaces as unknown as Record<string, string>;
  return `${cssName}: ${value in spaces ? spaces[value] : value};`;
};

const textStylesProps = (theme: StyleTheme, path?: keyof StyleThemeTextSizes) => {
  if (!path) {
    return '';
  }

  const original: StyleThemeTextSize = theme.textSizes[path];
  const mobileProps: any = theme.__mobilePatch?.textSizes && theme.__mobilePatch.textSizes[path];

  return css`
    ${rule('font-size', original.size)}
    ${rule('font-weight', original.weight)}
    ${rule('line-height', original.lineHeight)}
    ${original.isUppercase ? rule('text-transform', 'uppercase') : ''}

    ${() =>
      mobileProps &&
      theme.utils.media.mobile`
        ${rule('font-size', mobileProps.size)}
        ${rule('font-weight', mobileProps.weight)}
        ${rule('line-height', mobileProps.lineHeight)}
      `}
  `;
};

const colorProps = (theme: StyleTheme, path?: string) => {
  if (!path) {
    return '';
  }

  return css`
    ${rule('color', getByPath(theme.palette, path))}
  `;
};

const backGroundColorProps = (theme: StyleTheme, path?: string) => {
  if (!path) {
    return '';
  }

  return css`
    ${rule('background-color', getByPath(theme.palette, path))}
  `;
};

const flexProps = (props: ThemedStyledProps<BaseProps, StyleTheme>) => `
  ${rule('display', props.display)}
  ${rule('align-items', props.alignItems)}
  ${rule('justify-content', props.justifyContent)}
  ${rule('flex-direction', props.flexDirection)}
  ${rule('flex', props.flex)}
`;

export interface MarginProps extends BaseProps {
  margin?: Space;
  marginX?: Space;
  marginY?: Space;
  marginLeft?: Space;
  marginRight?: Space;
  marginTop?: Space;
  marginBottom?: Space;
}

const marginProps = (props: ThemedStyledProps<MarginProps, StyleTheme>) => `
  ${themeSpacing(props.theme, 'margin', props.margin)}
  ${themeSpacing(props.theme, 'margin-left', props.marginX)}
  ${themeSpacing(props.theme, 'margin-right', props.marginX)}
  ${themeSpacing(props.theme, 'margin-top', props.marginY)}
  ${themeSpacing(props.theme, 'margin-bottom', props.marginY)}
  ${themeSpacing(props.theme, 'margin-left', props.marginLeft)}
  ${themeSpacing(props.theme, 'margin-right', props.marginRight)}
  ${themeSpacing(props.theme, 'margin-top', props.marginTop)}
  ${themeSpacing(props.theme, 'margin-bottom', props.marginBottom)}
`;

interface PaddingProps {
  padding?: Space;
  paddingX?: Space;
  paddingY?: Space;
  paddingLeft?: Space;
  paddingRight?: Space;
  paddingTop?: Space;
  paddingBottom?: Space;
}

const paddingProps = (props: ThemedStyledProps<PaddingProps, StyleTheme>) => `
  ${themeSpacing(props.theme, 'padding', props.padding)}
  ${themeSpacing(props.theme, 'padding-left', props.paddingX)}
  ${themeSpacing(props.theme, 'padding-right', props.paddingX)}
  ${themeSpacing(props.theme, 'padding-top', props.paddingY)}
  ${themeSpacing(props.theme, 'padding-bottom', props.paddingY)}
  ${themeSpacing(props.theme, 'padding-left', props.paddingLeft)}
  ${themeSpacing(props.theme, 'padding-right', props.paddingRight)}
  ${themeSpacing(props.theme, 'padding-top', props.paddingTop)}
  ${themeSpacing(props.theme, 'padding-bottom', props.paddingBottom)}
`;

export const MarginWrapper = styled.div<MarginProps>`
  ${marginProps}
  ${flexProps}
`;

interface DivWrapperProps extends MarginProps {
  flexWrap?: boolean;
  font?: keyof StyleThemeTextSizes;
  fontSizePx?: number;
  color?: ExtractKeys<ThemePalette>;
  gap?: Space;
  disableOutline?: boolean;
  borderRadius?: 'base';
  width?: string;
  height?: string;
  backgroundColor?: ExtractKeys<ThemePalette>;
  templateColumns?: string;
  disableTemplateColumnsOnMobile?: boolean;
  cursorPointer?: boolean;
  position?: 'relative' | 'static';
}

const divWrapperProps = (props: ThemedStyledProps<DivWrapperProps, StyleTheme>) => `
  ${themeSpacing(props.theme, 'gap', props.gap)}
  ${rule('outline', props.disableOutline ? 'none' : '')}
  ${rule('border-radius', props.borderRadius === 'base' ? props.theme.shape.borderRadius : '')}
  ${props.width ? `width: ${props.width};` : ''}
  ${props.height ? `height: ${props.height};` : ''}
  ${props.cursorPointer ? `cursor: pointer;` : ''}
  ${props.flexWrap ? `flex-wrap: wrap;` : ''}
  ${rule('grid-template-columns', props.templateColumns)}
  ${rule('position', props.position ? props.position : undefined)}
`;

export const DivWrapper = styled.div<DivWrapperProps & PaddingProps>`
  ${marginProps}
  ${paddingProps}
  ${flexProps}
  ${({ theme, font }) => textStylesProps(theme, font)}
  ${({ theme, color }) => colorProps(theme, color)}
  ${({ theme, backgroundColor }) => backGroundColorProps(theme, backgroundColor)}

  ${divWrapperProps}
  ${({ theme, disableTemplateColumnsOnMobile, templateColumns }) => {
    if (disableTemplateColumnsOnMobile && templateColumns) {
      return theme.utils.media.lessThanDesktop`
        ${rule('grid-template-columns', 'initial')}
      `;
    }
  }}
`;
