import * as R from 'ramda';

export const groupBy = <T, K extends keyof T>(startArr: T[], key: K): T[] => {
  return startArr.reduce((acc: any, cur) => {
    (acc[cur[key]] = acc[cur[key]] || []).push(cur);
    return acc;
  }, {});
};

export const createOnlyUniqueFilter = <T, K extends keyof T>(key: K) => {
  return (tickItem: T, index: number, currentArr: T[]): boolean => {
    return currentArr.findIndex(item => item[key] === tickItem[key]) === index;
  };
};

export const deepEqual = (x: any, y: any) => {
  if (x === y) {
    return true;
  } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    if (Object.keys(x).length != Object.keys(y).length) return false;

    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};

export const getByPath = <T extends Object>(obj: T, strPath: string): any => {
  const { pipe, path, split } = R;
  const pathString = pipe(split(/[[\].]/), path);
  return pathString(strPath)(obj);
};
