import React from 'react';
import { Title, Value, Wrapper } from './styled';

interface Props {
  title: string | React.ReactNode;
}

export const Row: React.FC<Props> = ({ title, children }) => {
  return (
    <Wrapper>
      <Title>{typeof title === 'string' ? `${title}:` : title}</Title>
      <Value>{children}</Value>
    </Wrapper>
  );
};
