import styled from 'styled-components';
import { AccordionSummary as MuiAccordionSummary } from '@material-ui/core';
import { Text } from '@theme/shared/text';
import { BoxWrapper } from '@theme/shared/components';

export const Wrapper = styled(BoxWrapper)`
  padding: ${({ theme: { spaces } }) => spaces.xs};
  margin-top: ${({ theme }) => theme.spaces.m};

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }

  & .MuiPaper-root {
    box-shadow: none;
  }

  & .MuiAccordionDetails-root {
    padding: 12px 0px;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
     padding: ${({ theme }) => theme.spaces.m};
  `}
`;

export const Subtitle = styled(Text.Header3)`
  display: inline-block;
`;

export const AccordionSummary = styled(MuiAccordionSummary)<{ isActive: boolean }>`
  &.MuiButtonBase-root {
    background: ${({ isActive, theme }) =>
      isActive ? theme.palette.main.mediumGreen : theme.palette.system.errorLight};
    height: 60px;
    border-top: 1px solid ${({ theme }) => theme.palette.grey.middle};
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.middle};
    padding: 0 ${({ theme }) => theme.spaces.xs};
    margin-left: -12px;
    margin-right: -12px;

    ${({ theme }) => theme.utils.media.moreThanMobile`
         margin-left: -24px;
         margin-right: -24px;
  `}
  }

  & .MuiAccordionSummary-content {
    font-size: ${({ theme }) => theme.textSizes.header3.size};
    font-weight: ${({ theme }) => theme.textSizes.header3.weight};
  }

  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

export const IconWrap = styled.div<{ isActive: boolean }>`
  width: 24px;
  display: flex;
  margin-left: ${({ theme }) => theme.spaces.xs};
  color: ${({ theme: { palette }, isActive }) => (isActive ? palette.main.primary : palette.system.error)};
`;

export const SubtitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { spaces } }) => `${spaces.m}`};
  width: 100%;
`;
