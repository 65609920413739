import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';
import { Text } from '@theme/shared/text';

export const Wrapper = styled(BoxWrapper)`
  margin-top: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: ${({ theme }) => theme.spaces.m};
  `}
`;

export const Title = styled(Text.Header3)`
  font-size: ${({ theme }) => theme.textSizes.header4.size};
  line-height: ${({ theme }) => theme.textSizes.header4.lineHeight};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header3.size};
    line-height: ${({ theme }) => theme.textSizes.header3.lineHeight};
  `}
`;

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xs};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    display: grid;
    grid-template-columns: 40% 60%;
  `}
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  column-gap: ${({ theme }) => theme.spaces.xs};
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.s};

  a {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  svg {
    color: ${({ theme }) => theme.palette.text.link};
  }
`;

export const ExtraContacts = styled.div`
  position: absolute;
  top: 40px;
  padding: ${({ theme }) => theme.spaces.s};
  box-shadow: -1px 2px 15px 3px rgb(0 0 0 / 9%);
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: #fff;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${({ theme }) => theme.spaces.s};
  padding-right: ${({ theme }) => theme.spaces.xl};
  max-width: 290px;
`;

export const Cross = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const ExpandLink = styled.div`
  color: ${({ theme }) => theme.palette.text.link} !important;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const BlockLink = styled.a`
  display: block;
`;

export const BlockSpan = styled.span`
  display: block;
`;
