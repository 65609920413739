import React from 'react';
import { Wrapper, Title } from './styled';
import { PhotoGallery } from './PhotoGallery';
import { CompanyViewModel } from '@http/models/view-models/company';
import { useUser } from 'providers/user';

interface Props {
  company: CompanyViewModel;
}

export const CompanyPhotos = ({ company }: Props) => {
  const user = useUser();
  const userCompany = user.getCompany();

  const isUserCanEdit = (userCompany?.id === company.id && userCompany.isVerified) || user.isAdmin();

  if (!isUserCanEdit && !company.listFiles?.length) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Фото</Title>
      <PhotoGallery company={company} images={company.listFiles} editable={isUserCanEdit} />
    </Wrapper>
  );
};
