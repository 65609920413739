import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const Wrapper = styled.div`
  background: linear-gradient(106.25deg, #65d6b4 18.33%, #139f75 61.28%);
  backdrop-filter: blur(3px);
  padding: ${({ theme }) => theme.spaces.s};
  min-height: 220px;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  position: relative;

  ${({ theme }) => theme.utils.media.tablet`
      padding: ${({ theme }) => theme.spaces.s};
  `}

  ${({ theme }) => theme.utils.media.desktop`
     padding: 0 0 ${({ theme }) => theme.spaces.s};
  `}
`;

export const MenuWrapper = styled.div`
  width: fit-content;
  position: absolute;
  top: 8px;
  right: 0;

  > div {
    background: transparent;
    box-shadow: none;
    border-bottom: none;
    left: auto;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
     top: 8px;
  `}
`;

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  column-gap: 28px;

  ${({ theme }) => theme.utils.media.desktop`
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0 ${({ theme }) => theme.spaces.m};
  `}
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    column-gap: 8px;
  `}
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.m};
  ${({ theme }) => theme.utils.media.moreThanMobile`
     margin-bottom: 0px;
  `}
`;

export const Title = styled(Text.Header2)`
  margin-right: ${({ theme }) => theme.spaces.m};
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey.white};
  margin-bottom: 12px;
`;

export const Place = styled.p`
  color: ${({ theme }) => theme.palette.grey.white};
  font-size: ${({ theme }) => theme.textSizes.captionBold.size};

  ${({ theme }) => theme.utils.media.moreThanMobile`
     font-size: ${({ theme }) => theme.textSizes.bodyBold.size};
  `}
`;

export const PlaceIcon = styled.span`
  position: relative;
  top: 2px;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const OpenBlankWrapper = styled.a`
  color: ${({ theme }) => theme.palette.grey.white};
  font-size: ${({ theme }) => theme.textSizes.bodyBold.size};
  font-weight: ${({ theme }) => theme.textSizes.bodyBold.weight};
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-left: 8px;
  }

  ${({ theme }) => theme.utils.media.desktop`
       position: absolute;
       top: 32px;
       left: 24px;
  `}
`;

export const LogoWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.desktop`
      margin-bottom: 0;
  `}
`;

export const CustomUrlWrapper = styled.div`
  color: #fff;
  display: flex;
  align-items: center;

  a {
    color: ${({ theme }) => theme.palette.main.lightBlue};
    margin-left: 3px;
    display: flex;
    padding: 3px;
    background: #fff;
    width: fit-content;
    border-radius: ${({ theme }) => theme.shape.borderRadius};
  }
`;
