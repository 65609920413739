import { PaginationProps } from '@material-ui/lab';
import { StyledPaginataion } from './styled';
import { useScreenSize } from '@shared/hooks/use-screen-size';

const Pagination: React.FC<PaginationProps> = props => {
  const { isMobile } = useScreenSize();

  return <StyledPaginataion {...props} siblingCount={isMobile ? 0 : 1} />;
};

export default Pagination;
