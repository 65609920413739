import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { emitAppEvent } from '../../events/use-app-event-broadcast';
import { AppEvents } from '../../events/types';
import { CloseButton, Content, RegistrationLink, SmallText, TextWrap, Wrapper } from './styled';
import { useTimeout } from '@shared/hooks/use-timeout';
import { USER_RECOMENDATION_REGISTRATION_KEY } from '@constants/index';
import { useUser } from 'providers/user';
import { Icon } from '@components/Icon';
import { Text } from '@theme/shared/text';
import { continueParamsFactory, routes } from 'routes';

const showDelayMinutes = 10;
const ignoreRoutes = [
  routes.login,
  routes.registration,
  routes.resetPassword,
  routes.promo,
  '/order/[id]/offer/[offerId]',
];

export const RecomendationRegistration: FC = ({ children }) => {
  const user = useUser();
  const [isOpen, setOpenStatus] = useState(false);
  const { route, asPath, push } = useRouter();
  const isIgnoreRoute = ignoreRoutes.includes(route);

  useTimeout(() => {
    const isAccepted = Boolean(Cookies.get(USER_RECOMENDATION_REGISTRATION_KEY));
    if (!isAccepted && !user.exist() && !isIgnoreRoute) {
      setOpenStatus(true);
    }
  }, 1000 * 60 * showDelayMinutes);

  useEffect(() => {
    if (user.exist() || isIgnoreRoute) {
      handleClose();
    }
  }, [user, isIgnoreRoute]);
  const handleClose = () => {
    setOpenStatus(false);
    Cookies.set(USER_RECOMENDATION_REGISTRATION_KEY, DateTime.now().toISO(), {
      expires: 365,
      path: '/',
      samesite: 'Strict',
    });
  };

  const handleClickCross = () => {
    emitAppEvent(AppEvents.manually_close_registration_suggest);
    handleClose();
  };

  const hanldeClick = (e: SyntheticEvent) => {
    e.preventDefault();
    handleClose();
    emitAppEvent(AppEvents.go_to_registration_from_suggestion);
    push(routes.registrationQuery(continueParamsFactory(asPath)));
  };

  return (
    <>
      {children}
      {isOpen && (
        <Wrapper>
          <CloseButton onClick={handleClickCross}>
            <Icon name="x.outline" />
          </CloseButton>
          <Content marginBottom="s">
            <Icon name="robot.filled" />
            <TextWrap>
              <Text.Header4>Добро пожаловать на Критод!</Text.Header4>
              <SmallText>
                <RegistrationLink onClick={hanldeClick} href={routes.registrationQuery(continueParamsFactory(asPath))}>
                  Зарегистрируйтесь
                </RegistrationLink>
                , чтобы подтвердить свои контактные данные и начать работу!
              </SmallText>
            </TextWrap>
          </Content>
        </Wrapper>
      )}
    </>
  );
};
