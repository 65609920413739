import { IconName } from '@components/Icon/types';
import { TypeCompany, TypeMove } from '@http/models/api/company';

export const typesMoveDictionary = {
  [TypeMove.Transport]: 'Транспортирование',
  [TypeMove.Collection]: 'Сбор',
  [TypeMove.Neutralization]: 'Обезвреживание',
  [TypeMove.Disposal]: 'Утилизация',
  [TypeMove.Treatment]: 'Обработка',
  [TypeMove.Placement]: 'Размещение',
};

export const typesMoveOptions = Object.keys(typesMoveDictionary).map(key => ({
  label: (typesMoveDictionary as any)[key],
  value: +key,
}));

export const companyPhoneNumber = '8 800 600 0359';
export const companyEmail = 'support@kritod.ru';

type TypesCompanyDictionary = {
  [key in TypeCompany]: [string, IconName];
};

export const typesCompanyDictionary: TypesCompanyDictionary = {
  [TypeCompany.Recycle]: ['Утилизатор', 'recyclers.outline'],
  [TypeCompany.Transport]: ['Транспортировщик', 'transport.outline'],
  [TypeCompany.Placement]: ['Полигон', 'landfill.outline'],
};

type TypesCompanyDictionaryNew = {
  [key in TypeMove]: [string, IconName];
};

export const typesCompanyDictionaryNew: TypesCompanyDictionaryNew = {
  [TypeMove.Collection]: ['Сбор', 'collectionType.filled'],
  [TypeMove.Transport]: ['Транспортирование', 'transportType.filled'],
  [TypeMove.Treatment]: ['Обработка', 'treatmentType.filled'],
  [TypeMove.Disposal]: ['Утилизация', 'disposalType.filled'],
  [TypeMove.Neutralization]: ['Обезвреживание', 'neutralizationType.filled'],
  [TypeMove.Placement]: ['Размещение', 'placementType.filled'],
};

export const typesCompanyDictionaryKeys: TypeMove[] = [
  TypeMove.Collection,
  TypeMove.Transport,
  TypeMove.Treatment,
  TypeMove.Disposal,
  TypeMove.Neutralization,
  TypeMove.Placement,
];
