import { v4 as uuidv4 } from 'uuid';
import { httpService } from '@http/service';
import { HttpOptions } from '@http/service/options';
import { FileUploadResponse } from '@http/models/api/file';
import { AppEvents } from 'events/types';
import { emitAppEvent } from 'events/use-app-event-broadcast';

export const uploadFile = (body: FormData) => {
  const id = uuidv4();
  const options = new HttpOptions()
    .asAuthRoute()
    .timeout(3e5)
    .watchProgress(percent => {
      emitAppEvent(AppEvents.upload_file_update_percent, { id, value: percent });
    });
  return httpService.post<FileUploadResponse>(`/files/uploadfile`, body, options);
};

export const deleteFile = (body: string) => {
  const options = new HttpOptions().asAuthRoute();
  options.setHeader('Content-type', 'application/json');

  return httpService.delete<number>(`/files/uploadfile`, body, options);
};
