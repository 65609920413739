import React from 'react';
import Link from 'next/link';
import { ExtractKeys } from '../../types';
import { IconWrap, Wrapper } from './styled';
import { Text } from '@theme/shared/text';
import { ButtonSize } from '@components/Table/components/Fallback';
import { ThemePalette } from '@theme/types';

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: 'filled' | 'outlined' | 'text';
  fullWidth?: boolean;
  fullHeight?: boolean;

  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;

  href?: string;
  className?: string;
  startIcon?: React.ReactNode;
  size?: ButtonSize;
  customColor?: ExtractKeys<ThemePalette>;
  hoverColor?: ExtractKeys<ThemePalette>;
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'filled',
  className,
  href,
  onClick,
  ref,
  children,
  startIcon,
  fullWidth,
  size,
  color,
  hoverColor,
  ...extraButtonProps
}) => {
  const content = (
    <Wrapper
      customColor={color}
      hoverColor={hoverColor}
      size={size}
      fullWidth={fullWidth}
      onClick={onClick}
      variant={variant}
      className={className}
      {...extraButtonProps}
    >
      {startIcon && <IconWrap>{startIcon}</IconWrap>}
      <Text.ButtonText style={{ display: 'flex' }}>{children}</Text.ButtonText>
    </Wrapper>
  );

  if (href) {
    return (
      <Link passHref href={href}>
        <a className="text-decoration-none" style={{ display: 'flex' }}>
          {content}
        </a>
      </Link>
    );
  }

  return content;
};
