import React from 'react';
import { ListWrapper } from './styled';
import { OfferShortViewModel } from '@http/models/view-models/offer';
import { OfferShort } from '@components/OfferShort';

interface Props {
  offers: OfferShortViewModel[];
}

export const ShortOffersList = ({ offers }: Props) => {
  return (
    <ListWrapper>
      {offers.map(item => (
        <OfferShort key={item.id} offer={item} />
      ))}
    </ListWrapper>
  );
};
