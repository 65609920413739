import { FC } from 'react';
import Link from 'next/link';
import { documentsList, DocumentItem } from './documentsList';
import { PageLink, Wrapper } from './styled';
import { Icon } from '@components/Icon';
import { MarginWrapper } from '@theme/shared/wrappers';

interface Props {
  documents?: DocumentItem[];
}

const Documents: FC<Props> = ({ documents = documentsList }) => {
  return (
    <MarginWrapper marginTop="m">
      <Wrapper>
        {documents.map(({ title, link }) => (
          <Link key={link} href={link} passHref>
            <PageLink target="_blank" rel="noopener noreferrer">
              <span>{title}</span>
              <Icon name="arrowRight.solid" />
            </PageLink>
          </Link>
        ))}
      </Wrapper>
    </MarginWrapper>
  );
};

export default Documents;
