interface Ending {
  1: string;
  2: string;
  5: string;
}

export const wordEnding = (number: number, ending: Ending): string => {
  number = Math.abs(number);

  number;

  if (number >= 5 && number <= 20) {
    return ending['5'];
  }

  number %= 10;

  if (number === 1) {
    return ending['1'];
  }

  if (number >= 2 && number <= 4) {
    return ending['2'];
  }

  return ending['5'];
};
