import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import {
  GridOrderColumn,
  GridOrderComment,
  OrderBage,
  ReviewPropNameSpan,
  ScrollableTable,
  SimpleButton,
} from './styled';
import { OrderReviewFooter } from './OrderReviewFooter';
import { OrderReviewOwnProps } from './types';
import { FkkoLink } from '@components/FkkoLink';
import { MarginWrapper } from '@theme/shared/wrappers';
import { statusOrderText } from '@http/models/api/orders';
import { Text } from '@theme/shared/text';
import { wordEnding } from '@shared/utils/wordEnding';
import { useTabsControl } from '@components/Tabs';
import { typesMoveDictionary } from '@constants/company';
import { breaksHundreds, normalizeFloatDigits } from '@lib/Components/fields/input-value-helpers';
import { kgToTons, totalBy } from '@shared/utils/helpers';
import { useUser } from 'providers/user';
import { DEFAULT_MAX_FLOAT_DIGITS } from '@constants/numbers';

const orderExternalUrlToView = (url: string) => {
  return url.replace(/(https?:\/\/)/g, '');
};

export const OrderReview = ({ orderApi, isPageLayout, onDelete }: OrderReviewOwnProps) => {
  const user = useUser();
  const { toggleTab } = useTabsControl();
  const isAllowBrowseOffers = user.exist() && (orderApi.isMyOrder || user.isAdmin());

  const totalWeight = useMemo(() => {
    if (!orderApi.rows) {
      return '';
    }
    const total = totalBy(orderApi.rows, model => kgToTons(model.netto));
    return breaksHundreds(normalizeFloatDigits(total, DEFAULT_MAX_FLOAT_DIGITS));
  }, [orderApi.rows]);

  return (
    <MarginWrapper marginTop="l">
      <Grid container direction="row">
        <GridOrderColumn>
          <p>
            <ReviewPropNameSpan>Местонахождение отходов:</ReviewPropNameSpan>
            <span>{orderApi.regionTitle}</span>
          </p>
          <p>
            <ReviewPropNameSpan>Статус заявки:</ReviewPropNameSpan>
            <span>{statusOrderText[orderApi.status]}</span>
          </p>
          <p>
            <ReviewPropNameSpan>Общий объем:</ReviewPropNameSpan>
            <span>{totalWeight} т</span>
          </p>
        </GridOrderColumn>
        <GridOrderColumn>
          <p>
            <ReviewPropNameSpan>Дата создания:</ReviewPropNameSpan>
            <span>{DateTime.fromISO(orderApi.createdAt).toLocaleString()}</span>
          </p>
          <p>
            <ReviewPropNameSpan>Дата окончания:</ReviewPropNameSpan>
            <span>{(orderApi.dateEnd && DateTime.fromISO(orderApi.dateEnd).toLocaleString()) || '-'}</span>
          </p>
          <p>
            <ReviewPropNameSpan>Количество ответов:</ReviewPropNameSpan>
            <span>
              {orderApi.countCP}
              {isAllowBrowseOffers && <SimpleButton onClick={() => toggleTab(1)}>Посмотреть</SimpleButton>}
            </span>
          </p>
        </GridOrderColumn>
      </Grid>
      <GridOrderComment>
        <ReviewPropNameSpan>Комментарий:</ReviewPropNameSpan>
        <span>{orderApi.comment}</span>
      </GridOrderComment>
      {orderApi.url && (
        <GridOrderComment>
          <ReviewPropNameSpan>Ссылка (тендер): </ReviewPropNameSpan>
          <a href={orderApi.url} target="_blank" rel="noopener noreferrer">
            {orderExternalUrlToView(orderApi.url)}
          </a>
        </GridOrderComment>
      )}
      <MarginWrapper marginTop="xl">
        <Grid container direction="row" alignItems="center">
          <Text.Header2>Отходы</Text.Header2>
          {orderApi.rows && (
            <OrderBage variant="light">
              {orderApi.rows.length} {wordEnding(orderApi.rows.length, { 1: 'вид', 2: 'вида', 5: 'видов' })}
            </OrderBage>
          )}
        </Grid>
        <ScrollableTable>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell component="th">Код</TableCell>
                <TableCell component="th">Объем, т</TableCell>
                <TableCell component="th">Вид работ</TableCell>
                <TableCell component="th">Наименование</TableCell>
                <TableCell component="th">Комментарий к отходу</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderApi.rows &&
                orderApi.rows.map(fkko => (
                  <TableRow key={fkko.code}>
                    <TableCell scope="row">
                      <FkkoLink value={fkko.code} />
                    </TableCell>
                    <TableCell scope="row">{breaksHundreds(kgToTons(fkko.netto))}</TableCell>
                    <TableCell scope="row">{fkko.type.map(t => typesMoveDictionary[t]).join('\n')}</TableCell>
                    <TableCell>{fkko.title}</TableCell>
                    <TableCell>{fkko.comment}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </ScrollableTable>
        <OrderReviewFooter orderApi={orderApi} isPageLayout={isPageLayout} onDelete={onDelete} />
      </MarginWrapper>
    </MarginWrapper>
  );
};
