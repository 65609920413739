import React, { FC } from 'react';
import { TooltipWrapNew, WrapNew, Caption } from './styled';
import { Tooltip } from '@components/Tooltip';
import { Icon } from '@components/Icon';
import { IconName } from '@components/Icon/types';

interface Props {
  icon: IconName;
  title: string;
  compactView: boolean;
  isExist: boolean;
}

// Old, now use CompanyTypeViewNew
export const CompanyTypeViewNew: FC<Props> = ({ icon, title, isExist, compactView }) => {
  if (compactView) {
    return (
      <Tooltip title={title} arrow>
        <TooltipWrapNew isExist={isExist}>
          <Icon name={icon} />
        </TooltipWrapNew>
      </Tooltip>
    );
  }

  return (
    <WrapNew isExist={isExist}>
      <Icon name={icon} />
      <Caption isExist={isExist}>{title}</Caption>
    </WrapNew>
  );
};
