import { FC, useEffect, useRef, useState } from 'react';
import { DeleteButton, LightboxItem, StyledImage, Wrapper } from './styled';
import { Skeleton } from '@theme/shared/components';
import { Icon } from '@components/Icon';

export interface GalleryImageProps {
  src: string;
  editable?: boolean;
  onClick: () => void;
  onDelete: (src: string) => void;
}

const GalleryImage: FC<GalleryImageProps> = ({ src, editable, onDelete, onClick }) => {
  const [logoImgLoaded, setLogoImgLoaded] = useState<boolean>(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && (ref.current as HTMLImageElement).complete) {
      setLogoImgLoaded(true);
    }
  }, [ref]);

  function handleOnLoad() {
    setLogoImgLoaded(true);
  }

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete(src);
  };

  return (
    <LightboxItem $loaded={logoImgLoaded} onClick={onClick}>
      <Wrapper>
        {!logoImgLoaded && <Skeleton />}
        <StyledImage ref={ref} src={src} $loaded={logoImgLoaded} onLoad={handleOnLoad} />
        {logoImgLoaded && editable && (
          <DeleteButton size="small" onClickCapture={handleDeleteClick}>
            <Icon name="trash.outline" size="s" />
          </DeleteButton>
        )}
      </Wrapper>
    </LightboxItem>
  );
};

export default GalleryImage;
