import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { DESKTOP_LEFT_SIDEBAR_WIDTH_PX } from '../shared/constants';
import { Text } from '@theme/shared/text';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: ${DESKTOP_LEFT_SIDEBAR_WIDTH_PX}px;
  background: ${({ theme }) => theme.palette.grey.white};
  box-shadow: 0 4px 20px rgba(62, 83, 114, 0.08);
  overflow-x: hidden;
  overflow-y: auto;
  min-width: ${DESKTOP_LEFT_SIDEBAR_WIDTH_PX}px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0 16px 0;
  width: 60px;
`;

export const LogoLabel = styled.div`
  margin-top: 8px;
  width: 100%;
  color: ${({ theme }) => theme.palette.main.accent};
`;

export const MenuWrapper = styled.div`
  a:first-child {
    svg {
      color: ${({ theme }) => theme.palette.main.primary};
    }
  }

  a:first-child:hover {
    svg {
      color: ${({ theme }) => theme.palette.main.primaryHover};
    }
  }
`;

export const MenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.main.accent};
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.1s ${({ theme }) => theme.animate.base};
  border-right: 0;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      pointer-events: none;
      background: ${theme.palette.grey.background};
      border-right: 3px solid ${({ theme }) => theme.palette.main.primary};
    `}

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => rgba(theme.palette.grey.light, 0.2)};
  }
`;

export const MenuIcon = styled.div`
  width: 31px;
  height: 31px;
`;

export const MenuLabel = styled(Text.MenuCaption)`
  margin-top: 5px;
`;

export const LinkContent = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

export const DevIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${DESKTOP_LEFT_SIDEBAR_WIDTH_PX}px;
  height: 25px;
  background: orange;
  color: #fff;
  text-align: center;
  font-weight: bold;
`;
