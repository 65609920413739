import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../../../../providers/user';
import { continueParamsFactory, routes } from '../../../../routes';
import { Title } from '../Title';
import { LoginButtons, WrapperTitle, HeaderButtonsWrapper } from './styled';
import { UserContextMenu } from './user-context-menu';
import { MainLayoutTopMenuProps } from '@components/main-layout/top-menu';
import { MarginWrapper } from '@theme/shared/wrappers';
import { SimpleLink } from '@components/Link';
import { UserCounter } from '@components/main-layout/shared/user-counter';
import { DivWrapper } from '@theme/shared/wrappers';
import { GoBackTitle } from '@components/main-layout/shared/GoBackTitle';
import { usePageLoading } from '@shared/hooks/use-page-loading';
import { NavigationLink } from '@components/main-layout/shared/NavigationLink';

export const DesktopMenu: FC<MainLayoutTopMenuProps> = ({
  title,
  alignItems,
  isShowBackBtn = false,
  colorTheme,
  customButtonsStyles,
}) => {
  const user = useUser();
  const { asPath } = useRouter();
  const isLoading = usePageLoading();

  const titleRender = <Title title={title} />;

  return (
    <div>
      <WrapperTitle withTitle={!!title} alignItems={alignItems?.desktop || 'center'}>
        <DivWrapper display="flex" gap="xs" width="0" flex="1">
          {isShowBackBtn ? (
            <GoBackTitle isDisabled={isLoading} isActive={isShowBackBtn} title={titleRender} />
          ) : (
            titleRender
          )}
        </DivWrapper>
        <HeaderButtonsWrapper customButtonsStyles={customButtonsStyles}>
          <UserCounter colorTheme={colorTheme} />
          <NavigationLink colorTheme={colorTheme} text="Тарифы" iconName="credit-card.outline" link={routes.tariffs} />
          <NavigationLink
            colorTheme={colorTheme}
            text="Поддержка"
            iconName="question-mark-circle.outline"
            link={routes.support()}
          />
          {user.exist() ? (
            <UserContextMenu buttonTheme={colorTheme} />
          ) : (
            <LoginButtons>
              <MarginWrapper marginRight="s">
                <SimpleLink
                  href={routes.loginQuery(continueParamsFactory(asPath))}
                  variant="outlined"
                  colorTheme={colorTheme}
                >
                  Войти
                </SimpleLink>
              </MarginWrapper>
              <SimpleLink
                href={routes.registrationQuery(continueParamsFactory(asPath))}
                variant="filled"
                colorTheme={colorTheme}
              >
                Регистрация
              </SimpleLink>
            </LoginButtons>
          )}
        </HeaderButtonsWrapper>
      </WrapperTitle>
    </div>
  );
};
