import React, { FC } from 'react';
import { Wrap, CellWrapProps } from './styled';

export interface CellProps extends CellWrapProps {
  cellNumber: string;
}

export const Cell: FC<CellProps> = ({ cellNumber, color }) => {
  return <Wrap color={color}>{cellNumber}</Wrap>;
};
