import React from 'react';
import { routes } from '../../../../routes';
import { getSupportContactsTabLink } from '../../tabs.config';
import { Wrapper } from './styled';
import { VideoPlayerDynamic } from '@components/VideoPlayer';

const BlankLink: React.FC<{ to: string }> = ({ to, children }) => {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const HowAddCompany = () => {
  return (
    <Wrapper>
      <p>
        Для добавления компании перейдите в раздел <BlankLink to={routes.profile}>Профиль</BlankLink> с помощью
        всплывающего меню при клике на имя пользователя или из бокового меню если вы используете настольную версию
        приложения.{' '}
      </p>
      <p>
        В разделе “Компания” нажмите кнопку “Добавить”. Вас направит в{' '}
        <BlankLink to={routes.userCreateCompany()}>интерфейс добавления новой компании</BlankLink>, вам достаточно
        ввести только имя или ИНН компании для поиска по базе данных компаний. Выбрав свою компанию из предложенного
        списка вам необходимо подтвердить добавление нажав кнопку “Добавить компанию”.
      </p>
      <p>
        После выполнения перечисленных инструкций мы произведем верификацию компании для вашего профиля. Если
        верификация будет пройдена компания привяжется к вашему аккаунту и вы сможете выполнять действия в системе от её
        имени.
      </p>
      <p>
        Если у вас возникли любые сложности или вопросы на данном этапе вы всегда сможете{' '}
        <BlankLink to={getSupportContactsTabLink()}>связаться с нами</BlankLink> и мы поможем.
      </p>
      Так же вы можете обратиться к видео инструкции:
      <br />
      <br />
      <VideoPlayerDynamic source="/assets/faq/add-company/video.mp4" preview="/assets/faq/add-company/preview.jpg" />
    </Wrapper>
  );
};
