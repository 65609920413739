import styled, { css } from 'styled-components';
import { ColorTheme } from '@theme/types';

interface SupportIconProps {
  colorTheme: ColorTheme;
  activeRoute: boolean;
}

export const LinkWrap = styled.a<SupportIconProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.link};
  gap: 6px;

  svg {
    width: 20px;
    height: 20px;
  }

  :hover {
    color: ${({ theme }) => theme.palette.main.primary};
  }

  ${({ theme, colorTheme, activeRoute }) => {
    if (colorTheme === 'light') {
      return css`
        color: ${theme.palette.grey.white};

        :hover {
          color: ${({ theme }) => theme.palette.grey.light};
        }
      `;
    }

    if (activeRoute) {
      return css`
        border-bottom: 1px solid ${({ theme }) => theme.palette.text.link};
      `;
    }

    return '';
  }}
`;
