import { Modal } from '@material-ui/core';
import React from 'react';
import { FadeIn, ModalCloseBtn, ModalContent } from './styled';
import { Icon } from '@components/Icon';
import { ColorTheme } from '@theme/types';

interface Props {
  isOpen: boolean;
  isMultiple?: boolean;
  customWidth?: string;
  colorTheme?: ColorTheme;
  modalContentPaddings?: {
    default: string;
    moreThanMobile: string;
  };
  onClose(): void;
}

export const InlineFrame: React.FC<Props> = ({
  children,
  isOpen,
  customWidth,
  modalContentPaddings,
  isMultiple = false,
  colorTheme,
  onClose,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <FadeIn isMultiple={isMultiple} customWidth={customWidth} duration="0.3s" timingFunction="ease-in-out">
        <ModalContent paddings={modalContentPaddings}>
          <ModalCloseBtn onClick={onClose} colorTheme={colorTheme}>
            <Icon name="x.outline" />
          </ModalCloseBtn>
          {children}
        </ModalContent>
      </FadeIn>
    </Modal>
  );
};
