import React from 'react';
import { useUser } from '../../providers/user';
import { PrivateOrderLabel } from '../Orders/private/PrivateOrderLabel';
import { OrderReview } from './Order-tabs/OrderReview/OrderReview';
import { OrderOwnProps } from './types';
import { OrderTitle } from './Order-title/OrderTitle';
import { OrderResponds } from './Order-tabs/OrderResponds/OrderResponds';
import { NotesBlock } from './NotesBlock';
import { Tabs } from '@components/Tabs';
import { MarginWrapper } from '@theme/shared/wrappers';
import { OrderApi } from '@http/models/api/orders';
import { isNotFalsy } from '@shared/utils/is-not-falsy';

export const OrderView = ({ data, isPageLayout, onDelete }: OrderOwnProps) => {
  const user = useUser();
  const orderApi = data as OrderApi;
  const isUserExist = user.exist();
  const isAllowBrowseOffers = isUserExist && (orderApi.isMyOrder || user.isAdmin());

  return (
    <>
      <OrderTitle
        orderId={orderApi.id}
        isPageLayout={isPageLayout}
        isUserExist={isUserExist}
        companyName={orderApi.companyName}
        companyId={orderApi.companyId}
      />
      {isUserExist && orderApi.isPrivate && <PrivateOrderLabel />}
      <MarginWrapper marginTop="l">
        <NotesBlock data={data} />
        <Tabs
          ariaLabel="order-review-responds-tabs"
          tabs={[
            {
              title: 'Обзор',
              content: <OrderReview orderApi={orderApi} isPageLayout={isPageLayout} onDelete={onDelete} />,
            },
            isAllowBrowseOffers && {
              title: 'Ответы',
              content: <OrderResponds orderApi={orderApi} />,
            },
          ].filter(isNotFalsy)}
        />
      </MarginWrapper>
    </>
  );
};
