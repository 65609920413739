export interface UploadFile {
  companyId: number;
  newId?: number;
  fileType: FileType;
  reviewId?: string;
  file: string;
}

export enum FileType {
  CompanyBackGroundImage = 1,
  CompanyIcon,
  CompanyShortIcon,
  CompanyWork,
  NewImage,
  NewInText,
  NewVideo,
  Review,
}

export interface FileUploadResponse {
  fileType: FileType;
  url: string;
}
