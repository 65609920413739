import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { getFkkoBulk, getFkkoCode, getPlaceFkko } from '@http/endpoints/fkko';
import { PlaceFkkoBody, WasteClassBulkTotalRecords, WasteClassTotalRecords } from '@http/models/api/fkko';
import { getCompanyFkko } from '@http/endpoints/company';
import { FkkoGetRequestModel } from '@http/models/api/company';

export const useFkko = (fkko: string) => {
  return useQuery(
    ['fkko', fkko],
    () => {
      return getFkkoCode(fkko);
    },
    {
      staleTime: Infinity,
    }
  );
};

export const usePlaceFkko = (body: PlaceFkkoBody, options?: UseQueryOptions<any, any, WasteClassTotalRecords>) => {
  return useQuery(
    ['place-fkko', body],
    () => {
      return getPlaceFkko(body);
    },
    {
      staleTime: Infinity,
      ...options,
    }
  );
};

export const useCompanyFkko = (userCompanyId: number, options?: UseQueryOptions<any, any, FkkoGetRequestModel>) => {
  return useQuery(['get_company_fkko', userCompanyId], () => getCompanyFkko(userCompanyId), {
    staleTime: Infinity,
    ...options,
  });
};

export const useFkkoBulk = ({
  successHandler,
  errorHandler,
}: {
  successHandler: (response: WasteClassBulkTotalRecords) => void;
  errorHandler: () => void;
}) =>
  useMutation(getFkkoBulk, {
    onSuccess: successHandler,
    onError: errorHandler,
  });
