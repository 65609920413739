import { useMutation, useQuery } from 'react-query';
import {
  getUsersAdmin,
  getUsersByCompany,
  updateCompanyDescription,
  userCompanyAdditionalUpdate,
  updateUser,
} from '@http/endpoints/user';
import {
  UpdatableUserRequestModel,
  UpdateCompanyDescriptionRequest,
  UserCompanyAdditionalUpdateRequest,
  UserCompanyUpdateRequest,
  UsersAdminQuery,
} from '@http/models/api/user';
import { getUserCompanies, userCompanyUpdate } from '@http/endpoints/company';

export const useUserCompanies = (ids: number[]) => {
  return useQuery(['user-companies', ids], () => getUserCompanies(ids), {
    staleTime: Infinity,
  });
};

interface UseUserCompanyUpdateParams {
  successHandler: () => void;
  errorHandler: (err: any) => void;
}

export const useUserCompanyUpdate = ({ successHandler, errorHandler }: UseUserCompanyUpdateParams) =>
  useMutation<any, any, UserCompanyUpdateRequest>(userCompanyUpdate, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useUserCompanyAdditionalUpdate = ({ successHandler, errorHandler }: UseUserCompanyUpdateParams) =>
  useMutation<any, any, UserCompanyAdditionalUpdateRequest>(userCompanyAdditionalUpdate, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useUsersByCompany = (id: number) => {
  return useQuery(['usersByCompany', id], () => getUsersByCompany(id), { staleTime: Infinity });
};

export const useUsersAdmin = (query: UsersAdminQuery) => {
  return useQuery(['usersAdmin', query], () => getUsersAdmin(query), { staleTime: Infinity });
};

export const useCompanyDescriptionUpdate = ({ successHandler, errorHandler }: UseUserCompanyUpdateParams) =>
  useMutation<any, any, UpdateCompanyDescriptionRequest>(updateCompanyDescription, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

interface UseUserUpdateParams {
  successHandler: () => void;
  errorHandler: (err: any) => void;
}

export const useUserUpdate = ({ successHandler, errorHandler }: UseUserUpdateParams) =>
  useMutation<any, any, UpdatableUserRequestModel>(updateUser, {
    onSuccess: successHandler,
    onError: errorHandler,
  });
