import { useEffect, useRef } from 'react';
import { Inner, Wrapper } from './styled';
import { Table } from './Table';
import { MobileTable } from './MobileTable';
import { WasteClassTotalRecords } from '@http/models/api/fkko';
import { LoaderBox } from '@components/LoaderBox';
import { Nullable } from 'types';
import { useScreenSize } from '@shared/hooks/use-screen-size';

interface Props {
  fkkoData?: WasteClassTotalRecords;
  isLoading: boolean;
  totalCountByQuery: number;
}

export const FkkoResult: React.FC<Props> = ({ fkkoData, isLoading, totalCountByQuery }) => {
  // Устанавливаем минимальную высоту контента, когда приходят данные,
  // чтобы не скакало во время переключения страниц пагинации
  const contentRef = useRef<Nullable<HTMLDivElement>>(null);

  const { isMobile, isTablet } = useScreenSize();

  useEffect(() => {
    if (!fkkoData || isLoading || !contentRef.current) return;

    setTimeout(() => {
      if (!contentRef.current) return;
      const contentHeight = contentRef.current.offsetHeight;
      contentRef.current.style.minHeight = `${contentHeight}px`;
    });
  }, [fkkoData, isLoading]);

  const { recods: fkkoList = [] } = fkkoData || {};

  if (isLoading) {
    return <LoaderBox />;
  }

  const table = isMobile || isTablet ? <MobileTable fkkoList={fkkoList} /> : <Table fkkoList={fkkoList} />;

  return (
    <Wrapper ref={contentRef}>
      <Inner>{totalCountByQuery > 0 && table}</Inner>
    </Wrapper>
  );
};
