import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    padding: 4px;
    color: ${({ theme }) => theme.palette.grey.white};
    border: 1px solid ${({ theme }) => theme.palette.grey.white};
  }
`;
