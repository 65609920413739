import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  Body,
  CardContent,
  Container,
  Controls,
  DeleteButton,
  Description,
  EditButton,
  Header,
  Price,
  Title,
} from './styled';
import { CompanySale } from '@http/models/api/company';
import { Icon } from '@components/Icon';
import { useConfirmDialog } from 'providers/confirm-dialog';
import { pennyToRubs } from '@shared/utils/helpers';
import { breaksHundreds, normalizeFloatDigits } from '@lib/Components/fields/input-value-helpers';
import { PRICE_MAX_FLOAT_DIGITS } from '@constants/numbers';
import { Tooltip } from '@components/Tooltip';

interface SaleCardProps {
  sale: CompanySale;
  hasEditControls?: boolean;
  onEdit: (sale: CompanySale) => void;
  onDelete: (id: number) => void;
}

export const SaleCard: React.FC<SaleCardProps> = ({ sale, hasEditControls, onEdit, onDelete }) => {
  const confirm = useConfirmDialog();

  const [swiped, setSwiped] = useState(false);
  const handlers = useSwipeable({
    onSwipedLeft: () => hasEditControls && setSwiped(true),
    onSwipedRight: () => setSwiped(false),
  });

  const { id, title, description, price, priceFrom } = sale;

  const preparedPrice = breaksHundreds(normalizeFloatDigits(pennyToRubs(price), PRICE_MAX_FLOAT_DIGITS));
  const priceLabel = `${priceFrom ? 'от' : ''} ${preparedPrice}`;

  const handleOnClickEdit = () => {
    onEdit(sale);
    setSwiped(false);
  };

  const handleOnClickDelete = () => {
    confirm.show({
      dialogText: `Удалить услугу ${sale.title}?`,
      onApply() {
        onDelete(id);
        setSwiped(false);
      },
    });
  };

  return (
    <Container {...handlers}>
      <CardContent swiped={swiped}>
        <Header>
          <Tooltip title={title} placement="top" arrow>
            <Title>{title}</Title>
          </Tooltip>
          <Price>{`${priceLabel}`} ₽</Price>
        </Header>
        <Body>
          <Description>{description}</Description>
        </Body>
      </CardContent>
      {hasEditControls && (
        <Controls visible={swiped}>
          <EditButton onClick={handleOnClickEdit}>
            <Icon name="pencil.filled" size="sm" />
          </EditButton>
          <DeleteButton onClick={handleOnClickDelete}>
            <Icon name="trash.outline" size="sm" />
          </DeleteButton>
          <Icon name="dots-vertical.solid" onClick={() => setSwiped(true)} />
        </Controls>
      )}
    </Container>
  );
};
