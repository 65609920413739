import React, { MouseEvent, KeyboardEvent } from 'react';
import { useRouter } from 'next/router';
import { routes } from '../../routes';
import { useModals } from 'providers/modals';
import { ActiveModals } from 'providers/modals/types';

interface Props {
  companyId: number;
  customRender?: React.ReactNode | string;
  titleAttribute?: string;
}

export const CompanyLink = ({ companyId, customRender, titleAttribute }: Props) => {
  const companyModal = useModals();
  const router = useRouter();

  const handleClick = (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const payload: ActiveModals = {
      type: 'company',
      activeId: companyId.toString(),
    };

    if (companyModal.shouldOpenPage(payload)) {
      router.push(routes.company.info(companyId.toString()));
      return;
    }

    companyModal.open(payload);
  };

  return (
    <a href={routes.company.info(`${companyId}`)} onClick={handleClick} onKeyPress={handleClick} title={titleAttribute}>
      {customRender ? customRender : companyId}
    </a>
  );
};
