import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { routes } from '../../routes';
import { Button } from '@components/Button';
import NotFoundImage from '@assets/svg/404.svg';

const Wrapper = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 18px;

  svg {
    font-size: 10rem;
    opacity: 0.8;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
  margin-bottom: 32px;

  svg {
    display: block;
    width: 100%;
    max-width: 485px;
    height: auto;
  }
`;

const useStyles = makeStyles({
  title: {
    marginBottom: '12px',
  },
  text: {
    marginBottom: '32px',
  },
});

export const NotFoundScreen = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <ImageWrapper>
        <NotFoundImage />
      </ImageWrapper>
      <Typography variant="h1" className={classes.title}>
        Страница не найдена
      </Typography>
      <Typography variant="body1" className={classes.text}>
        Запрошенный раздел не существует, <br />
        пожалуйста проверьте правильность указанного пути.
      </Typography>
      <Button href={routes.home}>На главную</Button>
    </Wrapper>
  );
};
