import React, { FC } from 'react';
import { ActiveModals } from '../types';
import { LoaderBox } from '@components/LoaderBox';
import { useOfferById } from '@http/hooks/offer';
import Offer from 'screens/Offer';
import { useOrderById } from '@http/hooks/order';
import { OpenBlank } from '@components/OpenBlank';
import { MarginWrapper } from '@theme/shared/wrappers';
import { routes } from 'routes';

export type OfferInlineComponentProps = Omit<ActiveModals, 'type'>;

export const OfferInlineComponent: FC<OfferInlineComponentProps> = ({ adjectiveId, activeId }) => {
  const offerId = Number(activeId);
  const orderId = Number(adjectiveId);

  const { data: dataOffer, isLoading: isLoadingOffer } = useOfferById({ orderId, offerId });
  const { data: dataOrder, isLoading: isLoadingOrder } = useOrderById({ orderId });

  if (Number.isNaN(offerId) || Number.isNaN(orderId) || !dataOffer?.id || !dataOrder?.id) return null;

  if (isLoadingOffer || isLoadingOrder) return <LoaderBox />;

  return (
    <>
      <MarginWrapper marginBottom="s">
        <OpenBlank url={routes.offer(orderId, offerId)} />
      </MarginWrapper>
      <Offer order={dataOrder} offer={dataOffer} isPageLayout={false} />
    </>
  );
};
