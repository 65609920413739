import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { timeToAskInMs } from '../config';
import { Notification } from 'components/Notification';
import { MarginWrapper } from '@theme/shared/wrappers';
import { askVoice } from '@http/endpoints/ask';
import { useCurrentAsk } from '@http/hooks/ask';
import { useUser } from 'providers/user';

const DELAY_TO_SHOW_MS = 10000; // 10s

export const AskNotification: React.FC = () => {
  const [isOpen, setOpenStatus] = useState(false);
  const classes = useStyles();
  const userContext = useUser();
  const { data: userData } = userContext;

  const userMeta = userData?.metaData;

  const isShowAsk = useMemo(() => {
    if (!userMeta?.ask?.skipedDate) {
      return true;
    }
    return userMeta?.ask?.skipedDate && DateTime.now().toMillis() > userMeta?.ask?.skipedDate + timeToAskInMs();
  }, [userMeta]);
  const { data: currentAskData } = useCurrentAsk(Boolean(userData));

  const currentAsk = isShowAsk ? currentAskData : undefined;
  const skipDateMeta = { ...(userMeta || {}), ask: { skipedDate: DateTime.now().toMillis() } };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentAsk) {
      timeout = setTimeout(() => {
        setOpenStatus(true);
      }, DELAY_TO_SHOW_MS);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [currentAsk]);

  const handleVoice = async (id: number) => {
    setOpenStatus(false);

    try {
      await askVoice(id);
      await userContext.updateMetadata(skipDateMeta);
    } catch (e) {}
  };

  const handleSkipAsk = async () => {
    setOpenStatus(false);
    await userContext.updateMetadata(skipDateMeta);
  };

  if (!currentAsk) return null;

  return (
    <Notification isOpen={isOpen}>
      <Grid container alignItems="flex-start" wrap="nowrap" justifyContent="space-between">
        <MarginWrapper marginBottom="s">
          <Typography>{currentAsk.title}</Typography>
        </MarginWrapper>
        <IconButton className={classes.closeBtn} onClick={handleSkipAsk} size="small">
          <HighlightOffIcon htmlColor="white" />
        </IconButton>
      </Grid>
      {currentAsk.responses.map(variant => (
        <Button
          key={variant.id}
          onClick={() => handleVoice(variant.id)}
          classes={{ outlined: classes.variantBtn }}
          variant="outlined"
          fullWidth
        >
          {variant.response}
        </Button>
      ))}
    </Notification>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  closeBtn: {
    padding: spacing(0, 0, 0, 1.5),
  },
  variantBtn: {
    border: '1px solid #fff',
    color: '#fff',

    '&:not(:last-child)': {
      marginBottom: spacing(1.5),
    },
  },
}));
