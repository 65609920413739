import styled, { css } from 'styled-components';

interface ScrollerArrowProps {
  $isActive: boolean;
}

export const Wrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.utils.media.moreThanMobile`
     padding: 0 ${({ theme }) => theme.spaces.s};
  `};
`;

export const Container = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spaces.xs};
  overflow-x: auto;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    column-gap: ${({ theme }) => theme.spaces.m};
  `};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollerArrow = styled.div<ScrollerArrowProps>`
  background: ${({ theme }) => theme.palette.grey.white};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.main.primary};
  position: absolute;
  width: 40px;
  height: 40px;
  display: none;
  opacity: 0.6;
  align-items: center;
  justify-content: center;
  transition: 0.1s ${({ theme }) => theme.animate.base};
  box-shadow: 0 4px 20px rgb(0 0 0 / 40%);
  top: calc(50% - 20px);
  z-index: 100;

  ${({ $isActive }) =>
    $isActive &&
    css`
      display: flex;
    `}
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;
