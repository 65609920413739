import { QueryParams } from 'routes';
import { Nullable } from 'types';
import { MODAL_COMPANY_INFO, MODAL_ORDER_INFO } from 'types/routesQueryNames';

type modalNameType = typeof MODAL_ORDER_INFO | typeof MODAL_COMPANY_INFO;

export const openModalIfHaveId = (queryParams: QueryParams, modalName: modalNameType): Nullable<number> => {
  const modalInfo: Nullable<string | string[]> = queryParams[modalName] || null;

  if (modalInfo) {
    const id = Array.isArray(modalInfo) ? +modalInfo[0] : +modalInfo;

    if (!isNaN(id)) {
      return id;
    }
  }

  return null;
};
