import { Nullable } from 'types';

export type FormValues = {
  userAbout: Nullable<string>;
  desc: string;
  image: Nullable<string>;
  rate: Nullable<number>;
};

export const INITIAL_VALUES: FormValues = {
  userAbout: null,
  desc: '',
  image: null,
  rate: null,
};
