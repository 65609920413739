import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { Text } from '@theme/shared/text';
import { ColorTheme } from '@theme/types';

interface StyledButtonProps {
  $isActive?: boolean;
  $colorTheme?: ColorTheme;
}

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.main.accent};
`;

// eslint-disable-next-line prettier/prettier
export const StyledButton = styled(Button)<StyledButtonProps>`
  &.MuiButton-root {
    color: ${({ theme, $colorTheme }) =>
      $colorTheme === 'light' ? theme.palette.text.primary : theme.palette.grey.white};

    background-color: ${({ theme, $isActive, $colorTheme }) => {
      if ($colorTheme === 'light') {
        return $isActive ? theme.palette.grey.light : theme.palette.grey.white;
      }

      return $isActive ? theme.palette.text.link : theme.palette.main.accent;
    }};

    border-radius: 100px;
    padding: 6px 12px;

    :hover {
      background-color: ${({ theme, $colorTheme }) =>
        $colorTheme === 'light' ? theme.palette.grey.light : theme.palette.text.link};
    }
  }
`;

export const PopoverContent = styled.div`
  padding: ${({ theme }) => theme.spaces.m};
  display: flex;
`;

export const PopoverItem = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spaces.s};
  }
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.text.link};
  }
`;

export const PopoverItemTitle = styled.div`
  margin-left: 6px;
  white-space: nowrap;
`;

export const MenuSection = styled.div`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spaces.m};
  }
`;

export const MenuItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, fit-content(50%));
  column-gap: ${({ theme }) => theme.spaces.l};
  row-gap: ${({ theme }) => theme.spaces.s};
`;

export const CommonMenuItemsWrapper = styled.div`
  a {
    display: block;
    :not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spaces.s};
    }
  }
`;

export const Column = styled.div`
  :not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.palette.grey.light};
    padding-right: ${({ theme }) => theme.spaces.l};
    margin-right: ${({ theme }) => theme.spaces.l};
    max-width: 520px;
  }
`;

export const StyledMenuSectionCaption = styled(Text.MenuCaptionBold)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spaces.s};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuIcon = styled.div`
  width: 16px;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.link};
  stroke-width: 1.5px;
`;
