import { Nullable } from 'types';

export type FormValues = {
  title: string;
  description: string;
  price: Nullable<number>;
  priceDesc: Nullable<string>;
  priceFrom: boolean;
};

export const INITIAL_VALUES: FormValues = {
  title: '',
  description: '',
  price: null,
  priceDesc: null,
  priceFrom: false,
};
