import { HttpOptions } from '@http/service/options';
import { httpService } from '@http/service';
import { buildQs } from '@http/shared';
import { AsksResponseItem, CreatePoolRequest, ResultResponses } from '@http/models/api/ask';
import { getRandomFromArray } from '@shared/utils/helpers';

export const getAsks = async (params?: { isPublished?: boolean }) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<AsksResponseItem[]>(buildQs(`/ask`, params), options);
};

export const getCurrentAsk = async () => {
  const options = new HttpOptions().asAuthRoute();
  const response = await httpService.get<AsksResponseItem[]>(buildQs(`/ask`), options);
  return getRandomFromArray(response.filter(a => a.isPublished && !a.isAnswered));
};

export const createAsk = (model: CreatePoolRequest) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.post(buildQs(`/ask`), model, options);
};

export const deleteAsk = (askId: number) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.delete(buildQs(`/ask?id=${askId}`), null, options);
};

export const getAskById = (id: number) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<ResultResponses>(`/ask/${id}`, options);
};

export const askVoice = (askResponseId: number) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.post(`/ask/voice/${askResponseId}`, null, options);
};
