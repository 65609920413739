import { Nullable } from 'types';

export interface DateLicense {
  number: Nullable<string>;
  order: Nullable<string>;
  registrator: Nullable<string>;
  isActive: boolean;
  dated: string;
  company: Nullable<Omit<CompanyShortAPI, 'dateLicense'>>;
  companyId: number;
  rpnId: number;
  places: Nullable<PlaceShort[]>; // TODO: заполнить interface Place[], PlaceShort не полный
  updatedAt: Nullable<string>;
  sensetiveData: Nullable<string>;
  id: number;
  deletedAt: Nullable<string>;
  createdAt: string;
}

export interface CompanyShortAPI {
  title: Nullable<string>;
  site: Nullable<string>;
  inn: Nullable<string>;
  ogrn: Nullable<string>;
  phone: Nullable<string>;
  address: Nullable<string>;
  userName: Nullable<string>;
  licenseNames: Nullable<Array<string>>;
  id: number;
  fkkoCount: number;
  activeLicense: boolean;
  placeCount: number;
  isVerified: Nullable<boolean>;
  typeMoves: number;
  fkkoFoundCount: number;
  lt: number;
  ld: number;
  haveContacts: boolean;
  dateLicense: DateLicense;
  contracts: Nullable<Contract[]>;
  receipt: boolean;
  iconShort: Nullable<string>;
}

export interface CompanyFullAPI extends CompanyShortAPI {
  licenses: Nullable<License[]>;
  // moveTypes: Nullable<TypeMove[]>; пока убрали, потом может уберём из контракта на совсем
  classTypes: Nullable<WarningClass[]>;
  stationaryPhone: Nullable<string>;
  nds: boolean;
  email: Nullable<string>;
  fullTitle: Nullable<string>;
  kpp: Nullable<string>;
  okpo: Nullable<string>;
  okved: Nullable<string>;
  oktmo: Nullable<string>;
  postAddress: Nullable<string>;
  postOKTMO: Nullable<string>;
  regionId: Nullable<string>;
  icon: Nullable<string>;
  backGroundImage: Nullable<string>;
  listFiles: Nullable<string[]>;
  link: Nullable<string>;
  addressCode: number;
  postAddressCode: number;
  description: Nullable<string>;
}

export interface CompanyAdditional {
  id: number;
  company: Nullable<CompanyFullAPI>;
  surname: Nullable<string>;
  name: Nullable<string>;
  middlename: Nullable<string>;
  registerNalogDate: Nullable<string>;
  registerNalogDocumentDate: Nullable<string>;
  mailAddress: Nullable<string>;
  isDetachedAssociation: boolean;
  isForeignPerson: boolean;
  descriptionShort: Nullable<string>;
}

export interface License {
  id: number;
  number: Nullable<string>;
  places: Nullable<PlaceShort[]>;
  order: Nullable<string>;
  dated: string;
  registrator: Nullable<string>;
  isActive: boolean;
  rpnId?: number;
}

export interface PlaceShort {
  id: number;
  address: Nullable<string>;
  oktmo: Nullable<string>;
  fkkoCount: number;
  placeCopyId: Nullable<number>; // Ходить за местами по нему, если есть, иначе по id
}

export interface CompanyDetailsApi {
  moveTypes: Nullable<TypeMove[]>; // TODO: может тоже тогда заменить на typeMoves: number;
  classTypes: Nullable<WarningClass[]>;
  percentFkko: number;
  fkko: string[];
}

export interface CompanySearchBody {
  titleOrInn: string;
  regionId?: Nullable<number>;
  fkko?: Nullable<string[]>;
  placeRegionId?: Nullable<number>;
  typesMove?: Nullable<Array<TypeMove>>;
  warningClasses?: Nullable<Array<WarningClass>>;
  license?: string;
  skip?: number;
  size?: number;
  typesMoveAsAnd?: boolean;
  hasLicense?: boolean;
  distanceStep?: 2 | 4 | 6;
  sortByCount?: boolean;
}

export interface CompanyDetailsBody {
  id: number;
  fkkoList?: string[];
}

export interface SoCloseDistanceAPI {
  id: number;
  title: string;
  count: number;
  distanceKm: number;
}

export interface CompanyResponseTotalRecords {
  recods: CompanyShortAPI[];
  soCloseDistance: SoCloseDistanceAPI[];
  totalCount: number;
}

export enum TypeMove {
  Transport = 1,
  Collection = 2,
  Neutralization = 4,
  Disposal = 8,
  Treatment = 16,
  Placement = 32,
}

export enum WarningClass {
  First = 1,
  Second,
  Third,
  Fourth,
}

export interface CompanyCreateUpdateRequestModel {
  inn: string;
  title: string;
  site: Nullable<string>;
  ogrn: Nullable<string>;
  phone: Nullable<string>;
  address: Nullable<string>;
  regionId?: number;
  companyId?: number;
}

export interface RecodModel {
  class: number;
  code: string;
  codeId: number;
  title: string;
  type: number;
}

export interface FkkoGetRequestModel {
  totalCount: number;
  recods: Array<RecodModel>;
}

export enum TypeCompany {
  Transport = TypeMove.Transport | TypeMove.Collection,
  Recycle = TypeMove.Transport | TypeMove.Collection | TypeMove.Neutralization | TypeMove.Disposal | TypeMove.Treatment,
  Placement = TypeMove.Placement,
}

export const FactoryFlags =
  /^ЗАО|Закрытое акционерное общество|ОАО|Открытое акционерное общество|ГКУ|АО|Акционерное общество/i;

export interface AddExternalCompanyRequest {
  inn: string;
  title: string;
  site?: Nullable<string>;
  ogrn?: Nullable<string>;
  phone?: Nullable<string>;
  address?: Nullable<string>;
  regionId?: Nullable<number>;
  companyId?: Nullable<number>;
}

export interface Contract {
  id: number;
  deletedAt: Nullable<string>;
  createdAt: string;
  companyId: string;
  company: CompanyFullAPI;
  contragentId: string;
  contragent: Contragent;
  number: Nullable<string>;
  datedAt: string;
  endDate?: Nullable<string>;
  isSend: boolean;
}

export interface Contragent {
  id: number;
  deletedAt: Nullable<string>;
  createdAt: string;
  companyId: number;
  company: {};
  contragentCompanyId: number;
  contragentCompany: {};
  contracts: Contract[];
}

export interface AddCompanyContractRequest {
  userCompanyId: number;
  contragentId: number;
  number: string;
  datedAt: string;
  endDate?: Nullable<string>;
  isSend: boolean;
}

export interface UpdateCompanyContractRequest extends AddCompanyContractRequest {
  id?: number;
}

export interface CompanyShortContactorsAPI extends CompanyShortAPI {
  companyId: number;
}

export interface ProductionAreasResponse {
  recods: ProductionArea[];
  soCloseDistance: SoCloseDistanceAPI[];
  totalCount: number;
}

export interface ProductionArea {
  id: number;
  deletedAt: string;
  createdAt: string;
  companyId: number;
  name: string;
  oktmo: string;
  address: string;
  codeArea: string;
  categoryArea: CategoryArea;
}

export interface ProductionAreaBody {
  companyId: number;
  skip?: number;
  limit?: number;
}
export interface AddCompanyProductionAreaRequest {
  id?: number;
  companyId: number;
  name: Nullable<string>;
  oktmo: Nullable<string>;
  address: Nullable<string>;
  codeArea: Nullable<string>;
  categoryArea: Nullable<CategoryArea>;
}

export enum CategoryArea {
  I = 1,
  II,
  III,
  IV,
}

export interface AddCompanySaleRequest {
  companyId: number;
  title: string;
  description: string;
  price: number;
  priceDesc: Nullable<string>;
  priceFrom: boolean;
  id?: number;
}

export interface CompanySale {
  id: number;
  title: string;
  description: Nullable<string>;
  price: number;
  priceDesc: Nullable<string>;
  priceFrom: boolean;
}

export interface CompanySalesBody {
  companyId: number;
  skip?: number;
  size?: number;
}
export interface AddBalanceCompany {
  companyId: number;
  balance: number;
}

export interface SubTrackBalanceCompany {
  companyId: number;
  sumForSubtract: number;
  comment: string;
}
