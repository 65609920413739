import styled from 'styled-components';
import { BlockCollapse } from '@theme/shared/components';

export interface CellWrapProps {
  color: 'accent' | 'lightBlue' | 'lightGreen' | 'pink';
}

export const Wrap = styled(BlockCollapse)<CellWrapProps>`
  padding: ${({ theme }) => theme.spaces.xxs} 0;
  justify-content: center;
  width: 100%;
  color: ${({ theme, color }) => {
    switch (color) {
      case 'accent':
        return theme.palette.main.accent;
      case 'lightBlue':
        return theme.palette.main.lightBlue;
      case 'lightGreen':
        return theme.palette.main.lightGreen;
      case 'pink':
        return theme.palette.main.pink;
      default:
        return theme.palette.main.accent;
    }
  }};
  font-size: ${({ theme }) => theme.textSizes.header2.size};
  font-weight: ${({ theme }) => theme.textSizes.header2.weight};
  line-height: ${({ theme }) => theme.textSizes.header2.lineHeight};
`;
