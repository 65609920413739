import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import { Switch as MuiSwitch } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { Text } from '@theme/shared/text';

const maxWidthAdornment = '48px';

interface FieldWrapperProps {
  hasError?: boolean;
  withAdornment?: boolean;
}

interface RatingFieldProps {
  hasError?: boolean;
}

export const FieldWrapper = styled.div<FieldWrapperProps>`
  position: relative;

  > * {
    width: 100%;
  }

  .MuiInputBase-input {
    padding: ${({ theme }) => `27px ${theme.spaces.xs} 10px`};
    width: ${({ withAdornment }) => (withAdornment ? `calc(100% - ${maxWidthAdornment} - 24px)` : `100%`)};
  }

  .MuiInputLabel {
    &-root {
      transform: translate(12px, 22px) scale(0.9);
      color: ${({ theme }) => theme.palette.text.secondary};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }

    &-shrink {
      transform: translate(12px, 8px) scale(0.75);
      width: 100%;
    }
  }

  .MuiSelect-select:focus {
    background: white;
  }

  .MuiFilledInput-root {
    background: white;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-color: ${({ hasError, theme }) => (hasError ? `${theme.colors.error} !important` : theme.colors.gray)};
    border-radius: 0;
    box-sizing: border-box;

    :before,
    :after {
      display: none;
    }

    :hover {
      border: 1px solid rgb(33 33 33);
    }

    :hover,
    &.Mui-focused,
    &.Mui-disabled {
      background: white;
    }

    &.Mui-disabled {
      border-color: ${({ theme }) => theme.colors.gray};
    }

    &.Mui-focused {
      :before {
        display: ${({ hasError }) => (hasError ? 'none' : 'block')};
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 2px solid ${({ theme }) => theme.colors.primary};
        border-radius: inherit;

        :hover {
          border: none;
        }
      }
    }
  }
`;

export const Error = styled.p`
  position: absolute;
  top: 0;
  padding-left: 0.5rem;
  margin: 0;
  font-size: 11px;
  color: #fff;
  background: ${({ theme }) => theme.colors.error};
  line-height: 20px;
  z-index: 101;
`;

export const IconWrap = styled.div`
  width: 22px;
  height: 22px;
  color: ${({ theme }) => theme.palette.main.accent};
`;

export const StyledMenuItem = styled(MenuItem)`
  max-width: 800px;

  &.MuiMenuItem-root {
    white-space: normal;
  }

  &.Mui-selected {
    position: relative;

    &:after {
      content: '\\2713';
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 15px;
      width: 10px;
      height: 100%;
      font-size: 22px;
    }
  }
`;

export const Adornment = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: min-content;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: ${maxWidthAdornment};
`;

export const StyledMuiSwitch = styled(MuiSwitch)`
  .MuiIconButton-root {
    padding: 9px;
  }
`;

export const StyledToggleButton = styled(ToggleButton)`
  &.MuiToggleButton-root.Mui-selected {
    &:not(:disabled) {
      background: ${({ theme }) => theme.palette.main.primary};

      &:hover {
        background: ${({ theme }) => theme.palette.main.primaryHover};
      }
    }
  }
`;

export const RatingFieldWrapper = styled.div<RatingFieldProps>`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spaces.s};
  color: ${({ theme }) => theme.palette.text.secondary};
  border: 1px solid red;
  border: ${({ hasError }) => (hasError ? 'auto' : 'none')};
  padding-top: 16px;
  position: relative;

  & > p {
    width: 100%;
  }
`;

export const StyledRatingCaption = styled(Text.Caption)`
  padding-left: ${({ theme }) => theme.spaces.xs};
`;
