import styled from 'styled-components';
import { Icon } from '@components/Icon';

export const Wrapper = styled.div`
  width: 220px;
  min-width: 220px;
  height: 100px;
  background: ${({ theme }) => theme.palette.grey.white};
  border-radius: 10px;
  overflow: hidden;

  ${({ theme }) => theme.utils.media.desktop`
        width: 257px;
        height: 117px;
  `}
`;

export const EmptyLogoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xxs};
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
`;

export const LoadLabel = styled.div`
  font-size: ${({ theme }) => theme.textSizes.menuCaptionBold.size};
  font-weight: ${({ theme }) => theme.textSizes.menuCaptionBold.weight};
`;

export const CameraIcon = styled(Icon)`
  color: #d9d9d9;
`;
