import { FormControlLabel, withStyles } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

export const StyledFormControlLabel = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
  },
  label: {
    fontSize: '0.825rem',
  },
})(FormControlLabel);

export const BoxWrapper = styled.div`
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.base};
  overflow: auto;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: #fff;
`;

export const CardWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.s};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: #fff;
  border: 1px solid #eae8e8;
`;

export const BoxWrapperTablet = styled(BoxWrapper)`
  max-width: 772px;

  ${({ theme }) =>
    theme.utils.media.largeDesktop`
    max-width: 100%;
  `}
`;

export const Separator = styled.div`
  height: 1px;
  background: #000;
  opacity: 0.1;
  margin: ${({ theme }) => theme.spaces.m} 0;
`;

interface SkeletonProps {
  width?: number | string;
  height?: number | string;
  enableBorderRadius?: boolean;
}

const Animation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const valueOrFull = (candidate?: number | string) => {
  if (!candidate) {
    return '100%';
  }

  return typeof candidate === 'string' ? candidate : `${candidate}px`;
};

export const Skeleton = styled.div<SkeletonProps>`
  background-color: #eee;
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => rgba(theme.palette.grey.light, 0.1)},
    ${({ theme }) => rgba(theme.palette.grey.light, 0.1)},
    ${({ theme }) => rgba(theme.palette.grey.light, 0.3)}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
  width: ${({ width }) => valueOrFull(width)};
  height: ${({ height }) => valueOrFull(height)};
  animation: ${Animation} 1.2s ease-in-out infinite;
  border-radius: ${({ enableBorderRadius }) => enableBorderRadius !== false && '0.75rem'};
`;

export const BlockCollapse = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.grey.cell};
  border-radius: 6px;
  padding: ${({ theme }) => theme.spaces.s} ${({ theme }) => theme.spaces.m};
`;

export const SeparatorLight = styled.div`
  height: 2px;
  width: 100%;
  background: ${({ theme }) => theme.palette.grey.cell};
`;
