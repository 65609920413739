import { useRouter } from 'next/router';
import React, { useMemo, FC, createContext, useContext, useEffect, useState, useCallback } from 'react';
import { protectedRoutes, ProtectedAction, protectedNewsCategoryId, forbiddenActions } from './allowedActionsMap';
import { useUser } from 'providers/user';
import { NotFoundScreen } from 'screens/NotFound';
import { NewsPage } from '@http/models/api/news';
import { UnavailableByTariffModalLazy } from 'screens/Tariffs/Modals/UnavailableByTariff/lazy';

interface ContextProps {
  isRouteForbidden(path: string): boolean;
  setModalOpen(flag: boolean): void;
  isActionAllowed(action: ProtectedAction): boolean;
  filterNewsByCatId(news: NewsPage[]): NewsPage[];
}

const Context = createContext<ContextProps | undefined>(undefined);

export const TariffGuardProvider: FC = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const user = useUser();
  const router = useRouter();
  const tariff = user.getUserSubName();
  const isLowTariff = !tariff || tariff === 'light';
  const isAdmin = user.isAdmin();

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const isShouldRedirect = useMemo(() => {
    if (isAdmin) return false;

    return isLowTariff && protectedRoutes.some(r => router.asPath === r || router.pathname === r);
  }, [router, isLowTariff, isAdmin]);

  const handleRouteCheck = useCallback(
    (path: string) => {
      if (isAdmin) return false;

      return isLowTariff && protectedRoutes.includes(path);
    },
    [isLowTariff, isAdmin]
  );

  const handleActionAllowed = useCallback(
    (action: ProtectedAction) => {
      if (isAdmin) return true;

      if (forbiddenActions.includes(action)) {
        return !isLowTariff;
      }

      return true;
    },
    [isLowTariff, isAdmin]
  );

  const filterNewsByCatId = useCallback(
    (news: NewsPage[]) => {
      if (!isLowTariff || isAdmin) return news;

      return news.filter(n => n.categoryId !== protectedNewsCategoryId);
    },
    [isLowTariff, isAdmin]
  );

  useEffect(() => {
    if (isShouldRedirect) {
      setModalOpen(true);
    }
  }, [isShouldRedirect]);

  const value = useMemo((): ContextProps => {
    return {
      isRouteForbidden: handleRouteCheck,
      setModalOpen,
      isActionAllowed: handleActionAllowed,
      filterNewsByCatId,
    };
  }, [handleRouteCheck, handleActionAllowed, filterNewsByCatId]);

  if (isShouldRedirect) {
    return (
      <>
        <NotFoundScreen />
        <UnavailableByTariffModalLazy isOpen={isModalOpen} closeModal={handleCloseModal} recommendedTariff="standart" />
      </>
    );
  }

  return (
    <Context.Provider value={value}>
      {children}
      <UnavailableByTariffModalLazy isOpen={isModalOpen} closeModal={handleCloseModal} recommendedTariff="standart" />
    </Context.Provider>
  );
};

export const useTariffGuard = () => useContext(Context) as ContextProps;
