import React from 'react';
import { UnauthorizedUserTryToCreateOrder } from './components/try-to-create-order';
import { useAppEventBroadcast } from './use-app-event-broadcast';
import { AppEvents } from './types';
import { applyYMGoal } from '@shared/metrika';

export const AppEventsConsumer: React.FC = () => {
  useAppEventBroadcast(AppEvents.start_search_companies, () => {
    applyYMGoal('company_search');
  });

  useAppEventBroadcast(AppEvents.create_new_order, () => {
    applyYMGoal('send_a_request');
  });

  useAppEventBroadcast(AppEvents.register_new_user, () => {
    applyYMGoal('register_new_user');
  });

  useAppEventBroadcast(AppEvents.user_add_company, () => {
    applyYMGoal('user_add_company');
  });

  useAppEventBroadcast(AppEvents.user_add_company_by_admin, () => {
    applyYMGoal('user_add_company_by_admin');
  });

  useAppEventBroadcast(AppEvents.manually_close_registration_suggest, () => {
    applyYMGoal('manually_close_registration_suggest');
  });

  useAppEventBroadcast(AppEvents.go_to_registration_from_suggestion, () => {
    applyYMGoal('go_to_registration_from_suggestion');
  });

  return (
    <>
      <UnauthorizedUserTryToCreateOrder />
    </>
  );
};
