import React, { FC, forwardRef } from 'react';
import { LinkHTML, IconWrap, Wrapper } from './styled';
import { ColorTheme } from '@theme/types';

export interface LinkProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  variant?: 'filled' | 'outlined' | 'text';
  fullWidth?: boolean;
  targetBlank?: boolean;
  download?: string;
  href?: string;
  startIcon?: React.ReactNode;
  colorTheme?: ColorTheme;
  paddings?: string;
  display?: string;
}

export const SimpleLink: FC<LinkProps> = forwardRef(function SimpleLinkRef(
  {
    variant = 'filled',
    href,
    children,
    fullWidth,
    targetBlank = false,
    download,
    startIcon,
    colorTheme,
    paddings,
    display,
  },
  ref: React.Ref<HTMLAnchorElement>
) {
  const content = (
    <Wrapper fullWidth={fullWidth} variant={variant} colorTheme={colorTheme} paddings={paddings} display={display}>
      {startIcon && <IconWrap>{startIcon}</IconWrap>}
      <span>{children}</span>
    </Wrapper>
  );

  if (targetBlank) {
    return (
      <LinkHTML ref={ref} className={`text-decoration-none`} href={href} rel="noreferrer" target="_blank">
        {content}
      </LinkHTML>
    );
  }

  if (download) {
    return (
      <LinkHTML ref={ref} className={`text-decoration-none`} href={href} download={download}>
        {content}
      </LinkHTML>
    );
  }

  return (
    <LinkHTML ref={ref} className={`text-decoration-none`} href={href}>
      {content}
    </LinkHTML>
  );
});
