import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';

export const CompanyName = styled.div`
  color: ${({ theme }) => theme.palette.text.link};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Wrapper = styled(BoxWrapper)`
  &:hover {
    cursor: pointer;
  }

  &:hover ${CompanyName} {
    text-decoration: underline;
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.s};
`;

export const LocationMarker = styled.div`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.palette.text.link};
`;
