import styled from 'styled-components';
import { Rating } from 'react-simple-star-rating';
import { Text } from '@theme/shared/text';
import { BoxWrapper } from '@theme/shared/components';

export const Container = styled(BoxWrapper)`
  ${({ theme }) => theme.utils.media.moreThanMobile`
      display: flex;
      justify-content: space-between;
      column-gap: 100px;
      padding: 0;
      border: none;
      box-shadow: none;
      border-radius: 0;
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey.light};
      margin-bottom: ${({ theme }) => theme.spaces.l};
      padding-bottom: ${({ theme }) => theme.spaces.l};
  `}
`;

export const LeftColumn = styled.div``;

export const Header = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.utils.media.moreThanMobile`
      justify-content: start;
      column-gap: ${({ theme }) => theme.spaces.s};
  `}
`;

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.textSizes.bodyBold.size};
  font-weight: ${({ theme }) => theme.textSizes.bodyBold.weight};
  line-height: ${({ theme }) => theme.textSizes.bodyBold.lineHeight};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      font-size: ${({ theme }) => theme.textSizes.header4.size};
      font-weight: ${({ theme }) => theme.textSizes.header4.weight};
      line-height: ${({ theme }) => theme.textSizes.header4.lineHeight};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spaces.xs};
  margin-top: 6px;

  ${({ theme }) => theme.utils.media.moreThanMobile`
     margin-top: ${({ theme }) => theme.spaces.xs};
  `}
`;

export const Author = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xxs};
  font-size: ${({ theme }) => theme.textSizes.captionBold.size};
  font-weight: ${({ theme }) => theme.textSizes.captionBold.weight};
  line-height: ${({ theme }) => theme.textSizes.captionBold.lineHeight};
  color: ${({ theme }) => theme.palette.main.accent};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      margin-top: 6px;
      font-size: ${({ theme }) => theme.textSizes.bodyBold.size};
      font-weight: ${({ theme }) => theme.textSizes.bodyBold.weight};
      line-height: ${({ theme }) => theme.textSizes.bodyBold.lineHeight};
  `}
`;

export const Date = styled(Text.Caption)`
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  align-items: center;
`;

export const StyledRating = styled(Rating)``;

export const Content = styled(Text.MenuCaption)`
  margin-top: ${({ theme }) => theme.spaces.xxs};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      margin-top: ${({ theme }) => theme.spaces.m};
      font-size: ${({ theme }) => theme.textSizes.body.size};
      line-height: ${({ theme }) => theme.textSizes.body.lineHeight};
  `}
`;

export const EditControls = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xxs};
  display: flex;
  column-gap: ${({ theme }) => theme.spaces.m};

  & button {
    flex: 1;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: ${({ theme }) => theme.spaces.m};

    & button {
      flex: auto;
      width: 150px;
      max-width: 150px;
    }
  `}
`;

export const ImageContainer = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xxs};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: 0;
  `}
`;
