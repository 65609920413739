import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NoteLink } from './styled';
import { Note } from '.';
import { continueParamsFactory, routes } from 'routes';

interface Props {
  text?: string;
}

export const UnauthorizedNote: FC<Props> = ({ text = 'названий компаний и контакты' }) => {
  const { asPath } = useRouter();

  return (
    <Note>
      <span>
        Неавторизованные пользователи не видят {text},{` `}
        <Link passHref href={routes.loginQuery(continueParamsFactory(asPath))}>
          <NoteLink>авторизуйтесь</NoteLink>
        </Link>
        {` `}или{` `}
        <Link passHref href={routes.registrationQuery(continueParamsFactory(asPath))}>
          <NoteLink>зарегистрируйтесь</NoteLink>
        </Link>
        {` `}для получения всех возможностей, это бесплатно.
      </span>
    </Note>
  );
};
