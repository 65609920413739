import styled from 'styled-components';

export const MainCropzoneSection = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  overflow: hidden;
  width: 257px;
  height: 257px;
  margin-bottom: ${({ theme }) => theme.spaces.l};
  position: relative;

  ${({ theme }) => theme.utils.media.moreThanMobile`
       width: 400px;
       height: 400px;
  `}
`;
