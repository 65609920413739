import getConfig from 'next/config';
import { AppConfig } from './types';

const {
  publicRuntimeConfig: { WebAppConfig },
} = getConfig();

export const config = (): AppConfig => WebAppConfig;
export const isProduction = () => config().mode === 'production';
export const timeToAskInMs = () => Number(config().timeToAskInMs);
