import React, { FC } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useUser } from '../../providers/user';
import { getSupportContactsTabLink } from '../Support/tabs.config';
import { OrderOwnProps } from './types';
import { UnauthorizedNote } from '@components/Note/UnauthorizedNote';
import { OrderApi } from '@http/models/api/orders';
import { continueParamsFactory, routes } from 'routes';
import { Note } from '@components/Note';
import { NoteLink } from '@components/Note/styled';

type NotesBlockProps = Pick<OrderOwnProps, 'data'>;

export const NotesBlock: FC<NotesBlockProps> = ({ data }) => {
  const router = useRouter();
  const user = useUser();
  const orderApi = data as OrderApi;
  const isUserExist = user.exist();
  const company = user.getCompany();

  if (!isUserExist) {
    return <UnauthorizedNote />;
  }

  if (!orderApi.isMyOrder && !company) {
    return (
      <Note>
        <span>
          Для ответа на заявку необходимо{` `}
          <Link passHref href={routes.userCreateCompany(continueParamsFactory(router.asPath))}>
            <NoteLink>добавить компанию</NoteLink>
          </Link>
        </span>
      </Note>
    );
  }

  if (!orderApi.isMyOrder && company && !company.isVerified) {
    return (
      <Note>
        <span>
          Ваша компания сейчас в процессе верификации, как только мы закончим вам станет доступен функционал ответа на
          заявки.{` `}
          <Link passHref href={getSupportContactsTabLink()}>
            <NoteLink>Уточнить статус</NoteLink>
          </Link>
        </span>
      </Note>
    );
  }

  return null;
};
