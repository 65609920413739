import { useState } from 'react';
import { AbortController } from 'node-abort-controller';
import { OnlineDot, UsersCounterWrapper } from './styled';
import { Text } from '@theme/shared/text';
import { usePoolingEffect } from '@shared/hooks/use-pooling-effect';
import { getUsersCounter } from '@http/endpoints/user';
import { Nullable } from 'types';
import { wordEnding } from '@shared/utils/wordEnding';
import { ColorTheme } from '@theme/types';

export interface UserCounterProps {
  colorTheme?: ColorTheme;
}

export const UserCounter: React.FC<UserCounterProps> = ({ colorTheme = 'dark' }) => {
  const [currentCounter, setCurrentCounter] = useState<Nullable<number>>(null);

  usePoolingEffect(
    {
      pollingAction: async (controller: AbortController) => {
        try {
          const counter = await getUsersCounter(controller);
          setCurrentCounter(counter);
        } catch (e) {
          if (e?.message === 'canceled') {
            return;
          }
        }
      },
      duration: 600000, // 10 min
    },
    []
  );

  if (!currentCounter) {
    return null;
  }

  return (
    <UsersCounterWrapper colorTheme={colorTheme}>
      <OnlineDot />
      <Text.Secondary>{`Онлайн: ${currentCounter} ${wordEnding(currentCounter, {
        1: 'пользователь',
        2: 'пользователя',
        5: 'пользователей',
      })}`}</Text.Secondary>
    </UsersCounterWrapper>
  );
};
