import { httpService } from '@http/service';
import { ApiPaginateParams, buildQs } from '@http/shared';
import {
  AddExternalCompanyRequest,
  CompanyDetailsApi,
  CompanyDetailsBody,
  CompanyFullAPI,
  CompanyResponseTotalRecords,
  CompanySearchBody,
  FkkoGetRequestModel,
  TypeMove,
  AddCompanyContractRequest,
  CompanyShortContactorsAPI,
  AddCompanyProductionAreaRequest,
  ProductionAreaBody,
  ProductionAreasResponse,
  CompanyAdditional as CompanyAdditional,
  CompanySalesBody,
  CompanySale,
  AddCompanySaleRequest,
  CompanyCreateUpdateRequestModel,
  AddBalanceCompany,
  SubTrackBalanceCompany,
  UpdateCompanyContractRequest,
} from '@http/models/api/company';
import { HttpOptions } from '@http/service/options';
import { Nullable } from 'types';
import { UserCompanyUpdateRequest } from '@http/models/api/user';

interface Params {
  body: CompanySearchBody;
  paginate?: ApiPaginateParams;
}

export const searchCompaniesApi = ({ body, paginate }: Params) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });

  return httpService.get<CompanyResponseTotalRecords>(buildQs('/company/search', { ...body, ...paginate }), options);
};

export const getCompanyDetails = ({ id, ...rest }: CompanyDetailsBody) => {
  const options = new HttpOptions().withCredentials(false);
  return httpService.get<CompanyDetailsApi>(buildQs(`/company/short/${id}`, rest), options);
};

export const getCompany = (id: number | string, authToken?: Nullable<string>) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  if (authToken) {
    options.setHeader('Authorization', `Bearer ${authToken}`);
  }
  return httpService.get<CompanyFullAPI>(buildQs(`/company/${id}`), options);
};

export const getCompanyAdditional = (companyId: number | string) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<CompanyAdditional>(buildQs(`/companyadditional/${companyId}`), options);
};

export const verifiedCompany = (id: number, verifiedCode: string, success: boolean) => {
  return httpService.get(
    buildQs(`/company/${id}/verified?verifiedCode=${verifiedCode}&success=${success}`),
    new HttpOptions().withCredentials(false)
  );
};

export const setCommentCompany = (id: number, comment: string) => {
  return httpService.post(buildQs(`/company/${id}/comment`), { comment: comment }, new HttpOptions().asAuthRoute());
};

export const setCompanyFkkoType = (wasteCodeId: number, type: TypeMove[], companyId: number) => {
  return httpService.post(buildQs(`/company/fkko`), { wasteCodeId, type, companyId }, new HttpOptions().asAuthRoute());
};

export const deleteCompanyFkkoType = (id: number, codeId: number) => {
  return httpService.delete(
    `/Company/${id}/fkko/${codeId}`,
    null,
    new HttpOptions().withCredentials(false).asAuthRoute()
  );
};

export const getCompanyFkko = (id: number) => {
  return httpService.get<FkkoGetRequestModel>(`/FKKO/Company/${id}`, new HttpOptions().asAuthRoute());
};

export const getCompanyContractors = (id: number) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.get<CompanyShortContactorsAPI>(buildQs(`/company/${id}/contragent`), options);
};

export const addExternalCompany = (body: AddExternalCompanyRequest) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.post<number>(`/company/external`, body, options);
};

export const addCompanyContractor = (body: { userCompanyId: number; contractorId: number }) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.post<number>(`/company/${body.userCompanyId}/contragent/${body.contractorId}`, {}, options);
};

export const deleteCompanyContractor = (body: { userCompanyId: number; contractorId: number }) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.delete(`/company/${body.userCompanyId}/contragent/${body.contractorId}`, {}, options);
};

export const addCompanyContract = (body: AddCompanyContractRequest) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.post(`/company/${body.userCompanyId}/contract`, body, options);
};

export const deleteCompanyContract = (body: { userCompanyId: number; contractorId: number; contractId: number }) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.delete<number>(
    `/company/${body.userCompanyId}/contragent/${body.contractorId}/contract/${body.contractId}`,
    {},
    options
  );
};

export const getCompanyContractor = (body: { userCompanyId: number; contractorId: number }) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.get<CompanyShortContactorsAPI>(
    `/company/${body.userCompanyId}/contragent/${body.contractorId}`,
    options
  );
};

export const addCompanyProductionArea = (body: AddCompanyProductionAreaRequest) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.post<number>(`/productionareas`, body, options);
};

export const getCompanyProductionAreas = (body: ProductionAreaBody) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.get<ProductionAreasResponse>(buildQs(`/productionareas`, body), options);
};

export const deleteCompanyProductionArea = (productionAreaId: number) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.delete(`/productionareas/${productionAreaId}`, {}, options);
};

export const getCompanySales = (body: CompanySalesBody) => {
  const options = new HttpOptions();
  return httpService.get<CompanySale[]>(buildQs(`/api/sales`, { companyId: body.companyId, size: 100500 }), options);
};

export const addCompanySale = (body: AddCompanySaleRequest) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.post<number>('/api/sales/createsales', body, options);
};

export const deleteCompanySale = (id: number) => {
  const options = new HttpOptions().asAuthRoute();
  return httpService.delete(`/api/sales/${id}`, {}, options);
};
export const userCompanyUpdate = (model: UserCompanyUpdateRequest) => {
  return httpService.post(`/company/me`, model, new HttpOptions().asAuthRoute());
};

export const getUserCompanies = (companyIds: number[]) =>
  Promise.all(
    companyIds.map(companyId =>
      httpService.get<CompanyFullAPI>(`/company/${companyId}`, new HttpOptions().asAuthRoute())
    )
  );

export const userCreateCompany = (model: CompanyCreateUpdateRequestModel) =>
  httpService.post('/company', model, new HttpOptions().asAuthRoute());

export const userAddExistCompany = (companyId: number) =>
  httpService.post(`/company/${companyId}/adduser`, null, new HttpOptions().asAuthRoute());

export const addBalanceCompany = (body: AddBalanceCompany) =>
  httpService.post(`/company/addBalance`, body, new HttpOptions().asAuthRoute());

export const subTrackBalanceCompany = (body: SubTrackBalanceCompany) =>
  httpService.post(`/company/subtractBalance`, body, new HttpOptions().asAuthRoute());

export const updateCompanyContract = (body: UpdateCompanyContractRequest) => {
  const options = new HttpOptions().asAuthRoute({ strict: false });
  return httpService.post(`/company/${body.userCompanyId}/updateContract`, body, options);
};
