/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { AbortController } from 'node-abort-controller';

interface PollingProps {
  pollingAction: (controller: AbortController) => Promise<void>;
  duration: number;
}

export function usePoolingEffect<B>(props: PollingProps, deps: B[]): void {
  const func = () => {
    const controller = new AbortController();
    const { pollingAction, duration } = props;
    pollingAction(controller);

    const polling = setInterval(() => {
      pollingAction(controller);
    }, duration) as any;

    // returned function is executed on unmount, as was used in class-based components
    return (): void => {
      controller.abort();
      clearInterval(polling);
    };
  };
  React.useEffect(func, deps);
}
