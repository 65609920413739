import styled from 'styled-components';

interface StyledImageProps {
  $loaded?: boolean;
}

export const Container = styled.div``;

export const StyledImage = styled.img<StyledImageProps>`
  width: 183px;
  min-width: 183px;
  height: 253px;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.grey.light};
  opacity: ${({ $loaded }) => ($loaded ? 1 : 0)};
  transition: opacity 0.2s ease;
  object-fit: cover;
`;
