import { wordsBlackList, wordsWhiteList } from '@shared/validation/words-black-list';

export const testBlackListWords = (item?: string) => {
  if (!item) {
    return true;
  }

  if (wordsWhiteList.find(whiteWord => item.includes(whiteWord))) {
    return true;
  }

  return !wordsBlackList.find(blackWord => item.includes(blackWord));
};
