import useWindowSize from '@react-hook/window-size';
import { FC, useEffect, useRef, useState } from 'react';
import { DescriptionContainer, DescriptionContent, MoreButton } from './styled';
import { useScreenSize } from '@shared/hooks/use-screen-size';

const MAX_LINES = 6;
const MAX_LINES_MOBILE = 11;
const NOT_TRUNCATE = -1;

export interface TextTruncatedProps {
  value: string;
}

export const TextTruncated: FC<TextTruncatedProps> = ({ value }) => {
  const windowSize = useWindowSize();
  const { isDesktop } = useScreenSize();

  const maxTextLines = isDesktop ? MAX_LINES : MAX_LINES_MOBILE;
  const ref = useRef<HTMLDivElement>(null);
  const [textTruncated, setTextTruncated] = useState(false);
  const [textLines, setTextLines] = useState(maxTextLines);

  const moreButtonText = textLines !== NOT_TRUNCATE ? 'Показать еще' : 'Свернуть';
  const isButtonShown = textLines === NOT_TRUNCATE || textTruncated;

  useEffect(() => {
    setTextLines(maxTextLines);
  }, [maxTextLines]);

  const isEllipsisActive = (containerEl: HTMLDivElement) => {
    return containerEl.offsetHeight < containerEl.scrollHeight || containerEl.offsetWidth < containerEl.scrollWidth;
  };

  useEffect(() => {
    setTruncatedParams();
  }, [ref.current, windowSize]);

  const setTruncatedParams = () => {
    if (ref.current && isEllipsisActive(ref.current)) {
      setTextTruncated(true);
    } else {
      setTextTruncated(false);
    }
  };

  const handleMoreButtonClick = () => {
    if (textLines !== NOT_TRUNCATE) {
      setTextLines(NOT_TRUNCATE);
    } else {
      setTextLines(maxTextLines);
    }
  };

  return (
    <DescriptionContainer>
      <DescriptionContent ref={ref} lines={textLines}>
        {value}
      </DescriptionContent>
      {isButtonShown && (
        <MoreButton variant="text" onClick={handleMoreButtonClick}>
          {moreButtonText}
        </MoreButton>
      )}
    </DescriptionContainer>
  );
};
