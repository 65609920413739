import styled from 'styled-components';
import { CardWrapper } from '@theme/shared/components';

export const Wrap = styled(CardWrapper)`
  ${({ theme }) => theme.utils.media.moreThanMobile`
    column-gap: ${({ theme }) => theme.spaces.m};
    padding: ${({ theme }) => theme.spaces.m};
    margin-top: ${({ theme }) => theme.spaces.m};
  `}
`;

export const Row = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
  // display: flex;
  // align-items: center;
  // column-gap: ${({ theme }) => theme.spaces.m};
  // margin-bottom: 0;

  //   &:last-child {
  //     margin-bottom: 0;
  //   }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  `}
`;

export const Label = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: ${({ theme }) => theme.spaces.xs};

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-bottom: 0;
  `}
`;

export const ColLeft = styled.div`
  ${({ theme }) => theme.utils.media.moreThanMobile`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
  `}
`;

export const ColRight = styled.div`
  div:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`

    span:first-child {
      width: 162px;
    }
  `}
`;

export const SectionWrap = styled.div`
  margin-bottom: ${({ theme: { spaces } }) => `${spaces.s}`};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const LabelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  max-width: 180px;
`;

export const Bages = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spaces.s};
  row-gap: ${({ theme }) => theme.spaces.xs};
  margin-bottom: ${({ theme }) => theme.spaces.s};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CopyUrlWrapper = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.main.lightBlue};

  &:hover {
    opacity: 0.8;
  }
`;

export const LicensesTabLink = styled.a`
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.main.lightBlue};

  a:hover {
    opacity: 0.8;
  }
`;
