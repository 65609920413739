import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

export interface WrapperTitleProps {
  withTitle: boolean;
  alignItems: CSSProperties['alignItems'];
}

interface HeaderButtonsWrapperProps {
  customButtonsStyles?: boolean;
}

export const WrapperTitle = styled.div<WrapperTitleProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;

  & > div {
    margin-left: ${({ withTitle }) => (withTitle ? 'inherit' : 'auto')};
  }
`;

export const LoginButtons = styled.div`
  display: flex;
  margin-left: 18px;
`;

export const HeaderButtonsWrapper = styled.div<HeaderButtonsWrapperProps>`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spaces.s};

  ${({ customButtonsStyles }) =>
    customButtonsStyles &&
    css`
      flex-wrap: wrap;
      max-width: 250px;
      row-gap: 4px;
      justify-content: flex-end;

      & > div:last-child {
        margin-left: auto;
      }

      ${({ theme }) => theme.utils.media.largeDesktop`
        max-width: none;
        flex-wrap: nowrap;
        justify-content: flex-start;

        & > div:last-child {
          margin-left: 0;
        }
    `}
    `}
`;
