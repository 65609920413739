import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';

import { CircularProgress } from '@material-ui/core';
import {
  CancelButton,
  ConfirmButton,
  Content,
  DropzoneCaption,
  DropzoneWrapper,
  ModalFooter,
  Wrapper,
  CropZonesWrapper,
  CameraIcon,
} from './styled';
import { MainCropper } from './MainCropper';
import { MiniatureCropper } from './MiniatureCropper';
import { ImageThumbnails } from './ImageThumbnails';
import { AddedFile, FileUploaderProps, UploadedFilesParams } from './types';
import { Text } from '@theme/shared/text';
import { useNotifications } from 'providers/notifications';
import { toBytes } from '@shared/utils/helpers';
import { Nullable } from 'types';
import { Modal } from '@components/Modal';
import { MarginWrapper } from '@theme/shared/wrappers';

const MAX_FILE_SIZE = 10;
const MAX_FILES = 10;

export const FileUploader: FC<FileUploaderProps> = ({
  title,
  dropzoneText,
  dropzoneCaption,
  isOpen,
  withCrop,
  singleImage,
  isFething,
  maxSize = MAX_FILE_SIZE,
  progressCount = 0,
  onClose,
  onSave,
}) => {
  const notification = useNotifications();
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [addedFiles, setAddedFiles] = useState<AddedFile[]>([]);
  const [imageProcessing, setImageProcessing] = useState<boolean>();

  const [initialImageUrl, setImage] = useState('');
  const [mainCropper, setMainCropper] = useState<Nullable<Cropper>>(null);
  const [miniatureCropper, setMiniatureCropper] = useState<Nullable<Cropper>>(null);

  const isLoading = isFething || imageProcessing;

  const onDropAccepted = useCallback(acceptedFiles => {
    setAcceptedFiles(acceptedFiles);
  }, []);

  const sortedAddedFiles = useMemo(() => {
    return addedFiles.sort((a, b) => a.order - b.order);
  }, [addedFiles]);

  const onDropRejected = useCallback((rejections: FileRejection[]) => {
    const errorsMap: Record<string, FileError> = {};

    rejections?.forEach(rejection => {
      rejection.errors.forEach(error => {
        if (!errorsMap[error.code]) {
          errorsMap[error.code] = error;
        }
      });
    });

    showFileErrors(errorsMap);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/jpeg': [], 'image/png': [] },
    maxSize: toBytes(maxSize, 'MB'),
    multiple: !singleImage,
    maxFiles: MAX_FILES,
    onDropAccepted,
    onDropRejected,
  });

  const getCropData = (cropper: Nullable<Cropper>): Promise<Nullable<Blob>> => {
    return new Promise(res => {
      setImageProcessing(true);
      cropper?.getCroppedCanvas().toBlob(data => {
        res(data);
        setImageProcessing(false);
      }, addedFiles[0].blob.type);
    });
  };

  useEffect(() => {
    if (acceptedFiles.length) {
      filesToImages();
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (!isOpen) {
      setAddedFiles([]);
      setImage('');
    }
  }, [isOpen]);

  const showFileErrors = (errorsMap: Record<string, FileError>) => {
    Object.values(errorsMap).forEach(error => {
      if (error.code === 'too-many-files') {
        notification.push({
          text: `Максимальное количество одновременно загружаемых файлов - ${MAX_FILES}`,
          severity: 'error',
        });
      } else if (error.code === 'file-too-large') {
        notification.push({ text: `Размер загружаемого файла превышает ${maxSize} MB`, severity: 'error' });
      } else {
        notification.push({ text: `Ошибка при загрузке файла: ${error.message}`, severity: 'error' });
      }
    });
  };

  const filesToImages = async () => {
    const images = await readAsDataURL();

    const fileItems: AddedFile[] = acceptedFiles.map((file, idx) => ({
      id: String(idx),
      order: idx,
      src: images[idx],
      blob: file,
    }));
    setAddedFiles(fileItems);
    setImage(fileItems[0].src);
  };

  const fileToDataURL = (file: File): Promise<string> => {
    const reader = new FileReader();
    return new Promise(function (resolve) {
      reader.addEventListener('load', () => resolve(reader.result?.toString() || ''));

      reader.readAsDataURL(file);
    });
  };

  const readAsDataURL = async () => {
    const images = await Promise.all(acceptedFiles.map(fileToDataURL));
    return images;
  };

  const handleCloseClick = () => {
    onClose();
  };

  const handleSave = async () => {
    const uploadedFiles: UploadedFilesParams = {};

    if (singleImage) {
      const croppedMainImage = await getCropData(mainCropper);
      if (croppedMainImage) {
        uploadedFiles.singleImage = croppedMainImage;
      }

      if (withCrop) {
        const croppedMiniatureImage = await getCropData(miniatureCropper);
        if (croppedMiniatureImage) {
          uploadedFiles.cropedMiniature = croppedMiniatureImage;
        }
      }
    } else {
      uploadedFiles.multipleFiles = sortedAddedFiles.map(file => file.blob);
    }

    onSave(uploadedFiles);
  };

  const handleDeleteThumbnail = (fileIdx: number) => {
    setAddedFiles(files => files.filter((_, idx) => idx !== fileIdx));
  };

  const renderConfirmButton = () => {
    return (
      <ConfirmButton disabled={!addedFiles.length || isLoading} onClick={handleSave}>
        {isLoading && progressCount > 0 ? (
          <>
            <MarginWrapper marginRight="m">
              <CircularProgress size={25} />
            </MarginWrapper>
            {`${progressCount} из ${addedFiles.length}`}
          </>
        ) : (
          'Сохранить'
        )}
      </ConfirmButton>
    );
  };

  return (
    <Modal title={title} open={isOpen} onClose={onClose} modalContentPaddings={{ default: '32px' }} maxWidth="1200px">
      <Wrapper>
        <Content>
          {initialImageUrl && singleImage ? (
            <CropZonesWrapper>
              {singleImage && (
                <MainCropper
                  image={initialImageUrl}
                  cropperInstance={mainCropper}
                  onCropperInitialized={setMainCropper}
                />
              )}
              {withCrop && (
                <MiniatureCropper
                  image={initialImageUrl}
                  cropperInstance={miniatureCropper}
                  onCropperInitialized={setMiniatureCropper}
                />
              )}
            </CropZonesWrapper>
          ) : (
            <>
              <DropzoneWrapper {...getRootProps({ isDragActive })}>
                <input {...getInputProps()} />
                <CameraIcon name="camera-in-circle.solid" />
                <Text.MenuCaptionBold>{dropzoneText}</Text.MenuCaptionBold>
                {dropzoneCaption && <DropzoneCaption>{dropzoneCaption}</DropzoneCaption>}
              </DropzoneWrapper>
              {!singleImage && sortedAddedFiles.length > 0 && (
                <MarginWrapper marginTop="m">
                  <ImageThumbnails
                    files={sortedAddedFiles}
                    onChangeOrder={reorderedFiles => setAddedFiles(reorderedFiles)}
                    onDelete={handleDeleteThumbnail}
                  />
                </MarginWrapper>
              )}
            </>
          )}
        </Content>
        <ModalFooter>
          <CancelButton variant="outlined" onClick={handleCloseClick} disabled={isLoading}>
            Отмена
          </CancelButton>
          {renderConfirmButton()}
        </ModalFooter>
      </Wrapper>
    </Modal>
  );
};
