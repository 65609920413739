import styled from 'styled-components';
import { Text } from '@theme/shared/text';

export const TabsWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.s};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      padding: 0 26px;
      margin-top: ${({ theme }) => theme.spaces.s};
  `}
`;

export const StyledTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ theme }) => theme.spaces.xs};
`;

export const TabCounter = styled(Text.MenuCaptionBold)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.main.primary};
  color: ${({ theme }) => theme.palette.grey.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
