import { CompanyServiceForm } from './CompanyServiceForm';
import { SaleCardListMobile } from './mobile/SaleCardListMobile';
import { SaleItemList } from './SaleItemList';
import { useCompanySalesHandlers } from './hooks/company-sales';
import { SalesListWrapper } from './styled';
import { CompanyViewModel } from '@http/models/view-models/company';
import { useScreenSize } from '@shared/hooks/use-screen-size';

interface Props {
  company: CompanyViewModel;
  isShowCompanyTitle?: boolean;
}

export const CompanySales: React.FC<Props> = ({ company }) => {
  const { isMobile } = useScreenSize();
  const {
    companySales,
    isUserCanEdit,
    itemToEdit,
    formOpened,
    isDeleteLoading,
    handleAddSale,
    handleDelete,
    handleEditItem,
    handleFormClose,
    handleOnSaleAdded,
  } = useCompanySalesHandlers(company);

  const renderList = () => {
    if (isMobile) {
      return (
        <SaleCardListMobile
          sales={companySales}
          hasEditControls={isUserCanEdit}
          handleAddSale={handleAddSale}
          onEdit={handleEditItem}
          onDelete={handleDelete}
        />
      );
    }

    return (
      <SalesListWrapper>
        <SaleItemList
          sales={companySales}
          hasEditControls={isUserCanEdit}
          onClickAdd={handleAddSale}
          onEditItemClick={handleEditItem}
          onDeleteClick={handleDelete}
        />
      </SalesListWrapper>
    );
  };

  return (
    <>
      {renderList()}
      <CompanyServiceForm
        isOpen={formOpened}
        onClose={handleFormClose}
        companyId={company.id}
        editingSale={itemToEdit}
        isLoading={isDeleteLoading}
        afterAdded={handleOnSaleAdded}
        onDelete={handleDelete}
      />
    </>
  );
};
