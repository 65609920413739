import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import { LinkWrap } from './styled';
import { Icon } from '@components/Icon';
import { ColorTheme } from '@theme/types';
import { IconName } from '@components/Icon/types';

export interface SupportIconLinkProps {
  colorTheme?: ColorTheme;
  text: string;
  iconName: IconName;
  link: string;
}

export const NavigationLink: React.FC<SupportIconLinkProps> = ({ text, link, iconName, colorTheme = 'dark' }) => {
  const router = useRouter();

  return (
    <Link href={link} passHref>
      <LinkWrap colorTheme={colorTheme} activeRoute={link === router.pathname}>
        <Icon name={iconName} />
        {text}
      </LinkWrap>
    </Link>
  );
};
