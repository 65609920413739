import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';

export const Wrapper = styled(BoxWrapper)`
  margin-top: ${({ theme }) => theme.spaces.m};

  ${({ theme }) => theme.utils.media.moreThanMobile`
     padding: ${({ theme }) => theme.spaces.m};
  `}
`;

export const LinkWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xs};

  & button {
    padding-left: 0;
  }

  & button:not(:disabled):hover {
    background: none;
  }
`;
