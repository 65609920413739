import { FC } from 'react';
import { Https } from '@material-ui/icons';
import { PrivateWrap } from './styled';
import { Text } from '@theme/shared/text';
import { MarginWrapper } from '@theme/shared/wrappers';

export const PrivateOrderLabel: FC = () => (
  <PrivateWrap>
    <Https fontSize="small" />
    <MarginWrapper marginLeft="xs">
      <Text.Body>Приватная заявка</Text.Body>
    </MarginWrapper>
  </PrivateWrap>
);
