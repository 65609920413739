import { Wrapper, Title, Description } from './styled';
import Image403 from './Components/Image403.svg';
import { NextPageFC } from 'types';
import { MainLayout } from '@components/main-layout';
import { Button } from '@components/Button';

const Page403: NextPageFC = () => (
  <Wrapper>
    <Image403 />
    <Title>В доступе отказано!</Title>
    <Description>Просмотр данной заявки доступен только авторизованным заказчику или испольнителю</Description>
    <Button href="/">На главную</Button>
  </Wrapper>
);

Page403.layout = ({ children }) => MainLayout({ children, topMenuProps: { title: null } });
Page403.headProps = {
  title: 'Kritod.ru - Отказано в доступе',
};

export { Page403 };
