import { NextPageContext } from 'next';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';
import { getUserTokenByContext } from '@shared/utils/getUserTokenByContext';
import { getUser } from '@http/endpoints/user';
import { USER_AUTH_COOKIE_KEY } from '@constants/index';
import { routes } from 'routes';
import { isAxiosError } from '@shared/utils/isAxiosError';
import { refreshToken } from '@http/endpoints/oauth';
import { AUTH_REJECT_CODES } from '@http/service';
import { parseApiErrors } from '@http/shared';

export const getUserDataOrLogOut = async (ctx: NextPageContext) => {
  const bearerToken = getUserTokenByContext(ctx);

  let userData = null;

  if (bearerToken) {
    try {
      userData = await getUser(bearerToken);
    } catch (err) {
      const apiErrors = parseApiErrors(err);
      // TODO: to delete after tests
      Sentry.captureException(err.message || 'getUserDataOrLogOut', {
        extra: {
          apiErrors,
          json: JSON.parse(JSON.stringify(err)),
        },
        tags: {
          label: 'getUserDataOrLogOut',
        },
      });
      if (
        (isAxiosError(err) && err.response && AUTH_REJECT_CODES.includes(err.response.status)) ||
        AUTH_REJECT_CODES.includes(err.status)
      ) {
        try {
          const response = await refreshToken(bearerToken);
          const date = new Date(new Date().getTime() + 365 * 864e5);
          ctx.res?.setHeader('Set-Cookie', [
            `${USER_AUTH_COOKIE_KEY}=${response.bearer};expires=${date.toUTCString()};path=/;samesite=Lax`,
          ]);
          userData = await getUser(response.bearer);

          return userData;
        } catch (_err) {
          // TODO: to delete after tests
          Sentry.captureException(err.message || 'error refresh token SSR', {
            extra: {
              apiErrors,
              json: JSON.parse(JSON.stringify(_err)),
            },
            tags: {
              label: 'refreshTokenSSR',
            },
          });
          // silence
        }
      }

      logOut(ctx);
    }
  }

  return userData;
};

function logOut(ctx: NextPageContext) {
  if (ctx.res?.headersSent) {
    return;
  }

  ctx.res?.setHeader('Set-Cookie', [`${USER_AUTH_COOKIE_KEY}=deleted; Max-Age=0`]);
  ctx.res?.writeHead(302, { Location: routes.login });
  ctx.res?.end();
}

export function saveBearerCookie(bearer: string) {
  Cookies.set(USER_AUTH_COOKIE_KEY, bearer, { expires: 365, path: '/', samesite: 'Lax', secure: true });
}

export function getCookiesUserAuth() {
  return Cookies.get(USER_AUTH_COOKIE_KEY);
}
