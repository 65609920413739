import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { routes } from '../../routes';
import { config } from '../../config';
import { HttpBaseService } from '@http/service/base-service';
import { USER_AUTH_COOKIE_KEY } from '@constants/index';
import { isClient } from '@shared/utils/is-client';
import { getCookiesUserAuth, saveBearerCookie } from '@shared/userAuth';
import { isAxiosError } from '@shared/utils/isAxiosError';
import { refreshToken } from '@http/endpoints/oauth';
import { parseApiErrors } from '@http/shared';

export const AUTH_REJECT_CODES = [401, 403];
let isRefreshing = false;
let subscribers: Array<(token: string) => void> = [];

function onRrefreshed(authorisationToken: string) {
  subscribers.map(cb => cb(authorisationToken));
  subscribers = [];
}

function subscribeTokenRefresh(cb: (token: string) => void) {
  subscribers.push(cb);
}

export class HttpService extends HttpBaseService {}

const instance = axios.create({
  baseURL: process.env.NEXT_PROXY_URL ? `${config().PUBLIC_HOST}/api` : config().API_URL,
});

instance.interceptors.request.use(
  async config => {
    if (isClient && config.customParams?.isAuthRoute) {
      const token = getCookiesUserAuth();

      if (token) {
        if (!config.headers) {
          config.headers = {};
        }

        config.headers['Authorization'] = `Bearer ${token}`;
      }

      if (!token && config.customParams?.isStrictAuth) {
        location.href = routes.login;
        // todos(aolenin) bugtrack
        throw new Error(`[Http Service] Required token ${USER_AUTH_COOKIE_KEY}`);
      }
    }

    return config;
  },
  err => Promise.reject(err)
);

instance.interceptors.response.use(
  res => res,
  async error => {
    const apiErrors = parseApiErrors(error);
    // TODO: to delete after tests
    Sentry.captureException(error.message || 'instance.interceptors.response', {
      extra: {
        apiErrors,
        json: JSON.parse(JSON.stringify(error)),
      },
      tags: {
        label: 'instance.interceptors.response',
      },
    });
    if (isAxiosError(error) && isClient) {
      if (error.response && AUTH_REJECT_CODES.includes(+error.response.status)) {
        const originalRequest = error.config;
        let oldAuthToken: string | undefined = originalRequest.headers?.Authorization;

        if (!originalRequest.headers) {
          originalRequest.headers = {};
        }

        const retryOrigReq = new Promise(resolve => {
          subscribeTokenRefresh((token: string) => {
            originalRequest.headers!.Authorization = 'Bearer ' + token;
            return resolve(instance({ ...originalRequest }));
          });
        });

        if (!isRefreshing) {
          isRefreshing = true;

          if (oldAuthToken) {
            oldAuthToken = oldAuthToken.replace('Bearer ', '');

            try {
              const response = await refreshToken(oldAuthToken);
              saveBearerCookie(response.bearer);
              isRefreshing = false;
              originalRequest.headers.Authorization = 'Bearer ' + response.bearer;
              onRrefreshed(response.bearer);
            } catch (e) {
              location.href = routes.login;
              // TODO: to delete after tests
              Sentry.captureException(e.message || 'error refresh token Front', {
                extra: {
                  apiErrors,
                  json: JSON.parse(JSON.stringify(e)),
                },
                tags: {
                  label: 'refreshTokenSSR',
                },
              });
              return Promise.reject(e);
            }
          }
        }

        return retryOrigReq;
      }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export const httpService: HttpService = new HttpService(instance);
