import React, { FC } from 'react';
import { ExclamationIcon, Wrap, NoteWrapProps } from './styled';
import { Icon } from '@components/Icon';

export const Note: FC<NoteWrapProps> = ({ children, color }) => {
  return (
    <Wrap color={color}>
      <ExclamationIcon color={color}>
        <Icon name="exclamation.solid" size="m" />
      </ExclamationIcon>
      {children}
    </Wrap>
  );
};
