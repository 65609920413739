import { UseQueryOptions, useQuery, useMutation } from 'react-query';
import {
  getCompanyReviews,
  addCompanyReview,
  setReviewVisibility,
  deleteCompanyReview,
  getUserReviews,
} from '@http/endpoints/reviews';
import { CompanyReview, CompanyReviewResponse } from '@http/models/api/reviews';
import { getPagination } from '@http/shared';

interface GetCompanyReviewsParams {
  companyId: number;
  page?: number;
}

const SIZE_REVIEWS_RESULT = 10;

export const useCompanyReviews = (
  { companyId, page = 1 }: GetCompanyReviewsParams,
  options?: UseQueryOptions<any, any, CompanyReviewResponse>
) => {
  const paginate = getPagination(page, SIZE_REVIEWS_RESULT);

  return useQuery(
    ['company-reviews', companyId, page],
    () => getCompanyReviews({ companyId, isVisible: true, ...paginate }),
    {
      staleTime: Infinity,
      ...options,
    }
  );
};

export const useCompanyPendingReviews = (
  companyId: number,
  options?: UseQueryOptions<any, any, CompanyReviewResponse>
) => {
  return useQuery(
    ['company-pending-reviews', companyId],
    () => getCompanyReviews({ companyId, isVisible: false, size: 100500 }),
    {
      staleTime: Infinity,
      ...options,
    }
  );
};

export interface UserPendingReviewParams {
  userId: number;
  companyId: number;
}

export const useUserPendingReviews = (
  { userId, companyId }: UserPendingReviewParams,
  options?: UseQueryOptions<any, any, CompanyReview[]>
) => {
  return useQuery(['user-pending-reviews', userId, companyId], () => getUserReviews({ userId, isVisible: false }), {
    staleTime: Infinity,
    ...options,
  });
};

export const useCompanyAddReview = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(addCompanyReview, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useCompanyDeleteReview = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(deleteCompanyReview, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

export const useReviewSetVisibility = ({
  successHandler,
  errorHandler,
}: {
  successHandler: () => void;
  errorHandler: (e: unknown) => void;
}) =>
  useMutation(setReviewVisibility, {
    onSuccess: successHandler,
    onError: errorHandler,
  });
