import { CancelTokenSource, ResponseType } from 'axios';
import { AbortSignal, AbortController } from 'node-abort-controller';
import { AxiosProgressEvent } from './model';

export class HttpOptions {
  _headers: { [header: string]: string };
  _withCredentials: boolean;
  _responseType: ResponseType;
  _timeout: number;
  _cancelTokenSource: CancelTokenSource;
  _isAuthRoute: boolean;
  _isStrictAuth: boolean;
  _signal: AbortSignal;
  _skipAuthRefresh: boolean;
  _onUploadProgress: (event: AxiosProgressEvent) => void;

  constructor() {
    this._withCredentials = false;
    this._isAuthRoute = false;
    this._responseType = 'json';
    this._timeout = 10000;
    this._headers = {};
    this._skipAuthRefresh = false;
  }

  withCredentials(flag: boolean): HttpOptions {
    this._withCredentials = flag;
    return this;
  }

  responseType(type: ResponseType): HttpOptions {
    this._responseType = type;
    return this;
  }

  asAuthRoute(params?: { strict?: boolean }) {
    this._isAuthRoute = true;
    this._isStrictAuth = params?.strict || false; // TODO: согласовать с @daitonaaa
    return this;
  }

  timeout(n: number): HttpOptions {
    this._timeout = n;
    return this;
  }

  setHeader(name: string, value: string): HttpOptions {
    this._headers[name] = value;
    return this;
  }

  setCancelToken(source: CancelTokenSource): this {
    this._cancelTokenSource = source;
    return this;
  }

  setAbortController(controller: AbortController): this {
    this._signal = controller.signal;
    return this;
  }

  private setUploadProgress(onUpload: (event: AxiosProgressEvent) => void): this {
    this._onUploadProgress = onUpload;
    return this;
  }

  watchProgress(onUpload: (percent: number) => void): this {
    const handleProgres = (event: AxiosProgressEvent) => {
      onUpload((100 * event.loaded) / event.total);
    };
    this.setUploadProgress(handleProgres);
    return this;
  }

  skipAuthRefresh(flag: boolean): HttpOptions {
    this._skipAuthRefresh = flag;
    return this;
  }
}
