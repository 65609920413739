import styled from 'styled-components';

export const LicencesWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.m};
`;

export const LicenseStatusWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.xxs};
  align-items: center;
  font-size: ${({ theme }) => theme.textSizes.captionBold.size};
  font-weight: ${({ theme }) => theme.textSizes.captionBold.weight};

  ${({ theme }) => theme.utils.media.moreThanMobile`
      font-size: ${({ theme }) => theme.textSizes.bodyBold.size};
  `}

  &.active {
    color: ${({ theme }) => theme.palette.main.primary};
  }

  &.outdated {
    color: ${({ theme }) => theme.palette.system.error};
  }
`;
