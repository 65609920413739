import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

interface ImageProps {
  $loaded?: boolean;
}

interface LightboxItemProps {
  $loaded?: boolean;
}

export const StyledImage = styled.img<ImageProps>`
  opacity: ${({ $loaded }) => ($loaded ? 1 : 0)};
  transition: opacity 0.2s ease;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    left: initial;
    max-width: 330px;
  `}
`;

export const Wrapper = styled.div`
  width: 240px;
  min-width: 240px;
  height: 240px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
      width: 270px;
      min-width: 270px;
      height: 240px;
  `}
`;

export const DeleteButton = styled(IconButton)`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.palette.grey.white};
    border: 1px solid ${({ theme }) => theme.palette.main.primary};
    position: absolute;
    width: 28px;
    height: 28px;
    top: 4px;
    right: 4px;
    z-index: 10;
    color: ${({ theme }) => theme.palette.main.primary};
  }

  &.MuiButtonBase-root:hover {
    background: ${({ theme }) => theme.palette.main.primary};
    color: ${({ theme }) => theme.palette.grey.white};
  }
`;

export const LightboxItem = styled.div<LightboxItemProps>`
  pointer-events: ${({ $loaded }) => ($loaded ? 'auto' : 'none')};
  cursor: pointer;
`;
