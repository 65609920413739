import { CSSProperties } from 'react';
import styled from 'styled-components';
import { MOBILE_HEADER_HEIGHT } from '@components/main-layout/shared/constants';
import { ColorTheme } from '@theme/types';

interface WrapperProps {
  alignItems: CSSProperties['alignItems'];
}

export const Wrapper = styled.div<WrapperProps>`
  height: ${MOBILE_HEADER_HEIGHT}px;
  background: ${({ theme }) => theme.palette.grey.white};
  box-shadow: 0 4px 20px rgba(62, 83, 114, 0.08);
  z-index: 200;
  padding: ${({ theme }) => theme.spaces.xs};
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey.light};
`;

export const MenuButton = styled.div<{ buttonTheme?: ColorTheme }>`
  height: 30px;
  width: 30px;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: ${({ theme, buttonTheme }) =>
    buttonTheme === 'light' ? theme.palette.grey.white : theme.palette.main.primary};
  color: ${({ theme, buttonTheme }) =>
    buttonTheme === 'light' ? theme.palette.main.primary : theme.palette.grey.white};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
