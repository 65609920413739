import { MutableRefObject, useEffect, useRef } from 'react';
import { runOnClient } from '@shared/utils/is-client';
import { Nullable } from 'types';

export const useTimeout = (callback: () => void, delay: number): MutableRefObject<Nullable<number>> => {
  const timeoutRef = useRef<Nullable<number>>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    runOnClient(() => (timeoutRef.current = window.setTimeout(tick, delay)));
    return () => runOnClient(() => timeoutRef.current && window.clearTimeout(timeoutRef.current));
  }, [delay]);

  return timeoutRef;
};
