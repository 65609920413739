import React, { useEffect, useRef } from 'react';
import { Wrapper, RightContent, ContentWrapper, Content, UserCounterWrapper } from './styled';
import { LeftSidebar } from './left-sidebar';
import { MainLayoutTopMenuProps, TopMenu } from './top-menu';
import { UserCounter } from './shared/user-counter';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { Nullable } from 'types';
import { emitAppEvent } from 'events/use-app-event-broadcast';
import { AppEvents } from 'events/types';

interface Props {
  topMenuProps?: MainLayoutTopMenuProps;
  topMenu?: React.ReactNode;
  onLoadScrollTop?: boolean;
  noContentPaddingMobile?: boolean;
  renderContent?: React.ReactNode;
}

export const MainLayout: React.FC<Props> = ({
  topMenuProps,
  noContentPaddingMobile,
  children,
  onLoadScrollTop,
  renderContent,
}) => {
  const { isDesktop, isMobile, isTablet } = useScreenSize();
  const wrapRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    const element = wrapRef.current;
    if (onLoadScrollTop && element && element.scrollTop > 0) {
      element.scrollTo({
        top: 0,
        behavior: 'instant' as ScrollBehavior, // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
      });
    }
  }, [children, onLoadScrollTop]);

  useEffect(() => {
    const element = wrapRef.current;
    if (!element) return;

    const handleScroll = () => {
      emitAppEvent(AppEvents.scroll_layout);
    };

    element.addEventListener('scroll', handleScroll);

    return () => element.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Wrapper>
      {isDesktop && <LeftSidebar />}
      <RightContent>
        {renderContent ? (
          renderContent
        ) : (
          <>
            <TopMenu {...topMenuProps} />
            <ContentWrapper ref={wrapRef}>
              <Content noPaddingMobile={noContentPaddingMobile}>{children}</Content>
              <UserCounterWrapper>{(isMobile || isTablet) && <UserCounter />}</UserCounterWrapper>
            </ContentWrapper>
          </>
        )}
      </RightContent>
    </Wrapper>
  );
};

export const FluidTableLayout: React.FC<Props> = props => {
  return <MainLayout {...props} topMenuProps={{ ...props, isMenuHidden: true }} renderContent={props.children} />;
};
