import { SaleCard } from '../SaleCard';
import { AddButton } from '../../AddButton';
import { Container, Wrapper } from './styled';
import { CompanySale } from '@http/models/api/company';
import { Text } from '@theme/shared/text';

interface Props {
  sales?: CompanySale[];
  hasEditControls?: boolean;
  handleAddSale: () => void;
  onEdit: (sale: CompanySale) => void;
  onDelete: (id: number) => void;
}

export const SaleCardListMobile: React.FC<Props> = ({ sales, hasEditControls, handleAddSale, onEdit, onDelete }) => {
  const renderTitle = () => {
    return <Text.Header4>Услуги</Text.Header4>;
  };

  const renderHeaderBlock = () => {
    if (!hasEditControls) {
      return renderTitle();
    }

    return (
      <Wrapper>
        {renderTitle()}
        <AddButton onClick={handleAddSale} />
      </Wrapper>
    );
  };
  return (
    <Container>
      {renderHeaderBlock()}
      {sales?.map(sale => (
        <SaleCard key={sale.id} sale={sale} hasEditControls={hasEditControls} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </Container>
  );
};
