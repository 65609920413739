import React, { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';
import { DesktopMenu } from './desktop';
import { MobileMenu } from './mobile';
import { useScreenSize } from '@shared/hooks/use-screen-size';
import { Nullable } from 'types';
import { ColorTheme } from '@theme/types';

export const Wrapper = styled.div`
  padding-left: ${({ theme }) => theme.spaces.m};
  padding-right: ${({ theme }) => theme.spaces.m};
  padding-top: ${({ theme }) => theme.spaces.xs};
  padding-bottom: ${({ theme }) => theme.spaces.xs};
`;

export interface MainLayoutTopMenuProps {
  title?: Nullable<ReactNode>;
  isShowBackBtn?: boolean;
  colorTheme?: ColorTheme;
  isMenuHidden?: boolean;
  alignItems?: {
    desktop: CSSProperties['alignItems'];
  };
  customButtonsStyles?: boolean;
}

export const TopMenu = (props: MainLayoutTopMenuProps) => {
  const { isDesktop } = useScreenSize();

  if (props.isMenuHidden) {
    return null;
  }

  if (!isDesktop) {
    return <MobileMenu {...props} />;
  }

  return (
    <Wrapper>
      <DesktopMenu {...props} />
    </Wrapper>
  );
};
