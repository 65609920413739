import { Component } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ImageSvg } from './ImageSvg';
import { parseApiErrors } from '@http/shared';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e: any) {
    const apiErrors = parseApiErrors(e);
    Sentry.captureException(e, {
      extra: {
        apiErrors,
      },
      tags: {
        label: 'app-error',
      },
    });
    window.location.replace('/');

    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
            gap: '40px',
            width: '100%',
            height: '100vh',
          }}
        >
          <ImageSvg />
          <h1
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            Произошла ошибка
          </h1>
          <div style={{ fontSize: '20px', textAlign: 'center', width: '100%' }}>
            Мы уже знаем и устраняем неисправность, <br />
            приносим свои извинения за неудобства в ближайшее время всё начнёт работать. Сейчас мы загрузим главную
            страницу, если этого не произошло перейдите по ссылке ниже:
          </div>
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a
            href="/"
            style={{
              fontSize: '24px',
              textAlign: 'center',
              width: '100%',
              color: '#5ABFA1',
              cursor: 'pointer',
              textTransform: 'uppercase',
              fontWeight: '600',
            }}
          >
            На главную &#x279C;
          </a>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
