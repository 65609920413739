import styled from 'styled-components';
import { MarginWrapper } from '@theme/shared/wrappers';

export const PdfLinkWrapper = styled(MarginWrapper)`
  display: flex;
  align-items: center;

  .favorite {
    margin-left: auto;
  }

  .pdf-link {
    border-color: #e2574c;
    color: #e2574c;
    min-width: 190px;
  }

  .pdf-link:hover {
    background: #e2574c;
    color: ${({ theme }) => theme.palette.grey.white};

    svg path {
      fill: ${({ theme }) => theme.palette.grey.white};
    }

    svg path:last-child {
      fill: #e2574c;
    }
  }
`;
