import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0.5rem -1rem 1.25rem;
  position: relative;
`;

export const Inner = styled.div`
  overflow-x: auto;
  min-height: inherit;
  padding: 0 1rem;
`;
