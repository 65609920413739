import { Table, TableCell } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  min-width: 100px !important;

  .MuiTableCell-root {
    font-size: 12px;
  }

  & .MuiTableCell-root {
    padding: ${({ theme }) => theme.spaces.xxs} 0;
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    .MuiTableCell-root {
      font-size: 14px;
    }
  `}
`;

export const Label = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

export const MoveTypesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.xs};
  flex-wrap: wrap;
`;

export const MoveType = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledTableCell = styled(TableCell)``;
