import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { runOnClient } from '@shared/utils/is-client';

export const getPrevPagePath = (): string | null | undefined => {
  try {
    if (typeof window !== 'undefined' && window?.sessionStorage) {
      return window.sessionStorage.getItem('prevPath');
    }
  } catch {
    // silence
  }

  return undefined;
};

export const PrevPathSessionStorageSync = () => {
  const router = useRouter();
  useEffect(() => storePathValues, [router.asPath]);

  function storePathValues() {
    runOnClient(() => {
      try {
        const storage = window?.sessionStorage;
        if (!storage) return;
        const url = window.location.href;
        // Set the previous path as the value of the current path.
        const prevPath = storage.getItem('currentPath') || url;
        storage.setItem('prevPath', prevPath);
        // Set the current path value by looking at the browser's location object.
        storage.setItem('currentPath', url);
      } catch {
        // silence
      }
    });
  }

  return null;
};
