import { Field, FieldProps } from 'formik';
import { Rating, RatingProps } from 'react-simple-star-rating';
import { Error, RatingFieldWrapper, StyledRatingCaption } from './styled';

interface Props {
  name: string;
  ratingProps?: RatingProps;
}

const RatingField: React.FC<Props> = ({ name, ratingProps, ...rest }) => {
  return (
    <Field name={name}>
      {({ form, field }: FieldProps) => {
        const error = form.errors[field.name];
        return (
          <RatingFieldWrapper hasError={!!error}>
            <StyledRatingCaption>Ваша оценка: </StyledRatingCaption>
            <Rating
              onClick={r => form.setFieldValue(field.name, r)}
              initialValue={field.value}
              {...ratingProps}
              {...rest}
            />
            {error && <Error>{error}</Error>}
          </RatingFieldWrapper>
        );
      }}
    </Field>
  );
};

export default RatingField;
