import styled, { css } from 'styled-components';
import { ButtonProps } from '@components/Button/index';
import { ColorTheme } from '@theme/types';

interface Modes {
  variant: ButtonProps['variant'];
  fullWidth?: ButtonProps['fullWidth'];
  colorTheme?: ColorTheme;
  paddings?: string;
  display?: string;
}

export const LinkHTML = styled.a`
  &:hover {
    opacity: 1;
  }
`;

export const Wrapper = styled.span<Modes>`
  display: ${({ display }) => (display ? display : 'inline-flex')};
  align-items: center;
  justify-content: center;
  padding: ${({ paddings }) => (paddings ? paddings : '12px 16px')};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background: none;
  transition: background 0.1s ${({ theme }) => theme.animate.base};
  white-space: nowrap;

  ${({ theme, variant, colorTheme }) => {
    if (variant === 'filled') {
      if (colorTheme === 'light') {
        return css`
          color: ${theme.palette.main.primary};

          &:not(:disabled) {
            background: ${theme.palette.grey.white};

            &:hover {
              background: ${theme.palette.grey.light};
            }
          }

          &:disabled {
            background: ${theme.palette.grey.light};
          }
        `;
      }

      return css`
        color: ${theme.palette.grey.white};

        &:not(:disabled) {
          background: ${theme.palette.main.linear};

          &:hover {
            background: ${theme.palette.main.linearHover};
          }
        }

        &:disabled {
          background: ${theme.palette.grey.light};
        }
      `;
    }

    if (variant === 'outlined') {
      if (colorTheme === 'light') {
        return css`
          color: ${theme.palette.grey.white};
          border: 1px solid ${theme.palette.grey.white};
          background: none;

          &:hover {
            background: ${theme.palette.grey.white};
            color: ${theme.palette.main.primary};
          }
        `;
      }

      return css`
        color: ${theme.palette.main.primary};
        border: 1px solid ${theme.palette.main.primary};
        background: none;

        &:hover {
          background: ${theme.palette.main.primary};
          color: ${theme.palette.grey.white};
        }
      `;
    }

    if (variant === 'text') {
      return css`
        color: ${theme.palette.text.link};
        background: none;
      `;
    }

    return '';
  }}

  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        width: 100%;
      `;
    }

    return '';
  }}

  span:last-child {
    font-size: ${({ theme }) => theme.textSizes.buttonText.size};
    font-weight: ${({ theme }) => theme.textSizes.buttonText.weight};
    line-height: ${({ theme }) => theme.textSizes.buttonText.lineHeight};
    text-transform: ${({ theme }) => (theme.textSizes.buttonText.isUppercase ? 'uppercase' : 'initial')};
  }
`;

export const IconWrap = styled.span`
  align-items: center;
  display: flex;
  margin-right: ${({ theme }) => theme.spaces.xxs};
  min-width: 1rem;
  height: 1rem;
`;
