import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { Nullable } from '../../../types';
import { Icon } from '@components/Icon';
import { getPrevPagePath } from '@shared/PrevPathSessionStorageSync';
import { safe } from '@shared/utils/helpers';
import { Title } from '@components/main-layout/top-menu/Title';

const BackButtonStyled = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s ${({ theme }) => theme.animate.base};
  opacity: 0;
  transform: scale(0.2);
  margin-left: -20px;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      margin-left: 0;
      transform: scale(1);
    `}
`;

const ButtonBack = styled.div<{ isDisabled?: boolean }>`
  cursor: pointer;
  display: flex;
  gap: 5px;
  color: ${({ theme }) => theme.palette.main.accent};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
  &:hover {
    opacity: 0.7;
  }
`;

interface GoBackProps {
  isActive: boolean;
  title: React.ReactNode;
  isDisabled?: boolean;
}

export const GoBackTitle: React.FC<GoBackProps> = ({ isActive, title, isDisabled }) => {
  const { push, pathname } = useRouter();
  const [prevURL, setPrevURL] = useState<Nullable<URL> | undefined>(null);
  useEffect(() => {
    const prevPageHref = getPrevPagePath();
    if (prevPageHref) {
      safe(() => {
        setPrevURL(new URL(prevPageHref));
      });
    }
  }, [pathname]);

  const isActiveBack = Boolean(isActive && prevURL && pathname !== prevURL.pathname);

  const handleBack = (url: URL) => {
    if (!isActiveBack) {
      return;
    }

    push(url);
  };

  if (isActive && prevURL && pathname !== prevURL.pathname) {
    return (
      <ButtonBack isDisabled={isDisabled} tabIndex={isActiveBack ? 0 : -1} onClick={() => handleBack(prevURL)}>
        <BackButtonStyled isVisible={isActiveBack}>
          <Icon size={16} name="cheveron-left.outline" />
        </BackButtonStyled>
        <Title title={title} />
      </ButtonBack>
    );
  }

  return <>{title}</>;
};
