export const REG_EMAIL = 'reg_email';
export const CONTINUE = 'continue';
export const MODAL_ORDER_INFO = 'modal_order_info';
export const MODAL_COMPANY_INFO = 'modal_company_info';
export const MY_COMPANY_TABS = 'my_company_tabs';
export const NEWS_SELECTED_CATEGORY = 'categoryId';
export const LAYOUT_VIEW = 'layout_view';
export const USER_TABS = 'user_tabs';
export const COMPANY_TABS = 'company_tabs';
export const NEWS_SELECTED_ID = 'news_selected_id';
export const REPORT_TABS = 'report_tabs';
export const GET_WASTES_TABS = 'get_wastes_tabs';
export const SEND_WASTES_TABS = 'send_wastes_tabs';
export const PRODUCTION_TABS = 'production_tabs';
export const NEWS_PAGES_CATEGOIES_VIEW = 'news_pages_categories_view';
export const ACCOUNTING_VIEW = 'accounting_view';
