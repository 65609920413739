import React, { useEffect, useMemo, useState, useCallback } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Nullable } from '../../types';
import { UserCompanyViewModel, UserData, UserMeta } from '@http/models/view-models/user';
import { UserTypeApi } from '@http/models/api/user';
import { getUser, setUserMeta } from '@http/endpoints/user';
import { getCookiesUserAuth } from '@shared/userAuth';
import { parseApiErrors } from '@http/shared';
import { TariffPlan, isTariffExistInMap } from 'screens/Tariffs/tariffsMap';

export interface UserContextProps {
  data: Nullable<UserData>;

  exist(): boolean;

  isAdmin(): boolean;

  getCompany(): Nullable<UserCompanyViewModel>;

  updateMetadata(partialValue: Partial<UserMeta>): Promise<void>;

  refreshUserInfo(): Promise<void>;

  getUserSubName(): Nullable<TariffPlan>;
}

const Context = React.createContext<Nullable<UserContextProps>>(null);

interface Props {
  userData: Nullable<UserData>;
}

const getUserCompany = (user: UserData): Nullable<UserCompanyViewModel> => {
  return (user?.company && user.company[0]) ?? null;
};

export const UserProvider: React.FC<Props> = ({ userData, children }) => {
  const [user, setUser] = useState<Nullable<UserData>>(userData);

  const handleRefreshUserInfo = useCallback(async () => {
    if (!user) return;

    const token = getCookiesUserAuth();
    if (!token) {
      return;
    }
    try {
      const newUserData = await getUser(token);
      setUser({ ...user, ...newUserData });
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          apiErrors: parseApiErrors(e),
        },
        tags: {
          label: 'refreshUserInfo',
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
      Sentry.setUser({
        name: userData.nickname,
        id: userData.id.toString(),
        companyId: getUserCompany(userData)?.id,
        companyName: getUserCompany(userData)?.title,
      });
    }
  }, [userData]);

  const value = useMemo<UserContextProps>(() => {
    return {
      data: user,
      exist() {
        return !!user;
      },
      isAdmin(): boolean {
        return user?.type === UserTypeApi.ADMIN;
      },
      getCompany(): Nullable<UserCompanyViewModel> {
        return user && getUserCompany(user);
      },
      getUserSubName() {
        const tariff = user && getUserCompany(user)?.subName;
        if (tariff && isTariffExistInMap(tariff)) {
          return tariff;
        }
        return null;
      },
      async updateMetadata(partialValue: Partial<UserMeta>) {
        if (!user?.metaData) {
          return;
        }

        const newMeta = {
          ...user.metaData,
          ...partialValue,
        };

        await setUserMeta(newMeta);
        setUser({
          ...user,
          metaData: newMeta,
        });
      },
      refreshUserInfo: handleRefreshUserInfo,
    };
  }, [user, handleRefreshUserInfo]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useUser = () => React.useContext(Context) as UserContextProps;
