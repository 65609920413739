import styled, { keyframes } from 'styled-components';

const pulseKeyframe = keyframes`
  0% {
    transform: scale(.1);
    opacity: 0;
  }
  50% {
    opacity: .4;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
`;

const fadeAnimate = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

export const LoaderWrapper = styled.div`
  position: relative;
  animation: ${fadeAnimate} 2s 1;

  svg {
    width: 60%;
    height: 60%;
  }
`;

export const LoaderTick = styled.div`
  background-color: ${({ theme }) => theme.palette.main.primary};
  border-radius: 50%;
  position: absolute;
  margin: auto auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: inherit;
  height: inherit;

  &:nth-child(1) {
    animation: ${pulseKeyframe} 1.3s infinite;
  }

  &:nth-child(2) {
    animation: ${pulseKeyframe} 1.3s infinite 0.3s;
  }
`;
