import styled from 'styled-components';
import { rgba } from 'polished';
import { Text } from '@theme/shared/text';

export interface NoteWrapProps {
  color?: 'success' | 'info' | 'warning' | 'error';
}

export const Wrap = styled(Text.Caption)<NoteWrapProps>`
  background: ${({ theme, color }) => {
    switch (color) {
      case 'success':
        return rgba(theme.palette.main.primary, 0.4);
      case 'warning':
        return rgba(theme.palette.system.warning, 0.4);
      default:
        return rgba(theme.palette.system.warning, 0.4);
    }
  }};
  border: 1px solid;
  border-color: ${({ theme, color }) => {
    switch (color) {
      case 'success':
        return theme.palette.main.primary;
      case 'warning':
        return theme.palette.system.warning;
      default:
        return theme.palette.system.warning;
    }
  }};
  padding: ${({ theme }) => theme.spaces.s};
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spaces.m};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

export const ExclamationIcon = styled.span<NoteWrapProps>`
  display: flex;
  align-items: center;
  min-width: 25px;
  height: 20px;
  color: ${({ theme, color }) => {
    switch (color) {
      case 'success':
        return theme.palette.main.primary;
      case 'warning':
        return theme.palette.system.warning;
      default:
        return theme.palette.system.warning;
    }
  }};
  margin-right: ${({ theme }) => theme.spaces.s};
`;

export const NoteLink = styled.a`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
