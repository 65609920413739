import React, { FC } from 'react';
import { OrderRespondsContentOwnProps, OrderRespondsOwnProps } from './types';
import { SearchLoading } from '@components/SearchLoading';
import { SearchNotFound } from '@components/SearchNotFound';
import { ShortOffersList } from '@components/ShortOffersList';
import { useOffers } from '@http/hooks/offer';
import { MarginWrapper } from '@theme/shared/wrappers';

const OrderRespondsContent: FC<OrderRespondsContentOwnProps> = ({ orderId }) => {
  const { data, isLoading, isError } = useOffers({ orderId });

  if (isLoading) {
    return <SearchLoading />;
  }

  if (isError || !data || data.recods.length === 0) {
    return (
      <SearchNotFound title="Мы не смогли найти подходящих результатов" hint="Вероятно вы ещё не получали ответов" />
    );
  }

  return <ShortOffersList offers={data.recods} />;
};

export const OrderResponds: FC<OrderRespondsOwnProps> = ({ orderApi }) => (
  <MarginWrapper marginTop="l">
    <OrderRespondsContent orderId={orderApi.id} />
  </MarginWrapper>
);
