import styled from 'styled-components';
import { BoxWrapper } from '@theme/shared/components';
import { flexFullCenter } from '@theme/shared/utils';

export const Wrapper = styled(BoxWrapper)`
  ${flexFullCenter()}
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  color: ${({ theme }) => theme.palette.grey.middle};
`;

export const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  color: ${({ theme }) => theme.palette.grey.light};
`;
