import { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #__next {
    height: 100%;
  }

  body {
    color: ${({ theme }) => theme.palette.text.primary} !important;
    font-family: ${({ theme }) => theme.typography.fontFamily} !important;
    background: ${({ theme }) => theme.palette.grey.background} !important;
 
    button {
      background: none;
      color: inherit;
      cursor: pointer;
      border: none;
      box-shadow: none;
      padding: 0;
    }
    
    a,button {
      transition: 0.2s ease;
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.text.link};

      :hover {
        opacity: 0.8;
      }
    }
    
    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
      padding: 0;
    }
  
    .g-text-secondary {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
    
    .text-decoration-none {
      text-decoration: none;
    }
    
    a[id="custom-anchor"] {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.text.link};
    }

    // Animation css transition group
    .alert-enter {
      opacity: 0;
      transform: scale(0.9);
    }

    .alert-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }

    .alert-exit {
      opacity: 1;
    }

    .alert-exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms, transform 300ms;
    }

    .fade-enter {
      opacity: 0;
      transform: translateY(5px);
    }

    .fade-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 300ms, transform 300ms;
    }

    .fade-exit {
      opacity: 1;
    }

    .fade-exit-active {
      opacity: 0;
      transform: translateY(5px);
      transition: opacity 300ms, transform 300ms;
    }

    [tabindex="0"], a {
      &:hover,
      &:focus,
      &:active {
        outline: none;
      }
    }
    
    .blink {
      transition: 0.3s ${({ theme }) => theme.animate.base};
      
      &-active {
        pointer-events: none;

        background: ${({ theme }) => rgba(theme.palette.main.primary, 0.3)};
        color: ${({ theme }) => theme.palette.text.primary};
        * {
          color: ${({ theme }) => theme.palette.text.primary};
        }
      }
    }
    
    h1 {
      color: ${({ theme }) => theme.palette.main.accent};
    }
    
    .fader1 {
      opacity: .4;
      transform: scale(0.97);
      box-shadow: none;
      filter: blur(0.5px);
    }
    
    .fader-shadow {
      box-shadow: 3px -3px 12px 5px #3b3a3a17
    }

    .disableYearPicker .MuiToolbar-root .MuiButtonBase-root:first-child {
      pointer-events: none;
    }
  }
`;

export default GlobalStyle;
