import { withStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

export const StyledPaginataion = withStyles(theme => ({
  root: {
    border: 'none',
    '& .MuiPaginationItem-outlined': {
      border: 'none',
    },
    '& .Mui-selected': {
      background: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}))(Pagination);
