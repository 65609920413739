import { TypeMove } from './company';
import { Nullable } from 'types';

export interface OrderRequest {
  regionId: number;
  companyId: number;
  comment: Nullable<string>;
  dateEnd: Nullable<string>;
  status: OrderStatus;
  rows: OrderRequestRow[];
  isPrivate: boolean;
  url: Nullable<string>;
  companyIds: Nullable<number[]>;
}

export enum OrderStatus {
  currentPurchase = 1,
  marketSurvey,
  tender,
}

export interface OrderRequestRow {
  wasteCode: string;
  netto: number;
  comment: Nullable<string>;
  type: TypeMove[];
}

export interface OrderApi {
  id: number;
  url: Nullable<string>;
  regionTitle: string;
  companyName: string;
  companyId: number;
  comment?: string;
  status: OrderStatus;
  deletedAt: string;
  dateEnd?: string;
  isVerified: boolean;
  createdAt: string;
  rows: Nullable<OrderRowApi[]>;
  isMyOrder: boolean;
  countCP: number;
  isPrivate: boolean;
}

export interface OrderRowApi {
  netto: number;
  code: string;
  title: string;
  comment: string;
  type: TypeMove[];
  id: number;
}

export interface OrderSearchBody {
  titleOrInn: string;
  regionId: Nullable<number>;
  fkko: Nullable<string[]>;
  onlyMe: boolean;
  skip?: number;
  size?: number;
}

export const statusOrderText = {
  [OrderStatus.currentPurchase]: 'Текущая потребность',
  [OrderStatus.marketSurvey]: 'Опрос рынка',
  [OrderStatus.tender]: 'Тендер',
};

export interface ContactOrder {
  nickname: string;
  phone: string;
  title: string;
  inn: string;
}

export interface OrderOfferRequest {
  companyId: number;
  orderId: number;
  rows: OrderOfferRow[];
  comment: string;
  nds: boolean;
}

export interface OrderOfferRow {
  type: OrderOfferRowType;
  price: number;
  orderRowId: number;
  wasteCode: string;
}

export enum OrderOfferRowType {
  inLicense = 1,
  missAll = 2,
  inExtraFkko = 3,
}

export interface AddOfferFavorite {
  cpId: number;
}
