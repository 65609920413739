import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import { BoxWrapper } from '@theme/shared/components';
import { Text } from '@theme/shared/text';
import TextField from '@lib/Components/fields/TextField';
import { Button } from '@components/Button';

export const Wrapper = styled(BoxWrapper)`
  margin-bottom: ${({ theme }) => theme.spaces.m};
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: ${({ theme }) => theme.spaces.m} ${({ theme }) => theme.spaces.l};
  height: 100%;
  padding-bottom: ${({ theme }) => theme.spaces.xl};
`;

export const ContactInfoValue = styled.a`
  font-size: ${({ theme }) => theme.textSizes.header2.size};
  font-weight: ${({ theme }) => theme.textSizes.header2.weight};
  line-heigh: ${({ theme }) => theme.textSizes.header2.lineHeight};
  margin-top: ${({ theme }) => theme.spaces.s};
  width: 100%;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header1.size};
    font-weight: ${({ theme }) => theme.textSizes.header1.weight};
    line-heigh: ${({ theme }) => theme.textSizes.header1.lineHeight};
  `}
`;

export const ContactInfoTitle = styled(Text.Header4)`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 100%;

  svg {
    margin-right ${({ theme }) => theme.spaces.m};
  }
`;

export const WrapInfoItem = styled.div`
  margin-top ${({ theme }) => theme.spaces.xl};
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const PageLink = styled.a`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.m};
  width: 100%;

  span {
    width: 250px;
  }

  svg {
    margin-left: ${({ theme }) => theme.spaces.xxs};
  }

  ${({ theme }) => theme.utils.media.moreThanMobile`
    font-size: ${({ theme }) => theme.textSizes.header3.size};
    font-weight: ${({ theme }) => theme.textSizes.header3.weight};
    line-heigh: ${({ theme }) => theme.textSizes.header3.lineHeight};

    span {
      width: auto;
    }
  `}

  ${({ theme }) => theme.utils.media.desktop`
    margin-left: ${({ theme }) => theme.spaces.s};
    margin-top: ${({ theme }) => theme.spaces.xl};

    span {
      width: auto;
    }
  `}
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`;

export const FieldWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.s};
  width: 100%;
`;

export const TextFieldBorders = styled(TextField)`
  .MuiFilledInput-root {
    border-radius: ${({ theme }) => theme.shape.borderRadius};
  }

  & + p {
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius};
  }
`;

export const ErrorText = styled(Text.Caption)`
  display: block;
  margin-top: ${({ theme }) => theme.spaces.xxs};
  color: ${({ theme }) => theme.palette.system.error};
  text-align: center;
`;

export const SubmitButtonWithErrors = styled.div`
  margin-top: ${({ theme }) => theme.spaces.m};
  width: 100%;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    width: 270px;
    margin-left: auto;
  `}
`;

export const ButtonFlex = styled(Button)`
  & > div {
    display: flex;
    align-items: center;
  }
`;
