import { useRouter } from 'next/router';
import { CompanyServiceForm } from '../../CompanyServices/CompanyServiceForm';
import { MAX_SALES_SHOWN, SaleItemList } from '../../CompanyServices/SaleItemList';
import { useCompanySalesHandlers } from '../../CompanyServices/hooks/company-sales';
import { Wrapper, LinkWrapper } from './styled';
import { CompanyViewModel } from '@http/models/view-models/company';
import { routes } from 'routes';
import { Icon } from '@components/Icon';
import { Button } from '@components/Button';
import { MarginWrapper } from '@theme/shared/wrappers';

interface Props {
  company: CompanyViewModel;
  isShowCompanyTitle?: boolean;
}

export const CompanySalesSection: React.FC<Props> = ({ company }) => {
  const router = useRouter();

  const {
    companySales,
    itemToEdit,
    formOpened,
    isUserCanEdit,
    handleAddSale,
    handleDelete,
    handleEditItem,
    handleFormClose,
    handleOnSaleAdded,
  } = useCompanySalesHandlers(company);

  const companySalesRoute = routes.company.sales(String(company.id), 1);

  const onSaleAdded = () => {
    handleOnSaleAdded();
    router.push(companySalesRoute);
  };

  const salesQuantity = companySales?.length || 0;

  const renderSalesLink = () => {
    const overflown = salesQuantity > MAX_SALES_SHOWN;
    if (!isUserCanEdit && !overflown) {
      return null;
    }

    return (
      <MarginWrapper marginTop="m">
        {overflown ? (
          <LinkWrapper>
            <Button href={companySalesRoute} variant="text" startIcon={<Icon name="collection.solid" />}>
              Посмотреть все услуги
            </Button>
          </LinkWrapper>
        ) : null}
        {isUserCanEdit && (
          <MarginWrapper marginTop="m">
            <Button variant="outlined" onClick={handleAddSale} startIcon={<Icon name="plus.solid" />}>
              Добавить
            </Button>
          </MarginWrapper>
        )}
      </MarginWrapper>
    );
  };

  if (!salesQuantity) {
    return null;
  }

  return (
    <Wrapper>
      <SaleItemList
        sales={companySales}
        hasEditControls={false}
        hideAfterMax
        onClickAdd={handleAddSale}
        onEditItemClick={handleEditItem}
        onDeleteClick={handleDelete}
      />
      {renderSalesLink()}

      <CompanyServiceForm
        isOpen={formOpened}
        onClose={handleFormClose}
        companyId={company.id}
        editingSale={itemToEdit}
        afterAdded={onSaleAdded}
        onDelete={handleDelete}
      />
    </Wrapper>
  );
};
